import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalShareAccessToPatient.style";
import * as P from "../../../../../utils/pipes";

// Hooks
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput";
import ZaiaRoundInput from "../../../../zaiaComponents/zaiaRoundInput";
import { QUERY_GET_USERS } from "../../../../../graphql/queries/User";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import ProcessName from "../../../shared/processName";
import { QUERY_GET_DOCTORS_DATA } from "../../../../../graphql/queries/Doctor";
import useDoctorData from "../../../../../hooks/useDoctorData";
import { MUTATION_INSERT_ROLE_AND_DOCTOR_TO_PROCESS_ROLE, MUTATION_INSERT_DOCTOR_TO_PROCESS_ROLE } from "../../../../../graphql/mutations/Doctor";
import { QUERY_GET_PROCESS_ROLES_BY_ID } from "../../../../../graphql/queries/Process";


// Assets
const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalShareAccessToPatient({
  patientInfo,
  setModalActive
}) {

  const { t, i18n: { language } } = useTranslation("global");
  const [insertDoctorToProcessRole, { loading: insertDoctorToProcessRoleLoading }] = useMutation(MUTATION_INSERT_DOCTOR_TO_PROCESS_ROLE);
  const [insertRoleAndDoctorToProcessRole, { loading: insertRoleAndDoctorToProcessRoleLoading }] = useMutation(MUTATION_INSERT_ROLE_AND_DOCTOR_TO_PROCESS_ROLE);
  const [ getProcessRoleData ] = useLazyQuery(QUERY_GET_PROCESS_ROLES_BY_ID);
  const {doctorData} = useDoctorData();
  
  const { 
    data: patientsData,
    loading: loadingPatientsData,
    error: errorPatientsData,
  } = useQuery(
    QUERY_GET_USERS
  );

  const { 
    data: doctorsData,
    loading: loadingDoctorsData,
    error: errorDoctorsData,
  } = useQuery(
    QUERY_GET_DOCTORS_DATA,
    {
      variables: {
        doctorId: doctorData?.id,
        processId: patientInfo?.process?.id
      }
    }
  );

  const [formSuccess, setFormSuccess] = useState(null); 
  const [selectedDoctors, setSelectedDoctors] = useState([]);
  const [inputText, setInputText] = useState('');
  const [summaryActived, setSummaryActived] = useState(false); 

  const getFilterText = (text) => {
    return inputText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase())
      : true
  }

  const handleSubmit = () => {
    if(!summaryActived) {
      setSummaryActived(true);
      setInputText("")
    } else {
      insertProcessRoles();
    }
  }

  const insertProcessRoles = async () => {

    

    try {
      console.log(patientInfo);
      console.log(selectedDoctors);
      const { data } = await getProcessRoleData(
        {
          variables: {
            processId: patientInfo.process.id,
            roleId: "medical_staff"
          }
        }
      )

      console.log("dataaaaaa", data.processRole);

      if(data.processRole.length === 0) {
        await insertRoleAndDoctorToProcessRole({
          variables: {
            id: "medical_staff",
            processId: patientInfo.processId,
            roleName: { "en": "medical staff", "es": "personal médico" },
            doctorToProcessRoles: { data: selectedDoctors.map((doctor) => {
                return {
                  doctorId: doctor.id, 
                  // processId: patientInfo.processId, 
                  userToProcessId: patientInfo.id, 
                  // roleId: "medical_staff"
                }
              })
            }},
        });
      } else {
        await insertDoctorToProcessRole({
          variables: {
            doctorsInfo: selectedDoctors.map((doctor) => {
              return {
                doctorId: doctor.id, 
                processId: patientInfo.processId, 
                userToProcessId: patientInfo.id, 
                roleId: "medical_staff"
              }
            })
          },
        });
      }
      setFormSuccess(true);
    } catch(err) {
      console.log(err);
      setFormSuccess(false);
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const existSymptom = (patient) => { 
    return selectedDoctors?.find((item) => item.id === patient.id) ? true : false; 
  }

  const  handleChangePatientsList = (patient, exist) => {
    const tempPatientsList = JSON.parse(JSON.stringify(selectedDoctors));
    if (exist) {
      setSelectedDoctors(tempPatientsList.filter((item) => item.id !== patient.id))
    } else {
      tempPatientsList.push(patient);
      setSelectedDoctors(tempPatientsList)
    }
  }


  if(
    insertDoctorToProcessRoleLoading ||
    loadingDoctorsData ||
    insertRoleAndDoctorToProcessRoleLoading
  ) 
    return(
      <S.ModalShareAccessToPatient>
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <div className="modal-container__reaction">
            <ZaiaReaction
              widthimg={"150px"}
              zaiaImg={logoZaiaLoading}
              padding={"200px 0px"}
            />
          </div>
        </div>
      </S.ModalShareAccessToPatient>
    )

  if(formSuccess)
    return(
      <S.ModalShareAccessToPatient>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <div className="modal-container__reaction">
            <ZaiaModalResponseContent
              button1={() => setModalActive(null)}
              formSuccess={formSuccess}
              button1Text={t("globally.done")}
              title={t("profile.invitationManagement.sharePatientDataSuccess")}
            />
          </div>
        </div>
      </S.ModalShareAccessToPatient>
    )

  if(formSuccess === false)
    return(
      <S.ModalShareAccessToPatient>
        <div className="modal-background" onClick={() => setModalActive(false)}>
        </div>
        <div className="modal-container">
          <div className="modal-container__reaction">
            <ZaiaModalResponseContent
              button1={() => setModalActive(null)}
              formSuccess={formSuccess}
              title={t("globally.errorForm")}
              subtitle={t("profile.invitationManagement.sharePatientDataError")}
              button1Text={t("globally.accept")}            
            />
          </div>
        </div>
      </S.ModalShareAccessToPatient>
    )

  return (
    <S.ModalShareAccessToPatient>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        {
          summaryActived &&
          <div className="header">
            <div className="header__left">
              <div className="header__action header__action--back" onClick={() => setSummaryActived(false)}>
                <i className="header__action-icon icon zaia-icono-atras"/>
              </div>
              <div className="header__title">{t("profile.invitationManagement.sharePatiendDataTitle")}</div>
              <div className="header__summary">{t("globally.summary")}</div>
            </div>
            <div className="header__action" onClick={() => setModalActive(false)}>
              <i className="header__action-icon icon zaia-icono-cerrar"/>
            </div>
          </div>
        }
        {
          !summaryActived &&
          <div className="header">
            <div className="header__text">
              <div className="header__title">{t("profile.invitationManagement.sharePatiendDataTitle")}</div>
              <div className="header__patient">
                <ZaiaImage
                  size={24}
                  img={patientInfo.user.picture}
                  rounded={true}
                />
                <div className="header__patient-name">{`${patientInfo.user.name} ${patientInfo.user.lastname}`}</div>
                <div className="header__patient-process">{P.dataTranslation(patientInfo?.process?.processName, language)}</div>
              </div>
            </div>
            <div className="header__action" onClick={() => setModalActive(false)}>
              <i className="header__action-icon icon zaia-icono-cerrar"/>
            </div>
          </div>
        }
        <div className="modal-content">
          {
            summaryActived && 
            <>
              <div className="patient-card">
                <ZaiaImage
                  size={65}
                  img={patientInfo.user.picture}
                  rounded={true}
                />
                <div className="patient-card__name">{`${patientInfo.user.name} ${patientInfo.user.lastname}`}</div>
                <div className="patient-card__email">{patientInfo.user.email}</div>
                <div className="patient-card__process">{P.dataTranslation(patientInfo.process?.processName, language)}</div>
              </div>
              <div className="users-list users-list--summary">
                {
                  selectedDoctors.map((doctor) => 
                  {
                    if(getFilterText(`${doctor.name} ${doctor.lastname}`)) {
                        return(
                        <S.UserCard
                          exist={true}
                        >
                            <i class="icon icon zaia-i-checkbox-on"></i>
                            <ZaiaImage
                              size={55}
                              img={doctor.profile_picture}
                              rounded={true}
                            />
                            <div className="info">
                              <div className="name">{`${doctor.name} ${doctor.lastname}`}</div>
                              <div className="program">
                                {/* {
                                  <Popup
                                    trigger={
                                      <div className="doctor-option__info-program-detail">
                                        {
                                          patient?.userToProcesses.length > 1 
                                          ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                          <div>{P.dataTranslation(patient?.userToProcesses[0].process?.processName, language)}</div>
                                          
                                        }
                                      </div>
                                    }
                                    keepTooltipInside
                                    nested
                                    // position={"right bottom"}
                                    on="hover"
                                    disabled={patient?.userToProcesses.length > 1 ? false : true}
                                  >
                                    {
                                      <ProcessName userToProcesses={patient?.userToProcesses}/>
                                    }
                                  </Popup>
                                } */}
                              </div>
                          </div>
                        </S.UserCard>
                        )
                      } else {
                        return null
                      }
                    }
                  )
                }
              </div>
            </>
          }

          {
            !summaryActived && 
            <>
              <div className="input-container">
                <ZaiaRoundInput
                  placeholder={t("globally.search")} 
                  padding="8px 20px" 
                  type={'text'} 
                  getText={(e) => setInputText(e)}
                />
              </div>
              <div className="users-list">
                {
                  errorPatientsData && 
                  <div className="users-list__responses">
                    <ZaiaReaction
                      zaiaImg={logoZaiaAlert}
                      sizeText="20px"
                      textcolor="var(--blue-color)"
                      widthimg="110px"
                    />
                  </div>
                }
                {
                  loadingPatientsData && 
                  <div className="users-list__responses">
                    <ZaiaReaction
                      zaiaImg={logoZaiaLoading}
                      sizeText="20px"
                      textcolor="var(--blue-color)"
                      widthimg="110px"
                    />
                  </div>
                }
                {
                  !errorPatientsData && !loadingPatientsData && (patientsData?.user?.length === 0 || patientsData?.user.filter((patient) => getFilterText(`${patient.name} ${patient.lastname}`)).length === 0) && 
                  <div className="users-list__responses">
                    <ZaiaReaction
                      zaiaImg={eventsCalendarEmpty}
                      text={t("globally.noResults")}
                      subText={t("globally.noMatchingPatients")}
                      sizeText="33.25px"
                      sizeSubText="15px"
                      textcolor="var(--blue-color)"
                      widthimg="210px"
                    />
                  </div>
                }
                {
                  !errorPatientsData && !loadingPatientsData && patientsData?.user?.length !== 0 &&
                  doctorsData?.health_system_colleagues_by_process.health_system_colleagues[0].doctors.map((doctor) =>
                  {
                    if(getFilterText(`${doctor.name} ${doctor.lastname}`)) {
                        const exist = existSymptom(doctor);
                        return(
                        <S.UserCard
                          onClick={() => 
                            {
                              handleChangePatientsList(doctor, exist)
                            }
                          }
                          exist={exist}
                        >  

                            {
                              exist
                              ? <i class="icon icon zaia-i-checkbox-on"></i>
                              : <i class="icon icon zaia-i-checkbox-off"></i>
                            }
                            <ZaiaImage
                              size={55}
                              img={doctor.profile_picture}
                              rounded={true}
                            />
                            <div className="info">
                              <div className="name">{`${doctor.name} ${doctor.lastname}`}</div>
                              {/* <div className="program"> */}
                                {/* {
                                  <Popup
                                    trigger={
                                      <div className="patient-option__info-program-detail">
                                        {
                                          patient?.userToProcesses.length > 1 
                                          ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                          <div>{P.dataTranslation(patient?.userToProcesses[0].process?.processName, language)}</div>
                                          
                                        }
                                      </div>
                                    }
                                    keepTooltipInside
                                    nested
                                    // position={"right bottom"}
                                    on="hover"
                                    disabled={patient?.userToProcesses.length > 1 ? false : true}
                                  >
                                    {
                                      <ProcessName userToProcesses={patient?.userToProcesses}/>
                                    }
                                  </Popup>
                                } */}
                                {/* {patient?.email}
                              </div> */}
                          </div>
                        </S.UserCard>
                        )
                      } else {
                        return null
                      }
                    }
                  )
                }
              </div>
            </>
          }
        </div>
        <div className="footer">
          <div className="footer__text">
            {
              summaryActived
              ? t("profile.invitationManagement.sharePatientlastStep")
              : t("profile.invitationManagement.selectPersonnel")
            }
          </div>
          <div className="button-container">
            <div onClick={() => handleSubmit()}>
              <ZaiaButton
                type="button"
                option="primary"
                marginTop="5"
                marginBottom="5"
                backgroundColor={ selectedDoctors.length > 0 ? "#17AF54" : "#E0E6ED"}
                // backgroundColor={"#17AF54"}
                // title={"Done"}
                title={summaryActived ? t("profile.invitationManagement.sharePatientData") : t("globally.done")}
                // icon={""}
                icon={selectedDoctors.length > 0 ? "" : "zaia-i-lock-chat"}
                padding={"0px 10px"}
                iconSize={"20"}
                iconMargin={"5"}
                borderRadius="75"
                fontSize="14"
                height="36"
                fontFamily={"Nunito Sans"}
                shadow={false}
                border={false}
                // color={"#FFFFFF"}
                color={selectedDoctors.length > 0 ? "#FFFFFF" : "#8A939D"}
                disabled={selectedDoctors.length <= 0}
                // disabled={false}
              />
            </div>
          </div>
        </div>
      </div>
    </S.ModalShareAccessToPatient>
  );
}


export default ModalShareAccessToPatient;
