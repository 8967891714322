import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalCreateInvitePatient = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  /* background-color: var(--white); */
  /* background-color: ${`#090949${getHexOpacity(0.50)}`}; */
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    z-index: 200;
    background-color: var(--white);
    width: 700px;
    margin: auto;
    box-sizing: border-box;
    max-height: 80%;
    border-radius: 14px;
    &--already-sent {
      width: 850px;
    }
  }  
  .settings__container {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 30px 10px 0px 30px;
  }
  .modal-reaction {
    padding: 40px;
  }

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;

export const InvitePatientsModalDiv = styled.div`
  width: 100%;
  height: 100%;
  /* display: flex; */
  position: relative;
  overflow: auto;
  box-sizing: border-box;
  padding-right: 20px;
  padding-bottom: 30px; 
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 30px 30px 0 30px; */
    &__options {
      display: flex;
    }
    &__option {
      color: #36369B;
      cursor: pointer;
      font-family: "Nunito Sans";
      font-size: 25px;
      margin: 10px 12px;
      padding-bottom: 13px;
      &--active {
        font-family: "Nunito Sans Black";
        padding-bottom: 10px;
        border-bottom: 3px solid #B3B3F2;
      }
    }
    &__close {
      width: 28px;
      height: 28px;
      background-color: #36369B;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      cursor: pointer;
      color: #fff;
    }
  }
  form {
    width: 100%;
    /* max-height: 606px; */
    
    /* padding: 0px 30px 30px 30px; */
    box-sizing: border-box;
    /* height: calc(100% - 98px); */
    
    /* padding: 20px; */
    .send-by-container {
      margin-bottom: 10px;
      .send-by-title {
        font-size: 15px;
        color: #6C6BCC;
        font-style: normal;
        margin-bottom: 5px;
      }
      .send-by-buttons {
        display: flex;
        margin-top: 10px;
        .send-by-button {
          cursor: pointer;
          border-radius: 10px;
          outline: none;
          margin-right: 10px;
          border: none;
          background: #F0F0FF;
          display: flex;
          padding: 5px 20px;
          justify-content: center;
          align-items: center;
          color: #6C6BCC;
          font-family: "Nunito Sans";
          font-size: 15px;
          &--selected {
            background: #D2D2FF;
            color: #36369B;
            font-family: "Nunito Sans Bold";
          }
        }
      }
    }

    .card-inputs {
      /* height: calc(100% - 104px); */
      /* overflow: auto; */
      /* max-height: 420px; */
      background-color: ${({theme}) => theme.background};
      /* padding: 40px; */
      border-radius: 30px;
      /* box-shadow: 1px 1px 11px 5px ${({theme}) => theme.blackShadow}29; */
      /* overflow: auto; */
      h4 {
        margin-bottom: 15px;
        font-size: 17px;
        font-family:"Nunito ExtraBold";
      }
      p {
        font-family: "Nunito";
        font-size: .9rem;
        margin: 5px 0 10px;
      }
      .card-inputs-container {
        /* margin: 40px 0; */
        .phone-inputs {
          display: flex;
          &__indicative {
            width: 200px;
            margin-right: 20px;
          }
        }
        &-toggle {
          display: flex;
          margin: 16px 0 33px 0;
          color: #333365;
          font-family: "Nunito Sans Light";
          font-size: 19px;
          &-label {
            margin-left: 10px;
          }
        }
        ::-webkit-scrollbar {
          width: 26px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px 10px transparent;
          margin: 5px 0px; 
        }
        ::-webkit-scrollbar-thumb {
          border: 10px solid rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 10px 10px #3E3DA366;
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: transparent;
        }
        .label {
          display: flex;
          align-items: center;
          margin: 23px 0 6px 0;
          color: #3E3E5B;
          font-family: "Nunito Sans Light";
          font-size: 19px;
          &-icon {
            margin-right: 10px;
          }
        }
      }
      .invitePatientsModal__msgContainer {
        background-color: var(--background-header-input);
        padding: 5px 5px 60px 5px;
        border-radius: 10px;
        margin-top: 10px;
        max-width: 310px;
        p {
          line-height: 1.2;
        }
      }
    }
    .card-buttons {
      /* padding-bottom: 20px; */
      margin-top: 40px;
      text-align: center;
      p {
        font-size: .9rem;
      }
    }
  }
  .sendBtn {
    background-color: var(--blue-color);
    border: none;
    border-radius: 20px;
    padding: 5px 80px;
    color: white;
    box-shadow: 3px 3px 5px var(--box-shadow);
    width: 308px;
    height: 42px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  @media only screen and (max-width: 375px) {
    .card-inputs {
      padding: 30px 20px !important;
    }
  }
`;

export const InputParagraphDiv = styled.div`
  display: flex;
  .text-area-input {
    width: 100%;
    height: 100px;
    overflow: auto;
    background-color: var(--background-header-input);
    border-radius: 14px;
    padding: 20px 27px;
    display: flex;
    outline: none;
    border: none;
    resize: none;
    font-family: "Nunito Sans Light";
    font-size: 19px;
  }
`;

export const InvitationAlreadySent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .header {
    justify-content: end;
    display: flex;
    width: 100%;
    position: absolute;
    right: -20px;
    top: -20px;
    &__close {
      background-color: #36369B;
      color: #FCFCFE;
      border-radius: 50%;
      width: 28px;
      height: 28px;
      display: flex;
      align-items: center;
      font-size: 9px;
      justify-content: center;
      cursor: pointer;
    }
  }
  .patient-card {
    width: 100%;
    padding: 20px;
    background-color: #F6F6FF;
    border-radius: 40px;
    display: flex;
    box-sizing: border-box;	
    align-items: center;
    &__img {
      align-items: center;
      .patient-photo {
        width: 154px;
        height: 154px;
        border-radius: 50%;
        object-fit: cover;
        font-size: 8px;
        vertical-align: middle;
      }
      .icon-container {
        &__icon {
          font-size: 154px;
          color: #8A8AC3;
          opacity: 0.45;
        }
      }
    }
    &__info {
      margin-left: 40px;
    }
    &__title {
      font-family: "Nunito Sans";
      font-size: 30px;
      color: #1F1F52;
    }
    &__email {
      font-family: "Nunito Sans";
      font-size: 20px;
    }
    &__program-errolled {
      margin-top: 10px;
      font-family: "Nunito Sans Bold";
      font-size: 18px;
      color: #3E3E5B;
    }
    &__program-label {
      font-family: "Nunito Sans";
      font-size: 20px;
      color: #707582;
    }
    &__program {
      display: flex;
      align-items: center;
      &-text {
        font-family: "Nunito Sans Bold";
        font-size: 30px;
        color: #1F1F52;
      }
      &-icon-container {
        background-color: #27AE60;
        color: #FCFCFE;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        font-size: 15px;
        justify-content: center;
        margin-left: 10px;
      }
    }
    &__acceptance {
      display: flex;
      align-items: center;
      margin-top: 11px;
      font-size: 18px;
      &-text-acceptance {
        font-family: "Nunito Sans Bold";
        margin-right: 5px;
      }
      &-icon-container {
        color: #FCFCFE;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        font-size: 15px;
        justify-content: center;
      }
      &-text-1 {
        margin-right: 5px;
      }
      &-text-2 {
        margin-left: 5px;
      }
    }
  }
  .button-section {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    button {
      width: 100%;
      outline: none;
      border: none;
      border-radius: 64px;
      padding: 17px 0;
      justify-content: center;
      display: flex;
      font-family: "Nunito Sans";
      font-size: 20px;
      cursor: pointer;
    }
    .invite-other-process {
      background-color: #3E3DA3;
      color: #FFFFFF;
      margin-right: 7.5px;
    }
    .resend-invitation {
      margin-left: 7.5px;
      color: #3E3DA3;
      border: 1px solid #8A8AC3;
      background-color: #FFFFFF;
    }
  }
`;