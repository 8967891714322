import styled, { keyframes } from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";
const waveBottom = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

const expansion = () => keyframes`
 0% {
  box-shadow: 0 0 0 0px rgba(255,0,0,.5);
 }
 100% {
  box-shadow: 0 0 0 5px rgba(255,0,0,.5);
 }
`

export const TransducerView = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-image: url(${waveBottom});
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 125%;
  border-radius: 14px 14px 0px 0px;
  overflow: hidden;
  box-shadow: 0px -7px 34.2px 0px #0C0B471C;
  background-color: #F8F8FF;
  .partition-selected{
    width: 39px;
    display: flex;
    padding: 10px 10px 10px 10px;
    justify-content: center;
    align-items: center;
    &__container {
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
    }
    &__point {
      width: 21px;
      height: 21px;
      border-radius: 17px;
      background: #9393FF;
      margin-right: 10px;
    }
    &__text {
      color: #1F1F52;
      font-family: "Nunito Sans";
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .transductor {
    height: 100%;
    width: 100%;
  }
  .transductor-container {
    width: 100%;
    height: ${({maxViewActived, recordingActived}) => maxViewActived ? (recordingActived ? "calc(100% - 110px)" : "calc(100% - 65px)"): "650px"};
    overflow: auto;
    border-radius: 14px 14px 0px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

  .transducer-minimized {
    width: 261px;
    padding: 10px 20px 20px 20px;
    border-radius: 14px 14px 0px 0px;
    background-color: #F8F8FF;
    box-shadow: 0px 0px 23.59px -1.81px #23229D40;
    display: flex;
    flex-direction: column;
    width: 100%;
    &__state {
      margin-left: auto;
    }
    &__top {
      display: flex;
      width: 100%;
      .title {
        font-family: Nunito Sans light;
        font-size: 24.27px;
      }
      .subtitle {
        font-family: Nunito Sans light;
        font-size: 15px;
      }
      .transductor-state {
        display: flex;
        color: #707582;
        align-items: center;
        margin-left: auto;
        margin-top: 7px;
        .mic-container {
          color: transparent;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          animation: ${expansion()} 2s ease infinite;
          margin-right: 7px;
          cursor: pointer;
          display: flex;
          .mic-icon {
            color: ${({activedMic, theme}) => theme.warning};
            margin: 3px auto;
          }
        }
        &__icon {
          font-size: 10px;
          margin-right: 5px;
        }
        &__circle {
          margin-right: 5px;
          width: 11px;
          height: 11px;
          border-radius: 50%;
          background: #FF453A;
        }
        &__text {
          font-size: 14px;
          font-family: Nunito Sans Bold;
        }
      }
    }
    &__buttons {
      display: flex;
      .circle-button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        background: #6D6DB91F;
        border: none;
        outline: none;
        cursor: pointer;
        margin-right: 7.6px;
        font-size: 7.3px;
        &--maximize-notes {
          font-size: 12px;
        }
        &__icon {
          margin: auto;
        }
      }
    }
    &__text {
      color: #3E3DA3;
      font-family: Nunito Sans Bold;
      font-size: 20px;
      margin-left: 3px;
    }
    &__bottom {
      display: flex;
      margin-top: 10px;
      align-items: center;
    }
  }
`
                      
export const HeaderDiv = styled.div`
  .header {
    padding: 18px 20px 10px;
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    &__top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }
    &__bottom {
      justify-content: space-between;
      display: flex;
      width: 100%;
      margin: 8px 0 18px 0;
    }
    &__left {
      display: flex;
    }
    &__right {
      display: flex;
      flex-direction: ${({maxViewActived}) => maxViewActived ? "" : "column"};
    }
    &__center {
      font-family: Nunito Sans Bold;
      font-size: 18px;
      color: #3E3E5B;
    }
    .transductor-type{
      color: #707582;
      font-size: 14px;
    }
    .transductor-state {
      display: flex;
      color: #707582;
      align-items: center;
      .mic-container {
        color: transparent;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        animation: ${expansion()} 2s ease infinite;
        margin-right: 7px;
        cursor: pointer;
        display: flex;
        .mic-icon {
          color: ${({activedMic, theme}) => theme.warning};
          margin: 3px auto;
        }
      }
      &__icon {
        font-size: 10px;
        margin-right: 5px;
      }
      &__circle {
        margin-right: 5px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background: #FF453A;
      }
      &__text {
        font-size: 14px;
        font-family: Nunito Sans Bold;
      }
    }
    &__close-chat { 
      height: 36px;
      width: 36px;
      background: rgba(109, 109, 185, 0.12);                        
      display: flex;
      font-size: 9px;
      border-radius: 50%;
      cursor: pointer;
      &-icon {
        display: flex;
        margin: auto;
        color: ${({theme}) => theme.primaryColor};
      }
    }
    &__max {
      height: 36px;
      width: 53px;
      margin-left: 10px;
      background: rgba(109, 109, 185, 0.12);
      font-size: 9px;
      border-radius: 34.57px;
      cursor: pointer;
      align-items: center;
      display: flex;
      &-icon {
        display: flex;
        margin: auto;
        font-size: 18px;
        color: ${({theme}) => theme.primaryColor};
      }
    }
    &__reset-button {
      display: flex;
      padding: 8.894px;
      justify-content: center;
      align-items: center;
      font-family: "Nunito Sans Bold";
      font-size: 14px;
      background: rgba(109, 109, 185, 0.12);
      cursor: pointer;
      height: fit-content;
      margin-right: 10px;
      margin-left: 10px;
      border-radius: 15px;
      color: #36369B
    }
    &__minimize {
      align-items: center;
      height: 36px;
      margin-right: 10px;
      background: rgba(109, 109, 185, 0.12);
      border-radius: 34.57px;
      cursor: pointer;
      display: flex;
      padding: 0 14px;
      &-icon {
        display: flex;
        font-size: 16px;
        color: ${({theme}) => theme.primaryColor};
        margin-right: 13px;
      }
      &-text {
        font-size: 14px;
        font-family: "Nunito Sans Bold";
        color: #3E3DA3;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      margin-right: 10px;
      &-icon {
        color: #36369B;
        width: 17.75px;
        font-size: 17px;
        opacity: 0.4;
      }
      &-text {
        font-family: Nunito Sans;
        font-size: 25px;
        text-align: left;
        color: #707582;
        margin-left: 5px;
      }
    }
    &__patient-selected {
      display: flex;
      align-items: center;
      &-img {
        margin-right: 15px;
      }
      &-name {
        color: #3E3E5B;
        font-family: Nunito Sans;
        font-size: 20px;
        margin-right: 10px;
      }
      &-close {
        background-color: #36369B;
        width: 22px;
        height: 22px;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        &-icon {
          font-size: 6px;
          margin: auto;
        }
      }
    }
  }
`

export const ChatbotChooseOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .view-options {
    margin: auto;
    width: 292px;
  }
  .view-option {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    &__button {
      border-radius: 10px;
      background: #36369B;  
      display: flex;
      padding: 10px;
      justify-content: center;
      border: none;
      outline: none;
      color: #FFF;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      cursor: pointer;
      margin-bottom: 10px;
    }
    &__description {
      color: #93A9C0;
      text-align: center;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`

export const ModalResponse = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 300;
    background-color: var(--white);
    max-width: 900px;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    padding: 50px 0;
  }
`