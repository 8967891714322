import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, useField } from "formik";
import * as S from "./PatientEdition.style";
import * as P from "../../../../../utils/pipes";
import * as Yup from "yup";
import { ThemeContext } from "styled-components";
import CryptoJS from 'crypto-js';

// Components
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { MUTATION_EDIT_USER, MUTATION_ENRROLL_PATIENCE } from "../../../../../graphql/mutations/Patience";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../graphql/queries/Process";
import { MUTATION_SIGN_UP_PATIENT } from "../../../../../graphql/mutations/User";

// hooks
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import { QUERY_GET_COUNTRIES } from "../../../../../graphql/queries/Country";
import useUploadTempImg from "../../../../../hooks/useUploadTempImg";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function PatientEdition({
  setActiveCreatePatient,
}) {

  const [typeForm, setTypeForm] = useState("invitation");
  const NEW_USERS_PASSWORD = process.env.REACT_APP_NEW_USERS_PASSWORD;
  const { t, i18n: { language } } = useTranslation("global");
  const [formInfo, setFormInfo] = useState(null);
  const browserLang = language.split("-")[0];

  const [enrrollPatience, { loading: enrrollLoading }] = useMutation(MUTATION_ENRROLL_PATIENCE);
  const [registerUserByEmail, { loading: userRegisterUserByEmail }] = useMutation(MUTATION_SIGN_UP_PATIENT);
  const [editUserData] = useMutation(MUTATION_EDIT_USER);
  const { data: processData } = useQuery(QUERY_GET_PROCESS_TYPES);
  
  const [formSuccess, setFormSuccess] = useState(null);
  const [formSuccessText, setFormSuccessText] = useState("");
  const [formErrorText, setFormErrorText] = useState("");
  const [button2SuccessText, setButton2SuccessText] = useState("");
  
  const handleSubmit =  (values, { resetForm }) => {
    registerPatient(values, resetForm);
  };

  const registerPatient = async ( values, resetForm ) => {
    let enrrollMessage;

    const md5Hash = CryptoJS.MD5(values.lastname + values.name + new Date().getTime()).toString();

    const registerVariables = {
      lastname: values.lastname,
      name: values.name,
      email: `${md5Hash}@yopmail.com`,
      lang: browserLang === 'es' || browserLang === 'en' || browserLang === 'fr' ? browserLang : "en",
      password: NEW_USERS_PASSWORD,
      birthdate: values.birthdate,
      gender: values.gender
    }

    try {
      const {
        data: {
          userSignUp
        }
      } = await registerUserByEmail({
        variables: registerVariables,
      });

      try {
        enrrollMessage = P.dataTranslation(JSON.parse(userSignUp.message), language);
        setFormSuccess(true);
        setFormSuccessText(enrrollMessage);
        setButton2SuccessText(t("inviteCreatePatient.registerAnotherUser"));
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryAgain")}`;
        setFormSuccess(false);
        setFormErrorText(enrrollMessage);
      }
      enrrollPatient(md5Hash, values, resetForm);
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryAgain")}`;
      }
      setFormSuccess(false);
      setFormErrorText(enrrollMessage);
      console.log("error", err);
    }
    setFormInfo(null); 
    resetForm();
  }

  const enrrollPatient = async (md5Hash, values, resetForm ) => {
    let enrrollMessage;

    const enrrollPatientVareiables = {
      descript: values.descript,
      processId: Number(processData?.process.find((process) => process?.nameKey === "Police-Homeless")?.id),
      stageId: 'enroll',
      email: `${md5Hash}@yopmail.com`,
      lastname: ""
    }

    try {
      const {
        data: {
          enroll_user_to_process: { message }
        }
      } = await enrrollPatience({
        variables: enrrollPatientVareiables,
      });
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(message), language);
        setFormSuccess(true);
        setFormSuccessText(enrrollMessage);
        setButton2SuccessText(t("inviteCreatePatient.inviteAnotherPatient"));
      } catch (err) {
        setFormSuccess(false);
        setFormErrorText(t("inviteCreatePatient.errorInvitePatient"));
      }
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = "Error";
      }
      setFormSuccess(false);
      setFormErrorText(enrrollMessage);
      console.log("error", err);
    }
    setFormInfo(null); 
  }

  if(
    enrrollLoading || userRegisterUserByEmail 
  ) 
    return(
      <S.PatientEdition>
        <div className="modal-reaction">
          <ZaiaReaction
            widthimg={"150px"}
            zaiaImg={logoZaiaLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.PatientEdition>
    )

  if(formSuccess)
    return(
      <S.PatientEdition>
        <div className="modal-reaction">
          <ZaiaModalResponseContent
            button1={() => setActiveCreatePatient(false)}
            button2={() => setFormSuccess(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.done")}
            button2Text={button2SuccessText}
            title={formSuccessText}
            doubleButton={true}
          />
        </div>
      </S.PatientEdition>
    )

  if(formSuccess === false)
    return(
      <S.PatientEdition>
        <div className="modal-reaction">
          <ZaiaModalResponseContent
            button1={() => setFormSuccess(null)}
            button2={() => setActiveCreatePatient(false)}
            formSuccess={false}
            button1Text={t("globally.tryAgain")}
            button2Text={t("inviteCreatePatient.tryLater")}
            title={t("globally.errorForm")}
            subtitle={formErrorText}
            doubleButton={true}
          />
        </div>
      </S.PatientEdition>
    )

  return (
    <S.PatientEdition>
      <div className="settings__container">
        <Formik
          initialValues={{
            name: formInfo?.name ? formInfo?.name: "",
            lastname: formInfo?.lastname ? formInfo?.lastname : "",
            descript: formInfo?.descript ? formInfo?.descript : "",
          }}
          validationSchema={
            Yup.object().shape(
                {
                  name: Yup.string()
                    .min(3, t("register.validation.min3"))
                    .max(15, t("register.validation.max"))
                    .required(t("register.validation.required")),
                  lastname: Yup.string()
                    .min(3, t("register.validation.min3"))
                    .max(15, t("register.validation.max"))
                    .required(t("register.validation.required")),
                  birthdate: Yup.string()
                    .required(t("register.validation.required")),
                  gender: Yup.string()
                   .required(t("register.validation.required")),
                }, ['email', 'document']
              )
          }
          onSubmit={handleSubmit}
        >
          {({isValid, handleSubmit, touched, errors, values, setFieldValue}) => (
            <S.InvitePatientsModalDiv>
              <div className="modal-header">
                <div className="modal-header__title">
                  {t("inviteCreatePatient.createPatient")}
                </div>
                <div className="modal-header__close" onClick={() => setActiveCreatePatient(false)}>
                  <i className="modal-header__close-icon icon zaia-icono-cerrar"/>
                </div>
              </div>
              <form onSubmit={ handleSubmit }>
                <div className="card-inputs">
                  <FormRegisterInputs
                    touched={touched}
                    errors={errors}
                    values={values}
                    setFieldValue={setFieldValue}
                  /> 
                </div>
                <div className="card-buttons">
                  <ZaiaButton
                    type="submit"
                    option="primary"
                    title={isValid ? t("globally.done") : t("police.createNewUser")}
                    backgroundColor={ isValid ? "#17AF54" : "#707582"}
                    borderRadius="43"
                    fontSize="32.25"
                    height="64"
                    fontFamily={"Nunito Sans"}
                    shadow={false}
                    disabled={!isValid}
                  />
                  {
                    !isValid &&
                    <div className="button-warning">
                      {t("police.createUserWarning")}
                      <b className="button-warning__required"> *</b>
                    </div>
                  }
                </div>
              </form>
            </S.InvitePatientsModalDiv>
          )}
        </Formik>
      </div>
    </S.PatientEdition>
  );
}


const FormRegisterInputs = ({
  touched,
  errors,
  setFieldValue,
  values
}) => {
  const { t, i18n: { language } } = useTranslation("global");
  const theme = useContext(ThemeContext);
  const hiddenFileInput = React.useRef(null);
  const {tempImg, uploadImg} = useUploadTempImg();

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  return(
    <>
      <div className="card-inputs-container">
        <div className="picture-selector" onClick={handleClick}>
          <div className="picture-selector__img">
            {
              tempImg &&
              <img src={tempImg} alt="" className="picture-selector__img-tag" />
            }
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={uploadImg}
              style={{display: 'none'}}
              accept="image/*"  
            />
            {
              !tempImg &&
              <div className="picture-selector__button">
                <i className="picture-selector__icon icon zaia-i-other"></i>
              </div>
            }
          </div>
          <div className="picture-selector__label">{t("police.changePhoto")}</div>
        </div>

        <div className="label">
          {t("globally.patientName")}
        </div>
        <ZaiaInputForm 
          placeholder={t("globally.patientName")}
          type="text"
          name="name"
          padding={"20px 0px"}
          fontSize={"19px"}
          textcolor={"#1F1F52"}
          borderColor={"#526479"}
          placeholdertextcolor={"#878792"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
          // icon="icono-nombre"
        />
        <div className="label">
          {t("inviteCreatePatient.patientLastName")}
        </div>
        <ZaiaInputForm 
          placeholder={t("inviteCreatePatient.patientLastName")}
          type="text"
          name="lastname"
          padding={"20px 0px"}
          fontSize={"19px"}
          textcolor={"#1F1F52"}
          borderColor={"#526479"}
          placeholdertextcolor={"#878792"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
          // icon="icono-nombre"
        />
        
        
        <div className="label">
          {t("globally.gender")}
        </div>
        <div className="options-list">
          <div 
            className={`option ${values.gender === "masculine" && "option--selected"}`}
            onClick={() => setFieldValue("gender", "masculine")}
          >
            {t("globally.male")}
          </div>
          <div 
            className={`option ${values.gender === "femenine" && "option--selected"}`}
            onClick={() => setFieldValue("gender", "femenine")}
          >
            {t("globally.female")}
          </div>
        </div>
        {
          errors.birthdate && touched.birthdate &&
          <div className="input-error">
            <>
              <i className="icon zaia-icono-ayuda"></i>
              <div> {errors.birthdate} </div>
            </>
          </div>
        }

        <div className="label">
          {t("globally.dateOfBirth")}
        </div>
        <ZaiaInputForm 
          placeholder={t("inviteCreatePatient.patientLastName")}
          type="date"
          name="birthdate"
          padding={"20px 27px 20px 27px"}
          fontSize={"19px"}
          textcolor={"#878792"}
          borderColor={"#526479"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
          // icon="icono-nombre"
        />
        <div className="label">
          {t("police.userDescription")}
        </div>
        <div className="comment-content">
          <InputParagraph
            placeholder={t("police.userDescriptionText")}
            name="descript"
            type="textarea"
          />
        </div>
      </div>
    </>
  )
}

const InputParagraph = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <S.InputParagraphDiv>
      <textarea className="text-area-input" placeholder={placeholder} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </S.InputParagraphDiv>
  );
};

export default PatientEdition;


