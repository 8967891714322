import styled from "styled-components";

export const ZaiaSelectForm = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  .input-container {
    cursor: ${({disabled}) => disabled ? "default" :'pointer'};
    border: none;
    box-sizing: border-box;
    padding: ${({padding}) => padding ? padding :'7px 7px'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius :'8px'};;
    display: flex;
    align-items: center;
    min-width: 100px;
    height: ${({height}) => height ? height : 'auto'};
    background-color: ${({theme, backgroundColor, disabledColor, disabled}) => 
      disabled 
      ? (disabledColor ?? backgroundColor ?? theme.inputBackground)
      : backgroundColor 
        ? backgroundColor 
        : theme.inputBackground
    };
    border: 1.8px solid ${
      ({
        theme, 
        listActive, 
        error,
        activeBorder,
        borderColor,
      }) => 
        {
          if(activeBorder) {
            if (borderColor) {
              return borderColor;
            } else {
              if (listActive) {
                return theme.inputSelected;
              } else {
                if (error?.error && error?.touched) {
                  return theme.warning;
                } else {
                  return theme.inputBorder;
                }
              }
            }
          } else {
            return 'transparent';
          }
        }
    };
    border-left: ${({onlyBottomBorder}) => onlyBottomBorder && 'transparent'};
    border-right: ${({onlyBottomBorder}) => onlyBottomBorder && 'transparent'};
    border-top: ${({onlyBottomBorder}) => onlyBottomBorder && 'transparent'};
    .icon-input-form {
      font-size: 15px;
      color: ${({theme, iconColor, disabledIconColor, disabled}) => 
        disabled 
        ? (disabledIconColor ?? iconColor ?? theme.inputText)
        : iconColor 
          ? iconColor 
          : theme.inputText
      };
    }
    input {
      color: ${({theme, textcolor, disabled, disabledtextcolor}) => 
      disabled 
        ? (disabledtextcolor ?? textcolor ?? theme.inputText)
        : textcolor 
          ? textcolor 
          : theme.inputText
      };
      border: none;
      background-color: transparent;
      width: 100%;    
      font-family: ${({fontFamily}) => fontFamily ? fontFamily :'Nunito Sans Light'};
      cursor: ${({disabled}) => disabled ? "default" :'pointer'};
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({theme, placeholdertextcolor}) => placeholdertextcolor ? placeholdertextcolor : theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: ${({fontFamily}) => fontFamily ? fontFamily :'Nunito Sans Light'};
      }
    }
    .icon-input-form-arrow {
      margin-bottom: ${({listActive}) => listActive ? '2px' : '-5px'};;
      color: ${({theme}) => theme.inputBorder};
      font-size: 12px;
      transition: .3s ease;
      transform: ${({listActive}) => listActive ? 'rotate(180deg)' : 'none'};
      color: ${({theme, textcolor}) => textcolor ? textcolor : theme.inputText};
      margin-left: auto;
    }
  }
  .select-list {
    position: absolute;
    background-color: ${({theme, backgroundColor}) => backgroundColor ? backgroundColor : theme.inputBackground};
    z-index: 1;
    display: ${({listActive}) => listActive ? 'block' :'none' };
    width: 100%;
    max-height: ${({maxHeightList}) => maxHeightList ? maxHeightList : ''};
    overflow-y: auto;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
    font-family: "Nunito Sans";
    font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
    color: ${({theme, textcolor}) => textcolor ? textcolor : theme.inputText};
    .select-item {
      cursor: pointer;
      padding: 3px 7px;
      label {
        cursor: pointer;
      }
    }
    ::-webkit-scrollbar{
      display: none;
    }
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    display: flex;
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
`