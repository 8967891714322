import styled from "styled-components";

export const FacesCard = styled.div`
  background-color: ${({colorSecundary}) => colorSecundary};
  height: 100%;
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--white);
  border-radius: 10px;
  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-family: "Nunito Bold";
    font-size: 15px;
  }
  .card-faces-container {
    background-color: ${({colorPrimary}) => colorPrimary};
    display: flex;
    padding: 5px 10px 5px 10px;
    width: 75%;
    border-radius: 20px;
    justify-content: center;
    height: inherit;
    margin-bottom: 10px;
    align-items: center;
  }

  @media only screen and (max-width: 375px) {
    min-width: auto;
    .card-faces-container {
      padding: 5px 0px 5px 0px;
    }
  }
`;