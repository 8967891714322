import React, { useEffect, useState } from "react";
import * as S from  "./PatientData.style";
import { useParams, useNavigate } from "react-router-dom";
import { Routes } from "../../../../../utils/constants";
import * as P from "../../../../../utils/pipes";
import { useQuery, useSubscription } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { SUBSCRIPTION_GET_CUSTOM_FORM_BY_PROCESS } from "../../../../../graphql/subscriptions/Surveys";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import PatientCreation from "../patientCreation";
import Modal from "../../../../../utils/modal";

function PatientData({
  reportSelected,
  setReportSelected,
  patientData,
  processCustomForms,
  processCustomFormsError,
  processCustomFormsLoading
}) {  

  const { patientID } = useParams();
  const navigate = useNavigate();
  const { POLICE } = Routes;
  const { t, i18n: { language } } = useTranslation("global");
  const zaiaImgLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
  const [patientEdition, setPatientEdition] = useState(false);
  
    return (
      <S.PatientData>
        <Modal open={patientEdition}>
          <PatientCreation
            patientData={patientData}
            setActiveCreatePatient={() => setPatientEdition(false)}
          />
        </Modal>
        <div className="header">
          <div className="header__center">{t("police.patientProfile")}</div>
          <div className="header__right">
            <button className="close-button"
              onClick={() => {
                navigate(`${POLICE.route}`); 
                // recordAction(Actions.AINAVIGATOR_MODAL.GO_OUTCOMES);
              }}
            >
              <i className="close-button__icon icon zaia-icono-cerrar"></i>
            </button>
          </div>
        </div>
        {
          (!patientData || !processCustomForms) &&
          <div className="patient-info-loading">
            <ZaiaReaction
              widthimg={"150px"}
              zaiaImg={zaiaImgLoading}
              padding={"200px 0px"}
            />
          </div>
        }
        {
          patientData && processCustomForms &&
          <div className="patient-info">
            <div className="patient-info__pricipal">
              <div className="patient-info__top">
                <img src={patientData?.picture} alt="" className="patient-image" />
                <div className="text-info">
                  <div className="patient-name">{patientData?.name + " " + patientData?.lastname}</div>
                  <div className="patient-data">
                    <div className="patient-years">
                      {P.getAge(patientData?.birthdate)} {t("globally.yearsOld")}

                    </div>
                    <div className="patient-data__separator"></div>
                    <div className="patient-gender">
                      {
                        patientData?.gender !== "na" &&
                        (
                          patientData?.gender === "masculine" 
                          ? <i className="patient-gender__icon icon zaia-mars"></i>
                          : <i className="patient-gender__icon icon zaia-venus"></i>
                        ) 
                      }                    
                      {
                        patientData?.gender !== "na" &&
                        (
                          patientData?.gender === "masculine" 
                          ? t("globally.male") 
                          : t("globally.female")
                        )
                      }
                    </div>
                    {/* <div className="patient-attitude">
                      {t("police.aggressive")}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="patient-description">
                {patientData?.userToProcesses.find((userToProcess) => userToProcess?.process?.nameKey === "Police-Homeless").descript}
              </div>
              <div className="patient-info__buttons">
                <button className="button-action button-action--edit-info"
                  onClick={() => setPatientEdition(true)}
                >
                  <i className="button-action__icon icon zaia-icono-editar"></i>
                  {t("police.editInfo")}
                </button>
                {/* <button className="button-action button-action--edit-info">
                  <i className="button-action__icon icon zaia-icono-editar"></i>
                  ~Summary with IA
                </button> */}
              </div>
            </div>
            <div className="patient-info__reports">
              <div className="reports-title">{t("globally.reports")}</div>
              <div className="reports-list">
                {
                  processCustomForms?.process[0].processToCustomForms.slice(1).map(
                    (processToCustomForm) => {
                      return(
                        <div className="report-box"
                          onClick={() => setReportSelected(processToCustomForm)}
                        >
                          <div className="report-box__left">
                            <i className={`report-box__icon icon ${processToCustomForm.icon}`}></i>
                            <div className="report-box__text">
                              <div className="report-box__title">{P.dataTranslation(processToCustomForm.customForm.customFormName, language)}</div>
                              <div className="report-box__number-reports">{processToCustomForm.customForm.userCustomFormHistories.length} {t("globally.reports")}</div>
                            </div>
                          </div>
                          <div className="report-box__right">
                            <button className="report-box__redirect-button">
                              <i className="report-box__redirect-icon icon zaia-i-next"></i>
                            </button>
                          </div>
                        </div>
                      )
                    }
                  )
                }
              </div>
            </div>
          </div>
        }
      </S.PatientData>
    )
  }
  
  export default PatientData;