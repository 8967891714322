import styled from "styled-components";

export const ProgressBarCard = styled.div`
  margin: auto;
  position: relative;
  /* width: 100%; */
  width: -webkit-fill-available;
  height: auto;
  background-color: ${({bgActive, colorDark}) => bgActive? colorDark : ''}; 
  padding: 10px 10px 0 10px;
  border-radius: 7px;
  h4 {
    margin-bottom: 10px;
    font-family: "Nunito Bold";
    font-size: 15px;
    color: ${({bgActive}) => bgActive ? 'var(--white)' : 'var(--black)'}; 
  }
  .progress-bars-container {
    height: ${({heightBarsContainer}) => heightBarsContainer + 'px'}; 
    overflow: auto;
    width: auto;
    justify-content: center;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const ZaiaReactionContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`