import React, { useState, useEffect, useMemo } from "react";
import * as S from "./Chat.style";
import {useNavigate, useParams, Navigate, useLocation} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Components
import MessageDisplay from "./components/messageDisplay";
import SettingsChat from "./components/settingsChat";
import SidebarTopPatients from "../patients/components/sidebarTopPatients";
import ListOfChats from "./components/listOfChats";

// GraphQL
import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_GROUP_CHATS, QUERY_GET_GROUP_CHATS_BY_USER } from "../../../graphql/queries/Chat";
import { SUBSCRIPTION_GET_GROUP_CHATS, SUBSCRIPTION_GET_GROUP_CHATS_BY_USER } from "../../../graphql/subscriptions/Chat";

// Hooks
import useDoctorData from "../../../hooks/useDoctorData";
import useChatVerification from "../../../hooks/useChatVerification";
import MedicineSettingsChat from "./components/medicineSettingsChat";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

function Chat() {
  const [{ selectedPatient }, dispatch] = useStateValue();
  const { chatID } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { doctorData } = useDoctorData();
  const currentLocation = location.pathname.split('/')[1];
  const [t] = useTranslation("global");
  const [selectedChat, setSelectedChat] = useState();
  const [inputFilter, setInputFilter] = useState();
  const [quantityChats, setQuantityChats] = useState(0);
  const [settingsGroup, setSettingsGroup] = useState(false);
  const [medicineSettings, setMedicineSettings] = useState(false);
  const [filterProccessIds, setFilterProccessIds] = useState([]);
  const { width } = useWindowDimensions();
  
  const [queryGroupChats, setQueryGroupChats] = useState(QUERY_GET_GROUP_CHATS_BY_USER);
  const [subscriptionGroupChats, setSubscriptionGroupChats] = useState(SUBSCRIPTION_GET_GROUP_CHATS_BY_USER);
  const [variablesGroupChats, setVariablesGroupChats] = useState();

  const subsActive = window.navigator.userAgent.indexOf('Mac') === -1;
  
  const [chatActive, setChatActive] = useState(false);
  const [medicineMessage, setMedicineMessage] = useState("");

  const { chatValid, loadingValidation }  = useChatVerification(chatID);

  useEffect(() => {
    if(filterProccessIds.length === 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: filterProccessIds[0],
      });
    } else if (filterProccessIds.length === 0 || filterProccessIds.length > 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null,
      });
    }
  }, [filterProccessIds])

  useEffect(() => {
    if(chatValid === false && !loadingValidation) navigate('/chat');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatValid, loadingValidation])

  useEffect(() => {
    setQueryGroupChats(
      selectedPatient 
      ? QUERY_GET_GROUP_CHATS_BY_USER 
      : QUERY_GET_GROUP_CHATS
    )
    setSubscriptionGroupChats( 
      selectedPatient 
      ? SUBSCRIPTION_GET_GROUP_CHATS_BY_USER 
      : SUBSCRIPTION_GET_GROUP_CHATS
    )

    setVariablesGroupChats(
      selectedPatient 
      && {
        userId: selectedPatient?.id
      }
    );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPatient]);

  const { 
    data: groupChatsDataSub, 
    loading: groupChatsLoadingSub, 
    error: groupChatsErrorSub
  } = useSubscription(
    subscriptionGroupChats, 
    { 
      variables: variablesGroupChats,
      skip: !subsActive,
    }
  );

  const { 
    data: groupChatsData, 
    loading: groupChatsLoading, 
    error: groupChatsError
  } = useQuery(
    queryGroupChats, 
    { 
      variables: variablesGroupChats,
      skip: subsActive,
    }
  );

  useEffect(() => {    
    if(selectedChat) {
      setChatActive(true);
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: selectedChat.personToChats[0]?.user?.id,
      });
    } else {
      dispatch({
        type: "SET_CONTEXT_PATIENT",
        contextPatient: null,
      });
    }
  }, [selectedChat])

  const getGroupChatDataWithName = (groupsChat) => {
    return groupsChat.map((group) => {

      let chatNameUser = group.nameChat;
      let chatPhotoUser = group.imagen;
      if (group.personToChats.length > 0) {
        if (group.ownerUserId) {
          chatNameUser = `${group.ownerUser.name} ${group.ownerUser.lastname}`;
          chatPhotoUser = group.ownerUser.picture;
        } else if (group.ownerDoctorId !== doctorData?.id) {
          chatNameUser = `${group.ownerDoctor.name} ${group.ownerDoctor.lastname}`;
          chatPhotoUser = group.ownerDoctor.picture;
        } else {
          if (group.personToChats[0].user) {
            chatNameUser = `${group.personToChats[0].user.name} ${group.personToChats[0].user.lastname}`;
            chatPhotoUser = group.personToChats[0].user.picture;
          }
        }
      }

      let groupTemp = JSON.parse(JSON.stringify(group));
      groupTemp.chatNameUser = chatNameUser; 
      groupTemp.chatPhotoUser = chatPhotoUser;

      return groupTemp;
    });
  }


  function orderChatGroups (a, b) {
    return (
      new Date(b.messages.length === 0 ? 0 : b.messages[0]?.createdAt) -
      new Date(a.messages.length === 0 ? 0 : a.messages[0]?.createdAt)
    );
  }

  const groupChatsOrderRecent = useMemo(() => {
    let groupsChat = [];
  
    if (subsActive) {
      groupsChat = groupChatsDataSub?.chatGroup ? [...groupChatsDataSub?.chatGroup] : [];
    } else {
      groupsChat = groupChatsData?.chatGroup ? [...groupChatsData?.chatGroup] : [];
    }
  
    groupsChat.sort(orderChatGroups);
  
    let groupsChatWithName = getGroupChatDataWithName(groupsChat);
  
    return groupsChatWithName;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupChatsDataSub, groupChatsData]);

  useEffect(() => {
    if(
      (
        subsActive 
        ? groupChatsDataSub?.chatGroup.length > 0 
        : groupChatsData?.chatGroup.length > 0
      ) &&
      chatID
    ) {
      setSelectedChat(groupChatsOrderRecent?.find(chatGroup => chatGroup.id === chatID ));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatID, currentLocation, location, width, groupChatsOrderRecent])

  if (
    ( subsActive 
      ? groupChatsDataSub?.chatGroup.length > 0 
      : groupChatsData?.chatGroup.length > 0 
    ) && 
    !chatID && 
    width >= 768 
  ) {
    return <Navigate to={`/${currentLocation}/` + groupChatsOrderRecent[0].id} />
  } 
  
  return (
    <S.ChatDiv 
      settingsGroup={settingsGroup | medicineSettings}
      chatActive={chatActive}
    >
      <div className="chat__left">
        <div className="chat__centerContainer">
          <div className="chat__centerContainer-top">
            <SidebarTopPatients 
              title={'Chats'} 
              quantity={quantityChats ? quantityChats : 0} 
              setInputFilter={setInputFilter}
              typeFilter="text"
              filterText={t("globally.name")}
              filterPlaceholder={t("chat.search")}
              processIds={filterProccessIds}
              setProcessIds={setFilterProccessIds}
              chatActive={chatActive}
              setChatActive={setChatActive}
            />
          </div>
          <div className="chat__centerContainerChats">
            <ListOfChats 
              setQuantityChats={setQuantityChats}
              setSelectedChat={setSelectedChat}
              groupChatsOrderRecent={groupChatsOrderRecent}
              groupChatsLoading={subsActive ? groupChatsLoadingSub : groupChatsLoading}
              groupChatsError={subsActive ? groupChatsErrorSub : groupChatsError}
              filterText={inputFilter}
              filterProccessIds={filterProccessIds}
              chatActive={chatActive}
            />
          </div>
        </div>
      </div>
      <div className="chat__right">
        <div className="chat__right-message-display">
          <MessageDisplay
            selectedChat={selectedChat}
            groupChatsData={subsActive ? groupChatsDataSub : groupChatsData}
            groupChatsLoading={subsActive ? groupChatsLoadingSub : groupChatsLoading}
            groupChatsError={subsActive ? groupChatsErrorSub : groupChatsError}
            setSettingsGroup={setSettingsGroup}
            setMedicineSettings={setMedicineSettings}
            setSelectedChat={setSelectedChat}
            medicineMessage={medicineMessage}
            setMedicineMessage={setMedicineMessage}
            setChatActive={setChatActive}
          />
        </div>
        {
          settingsGroup &&
          <div className="chat__right-settings">
            <SettingsChat
              setSettingsGroup={setSettingsGroup}
              selectedChat={selectedChat}
            />
          </div> 
        }
        {
          medicineSettings &&
          <div className="chat__right-settings">
            <MedicineSettingsChat
              setMedicineSettings={setMedicineSettings}
              selectedChat={selectedChat}
              setMedicineMessage={setMedicineMessage}
            />
          </div>
        }
      </div>
    </S.ChatDiv>
  );
}

export default Chat;
