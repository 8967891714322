  import styled from "styled-components";

  export const ZaiaCounter = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    font-family: 'Nunito Semibold';
    display: flex;
    .zaia-counter__button {
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none;
      font-size: 29px;
      color: var(--white) !important;
      display: flex;
      justify-content: center;
      background-color: ${({theme}) => theme.primarySectionMedicines};
      border-radius: 10px;
      /* padding: 5px; */
      width: 40px;
      height: 40px;
      margin: 0px 8px 0px 0px;
      font-family: "Nunito ExtraBold";
      cursor: pointer;
      &--disabled {
        cursor: default;
        background-color: ${({theme}) => theme.disableButton};
      }
    }
    .zaia-counter__number {
      font-size: 16px;
      display: flex;
      justify-content: center;
      border: 1px ${({theme}) => theme.disableButton} solid;
      border-radius: 10px;
      /* padding: 5px; */
      align-items: center;
      width: 40px;
      /* height: 40px; */
      margin: 0px 8px 0px 0px;
      font-family: "Nunito ExtraBold";
    }
  `