import styled from "styled-components";

export const CmpDateSelection = styled.div`
  /* background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px; */
  h5 {
    font-family: "Nunito Bold";
  }
  .selection-container {
    /* display: flex; */
    align-items: center;
    &__item {
      display: flex;
      width: 100%;
    }
    i {
      margin-left: 5px;
    }
  }
  i {
    /* margin-right: 5px; */
  }
  .progression-free-survival-form-input {
    margin-bottom: 0px;
  }
  .input-container {
    /* height: 33px;
    background-color: #EFF3F6;
    box-sizing: border-box;
    padding: 5px 10px;
    border-radius: 4px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    width: min-content;
    cursor: text;
    position: relative;
    border: 1.8px solid transparent; */
    input {
      /* border: none;
      background-color: transparent;
      font-family: "Nunito Sans light";
      color: #36369B;
      text-transform: uppercase; */
    }
  }
`;