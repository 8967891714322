import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NewTransducer.style";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import Modal from "../../../../../utils/modal";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import * as P from "../../../../../utils/pipes";
import TranducerMessagesList from "../tranducerMessagesList";
import useInfoToken from "../../../../../hooks/useInfoToken";

const record = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/record.svg`;
const talkingHead = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/talking-head.svg`;
const talkingHeads = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/talking-heads.svg`;

const TRANSDUCTOR_MD_ENDPOINT = process.env.REACT_APP_TRANSDUCTOR_MD_ENDPOINT;
const TRANSDUCTOR_MC_ENDPOINT = process.env.REACT_APP_TRANSDUCTOR_MC_ENDPOINT;

function NewTransducer({
  transducerTypes,
  transducerType,
  setTransducerType,
  recordingActived,
  setRecordingActived,
  transducerState,
  setTransducerState,
  setManageTransductionActiveConfirmation,
  messages,
  setMessages,
  transcription,
  setTranscription
}) {

  const { t, 
    i18n: { language }
  } = useTranslation("global"); 

  const [status, setStatus] = useState("");
  const [error, setError] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [model, setModel] = useState();
  const { token } = useInfoToken();  
  
  const audioContextRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const socketRef = useRef(null);

  const models = [
    {
      id: "latest_long",
      name: "Latest long",
      transducerType: 0,
    },
    {
      id: "medical_dictation",
      name: "Medical dictation",
      transducerType: 1
    },
    {
      id: "medical_conversation",
      name: "Medical conversation",
      transducerType: 2
    }    
  ]

  const getTransductorEndpoint = () => {
      if(transducerType === 1) {
        return `${TRANSDUCTOR_MD_ENDPOINT}/?language=${P.languageSplited(language) === "es" ? "es-ES" : "en-US"}&model=${model}&token=${token}`;
      } else {
        return `${TRANSDUCTOR_MC_ENDPOINT}/?language=${P.languageSplited(language) === "es" ? "es-ES" : "en-US"}&model=${model}&token=${token}`;
      } 
    }

  // use effect with function that finish process whe the component is unmounted
  useEffect(() => {
    return () => {
      stopRecording();
    };
  }, []);

  const startRecordingMedicalConsultation = async () => {

    try {
      setError("");
      console.log("startRecordingMedicalConsultation", getTransductorEndpoint());
      
      socketRef.current = new WebSocket(getTransductorEndpoint());

      await new Promise((resolve, reject) => {
        socketRef.current.onopen = () => {
          resolve()
          console.log("Grabación iniciada");
          setIsRecording(true);
        };
        socketRef.current.onerror = (err) => {
          reject(
            new Error("Error en la conexión WebSocket: " + err.message)
          );
          setStatus("Desconectado");
          setIsRecording(false);
        }
      });

      socketRef.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        addMessage(message.speakerTag, message.transcript);
      };

      socketRef.current.onclose = (closeEvent) => {
        if (closeEvent.code !== 1000) {
          console.error(`WebSocket Error: ${closeEvent.reason}`);
          setStatus("Desconectado");
          setIsRecording(false);
        }
      };

      // Crear AudioContext con tasa de muestreo de 16 kHz y reanudar si está suspendido
      audioContextRef.current = new  AudioContext({ sampleRate: 16000 });
      if (audioContextRef.current.state === "suspended") {
        await audioContextRef.current.resume();
      }

      // Cargar el módulo del AudioWorklet antes de obtener el audio para evitar retrasos
      await audioContextRef.current.audioWorklet.addModule("audio-processor-MC.js");

      // Obtener el stream de audio del micrófono
      mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });

      const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);

      // Crear AudioWorkletNode y configurar el envío continuo de datos
      const audioWorkletNode = new AudioWorkletNode(
        audioContextRef.current,
        "audio-processor-MC"
      );

      audioWorkletNode.port.onmessage = (event) => {
        if (socketRef.current?.readyState === WebSocket.OPEN) {
          socketRef.current?.send(event.data); // Enviar datos de audio al WebSocket
        }
      };

      // Conectar los nodos del audio: del micrófono al worklet
      source.connect(audioWorkletNode);
      // No se conecta al destination para evitar eco y retrasos:
      // audioWorkletNode.connect(audioContext.destination);

    } catch (err) {
      setError("Error: " + err.message);
      setIsRecording(false);
    }
  };

  const addMessage = (speakerTag, text) => {
    setMessages((prev) => [...prev, { 
      messagerType: speakerTag === 1, 
      messageText: text 
    }]);
  };

  const startRecordingNoteTaking= async () => {
    try {
      // Crear la conexión WebSocket
      const socket = new WebSocket(getTransductorEndpoint());
      socketRef.current = socket;

      await new Promise((resolve, reject) => {
        socket.onopen = () => {
          resolve();
        };
        socket.onerror = (err) =>
          reject(
            new Error("Error en la conexión WebSocket: " + err.message)
          );
      });

      socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        console.log(message);
        setTranscription((prev) => prev + message.transcription + " ");
      };
      

      socket.onerror = (err) => {
        setError(`Error de WebSocket: ${err.message}`);
      };

      socket.onclose = (closeEvent) => {
        if (closeEvent.code === 1008) {
          console.error(`WebSocket Error: ${closeEvent.reason}`);
        }
        setStatus("Desconectado");
        setIsRecording(false);
      };

      // Crear AudioContext con tasa de muestreo de 16 kHz y reanudar si está suspendido
      const audioContext = new AudioContext({ sampleRate: 16000 });
      if (audioContext.state === "suspended") {
        await audioContext.resume();
      }
      audioContextRef.current = audioContext;

          // Cargar el módulo del AudioWorklet antes de obtener el audio para evitar retrasos
      await audioContext.audioWorklet.addModule("audio-processor-MD.js");

      // Crear AudioWorkletNode
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      mediaStreamRef.current = mediaStream;

      const source = audioContext.createMediaStreamSource(mediaStream);

      // Crear el AudioWorkletNode y configurar el envío continuo de datos
      const audioWorkletNode = new AudioWorkletNode(
        audioContext,
        "audio-processor-MD"
      );
      audioWorkletNode.port.onmessage = (event) => {
        if (socket.readyState === WebSocket.OPEN) {
          socket.send(event.data);
        }
      };

      // Conectar la cadena de audio: del micrófono al worklet
      source.connect(audioWorkletNode);

      console.log("Grabación iniciada");
      setIsRecording(true);
      setStatus("Grabación iniciada");
    } catch (err) {
      setError(`Error: ${err.message}`);
    }
  };

  const stopRecording = () => {
    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach(track => {
        track.stop(); // Detiene cada track de audio
      });
      mediaStreamRef.current = null;
    }
  
    if (audioContextRef.current) {
      audioContextRef.current.close().catch(err => console.error("Error al cerrar AudioContext:", err));
      audioContextRef.current = null;
    }
  
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
    }
  
    setIsRecording(false);
    setStatus("Grabación detenida");
    navigator.mediaDevices.enumerateDevices().then(devices => console.log("devicessss", devices));
  };
  

  const finilizeSession = () => {
    setManageTransductionActiveConfirmation(true);
    setTransducerState(false);
    stopRecording();
  }

  return (
    <S.NewTransducer
      modelsSelection={P.languageSplited(language) === "en"}
    >
      <div className="transducer-content">
        {
          recordingActived &&
          <TranducerMessagesList
            transducerState={transducerState}
            transducerType={transducerType}
            transcription={transcription}
            messages={messages}
          />
        }
        {
          !recordingActived &&
          <TransductorChooseType
            transducerTypes={transducerTypes}
            transducerType={transducerType}
            setTransducerType={setTransducerType}
            model={model}
            setModel={setModel}
            models={models}
          />
        }
      </div>
      {/* <p id="status" style={{ color: isRecording ? "green" : "red" }}>
        {status}
      </p> */}
      <div className="space-start-recording">
        {
          transducerState === null &&  
          <button className="recording-button"
            onClick={() => {
              if(transducerType && model) {
                setRecordingActived(!recordingActived)
                setTransducerState(true);
                if(transducerType === 1) {
                  startRecordingNoteTaking();
                } else {
                  startRecordingMedicalConsultation();
                }
              }
            }}
          >
            <div className="recording-button__text">{t("transductor.startSession")}</div>
            {
              transducerType && model
              ? <div className="recording-button__circle"></div>
              : <i className="recording-button__icon icon zaia-i-lock-chat"></i>
            }
          </button>
        }
        {
          (transducerState === true || transducerState === false) &&
          <div className="active-recording-buttons">
            {
              transducerState === true &&
              <button className="recording-button recording-button--pause"
                onClick={() => {
                  setTransducerState(false);
                  stopRecording();
                }}
              > 
                <i className="recording-button__icon recording-button__icon--pause icon zaia-i-paused"></i>
              </button>
            }
            {
              transducerState === false &&
              <button className="recording-button recording-button--continue"
                onClick={() => {
                  setTransducerState(true);
                  if(transducerType === 1) {
                    startRecordingNoteTaking();
                  } else {
                    startRecordingMedicalConsultation();
                  }
                }}
              > 
                <div className="recording-button__text">{t("globally.continue")}</div>
                <div className="recording-button__circle"></div>
              </button>
            }
            <button className={`recording-button recording-button--finish ${(transcription || messages.length > 0) && "recording-button--finish--active"}`}
              onClick={() => 
                (transcription || messages.length > 0)  &&  
                finilizeSession()
              }
            >
              {t("transductor.finalizeSession")}
            </button>
          </div>
        }
      </div>
    </S.NewTransducer>
  );
}

function TransductorChooseType({
  transducerType,
  setTransducerType,
  transducerTypes,
  models,
  model,
  setModel
}) {

  const { t, 
    i18n: { language }
  } = useTranslation("global"); 

  useEffect(() => {
    if(P.languageSplited(language) !== "es") {
      setModel(null);
    } else {
      setModel(models[0].id);
    }
  }, [transducerType]);

  return (
    <div className="transducer-choose-type">
      <div className="transductor-mode">
        <div className="transductor-mode__label">{t("transductor.transductorMode")}</div>
        <div className="transductor-mode__selected">
          <ZaiaSelect
            type="select"
            setValue={(e) => {
                setTransducerType(e)
              }
            }
            value={transducerType}
            items={transducerTypes.map(
              (type) => {
                return {
                  value: type.id,
                  text: type.name
                }
              }
            )}
            placeholder={t("transductor.noMode")}
            placeholdertextcolor={"#3E3DA3"}
            shadow={false}
            fontSize="20px"
            backgroundColor={"#FFFFFF"}
            borderRadius={"20px"}
            heightList={"200px"}
            padding={"20px 15px"}
            textcolor={"#59599E"}
            arrowColor={"#59599E"}
            borderColor={"#6C6BCC"}
            textItemsColor={"#3E3DA3"}
          />
        </div>
      </div>
      {
        P.languageSplited(language) === "en" && transducerType &&
        <div className="transductor-mode">
          <div className="transductor-mode__label">{t("transductor.transductionModel")}</div>
          <div className="transductor-mode__selected">
            <ZaiaSelect
              type="select"
              setValue={(e) => {
                  setModel(e)
                }
              }
              value={model}
              items={models.filter(model => model.transducerType === 0 || model.transducerType === transducerType).map(
                (type) => {
                  return {
                    value: type.id,
                    text: type.name
                  }
                }
              )}
              placeholder={t("transductor.noMode")}
              placeholdertextcolor={"#3E3DA3"}
              shadow={false}
              fontSize="20px"
              backgroundColor={"#FFFFFF"}
              borderRadius={"20px"}
              heightList={"200px"}
              padding={"20px 15px"}
              textcolor={"#59599E"}
              arrowColor={"#59599E"}
              borderColor={"#6C6BCC"}
              textItemsColor={"#3E3DA3"}
            />
          </div>
        </div>
      }
      <div className="message-space">
        {
          transducerType === 1 &&
          <img src={talkingHead} alt="note talking" className="message-space__img--note-taking" />
        }
        {
          transducerType === 2 &&
          <img src={talkingHeads} alt="medical consultation" className="message-space__img--medical-consultation" />
        }
        {
          !transducerType &&
          <img src={record} alt="record" className="message-space__img--start" />
        }
        <div className="message-space__text">
          {
            !transducerType
            ? t("transductor.selectAMode")
            : transducerTypes.find(type => type.id === transducerType)?.description
          }
        </div>
      </div>
    </div>
  );
}


export default NewTransducer;
