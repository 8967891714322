import styled from "styled-components";

export const ModalPatientCard = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  /* z-index: 1; */
  display: flex;
  .box-container {
    margin: auto;
    width: 49%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .buttons-box-container {
      .buttons-box-patient {
        display: flex;
        justify-content: space-between;
        .button-box-patient {
          width: 48%;
        }
        &--center {
          justify-content: center;
        }
      }
      .phone-number-container {
        /* height: 43px; */
        width: 212px;
        position: relative;
        margin: 30px auto 0;
        .contact-info {
          background-color: ${
            ({
              theme,
            }) => 
              theme.background
          };
          border-radius: 10px;
          box-shadow: ${
            ({theme}) => 
            `3px 3px 5px ${theme.blackShadow}29`
          };
          &-box {
            padding: 10px 15px 7px 15px;
            &--emergency{ 
              border-top: solid 1px ${({theme}) => theme.lineSeparation};
              padding: 7px 15px 10px 15px;
            }
            .label {
              font-size: 12px;
              font-family: 'Nunito Bold';  
              color: ${({theme}) => theme.primaryColor};
              &--emergency{ 
                color: ${({theme}) => theme.primaryVariantColor};
              }
            }
            .name {
              font-family: 'Nunito';
              font-size: 15px;  
              color: ${({theme}) => theme.primaryVariantColor};
            }
            .number {
              font-family: 'Nunito ExtraBold';  
              font-size: 18px;
              color: ${({theme}) => theme.primaryColor};
              &--emergency{ 
                color: ${({theme}) => theme.primaryVariantColor};
              }
            }
          }
        }
      }
      /* .buttons-box-close {
        width: 35%;
        margin: auto;
      } */
    }
  }

  @media only screen and (max-width: 1024px) {
    .box-container {
      width: 500px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .dashboard__container-left{
      width: 100%;
    }
  }

  @media only screen and (max-width: 500px) {
    .box-container {
      margin: auto 15px !important;
      min-width: initial !important;
    }
  }
  
  @media only screen and (max-width: 375px) {
    .buttons-box-patient {
      display: block !important;
    }
    .button-box-patient {
      width: 100% !important;
    }
  }
`;
