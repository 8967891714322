import React, { useEffect, useState } from "react";
import * as S from "./ModalManagedAlerts.style"
import * as P from "../../../../../utils/pipes";

import { useTranslation } from "react-i18next";

const correctImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;

function ModalManagedAlerts({ 
  setModalActive
}) {

  const {t, i18n: { language }} = useTranslation("global"); 
  
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  return (
    <S.ModalManagedAlerts>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <i className="header__icon icon zaia-i-manage-account"></i>
            <div className="header__title">{"Gestion de alertas"}</div>
          </div>
          <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className="content">
          <img src={correctImg} alt="" className="content__img" />
          <div className="content__number">
            {"Se ha marcado "}
            <b>44</b>
            {" alerta como"}
          </div>
          <div className="content__option">
            <div className="content__option-text">
              Revisada
            </div>
            <i className="icon content__icon ion zaia-i-check-1"></i>
          </div>
          <button className="content__button"
            onClick={() => setModalActive(false)}
          >
            Hecho
          </button>
        </div>
      </div>
    </S.ModalManagedAlerts>
  );
}

export default ModalManagedAlerts;
