import styled from "styled-components";

export const LoginFormDiv = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  .loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    width: 300px;
    .input-container {
      min-width: 300px;
    }
    h2 {
      color: ${({theme}) => theme.primaryColor};
      margin: 10px 0 20px 0;
      font-family: "Nunito Black";
    }
    div {
      .login-title {
        color: ${({theme}) => theme.onBackground};
        margin-top: 30px;
        margin-bottom: 10px;
        font-size: 17px;
        font-family: "Nunito ExtraBold";
      }
    }
    .captcha-container {
      color: ${({theme}) => theme.warning};
      font-size: 12px;
    }
  }
  .text-center {
    a {
      color: ${({theme}) => theme.primaryColor};
    }
    margin-top: 20px;
    margin: 20px 0;
    text-align: center;
    font-family: "Nunito";
  }
`;
