import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../../../../contextAPI/StateProvider";
import * as S from "./ChatbotAlgorithmsCase.style"// pipes
import * as P from "../../../../../../utils/pipes";
import AlgorithmsView from "./components/algorithmsView/AlgorithmsView";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../../graphql/queries/Process";
import useRecordNavigation from "../../../../../../hooks/useRecordNavigation";
import { useQuery } from "@apollo/client";
import ZaiaReaction from "../../../../../zaiaComponents/zaiaReaction";
import { Actions } from "../../../../../../utils/actionsIds";

// Assets
const algorithmsImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/algoritms.svg`;

function ChatbotAlgorithmsCase() {


  const container = useRef(null);
  const textInput = useRef(null);
  const [activeinput, setActiveinput] = useState(false);
  const { t, i18n: { language } }= useTranslation("global");
  const [{ selectedPatient }, dispatch] = useStateValue();
  const { recordAction } = useRecordNavigation();
  const [inputText, setInputText] = useState('');
  
  const [programSelected, setProgramSelected] = useState();

  const { data: processData } = useQuery( 
    QUERY_GET_PROCESS_NAMES, 
    { 
      variables: { 
        whereFilter: 
        selectedPatient 
        ? {
          userToProcesses: {
            userId: {
              _in: [ selectedPatient?.id ]
            }
          }
        } 
        : null
      } 
    } 
  );

  const algorithmLinks = [
    {
      processName: {
        en: 'HACHOP',
        es: 'HACHOP'
      },
      icon: 'zaia-acho-covid',
      action: 'link',
      link: 'https://maia-survey.web.app/public'
    },
    {
      processName: {
        en: 'ORT Risk Calculator',
        es: 'Calculadora de riesgo ORT'
      },
      icon: 'zaia-i-opiods',
      action: 'link',
      link: 'https://landing.laboratorioslegrand.com/calculadora-de-riesgo-ort'
    }
  ]

  useEffect(() => {
    if(programSelected){
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: programSelected.id
      });
    } else {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null
      });
    }
  }, [programSelected])

  function removeSpecialCharacters(str) {
    return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
  }

  const activeInput = () => {
    textInput.current.focus(); 
    setActiveinput(true);
  } 

  if(!programSelected)
    return (
      <S.ChatbotAlgorithmsCase>
        <S.ProgramBoxes>
          <div className="box-searcher">
            <div className="searcher-container">
              <div className="searcher-content" ref={container} onClick={() => activeInput()}>
                <i className="searcher-content__icon icon zaia-i-search"></i>
                <div className="searcher-input">
                  <input
                    className="searcher-input__input"
                    type="text"
                    onChange={(event) => setInputText(event.target.value)}
                    value={inputText}
                    ref={textInput} 
                    placeholder={t("algorithms.search")}
                  />
                  <i className="searcher-input__icon icon zaia-search"/>
                </div>
              </div>
            </div>
          </div>
          <div className="reaction-content">
            <ZaiaReaction
              zaiaImg={algorithmsImg}
              text={t("algorithms.select")}
              sizeText="20px"
              widthimg="110px"
              textcolor="#707582"
              weighttext={false}
            />
          </div>
          <div className="algorithms__containerBoxes" >
            {
              algorithmLinks.map((item) => {
                
                if(inputText && !removeSpecialCharacters(P.dataTranslation(item.processName, language))?.includes(removeSpecialCharacters(inputText))){
                  return null;
                }

                return (
                  <div className="box-container" 
                    key={item.processName.en} 
                    onClick={() => {
                      window.open(item.link)
                    }}
                  >
                      <Box
                        icon={item.icon}
                        title={P.dataTranslation(item.processName, language)}
                      />
                  </div>
                )
              })
            }
            {
              processData?.process.map((process, index) => {

                if(inputText && !removeSpecialCharacters(P.dataTranslation(process.processName, language))?.includes(removeSpecialCharacters(inputText))){
                  return null;
                }

                return (
                  <div className="box-container" 
                    key={process.id} 
                    onClick={() => {
                      recordAction(Actions.ALGORITHMS.SELECT_PROGRAM, null, process.id);
                      setProgramSelected(process);
                    }}
                  >
                      <Box
                        icon={process.icon}
                        title={P.dataTranslation(process.processName, language)}
                        process={process}
                      />
                    {/* </ConditionalLink> */}
                  </div>
                )                
              })
            }            
          </div>
        </S.ProgramBoxes>
      </S.ChatbotAlgorithmsCase>
    )

  return (
    <S.ChatbotAlgorithmsCase>
      <AlgorithmsView
        algorithm={programSelected}
        setProgramSelected={setProgramSelected}
      />
    </S.ChatbotAlgorithmsCase>
  );
}

const Box = ({ icon, title }) => {
  return (
    <div>
      <S.BoxDiv>
        <div className="box__iconContainer">
          <i className={`icon ${icon}`} />
        </div>
        <h5>{title}</h5>
      </S.BoxDiv>
    </div>
  );
}

export default ChatbotAlgorithmsCase;
