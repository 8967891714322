import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalManagedAlerts = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    width: 100%;
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    max-width: 783px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: center;
    position: relative;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #E0E6ED;
      &__title {
        font-family: "Nunito Sans Bold";
        font-size: 20px;
      }
      &__text {
        display: flex;
        align-items: center; 
        color: #3E3E5B;
        font-size: Nunito Sans Extrabold;
      }
      &__icon {
        font-size: 20px;
        margin-right: 5px;
      }
      &__close {
        display: flex;
        width: 28px;
        height: 28px;
        border-radius: 50%;
        font-size: 9px;
        display: flex;
        background: #36369B;
        cursor: pointer;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #F8F8FF;
      border-radius: 0 0 14px 14px;
      &__img {
        width: 330px;
        margin-top: 20px;
      }
      &__number {
        margin-top: 40px;
        color: #3E3E5B;
        font-family: Nunito Sans;
        font-size: 25px;
      }
      &__option {
        display: flex;
        align-items: center; 
        margin-bottom: 10px;
        &-text {
          color: #3E3E5B;
          font-family: Nunito Sans Bold;
          font-size: 30px;
          margin-right: 10px;
        }
        &-icon {
          font-size: 20px;
          color: #3E3E5B;
          opacity: 0.4;
          margin-left: 10px;
        }
      }
      &__button {
        background-color: #27AE60;
        width: 250px;
        padding: 8px;
        border-radius: 75px;
        color: white;
        font-size: 20px;
        margin-bottom: 54px;
      }
    }
  }
`;