import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik, useField } from "formik";
import * as S from "./ModalCreateInvitePatient.style";
import * as P from "../../../../../utils/pipes";
import * as Yup from "yup";
import { ThemeContext } from "styled-components";

// Components
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { MUTATION_ENRROLL_PATIENCE } from "../../../../../graphql/mutations/Patience";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";
import {
  MUTATION_SIGN_UP_PATIENT,
  MUTATION_SIGN_UP_PATIENT_BY_PHONE,
} from "../../../../../graphql/mutations/User";

// hooks
import useActivateModal from "../../../../../hooks/useActivateModal";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import { QUERY_GET_COUNTRIES } from "../../../../../graphql/queries/Country";
import { QUERY_GET_USER_AND_USER_TO_PROCESS, QUERY_GET_USER_AND_USER_TO_PROCESS_PHONE, QUERY_GET_USER_TO_PROCESS } from "../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_USER_BY_EMAIL } from "../../../../../graphql/queries/User";
import { MUTATION_UPDATE_USER_TO_PROCESS_IS_ACEPTED } from "../../../../../graphql/mutations/UserToProcess";

// Assets
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaOk = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalCreateInvitePatient({ setCreateInvitePatientActive }) {
  const [typeForm, setTypeForm] = useState("invitation");
  const NEW_USERS_PASSWORD = process.env.REACT_APP_NEW_USERS_PASSWORD;
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const [formInfo, setFormInfo] = useState(null);
  const [response, setResponse] = useState();
  const [errorResponse, setErrorResponse] = useState();
  const { desactiveModal } = useActivateModal();
  const [createAuditPatient, setCreateAuditPatient] = useState(null);
  const browserLang = language.split("-")[0];

  const [enrrollPatience, { loading: enrrollLoading }] = useMutation(MUTATION_ENRROLL_PATIENCE);
  const [registerUserByEmail, { loading: userRegisterUserByEmail }] =
    useMutation(MUTATION_SIGN_UP_PATIENT);
  const [registerUserByPhone, { loading: userRegisterUserByPhone }] = useMutation(
    MUTATION_SIGN_UP_PATIENT_BY_PHONE
  );
  const { data: countriesData } = useQuery(QUERY_GET_COUNTRIES);
  const [formSuccess, setFormSuccess] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [formSuccessText, setFormSuccessText] = useState("");
  const [formErrorText, setFormErrorText] = useState("");
  const [button2SuccessText, setButton2SuccessText] = useState("");

  const [userAlreadyInvited, setUserAlreadyInvited] = useState(false);

  const [sendByEmail, setSendByEmail] = useState(true);

  const handleSubmit = (values, { resetForm }) => {
    if (typeForm === "invitation") {
      enrrollPatient(values, resetForm);
    } else {
      registerPatient(values, resetForm);
    }
  };

  const registerPatient = async (values, resetForm) => {
    let enrrollMessage;

    const registerUser = sendByEmail ? registerUserByEmail : registerUserByPhone;
    const codeNumber = values.phoneCode
      ? countriesData?.country.find((country) => country.id === values.phoneCode).phonecode
      : null;

    const registerVariables = sendByEmail
      ? {
          lastname: values.lastname,
          name: values.name,
          email: !createAuditPatient ? values.email.toLowerCase() : `${values.document}@zaia.com`,
          lang:
            browserLang === "es" || browserLang === "en" || browserLang === "fr"
              ? browserLang
              : "en",
          password: NEW_USERS_PASSWORD,
        }
      : {
          lastname: values.lastname,
          name: values.name,
          phone_number: `+${codeNumber}${values.phone}`,
          lang:
            browserLang === "es" || browserLang === "en" || browserLang === "fr"
              ? browserLang
              : "en",
        };

    try {
      const {
        data: { userSignUp },
      } = await registerUser({
        variables: registerVariables,
      });

      try {
        enrrollMessage = P.dataTranslation(JSON.parse(userSignUp.message), language);
        setFormSuccess(true);
        setFormSuccessText(enrrollMessage);
        setButton2SuccessText(t("inviteCreatePatient.registerAnotherUser"));
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryAgain")}`;
        setFormSuccess(false);
        setFormErrorText(enrrollMessage);
      }
      enrrollPatient(values);
    } catch (err) {
      try {
        enrrollMessage = P.dataTranslation(JSON.parse(err.message), language);
      } catch (err) {
        enrrollMessage = `${t("globally.genericError")},${t("globally.tryAgain")}`;
      }
      setFormSuccess(false);
      setFormErrorText(enrrollMessage);
      console.log("error", err);
    }
    setFormInfo(null);
    resetForm();
  };

  const enrrollPatient = async (values, resetForm) => {
    let enrrollMessage;
    setUserAlreadyInvited(null);

    const codeNumber = values.phoneCode
      ? countriesData?.country.find((country) => country.id === values.phoneCode).phonecode
      : null;

    const enrrollPatientVareiables = sendByEmail
      ? {
          descript: values.descript,
          processId: Number(values.processId),
          stageId: "enroll",
          email: !createAuditPatient ? values.email.toLowerCase() : `${values.document}@zaia.com`,
          lastname: "",
        }
      : {
          descript: values.descript,
          processId: Number(values.processId),
          stageId: "enroll",
          phone_number: `+${codeNumber}${values.phone}`,
          lastname: "",
        };

    try {
      const {
        data: {
          enroll_user_to_process: { status },
        },
      } = await enrrollPatience({
        variables: enrrollPatientVareiables,
      });

      if(status === "OK") {
        setFormSuccess(true);
        setFormSuccessText(t("inviteCreatePatient.successInvitePatient"));
        setButton2SuccessText(t("inviteCreatePatient.inviteAnotherPatient"));
      } else if(status === "EXISTING_PROCESS_RECORD") {
        setFormSuccess(false);
        setUserAlreadyInvited(values);
      } else if (status === "USER_NOT_FOUND") {
        setFormSuccess(false);
        setFormErrorText(t("inviteCreatePatient.userDoesntExist"));
      } else {
        setFormSuccess(false);
        setFormErrorText(t("inviteCreatePatient.errorInvitePatient"));
      }
      setResponse(enrrollMessage);
    } catch (err) {
      setFormSuccess(false);
      setFormErrorText("Error");
    }
    setFormInfo(null);
  };

  const countriesCodeArray = useMemo(() => {
    if (countriesData) {
      const countries = JSON.parse(JSON.stringify(countriesData?.country));

      const seenPhonecodes = new Set();
      const filterCountries = countries.filter((country) => {
        if (seenPhonecodes.has(country.phonecode)) {
          return false;
        } else {
          seenPhonecodes.add(country.phonecode);
          return true;
        }
      });

      return filterCountries
        ?.sort((a, b) => a.phonecode - b.phonecode)
        .map((country) => {
          return {
            value: country.id,
            text: "+" + country.phonecode,
          };
        });
    } else {
      return [];
    }
  }, [countriesData]);

  useEffect(() => {
    setFormLoading(enrrollLoading || userRegisterUserByEmail || userRegisterUserByPhone);
  }, [
    enrrollLoading,
    userRegisterUserByEmail,
    userRegisterUserByPhone
  ]);

  if (formLoading)
    return (
      <S.ModalCreateInvitePatient>
        <div className="modal-background"></div>
        <div className="modal-container">
          <div className="modal-reaction">
            <ZaiaReaction widthimg={"150px"} zaiaImg={logoZaiaLoading} padding={"200px 0px"} />
          </div>
        </div>
      </S.ModalCreateInvitePatient>
    );

  if (formSuccess)
    return (
      <S.ModalCreateInvitePatient>
        <div className="modal-background" onClick={() => setCreateInvitePatientActive(false)}></div>
        <div className="modal-container">
          <div className="modal-reaction">
            <ZaiaModalResponseContent
              button1={() => setCreateInvitePatientActive(false)}
              button2={() => setFormSuccess(null)}
              formSuccess={formSuccess}
              button1Text={t("globally.done")}
              button2Text={button2SuccessText}
              title={formSuccessText}
              doubleButton={true}
            />
          </div>
        </div>
      </S.ModalCreateInvitePatient>
    );

  if (formSuccess === false && userAlreadyInvited)
    return (
      <S.ModalCreateInvitePatient>
        <div className="modal-background" onClick={() => setCreateInvitePatientActive(false)}></div>
        <div className="modal-container modal-container--already-sent">
          <div className="modal-reaction">
            <InvitationAlreadySent
              userAlreadyInvited={userAlreadyInvited}
              setUserAlreadyInvited={setUserAlreadyInvited}
              setCreateInvitePatientActive={setCreateInvitePatientActive}
              setFormSuccess={setFormSuccess}
              setFormLoading={setFormLoading}
              setFormSuccessText={setFormSuccessText}
              setButton2SuccessText={setButton2SuccessText}
            />
          </div>
        </div>
      </S.ModalCreateInvitePatient>
    );

    if (formSuccess === false)
      return (
        <S.ModalCreateInvitePatient>
          <div className="modal-background" onClick={() => setCreateInvitePatientActive(false)}></div>
          <div className="modal-container">
            <div className="modal-reaction">
              <ZaiaModalResponseContent
                button1={() => setFormSuccess(null)}
                button2={() => setCreateInvitePatientActive(false)}
                formSuccess={false}
                button1Text={t("globally.tryAgain")}
                button2Text={t("inviteCreatePatient.tryLater")}
                title={t("globally.errorForm")}
                subtitle={formErrorText}
                doubleButton={true}
              />
            </div>
          </div>
        </S.ModalCreateInvitePatient>
      );

  return (
    <S.ModalCreateInvitePatient>
      <div className="modal-background" onClick={() => setCreateInvitePatientActive(false)}></div>
      <div className="modal-container">
        {/* <header className="settings__header">
          <div className="settings__header-title">
            <div className="settings__header-title-icon">
              <i className="zaia-icono-calendar"></i>
            </div>
            <h4>{t("calendar.form.title")}</h4>
          </div>
        </header> */}
        <div className="settings__container">
          <Formik
            initialValues={{
              name: formInfo?.name ? formInfo?.name : "",
              lastname: formInfo?.lastname ? formInfo?.lastname : "",
              email: formInfo?.email ? formInfo?.email : "",
              phoneCode: formInfo?.phoneCode ? formInfo?.phoneCode : "",
              phone: formInfo?.phone ? formInfo?.phone : "",
              processId: formInfo?.processId ? formInfo?.processId : "",
              descript: formInfo?.descript ? formInfo?.descript : "",
            }}
            validationSchema={Yup.object().shape(
              typeForm === "invitation"
                ? {
                    email:
                      sendByEmail &&
                      Yup.string()
                        .email(t("register.validation.email"))
                        .required(t("register.validation.required")),
                    phoneCode:
                      !sendByEmail && Yup.string().required(t("register.validation.required")),
                    phone: !sendByEmail && Yup.string().required(t("register.validation.required")),
                    processId: Yup.number()
                      .positive(t("register.validation.invalid"))
                      .required(t("register.validation.required")),
                  }
                : {
                    name: Yup.string()
                      .min(3, t("register.validation.min3"))
                      .max(15, t("register.validation.max"))
                      .required(t("register.validation.required")),
                    lastname: Yup.string()
                      .min(3, t("register.validation.min3"))
                      .max(15, t("register.validation.max"))
                      .required(t("register.validation.required")),
                    email:
                      sendByEmail &&
                      Yup.string()
                        .email(t("register.validation.email"))
                        .required(t("register.validation.required")),
                    phoneCode:
                      !sendByEmail && Yup.string().required(t("register.validation.required")),
                    phone: !sendByEmail && Yup.string().required(t("register.validation.required")),
                    processId: Yup.number()
                      .positive(t("register.validation.invalid"))
                      .required(t("register.validation.required")),
                  },
              ["email", "document"]
            )}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <S.InvitePatientsModalDiv>
                <div className="modal-header">
                  <div className="modal-header__options">
                    <div
                      className={`modal-header__option ${
                        typeForm === "invitation" && `modal-header__option--active`
                      }`}
                      onClick={() => setTypeForm("invitation")}
                    >
                      {t("inviteCreatePatient.invitePatient")}
                    </div>
                    <div
                      className={`modal-header__option ${
                        typeForm === "register" && `modal-header__option--active`
                      }`}
                      onClick={() => setTypeForm("register")}
                    >
                      {t("inviteCreatePatient.createPatient")}
                    </div>
                  </div>
                  <div
                    className="modal-header__close"
                    onClick={() => setCreateInvitePatientActive(false)}
                  >
                    <i className="modal-header__close-icon icon zaia-icono-cerrar" />
                  </div>
                </div>
                <form onSubmit={props.handleSubmit}>
                  {/* <div className="send-by-container">
                    <div className="send-by-buttons">
                      <button
                        className={`send-by-button ${sendByEmail && "send-by-button--selected"}`}
                        onClick={() => setSendByEmail(true)}
                        type="button"
                      >
                        {t("inviteCreatePatient.byEmail")}
                      </button>
                      <button
                        className={`send-by-button ${!sendByEmail && "send-by-button--selected"}`}
                        onClick={() => setSendByEmail(false)}
                        type="button"
                      >
                        {t("inviteCreatePatient.byPhoneNumber")}
                      </button>
                    </div>
                  </div> */}
                  <div className="card-inputs">
                    {typeForm === "invitation" && (
                      <FormInvitationInputs
                        countriesData={countriesData}
                        sendByEmail={sendByEmail}
                        countriesCodeArray={countriesCodeArray}
                      />
                    )}
                    {typeForm === "register" && (
                      <FormRegisterInputs
                        createAuditPatient={createAuditPatient}
                        setCreateAuditPatient={setCreateAuditPatient}
                        countriesData={countriesData}
                        sendByEmail={sendByEmail}
                        countriesCodeArray={countriesCodeArray}
                      />
                    )}
                  </div>
                  <div className="card-buttons">
                    {typeForm === "invitation" && (
                      <>
                        {/* buttons for invitation form */}
                        <ZaiaButton
                          type="submit"
                          option="primary"
                          title={t("inviteCreatePatient.sendInvitation")}
                          backgroundColor={"#17AF54"}
                          borderRadius="5"
                          fontSize="32.25"
                          height="64"
                          fontFamily={"Nunito Sans"}
                          shadow={false}
                        />
                      </>
                    )}
                    {typeForm === "register" && (
                      <>
                        <ZaiaButton
                          type="submit"
                          option="primary"
                          title={t("inviteCreatePatient.registerNewUser")}
                          backgroundColor={"#17AF54"}
                          borderRadius="5"
                          fontSize="32.25"
                          height="64"
                          fontFamily={"Nunito Sans"}
                          shadow={false}
                        />
                      </>
                    )}
                  </div>
                </form>
              </S.InvitePatientsModalDiv>
            )}
          </Formik>
        </div>
      </div>
    </S.ModalCreateInvitePatient>
  );
}

// components

const FormInvitationInputs = ({ countriesData, sendByEmail, countriesCodeArray }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { data: processData } = useQuery(QUERY_GET_PROCESS_NAMES);

  return (
    <>
      <div className="card-inputs-container card-inputs-container--invitation">
        {sendByEmail && (
          <ZaiaInputForm
            placeholder={t("inviteCreatePatient.patientEmail")}
            type="text"
            name="email"
            padding={"20px 27px 20px 27px"}
            fontSize={"19px"}
            textcolor={"#878792"}
            borderColor={"#526479"}
            borderRadius={"0px"}
            onlyBottomBorder={true}
          />
        )}
        {!sendByEmail && (
          <div className="phone-inputs">
            <div className="phone-inputs__indicative">
              <ZaiaSelectForm
                type="select"
                name="phoneCode"
                maxHeightList={"200px"}
                borderRadius={"14px"}
                textcolor={"#878792"}
                borderColor={"#526479"}
                padding={"20px 27px 20px 27px"}
                fontSize={"19px"}
                items={countriesCodeArray}
              />
            </div>
            <ZaiaInputForm
              placeholder={t("globally.phoneNumber")}
              type="text"
              name="phone"
              padding={"20px 27px 20px 27px"}
              fontSize={"19px"}
              textcolor={"#878792"}
              borderColor={"#526479"}
              borderRadius={"0px"}
              onlyBottomBorder={true}
            />
          </div>
        )}

        {/* <p>{t("inviteCreatePatient.chatName")}</p>
        <ZaiaInputForm 
          placeholder={t("inviteCreatePatient.chatName")}
          type="text"
          name="chatName"
          icon="icono-chat-relleno"
        /> */}
        <div className="label">
          <i className="label-icon icon zaia-i-check" />
          {t("inviteCreatePatient.patientProgram")}
        </div>
        <ZaiaSelectForm
          placeholder={t("globally.selectProgram")}
          type="text"
          name="processId"
          padding={"20px 27px 20px 27px"}
          fontSize={"19px"}
          textcolor={"#878792"}
          borderColor={"#526479"}
          borderRadius={"14px"}
          // icon="icono-salud"
          items={processData?.process
            .map((process) => {
              return { value: process.id, text: P.dataTranslation(process.processName, language) };
            })
            .sort(function (a, b) {
              return a.text?.localeCompare(b.text);
            })}
        />
        <div className="label">
          <i className="label-icon icon zaia-i-message-outline" />
          {t("inviteCreatePatient.invitationMessage")}
        </div>
        <InputParagraph
          placeholder={t("inviteCreatePatient.invitationMsgPlaceholder")}
          name="descript"
          type="textarea"
        />
      </div>
    </>
  );
};

const FormRegisterInputs = ({
  createAuditPatient,
  setCreateAuditPatient,
  countriesData,
  sendByEmail,
  countriesCodeArray,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { data: processData } = useQuery(QUERY_GET_PROCESS_NAMES);
  const theme = useContext(ThemeContext);

  return (
    <>
      <div className="card-inputs-container">
        {sendByEmail && (
          <ZaiaInputForm
            placeholder={t("inviteCreatePatient.patientEmail")}
            type="text"
            name="email"
            padding={"20px 27px 20px 27px"}
            fontSize={"19px"}
            textcolor={"#878792"}
            borderColor={"#526479"}
            borderRadius={"0px"}
            onlyBottomBorder={true}
          />
        )}
        {!sendByEmail && (
          <div className="phone-inputs">
            <div className="phone-inputs__indicative">
              <ZaiaSelectForm
                type="select"
                name="phoneCode"
                maxHeightList={"200px"}
                borderRadius={"14px"}
                textcolor={"#878792"}
                borderColor={"#526479"}
                padding={"20px 27px 20px 27px"}
                fontSize={"19px"}
                items={countriesCodeArray}
              />
            </div>
            <ZaiaInputForm
              placeholder={t("globally.phoneNumber")}
              type="text"
              name="phone"
              padding={"20px 27px 20px 27px"}
              fontSize={"19px"}
              textcolor={"#878792"}
              borderColor={"#526479"}
              borderRadius={"0px"}
              onlyBottomBorder={true}
            />
          </div>
        )}

        {/* {
          !createAuditPatient ?
          <>
            <div className="label">
              <i className="label-icon icon zaia-i-user-card"/>
              {t("inviteCreatePatient.patientEmail")}
            </div>
            <ZaiaInputForm 
              placeholder={t("inviteCreatePatient.patientEmail")}
              type="text"
              name="email"
              padding={"20px 27px 20px 27px"}
              fontSize={"19px"}
              textcolor={"#878792"}
              borderColor={"#526479"}
              borderRadius={"0px"}
              onlyBottomBorder={true}
              // icon="icono-id"
            />
          </> : null
        } */}

        <div className="label">
          <i className="label-icon icon zaia-i-user-card" />
          {t("globally.patientName")}
        </div>
        <ZaiaInputForm
          placeholder={t("globally.patientName")}
          type="text"
          name="name"
          padding={"20px 27px 20px 27px"}
          fontSize={"19px"}
          textcolor={"#878792"}
          borderColor={"#526479"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
          // icon="icono-nombre"
        />
        <div className="label">
          <i className="label-icon icon zaia-i-user-card" />
          {t("inviteCreatePatient.patientLastName")}
        </div>
        <ZaiaInputForm
          placeholder={t("inviteCreatePatient.patientLastName")}
          type="text"
          name="lastname"
          padding={"20px 27px 20px 27px"}
          fontSize={"19px"}
          textcolor={"#878792"}
          borderColor={"#526479"}
          borderRadius={"0px"}
          onlyBottomBorder={true}
          // icon="icono-nombre"
        />
        {/* <p>{t("inviteCreatePatient.chatName")}</p>
        <ZaiaInputForm 
          placeholder={t("inviteCreatePatient.chatName")}
          type="text"
          name="chatName"
          icon="icono-chat-relleno"
        /> */}
        {/* <p>{!createAuditPatient ? t("inviteCreatePatient.doc") : t("inviteCreatePatient.idetificationCode")}</p>
        <ZaiaInputForm 
          placeholder={!createAuditPatient ? t("inviteCreatePatient.doc") : t("inviteCreatePatient.idetificationCode")}
          type="text"
          name="document"
          icon="icono-id"
        /> */}

        <div className="label">
          <i className="label-icon icon zaia-i-check" />
          {t("inviteCreatePatient.patientProgram")}
        </div>
        <ZaiaSelectForm
          placeholder={t("globally.selectProgram")}
          type="text"
          name="processId"
          padding={"20px 27px 20px 27px"}
          fontSize={"19px"}
          textcolor={"#878792"}
          borderColor={"#526479"}
          borderRadius={"14px"}
          // icon="icono-salud"
          items={processData?.process.map((process) => {
            return { value: process.id, text: P.dataTranslation(process.processName, language) };
          })}
        />
        {!createAuditPatient ? (
          <>
            <div className="label">
              <i className="label-icon icon zaia-i-message-outline" />
              {t("inviteCreatePatient.invitationMessage")}
            </div>
            <InputParagraph
              placeholder={t("inviteCreatePatient.invitationMsgPlaceholder")}
              name="descript"
              type="textarea"
            />
          </>
        ) : null}
      </div>
    </>
  );
};

const InputParagraph = ({ placeholder, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <S.InputParagraphDiv>
      <textarea className="text-area-input" placeholder={placeholder} {...field} {...props} />
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
    </S.InputParagraphDiv>
  );
};

const InvitationAlreadySent = ({
  userAlreadyInvited,
  setUserAlreadyInvited,
  setCreateInvitePatientActive,
  setFormSuccess,
  setFormLoading,
  setFormSuccessText,
  setButton2SuccessText
}) => {

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
 
  const [userInvited, setUserInvited] = useState();
  const [userInvitedStatus, setUserInvitedStatus] = useState(false);
  const [updateUserToProcessIsAcepted] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_IS_ACEPTED);

  const { data: userData, loading: userLoading } = useQuery(
    userAlreadyInvited.email 
    ? QUERY_GET_USER_AND_USER_TO_PROCESS
    : QUERY_GET_USER_AND_USER_TO_PROCESS_PHONE,
    {
      variables: userAlreadyInvited.email 
      ? {
        emailPatient: userAlreadyInvited.email.toLowerCase(),
        processId: userAlreadyInvited.processId
      }
      : {
        phonePatient: userAlreadyInvited.phone,
        processId: userAlreadyInvited.processId
      }
    }
  );

  console.log("userData", userData);

  useEffect(() => {
    if(userData) {
      setUserInvited(userData?.user[0]);
      setUserInvitedStatus(userData?.user[0]?.userToProcesses?.[0].isAcepted)
    }
  }, [userData]);

  const resendInvitation = async () => {
    setFormLoading(true);
    try {
      await updateUserToProcessIsAcepted({
        variables: {
          isAcepted: userInvitedStatus === null ? false : null,
          id: userInvited.userToProcesses?.[0].id
        },
      });

      if(userInvitedStatus === null) {
        try {
          await updateUserToProcessIsAcepted({
            variables: {
              isAcepted: null,
              id: userInvited.userToProcesses?.[0].id
            },
          });
          setFormLoading(false);
          setUserAlreadyInvited(false);
          setFormSuccess(true);
          setFormSuccessText(t("profile.invitationManagement.sendInvitationSuccess"));
          setButton2SuccessText(t("inviteCreatePatient.inviteAnotherPatient"));
        } catch (err) {
          setFormLoading(false);
          setUserAlreadyInvited(false);
          setFormSuccess(false);
          setFormSuccessText("Error al reenviar la invitación");
        }
      } else {
        setFormLoading(false);
        setUserAlreadyInvited(false);
        setFormSuccessText(t("profile.invitationManagement.sendInvitationSuccess"));
        setFormSuccess(true);
        setButton2SuccessText(t("inviteCreatePatient.inviteAnotherPatient"));
      }
      
    } catch (err) {
      setFormLoading(false);
      setUserAlreadyInvited(false);
      setFormSuccess(false);
      setFormSuccessText("Error al reenviar la invitación");
    }
    
  }

  if (userLoading)
    return (
      <ZaiaReaction widthimg={"150px"} zaiaImg={logoZaiaLoading} padding={"200px 0px"} />
    );

  return (
    <S.InvitationAlreadySent>
      <div className="header">
        <div className="header__close"
          onClick={() => setCreateInvitePatientActive(false)}
        >
          <i className="header__close-icon icon zaia-icono-cerrar"></i>
        </div>
      </div>
      <div className="patient-card">
        <div className="patient-card__img">
          {
            userInvitedStatus
            ? <img className="patient-photo" src={userInvited?.picture} alt="" />
            : <div className="icon-container">
              <i className="icon-container__icon icon zaia-i-person"></i>
            </div>
          }
        </div>
        <div className="patient-card__info">
          <div className="patient-card__title">{
            userInvitedStatus      
            ? userInvited?.name + " " + userInvited?.lastname
            : t("inviteCreatePatient.patientInvitedBefore") 
          }</div>
          <div className="patient-card__email">{userInvited?.email}</div>
          {
            userInvitedStatus
            ? <div className="patient-card__program-errolled">{t("inviteCreatePatient.alreadyInProgram")}</div>
            : <div className="patient-card__program-label">{t("globally.program")}</div>
          }
          <div className="patient-card__program">
            <div className="patient-card__program-text">{P.dataTranslation(userInvited?.userToProcesses?.[0].process.processName, language)}</div>
            {
              userInvitedStatus &&
              <div className="patient-card__program-icon-container">
                <i className="patient-card__program-icon icon zaia-i-check-circle"></i>
              </div>
            }
          </div>
          {
            !userInvitedStatus &&
            <div className="patient-card__acceptance">
              <div className="patient-card__acceptance-text-1">{t("inviteCreatePatient.thePatient")}</div>
              <div className="patient-card__acceptance-text-acceptance"
                style={{
                  color: userInvitedStatus === false ? "#1F1F52": "#019AF6"
                }}
              >
                {
                  userInvitedStatus === false ? t("inviteCreatePatient.hasRejected") : t("inviteCreatePatient.hasNotAccepted")
                }
              </div>
              <div className="patient-card__acceptance-icon-container"
                style={{
                  backgroundColor: userInvitedStatus === false ? "#1F1F52": "#019AF6"
                }}
              >
                <i className={`patient-card__acceptance-icon icon ${userInvitedStatus === false ? "zaia-i-tooltip" : "zaia-i-tooltip"}`}></i>
              </div>
              <div className="patient-card__acceptance-text-2">{t("inviteCreatePatient.theInvitation")}</div>
            </div>
          }
        </div>
      </div>
      <div className="button-section">
        <button className="invite-other-process"
          onClick={
            () => setFormSuccess(null)
          }
        >
          {t("inviteCreatePatient.inviteToAnotherProcess")}
        </button> 
        {
          !userInvitedStatus &&
          <button className="resend-invitation"
            onClick={
              () => {
                resendInvitation()
              }
            }
          >
            {t("inviteCreatePatient.resendInvitation")}
          </button>
        }
      </div>
    </S.InvitationAlreadySent>
  );
};

export default ModalCreateInvitePatient;
