import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./TransductionActions.style";
import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import TranducerMessagesList from "../tranducerMessagesList/TranducerMessagesList";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../graphql/queries/Process";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { CONSULT_GENERAL_CHAT_IN_FLORENCE_CHATGPT } from "../../../../../graphql/mutations/Chat";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import { jsPDF } from "jspdf";
import Modal from "../../../../../utils/modal";
import ModalSelectPatient from "../../../shared/modals/modalSelectPatient";
import { MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP } from "../../../../../graphql/mutations/UserToProcess";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function TransductionActions({
  messages,
  setMessages,
  transducerType,
  transcription,
  setTranscription
}) {

  const [processSelected, setProgramSelected] = useState();
  const [templateSelected, setTemplateSelected] = useState();
  const [addNotePatient, setAddNotePatient] = useState(false);
  const [addNotePatientSelected, setAddNotePatientSelected] = useState();
  const [addNotePatientProcessSelected, setAddNotePatientProcessSelected] = useState();
  const [addNotePatientProcessList, setAddNotePatientProcessList] = useState();
  const [templatedApplied, setTemplatedApplied] = useState(false);
  const [transcriptionToTemplate, setTranscriptionToTemplate] = useState(false);
  const [activePatientSelection, setActivePatientSelection] = useState(false);
  const [success, setSuccess] = useState(null);

  const [chatBotGeneral, {loading: chatBotGeneralLoading}] = useLazyQuery(
    CONSULT_GENERAL_CHAT_IN_FLORENCE_CHATGPT,
    {
      fetchPolicy: "network-only"
    }
  );
  const [updateUserToProcess, {loading: updateUserToProcessLoading}] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP);
  
  const { data: processTypes } = useQuery(QUERY_GET_PROCESS_TYPES);

  const { t, 
    i18n: { language } 
   } = useTranslation("global"); 

  const getTranscription = () => {
    if(transducerType === 2) {
      return transcription;   
    } else {
      return messages.map((message) => 
        (message.messagerType ? `${t("globally.doctor")}: ` : `${t("globally.patient")}: `) +
        message.messageText).join(" \n");
    }
  }

  const handleCopy = async () => {
    try {
      const textToCopy = getTranscription(); // Obtiene el texto del textarea
      await navigator.clipboard.writeText(textToCopy); // Copia el texto al portapapeles
    } catch (err) {
      console.error("Error al copiar el texto: ", err);
    }
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    const pageWidth = doc.internal.pageSize.getWidth(); // Ancho de la página
    const marginLeft = 10; // Margen izquierdo
    const maxWidth = pageWidth - marginLeft * 2; // Ancho máximo para el texto
    let lines;

    // Divide el texto en líneas automáticas
    if(templatedApplied) {
      lines = doc.splitTextToSize(transcriptionToTemplate, maxWidth);
    } else {
      lines = doc.splitTextToSize(getTranscription(), maxWidth);
    }

    // Añade el texto línea por línea
    doc.text(lines, marginLeft, 20); // Empieza desde la posición (x: 10, y: 20)

    // Descarga el PDF
    doc.save(`${getTranscription().slice(0, 20)}.pdf`);    
  };

  const applyTemplate = async () => {    
    setTemplatedApplied(true);
    setTranscriptionToTemplate("");

    const {
      data: {
        consult_chat_gpt,
      },
    } = await chatBotGeneral({
      variables: {
        prompt: `
          Objective: Adapt the following text to match the structure of the provided template. Do not use any content from the template itself; the template serves only as a structural guide. Ensure the adapted text preserves the language of the original input. If the original text does not provide content for a specific section of the template, omit that section entirely in the output.

          Original Text (to be adapted):
          ${
            getTranscription()
          }

          Template (structure only, do not use the text as content):
          ${
            P.dataTranslation(
              completeProcessSelected?.templates?.find((template) => template.id === templateSelected)?.content,
              language
            )
          }

          Instructions:

          Use the template strictly as a guide for structure; do not copy or include any text from the template in the response.
          Preserve the language of the original text.
          Organize the original text to fit the format and structure of the template, filling in only the sections where relevant content exists.
          If the original text does not provide content for a specific section, leave that section out of the final response.
          Return the final result in the same language as the original text, structured according to the template.
          Output Format:

          Reflect the structure of the template but include only the sections that can be filled with content from the original text.
          Omit any sections without relevant information from the original text.
          Expected Output Example:
          If the template is in English and has three sections, but the original text provides content for only two of them, the output will only include those two sections and exclude the empty one.
        `,
      },
    });

    setTranscriptionToTemplate(consult_chat_gpt.answer);
  }

  const clearTemplateOptions = () => {
    setProgramSelected(null);
    setTemplateSelected(null);
    setTemplatedApplied(false);
  }

  // use memo to get procces by programSelected
  const completeProcessSelected = useMemo(() => {
    if(processSelected) {
      return processTypes.process.find((item) => item.id === processSelected);
    }
    return null;
  }, [processSelected]);

  const handleCloseModalPatients = (e) => {
    setActivePatientSelection(e);
    
    const button = document.getElementById('saveButton'); // Obtener el elemento por id
    console.log("activePatientSelection", button);
    if (button) {
      button.click(); // Simular el clic
    } else {
      console.error("El botón con el id 'saveButton' no está disponible.");
    }
  }

  useEffect(() => {
    if(addNotePatientSelected) {
      if(addNotePatientSelected.userToProcesses.length === 1) {
        setAddNotePatientProcessSelected(
          addNotePatientSelected.userToProcesses[0].process
        );
        setAddNotePatientProcessList(null);
      } else {
        setAddNotePatientProcessSelected(null);
        setAddNotePatientProcessList(addNotePatientSelected.userToProcesses.map((userToProcess) => userToProcess.process));
      }
    }
  }, [addNotePatientSelected]);

  const handleSubmitNewNote = async () => {    
    const userToProcessId = await addNotePatientSelected.userToProcesses.find(userToProcess => userToProcess.process.id === addNotePatientProcessSelected.id).id;
    try {
      await updateUserToProcess({
        variables: {
          updates: {
            descript: templatedApplied ? transcriptionToTemplate : getTranscription()
          },
          userToProcessId: userToProcessId
        },
      });
      setSuccess(true);
    } catch (error) {
      setSuccess(false);
      console.log(error);
    }
  }


  return (
    <S.TransductionActions>
      { 
        <Modal open={activePatientSelection}>
          <ModalSelectPatient
            setModalActive={handleCloseModalPatients}
            setPatient={setAddNotePatientSelected}
            patientSelected={addNotePatientSelected}
          />
        </Modal>
      }
      <Modal
        open={success || success === false}
      >
        <S.ModalResponse>
          <div className="modal-background" onClick={() => setSuccess(null)}>
          </div>
          <div className="modal-container">
              <ZaiaModalResponseContent
                button1={() => {setSuccess(null)}}
                formSuccess={success}
                button1Text={success ? t("followUp.confirm") : t("globally.accept")}
                title={
                  success
                  ? t("notes.createNoteSuccess")
                  : t("globally.errorForm")
                }
                subtitle={!success && t("notes.createNoteError")}
              />
          </div>
        </S.ModalResponse>
      </Modal>
      <div className="transductions-actions">
        <div className="options-panel">
          <div className="options-panel__left">
            <div className="fill-template">
              
              <Popup
                trigger={
                  <button className={`select-filter ${"select-filter--actived"}`}>
                    <div className="select-filter__text">
                      <b>{
                        templatedApplied 
                        ? t("transductor.template") + " "
                        : t("transductor.fillTemplate") + " "
                      }</b> 
                        {
                          templatedApplied &&
                          P.dataTranslation(completeProcessSelected?.templates?.find((template) => template.id === templateSelected)?.title, language)
                        }
                    </div>
                    <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                  </button>
                }
                closeOnDocumentClick
                keepTooltipInside
                position={["bottom center"]}
                nested
              >
                {
                  close => (
                    <S.SelectTemplate>
                      <div className="select">
                        <div className="select__label">{t("globally.selectProgram")}</div>
                        <div className="select__selector">
                          <ZaiaSelect
                            placeholder={t("globally.selectProgram")}
                            textcolor="#3E3DA3"
                            placeholdertextcolor="#36369B"
                            fontSize={"15px"}
                            height={"40px"}
                            errorOption={false}
                            maxHeightList={"200px"}
                            padding={"10px 20px"}
                            borderColor={"#3E3DA3"}
                            borderRadius={"70px"}
                            setValue={(value) => setProgramSelected(value)}
                            value={processSelected}
                            items={
                              processTypes?.process?.filter((process) => process.templates.length > 0).map((process) => {
                              return {
                                value: process.id,
                                text: P.dataTranslation(
                                  process.processName,
                                  language  
                                ),
                              };
                            })
                            }
                          />
                        </div>
                      </div>
                      <div className="select">
                        <div className="select__label">{t("transductor.selectTemplateProgram")}</div>
                        <div className="select__selector">
                          <ZaiaSelect
                            placeholder={t("transductor.selectTemplate")}
                            textcolor={processSelected ? "#3E3DA3" : "#707582"}
                            placeholdertextcolor={processSelected ? "#36369B" : "#707582"}
                            fontSize={"15px"}
                            height={"40px"}
                            errorOption={false}
                            maxHeightList={"200px"}
                            padding={"10px 20px"}
                            borderColor={processSelected ? "#3E3DA3" : "transparent"}
                            backgroundColor={processSelected ? "#FFFFFF" : "#E0E6ED"}
                            borderRadius={"70px"}
                            setValue={(value) => setTemplateSelected(value)}
                            value={templateSelected}
                            items={
                              completeProcessSelected?.templates?.map((template) => {
                                return {
                                  value: template.id,
                                  text: P.dataTranslation(
                                    template.title,
                                    language
                                  ),
                                };
                              })
                            }
                          />
                        </div>
                      </div>
                      {
                        !templateSelected &&
                        <div className="select__warning">
                          <div className="select__warning-text">{t("transductor.selectProgramToProceed")}
                          <div className="error">*</div>
                          </div>
                        </div>
                      }
                      {
                        processSelected && templateSelected &&
                        <button 
                          className="apply-template-button"
                          onClick={() => {
                            applyTemplate();
                          }}
                        >
                          {t("transductor.applyTemplate")}
                        </button>
                      }
                    </S.SelectTemplate>
                  )
                }
              </Popup>   
            </div>
            {
              templatedApplied &&
              <button className="reset-button"
                onClick={() => clearTemplateOptions()}
              >
                <i className="reset-button__icon icon zaia-icono-cerrar"></i>
                <div className="reset-button__text">{t("globally.reset")}</div>
              </button>
            }
          </div>
          <div className="options-panel__right">
            
            <Popup
              trigger={
                <button className="save-button" id="saveButton">
                  <div className="save-button__text">{t("transductor.saveAs")}</div>
                  <div className="save-button__icon-container">
                    <i className="save-button__icon icon zaia-icono-dots-vertical"></i>
                  </div>
                </button>
              }
              closeOnDocumentClick
              keepTooltipInside
              position={["bottom center"]}
              nested
            >
              {
                close => (
                  <S.SaveAs>
                    <button className="button-option"
                      onClick={() => {
                        handleCopy();
                        close();
                      }}
                    >
                      <i className="button-option__icon icon zaia-i-doc-normal"></i>
                      <div className="button-option__text">{t("transductor.justCopy")}</div>
                    </button>
                    <button className="button-option"
                      onClick={() => {
                        handleDownloadPDF();
                        close();
                      }}
                    >
                      <i className="button-option__icon icon zaia-i-download"></i>
                      <div className="button-option__text">{t("transductor.downloadAsAPdf")}</div>
                    </button>
                    {
                      !addNotePatient 
                      ? <button className="button-option"
                        onClick={() => {
                          setAddNotePatient(true);
                        }}
                      >
                        <i className="button-option__icon icon zaia-i-notes button-option__icon--feather"></i>
                        <div className="button-option__text">{t("transductor.notePatient")}</div>
                        <i className="button-option__icon-arrow icon zaia-icono-dropdown-light"></i>
                      </button>
                      : <div className="add-note-patient">
                        <button className="button-option button-option--open"
                          onClick={() => {
                            setAddNotePatient(false);
                          }}
                        >
                          <i className="button-option__icon icon zaia-i-notes button-option__icon--feather"></i>
                          <div className="button-option__text">{t("transductor.notePatient")}</div>
                          <i className="button-option__icon-arrow zaia-i-up"></i>
                        </button>
                        <div className="select-patient">
                          <div className="select-patient__label">{t("globally.patients")}</div>
                          <div className="select-patient__container">
                            <div className="button-select"
                              onClick={() => {
                                setActivePatientSelection(true);
                                close();
                              }}
                            >
                              <div className="button-select__text">{t("globally.selectPatient")}</div>
                              <i className="button-select__icon icon zaia-i-down"></i>
                            </div>
                          </div>
                        </div>
                        {
                          addNotePatientProcessList && addNotePatientSelected &&
                          <div className="select-patient">
                            <div className="select-patient__label">{t("globally.process")}</div>
                            <div className="select-patient__container">
                              <ZaiaSelect
                                placeholder={t("globally.selectProgram")}
                                textcolor="#FFFFFF"
                                placeholdertextcolor="#FFFFFF"
                                backgroundColor={"#36369B"}
                                fontSize={"15px"}
                                height={"40px"}
                                errorOption={false}
                                maxHeightList={"200px"}
                                padding={"10px 20px"}
                                borderColor={"#36369B"}
                                borderRadius={"70px"}
                                value={addNotePatientProcessSelected}
                                textItemsColor={"#36369B"}
                                setValue={(value) => setAddNotePatientProcessSelected(
                                  addNotePatientProcessList.find((process) => process.id === value)
                                )}
                                items={
                                  addNotePatientProcessList?.map((process) => {
                                    return {
                                      value: process.id,
                                      text: P.dataTranslation(

                                        process.processName,
                                        language
                                      ),
                                    };
                                  })
                                }
                              />
                            </div>
                          </div>
                        }
                        {
                          addNotePatientSelected &&
                          <>
                            <div className="patient-selected"
                              onClick={() => {
                                setActivePatientSelection(true);
                                close();
                              }}
                            >
                              <div className="patient-selected__left">
                                <img src={addNotePatientSelected?.picture} alt="" className="patient-selected__img" />
                                <div className="patient-selected__info">
                                  <div className="patient-selected__name">
                                    {addNotePatientSelected?.name + " " + addNotePatientSelected?.lastname}
                                    
                                  </div>
                                  {
                                    addNotePatientProcessSelected &&
                                    <div className="patient-selected__process">
                                      {
                                        addNotePatientProcessSelected &&
                                        P.dataTranslation(addNotePatientProcessSelected.processName, language)
                                      }
                                    </div>
                                  }
                                  <div className="patient-selected__change">{t("transductor.changePatient")}</div>
                                </div>
                              </div>
                              <div className="patient-selected__right">
                                <i className="patient-selected__icon-change icon zaia-i-refresh"></i>
                              </div>
                            </div>
                          </>
                        }
                        {
                          addNotePatientProcessSelected && addNotePatientSelected &&
                          <button className="save-patient-note"
                            onClick={() => {
                              handleSubmitNewNote();
                              close();
                            }}
                          >
                            {t("transductor.saveNote")}
                          </button>
                        }
                      </div>
                    }
                  </S.SaveAs>
                )
              }
            </Popup>   
          </div>
        </div>
        <div className="transdution-display">
        {
          templatedApplied
          ? <div className="transduction-to-template">
            <div className="transduction-to-template__text">
              {
                chatBotGeneralLoading && 
                <ZaiaReaction
                  widthimg="150px"
                  zaiaImg={logoZaiaLoading}
                /> 
              }
              {`${transcriptionToTemplate}`}
            </div>
            {/* <div className="transduction-to-template__footer">
              <div className="transduction-to-template__footer-label">
                {t("transductor.conversation")}
              </div>
              <div className="transduction-to-template__footer-redirection">
                {t("transductor.fillTemplate")}
              </div>
            </div> */}
          </div>
          : <div className="transduction-original">
            <TranducerMessagesList
              messages={messages}
              setMessages={setMessages}
              transducerType={transducerType}
              transcription={transcription}
              setTranscription={setTranscription}
            />
          </div>
        }
        </div>
      </div>
    </S.TransductionActions>
  );
}

export default TransductionActions;
