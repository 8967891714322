import styled from "styled-components";

export const Face = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 10px;
  align-items: center;
  div {
    display: ${({flex}) => flex ? 'flex' : 'block'};
    justify-content: center;
    align-items: center;
    h4 {
      font-family: "Nunito ExtraBold";
      margin: 0;
    }
    img {
      height: 30px;
      width: 30px;
      object-fit: cover;
      margin-right: 5px;
    }
  }
  button {
    background-color: ${(props) => props.btnColor};
    border: none;
    color: var(--white);
    font-size: 0.5rem;
    border-radius: 10px;
    margin-top: 3px;
    padding: 2px 0;
    min-width: 63px;
    font-family: "Nunito Semibold"
  }

  @media only screen and (max-width: 375px) {
    margin: 0 2px;
  }
`;
