import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalOutcomesEventHistoryInfo.style"
import { v4 as uuidv4 } from 'uuid';
import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../utils/pipes";
import { useQuery } from "@apollo/client";
import { QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID } from "../../../../../graphql/queries/CustomForm";
import { useMemo } from "react";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalOutcomesEventHistoryInfo({ 
  patientID,
  processID,
  cmpID,
  setItemsActived,
  setEditionActived,
  itemsActived,
  editionActived,
  setActiveConfirmationModal,
  setSeeEventHistoryActive,
  setCreateEventActive
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const { data: customFormHistory, loading: customFormHistoryLoading, error: customFormHistoryError, refetch: refetchCustomFormHistory } = useQuery(
    QUERY_GET_CUSTOM_FORMS_HISTORY_BY_ID, {
      variables: {
        customFormId: cmpID,
        userId: patientID
      }
    }
  );

  useEffect(() => {
    if(itemsActived === null && editionActived === null) {
      refetchCustomFormHistory();
    }
  }, [customFormHistory]);

  const customFormHistoryDates = useMemo(() => {
    const customFormHistoryDates = customFormHistory?.userToCustomFormField.map((item) => {

      const creationDate = new Date(item.created_at);

      return {
        date: P.formatNumberDate(item.created_at, true, language),
        time: P.hoursIn12format(creationDate.getHours(), creationDate.getMinutes()),
        value: item.created_at,
        doctor: item.doctor,
        seconds: creationDate.getSeconds()
      };
    })
    
    const orderAndFilteredDated = [
      ...new
      Map(customFormHistoryDates?.map(item => [item["date"] && item["time"] && item["seconds"], item])).values()
    ]    

    return orderAndFilteredDated?.sort((a, b) => new Date(b.value) - new Date(a.value));
  }, [customFormHistory]);

  function getFormattedValue(item) {
    const userField = item;
    let formattedValue = "–";
    if (userField) {
      formattedValue =
        item?.customFormField.typeFieldValue === "dateInput"
          ? P.formatNumberDate(userField.value, true, language)
          : userField.value;
    }
    return formattedValue;
  }

  const renderCustomFormGroupsindex1 = (item, language) => {  

    return (
      <div 
        className="detail"
        key={item.id}
      >
        <div className="detail__label">
          {P.dataTranslation(item.customFormField.fieldName, language)}
          {item.customFormField.descript && (
            <Popup
              trigger={
                <i className="detail__label-icon icon zaia-alert"></i>
              }
              on={["hover", "focus"]}
              closeOnDocumentClick
              keepTooltipInside
              position={["right center"]}
              nested
            >
              <S.PopUpPayload>
                <p>
                  {P.dataTranslation(item.descript, language)}
                </p>
              </S.PopUpPayload>
            </Popup>
          )}
        </div>
        <div className="detail__value">
          {
            item?.customFormField?.typeFieldValue === "fileInput"
            ? <div className="file-value">
              <i className="file-value__icon icon zaia-i-doc-normal"></i>
              <a className="file-value__text" target="_blank" rel="noopener noreferrer" href={item?.key} download={getFormattedValue(item)}>{getFormattedValue(item)}</a>
            </div>
            : getFormattedValue(item)
          }
          {item?.payload && (
            <Popup
              trigger={
                <i className="detail__value-icon icon zaia-alert"></i>
              }
              on={["hover", "focus"]}
              closeOnDocumentClick
              keepTooltipInside
              position={["right"]}
              nested
            >
              <S.PopUpPayload>
                <p>{item.payload}</p>
              </S.PopUpPayload>
            </Popup>
          )}
        </div>
      </div>
    );
  }

  const customFormHistoryOrder = useMemo(() => {
    return customFormHistory?.userToCustomFormField.map((item) => {

      const creationDate = new Date(item.created_at);

      return [    
        item,    
        (
          item.doctor 
          ? `${item.doctor.name} ${item.doctor.lastname}` 
          : "–"
        ),
        (
          creationDate
          ? P.formatNumberDate(item.created_at, true, language)
          : "–"
        ),
        (
          creationDate 
          ? P.hoursIn12format(creationDate.getHours(), creationDate.getMinutes())
          : "–"
        ),
        (
          creationDate 
          ? creationDate.getSeconds()
          : "–"
        )
      ]
    }


  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormHistory]);

  return (
    <S.ModalOutcomesEventHistoryInfo>
      <div className="modal-content">
        {
          !customFormHistoryDates && customFormHistoryError && 
          <div className="users-list__responses">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              sizeText="25px"
              textcolor="var(--blue-color)"
              widthimg="210px"
              text={t("globally.genericError")}
            />
          </div>
        }
        {
          !customFormHistoryDates && customFormHistoryLoading && 
          <div className="users-list__responses">
            <ZaiaReaction
              zaiaImg={logoZaiaLoading}
              sizeText="20px"
              textcolor="var(--blue-color)"
              widthimg="200px"
            />
          </div>
        }
        {
          !customFormHistoryError && !customFormHistoryLoading && customFormHistoryDates.length === 0 && 
          <div className="users-list__responses">
            <ZaiaReaction
              zaiaImg={eventsCalendarEmpty}
              text={t("globally.noResults")}
              sizeText="25px"
              textcolor="var(--blue-color)"
              widthimg="210px"
            />
          </div>
        }
        {
          customFormHistoryDates?.map((dateItem) => {
            return (
              <div 
                key={uuidv4()}
                className="history-item">
                <div className="history-item__header">
                  <div className="history-item__header-left">
                    <div className="date">
                      {P.longDate(dateItem.value, t, language)}
                    </div>
                    <div className="time">
                      {dateItem.time}
                    </div>
                    {
                      dateItem.doctor && 
                      <div className="doctor">
                        <div className="doctor__label">{t("aiNavigator.outcomes.doctorWhoEdited")}</div>
                        <div className="doctor__name">{`${dateItem.doctor.name} ${dateItem.doctor.lastname}`}</div>
                      </div>
                    }
                  </div>
                  <div className="history-item__header-right">
                    <button className="history-action-button"
                      onClick={() => {
                        setItemsActived(customFormHistoryOrder?.filter((item) => item[2] === dateItem.date && item[3] === dateItem.time && item[4] === dateItem.seconds));
                        setEditionActived(true);
                      }}
                    >
                      <div className="history-action-button__text">{t("globally.edit")}</div>
                      <i className="history-action-button__icon icon zaia-icono-editar"></i>
                    </button>
                    <button className="history-action-button"
                      onClick={() => {
                        setItemsActived(customFormHistoryOrder?.filter((item) => item[2] === dateItem.date && item[3] === dateItem.time && item[4] === dateItem.seconds));
                        setEditionActived(false);
                        setActiveConfirmationModal(true);
                      }}
                    >
                      <div className="history-action-button__text">{t("globally.delete")}</div>
                      <i className="history-action-button__icon icon zaia-i-trash-can"></i>
                    </button>
                  </div>
                </div>
                <div className="history-fields-list">
                  {
                    customFormHistoryOrder?.filter((item) => item[2] === dateItem.date && item[3] === dateItem.time && item[4] === dateItem.seconds).map((item) => {
                      return (
                        renderCustomFormGroupsindex1(item[0], language)
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </div>
      <div className="footer">
        <button className="footer__button"
          onClick={() => {
            setSeeEventHistoryActive(false)
            setCreateEventActive(true);
          }}
        >
          <div className="footer__button-text">{t("globally.addRegister")}</div>
          <i className="footer__button-icon icon zaia-icono-agregar"></i>
        </button>
      </div>
    </S.ModalOutcomesEventHistoryInfo>
  );
}

export default ModalOutcomesEventHistoryInfo;
