import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListOfAlerts.Style";
import { Actions } from "../../../../../utils/actionsIds";

// Pipes
import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-alerts.svg`;
const alertsEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-alerts-empty.svg`;
const feather = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/feather.svg`;

function ListOfAlerts({
  selectPatient,
  pendingAlerts,
  setPendingAlerts,
  alertsList,
  alertsListSelected,
  setAlertsListSelected
}) {

  const [allAlertsSelected, setAllAlertsSelected] = useState(false);
  const [filterAZ, setFilterAZ] = useState(null);
  const [filterDate, setFilterDate] = useState(null);
  const [filterAlertLevel, setFilterAlertLevel] = useState([]);
  const [filterAlertType, setFilterAlertType] = useState([]);
  const [commentsOnly, setCommentsOnly] = useState(false);
  const [managementStatusSelected, setManagementStatusSelected] = useState(null);

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const managementStatus = [
    {
      value: 1,
      text: "Gestionada",
      icon: "zaia-i-check-1"
    },
    {
      value: 2,
      text: "No gestionada",
      icon: "zaia-i-close"
    }
  ]

  const alertLevels = [
    {
      id: 1,
      name: t("globally.mildAlert"),
      onlyName: t("globally.mild"),
      color: "#05A530"
    },
    {
      id: 2,
      name: t("globally.mediumAlert"),
      onlyName: t("globally.medium"),
      color: "#F48600"
    },
    {
      id: 3,
      name: t("globally.severeAlert"),
      onlyName: t("globally.severe"),
      color: "#EA3330"
    },
    {
      id: 4,
      name: t("globally.comment"),
      onlyName: t("globally.comment"),
      color: "#3687F0"
    }
  ]

  const alertTypes = [
    {
      id: 1,
      name: t("globally.symptom"),
      icon: "zaia-icono-sintomas",
      type: "sideEffect"
    },
    {
      id: 2,
      name: t("globally.healthIndicator"),
      icon: "zaia-icono-signos-vitales-relleno",
      type: "vitalSign"
    },
    {
      id: 3,
      name: t("globally.medication"),
      icon: "zaia-icono-medicina-relleno",
      type: "drug"
    }
  ]

  const alertsFiltered = useMemo(() => {
    return alertsList.filter((alert) => {
      return (
        (filterAlertLevel.length > 0 && filterAlertLevel.includes(alert.alertLevelId)) ||
        (filterAlertType.length > 0 && filterAlertType.includes(alert.alertTypeId)) ||
        (!pendingAlerts || (pendingAlerts && alert.alertStatusId !== "notManaged"))
      )
    })
  } , [alertsList, filterAlertLevel, filterAlertType, commentsOnly, managementStatusSelected])

  const selectAlert = (item, option) => {
    if(option) {
      setAlertsListSelected(alertsListSelected.filter((alert) => alert !== item.id))
    } else {
      setAlertsListSelected([...alertsListSelected, item.id])
    }
  } 

  const getSeverityText = (severity) => {
    switch (severity) {
      case 1:
        return t("globally.veryMild");
      case 2:
        return t("globally.mild");
      case 3:
        return t("globally.medium");
      case 4:
        return t("globally.severe");
      case 5:
        return t("globally.verySevere");
      default:
        return t("globally.severity");
    }
  } 

  return (
    <S.ListOfAlerts>
      <div className="table-container">
        {
          (filterAlertLevel.length > 0 ||
          filterAlertType.length > 0 ||
          commentsOnly ) &&
          <div className="filters">
            <div className="filters__left">
              <div className="filters__label">
                {"~Filtrando"}
              </div>
              {
                filterAlertLevel.length > 0 &&
                <div className="filter-container">
                  <button className="filter-container__close"
                    onClick={() => setFilterAlertLevel([])}
                  >
                    <i className="filter-container__close-icon icon zaia-icono-cerrar"></i>
                  </button>
                  <div className="filter-container__label">
                    {"~Nivel de alerta"}
                  </div>
                  {
                    filterAlertLevel.map((level) => {
                      return (
                        <div 
                          className="filter-container__item"
                          key={level}
                        >
                          <button className="filter-container__item-icon"
                            onClick={() => setFilterAlertLevel(filterAlertLevel.filter((item) => item !== level))}
                          >
                            <i className="icon zaia-icono-cerrar"></i>
                          </button>
                          <div className="filter-container__item-text">
                            {alertLevels.find((item) => item.id === level)?.onlyName}
                          </div>
                          <div className="filter-container__item-point"
                            style={{
                              backgroundColor: alertLevels.find((item) => item.id === level)?.color
                            }}
                          ></div>
                        </div>
                      )
                    })
                  }
                </div>
              }
              {
                filterAlertType.length > 0 &&
                <div className="filter-container">
                  <button className="filter-container__close"
                    onClick={() => setFilterAlertType([])}
                  >
                    <i className="filter-container__close-icon icon zaia-icono-cerrar"></i>
                  </button>
                  <div className="filter-container__label">
                    {"~Tipo de alerta"}
                  </div>
                  {
                    filterAlertType.map((type) => {
                      return (
                        <div 
                          className="filter-container__item"
                          key={type}
                        >
                          <button className="filter-container__item-icon"
                            onClick={() => setFilterAlertType(filterAlertType.filter((item) => item !== type))}
                          >
                            <i className="icon zaia-icono-cerrar"></i>
                          </button>
                          <div className="filter-container__item-icon-type">
                            <i className={`icon ${
                              alertTypes.find((item) => item.id === type)?.icon
                            }`}></i>
                          </div>
                          <div className="filter-container__item-text">
                            {alertTypes.find((item) => item.id === type)?.name}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              }
              {
                commentsOnly &&
                <div className="filter-container">
                  <button className="filter-container__close"
                    onClick={() => setCommentsOnly(false)}
                  >
                    <i className="filter-container__close-icon icon zaia-icono-cerrar"></i>
                  </button>
                  <div className="filter-container__label">
                    {"~Comentarios"}
                  </div>
                </div>
              }
            </div>
            <div className="filters__right">
              <div className="filter-container">
                <button className="filter-container__close"
                  onClick={() => {
                    setFilterAlertLevel([]);
                    setFilterAlertType([]);
                    setCommentsOnly(false);
                  }}
                >
                  <i className="filter-container__close-icon icon zaia-icono-cerrar"></i>
                </button>
                <div className="filter-container__label">
                  {"~Limpiar filtros"}
                </div>
              </div>
            </div>
          </div>
        }
        <table className="suggestion-table">
          <thead className="suggestion-table__head">
            <tr>
              <th className="filter filter--patient-name"> 
                <div className="filter__container">
                  {
                    !allAlertsSelected 
                      ? <i className="check-patient icon zaia-i-checkbox-off"
                        onClick={() => {
                          setAllAlertsSelected(true);
                          setAlertsListSelected(alertsList.map(alert => alert.id));
                        }}
                        ></i>
                        : <i className="check-patient icon zaia-i-checkbox-on"
                        onClick={() => {
                          setAllAlertsSelected(false);
                          setAlertsListSelected([]);
                        }}
                      ></i>
                  }
                  <div className="filter__button-content filter__button-content--name">
                    <div 
                      className={`filter__button filter__button--name ${filterAZ !== null && "filter__button--actived"}`}
                      onClick={() => setFilterAZ(
                        filterAZ === null ? true : (filterAZ === false ? null : false)
                      )}
                    >
                      <i className={`filter__icon icon zaia-i-name-sort`}></i>
                      <div className="filter__text">
                        ~Nombre
                      </div>
                    </div>
                    <div className="filter__label-actived filter__label-actived--name">
                      {
                        filterAZ === null
                        ? ""
                        : filterAZ === false 
                          ? `${t("aiNavigator.filters.orderAZ")} Z-A`
                          : `${t("aiNavigator.filters.orderAZ")} A-Z`
                      }
                    </div>
                  </div>
                </div>
              </th>
              <th className="filter filter--program">
                <Popup
                  trigger={
                    <div className={`filter__button ${filterDate !== null && "filter__button--actived"}`}>
                      <i className="filter__icon icon zaia-i-especilidad-sort"></i>
                      <div className="filter__text">
                        ~Nivel del alerta
                      </div>
                    </div>
                  }
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["bottom center"]}
                  nested
                >
                  {
                    close => (
                      <FilterAlerts
                        close={close}
                        filterAlertLevel={filterAlertLevel}
                        setFilterAlertLevel={setFilterAlertLevel}
                        filterAlertType={filterAlertType}
                        setFilterAlertType={setFilterAlertType}
                        commentsOnly={commentsOnly}
                        setCommentsOnly={setCommentsOnly}
                        alertLevels={alertLevels}
                        alertTypes={alertTypes}
                      />
                    )
                  }
                </Popup>                   
              </th>
              <th className="filter filter--program">
                <Popup
                  trigger={
                    <div className={`filter__button ${filterDate !== null && "filter__button--actived"}`}>
                      <i className="filter__icon icon zaia-i-especilidad-sort"></i>
                      <div className="filter__text">
                        ~tipo del alerta
                      </div>
                    </div>
                  }
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["bottom center"]}
                  nested
                >
                  {
                    close => (
                      <FilterAlerts
                        close={close}
                        filterAlertLevel={filterAlertLevel}
                        setFilterAlertLevel={setFilterAlertLevel}
                        filterAlertType={filterAlertType}
                        setFilterAlertType={setFilterAlertType}
                        commentsOnly={commentsOnly}
                        setCommentsOnly={setCommentsOnly}
                        alertLevels={alertLevels}
                        alertTypes={alertTypes}
                      />
                    )
                  }
                </Popup>                   
              </th>
              <th className="filter">
                <div className="filter__button">
                  <i className="filter__icon filter__icon-description icon zaia-icono-menu"/>
                  <div className="filter__text">~Descripción de alerta</div>
                </div>
              </th>
              <th className="filter filter--date">
                <div className="filter__container">
                  <div className="filter__button-content">
                    <div 
                      className={`filter__button ${filterDate !== null && "filter__button--actived"}`}
                      onClick={() => setFilterDate(
                        filterDate === null ? true : (filterDate === false ? null : false)
                      )}
                    >
                      <i className="filter__icon icon zaia-i-name-sort"></i>
                      <div className="filter__text">
                        ~Date
                      </div>
                    </div>
                    <div className="filter__label-actived">
                      {
                        filterDate === null
                        ? ""
                        : filterDate === true
                          ? t("aiNavigator.filters.mostRecent")
                          : t("aiNavigator.filters.oldest")
                      }
                    </div>
                  </div>
                </div>
              </th>
              <th>
                {
                  false &&
                  <div className="management-status-selector">
                    <ZaiaSelect
                      type="select"
                      setValue={(e) => {
                        setManagementStatusSelected(e)
                      }}
                      value={managementStatusSelected}
                      items={managementStatus.map(
                        (status) => {
                          return {
                            value: status?.value,
                            text: status?.text
                          }
                        }
                      )}
                      shadow={false}
                      placeholder={"~Estado de gestión"}
                      fontSize="14px"
                      backgroundColor={"#F0F0FF"}
                      borderRadius={"31px"}
                      heightList={"200px"}
                      color={"#36369B"}
                      textcolor={"#36369B"}
                      arrowColor={"#36369B"}
                      borderColor={"transparent"}
                      textItemsColor={"#36369B"}
                      placeholdertextcolor={"#36369B"}
                    />
                  </div>
                }
              </th>
            </tr>
          </thead>
          {
            false &&
            <div className="suggestion-table__reaction">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                widthimg="100px"
                text={t("globally.errorLoadingPatients")}
                sizeText="15px"
                widthText="240px"
              />
            </div>
          }
          {
            false &&
            <div className="suggestion-table__reaction">
              <ZaiaReaction
                widthimg="150px"
                zaiaImg={logoZaiaLoading}
              />  
            </div>
          }
          {
            false &&
            <div className="suggestion-table__reaction">
              <div className="reaction-empty">
                <img 
                  className="reaction-empty__img"
                  src={alertsEmpty}
                  alt=""
                />
                <div className="reaction-empty__text">No hay alertas para revisar por el momento</div>
                <button className="reaction-empty__button">
                  Revisar historial de alertas gestionadas
                </button>
              </div>
            </div>
          }
          <tbody className="suggestion-table__body">
            { 
              alertsFiltered?.map((alert) => {
                return (
                  <tr 
                    key={alert.id}
                    className={`suggestion-table__item ${alertsListSelected.includes(alert.id) && "suggestion-table__item--selected"}`}>
                    <td className="patient-info">
                      {
                        alertsListSelected.includes(alert.id)
                        ? <i className="check-patient icon zaia-i-checkbox-on"
                          onClick={
                            () => {selectAlert(alert, true)}
                          }
                        ></i>
                        : <i className="check-patient icon zaia-i-checkbox-off"
                          onClick={
                            () => {selectAlert(alert, false)}
                          }
                        ></i>
                      }
                      <div className="patient-content">
                        <ZaiaImage
                          img={alert.user.picture}
                          alt=""
                          size={31}
                          rounded={true}
                        />
                        <div className="patient-content__info">
                          {
                            alertsListSelected.includes(alert.id) &&
                            <div className="patient-content__alert-selected">
                              <div className="patient-content__alert-selected">~Alerta seleccionada</div>
                            </div>
                          }
                          <div className="patient-content__info-name-psa">
                            {alert.user.name + " " + alert.user.lastname}
                          </div>
                          {
                            <div className="patient-content__extra-option"
                              onClick={() => {
                                selectPatient(alert.user)
                              }}
                            >
                              <div className="patient-content__extra-option-text">~Filtrar por este paciente</div>
                              <i className="patient-content__extra-option-icon icon zaia-i-especilidad-sort"></i>
                            </div>
                          }
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="alert-level"
                        style={{
                          color: alertLevels[2]?.color
                        }}
                      >
                        <i className="alert-level__icon icon zaia-i-tooltip"></i>
                        <div className="alert-level__text">{alertLevels[2]?.name}</div>
                      </div>
                    </td>
                    <td>
                      <div className="alert-type">
                          <i className={`alert-type__icon icon ${
                            alertTypes.find((item) => item.type === alert.alertTypeId)?.icon
                          }`}></i>
                          <div className="alert-type__text">{
                            alertTypes.find((item) => item.type === alert.alertTypeId)?.name
                          }</div>
                        </div>
                      </td>
                    <td>
                      {
                        true &&
                        <div className="alert-description">
                          <div className="alert-description__text">
                            {
                              "~El paciente ha presentado dolor de cabeza colores azul amarillo y otros como una figura y unas letras que creen decir algo pero en realidad no dicen dad, cuanto más cuanto menos?".split(" ").length > 10
                              ? `${"~El paciente ha presentado dolor de cabeza colores azul amarillo y otros como una figura y unas letras que creen decir algo pero en realidad no dicen dad, cuanto más cuanto menos?".split(" ").slice(0, 7).join(" ")}... `
                              : "~El paciente ha presentado dolor de cabeza colores azul amarillo y otros como una figura y unas letras que creen decir algo pero en realidad no dicen dad, cuanto más cuanto menos?"
                            } 
                              
                            <Popup
                              trigger={
                                <b className="expand-comment">{
                                  "~El paciente ha presentado dolor de cabeza colores azul amarillo y otros como una figura y unas letras que creen decir algo pero en realidad no dicen dad, cuanto más cuanto menos?".split(" ").length > 10 && t("globally.readMore")
                                }</b>
                              }
                              keepTooltipInside
                              closeOnDocumentClick
                              position={["bottom"]}
                              on={["click"]}
                              nested
                            >
                              <S.PopUpPayload>
                                {
                                  "~El paciente ha presentado dolor de cabeza colores azul amarillo y otros como una figura y unas letras que creen decir algo pero en realidad no dicen dad, cuanto más cuanto menos?"
                                }
                              </S.PopUpPayload>
                            </Popup>
                          </div>
                        </div>
                      }
                      {
                        alert.alertTypeId === "sideEffect" &&
                        <div className="alert-description">
                          <div className="alert-description__icon icon zaia-i-tooltip"></div>
                          <div className="alert-description__text">
                            <b>{P.dataTranslation(alert.sideEffectName, language)}</b> {getSeverityText(alert.sideEffectSeverity)}
                          </div>
                        </div>
                      }
                      {
                        alert.alertTypeId === "vitalSign" &&
                        <div className="alert-description">
                          <div className="alert-description__icon icon zaia-i-tooltip"></div>
                          <div className="alert-description__text">
                           {"~Temperatura"}  <b>{alert.measure.measure + alert.measure.unit}</b>
                          </div>
                        </div>
                      }
                    </td>
                    <td>
                      <div className="alert-date">
                        {P.longDate(alert.created_at, t)}
                      </div>
                    </td>
                    <td>
                      <div className="alert-management">
                        {
                          alert.alertStatusId === "managed" &&
                          <div className="alert-management__status">
                            <div className="alert-management__status-text">Gestionada</div>
                            <i className="alert-management__status-icon icon zaia-i-check-1"></i>
                          </div>
                        }
                        {
                          alert.alertStatusId === "notAnAlert" &&
                          <div className="alert-management__status">
                            <div className="alert-management__status-text">Esto no es una alerta</div>
                            <i className="alert-management__status-icon icon zaia-i-alerts"></i>
                          </div>
                        }
                        <div className="alert-options">
                          <i className="alert-options__icon zaia-icono-dots-vertical"></i>
                        </div>
                      </div>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        {
          false &&
          <div className="no-alerts">
            <img className="no-alerts__img" src={feather} alt="" />
            <div className="no-alerts__text">No hay alertas para revisar por el momento</div>
            <button className="no-alerts__button"
              onClick={() => {setPendingAlerts(true)}}
            >Revisar historial de alertas gestionadas</button>
          </div>
        }
      </div>
    </S.ListOfAlerts>
  );
}


const FilterAlerts = ({ 
  close,
  filterAlertLevel,
  setFilterAlertLevel,
  filterAlertType,
  setFilterAlertType,
  commentsOnly,
  setCommentsOnly,
  alertLevels,
  alertTypes
}) => {

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  
  return(
    <S.FiltersAlerts>
      <div className="filters-box filters-box--left">
        <div className="filters-box__label">Seleccione nivel</div>
        <div className="filters-box__title">Nivel de alerta</div>
        <div className="filters-box__content">
          <div className="levels-list">
            <div className={`level-item ${filterAlertLevel.length === 0 && "level-item--actived"}`}
              onClick={() => {
                setFilterAlertLevel([]);
              }}
            >
              <div className="level-item__point"
                style={{
                  backgroundColor: `${filterAlertLevel.length === 0 ? "white" : "#1F1F52"}`
                }}
              ></div>
              <div className="level-item__text">{t("globally.all")}</div>
              <div className="level-item__number">20</div>
            </div>
            {
              alertLevels?.slice(0, -1)?.map((level) => {
                return (
                  <div className={`level-item ${filterAlertLevel.includes(level.id) && "level-item--actived"}`}
                    onClick={() => {
                      if(filterAlertLevel.includes(level.id)) {
                        setFilterAlertLevel(filterAlertLevel.filter((item) => item !== level.id))
                      } else {
                        setFilterAlertLevel([...filterAlertLevel, level.id])
                      }
                    }}
                  >
                    <div className="level-item__point"
                      style={{
                        backgroundColor: level.color
                      }}
                    ></div>
                    <div className="level-item__text">{level.onlyName}</div>
                    <div className="level-item__number">4</div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="filters-box filters-box--right">
        <div className="filters-box__label">Seleccione tipo de alerta</div>
        <div className="filters-box__title">Tipo de alerta</div>
        <div className="filters-box__content">
          <div className="types-list">
            <div className="type-item"
              onClick={() => {setFilterAlertType([])}}
            >
              <div className={`type-item__circle ${filterAlertType.length === 0 && "type-item__circle--actived"}`}>
                <div className={`type-item__circle-number ${filterAlertType.length === 0 && "type-item__circle-number--actived"}`}>
                  20
                </div>
                <div className="type-item__circle-icon icon zaia-icono-home"></div>
              </div>
              <div className="type-item__label">{t("globally.all")}</div>
            </div>
            {
              alertTypes?.map((type) => {
                return (
                  <div className="type-item"
                    onClick={() => {
                      // add value to a filterAlertType if it exist delete it
                      if(filterAlertType.includes(type.id)) {
                        setFilterAlertType(filterAlertType.filter((item) => item !== type.id))
                      } else {
                        setFilterAlertType([...filterAlertType, type.id])
                      }
                    }}
                  >
                    <div className={`type-item__circle ${filterAlertType.includes(type.id) && "type-item__circle--actived"}`}>
                      <div className={`type-item__circle-number ${filterAlertType.includes(type.id) && "type-item__circle-number--actived"}`}>
                        20
                      </div>
                      <div className={`type-item__circle-icon icon ${type.icon}`}></div>
                    </div>
                    <div className="type-item__label">{type.name}</div>
                  </div>
                )
              })
            }
          </div>
          <div className="just-comments">
            <div className="just-comments__left">
              <div className="just-comments__icon icon zaia-i-message-outline"></div>
              <div className="just-comments__text">Sólo comentarios</div>
            </div>
            <div className="just-comments__right">
              <div className="just-comments__switch">
              <ZaiaToggleCircular
                id={'patient-alert-toggle'}
                color={"#27AE60"}
                toggle={(e) => {
                  setCommentsOnly(e)
                }}
                active={commentsOnly}
              />
              </div>
            </div>
          </div>
        </div>
      </div>
    </S.FiltersAlerts>
  )

}

export default ListOfAlerts;