import styled from "styled-components";

export const OneSingleChatDiv = styled.div`
  display: flex;
  align-items: center;
  background-color: var(--white);
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid var(--hover-box);
  color: ${({active}) => active ? 'var(--white)' : 'var(--blue-color)'};
  background-color: ${({active}) => active ? 'var(--blue-color)' : 'var(--white)' };
  &:hover {
    background-color: ${({active}) => active ? "" : "var(--hover-box)"};
  } 
  .oneSingleChat__imgContainer {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
    .oneSingleChat__online {
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: ${(props) => props.online ? "var(--green-color)" : "var(--red-color)"};
      position: absolute;
      right: 5px;
      top: -5px;
      border: 2px solid var(--white);
    }
  }

  .oneSingleChat__container {
    width: 100%;
    margin-left: 8px;
    h4 {
      /* margin-bottom: 1px; */
      font-family: "Nunito ExtraBold";
    }
    .oneSingleChat__process {
      font-size: 0.71rem;
      color: ${({active}) => active ? 'var(--white)' : '' };
      margin-bottom: 4px;
    }
    /* h6 {
      margin-left: auto; 
      font-family: "Nunito ExtraBold";
      color: var(--text-grey)
    } */
    .oneSingleChat__message {
      font-size: 0.7rem;
      color: ${({active}) => active ? 'var(--white)' : 'var(--text-toggle-gray)' };
      font-family: "Nunito";
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      height: 30px;
      line-height: 1.3;
      display: block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .oneSingleChat__time {
    display: flex;
    flex-direction: column;
    align-items: end;
    width: 65px;
    height: 100%;
    align-items: flex-end;
    p {
      font-size: 0.55rem;
      color: ${({active}) => active ? 'var(--white)' : 'var(--blue-color)'};
      font-family: "Nunito Semibold";
    }
    div {
      background-color: var(--blue-color);
      color: var(--white);
      border-radius: 50%;
      padding: 5px;
      font-size: 0.47rem;
      height: 10px;
      width: 10px;
      display: flex;
      justify-content: center;
      margin-top: auto;
      box-shadow: 0px 0px 5px #6C6BD38C;
    }
  }
`;
