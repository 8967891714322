import styled from 'styled-components';
import { getHexOpacity } from "../../../../../utils/pipes";

export const PatientCreation = styled.div`
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .settings__container {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    padding: 30px 19px 0 19px;
  }
  .modal-reaction {
    padding: 40px;
    margin: auto;
    width: 100%;
    box-sizing: border-box;
  }
`

export const InvitePatientsModalDiv = styled.div`
  width: 100%;
  height: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
  /* display: flex; */
  position: relative;
  overflow: auto;
  box-sizing: border-box;
  padding-bottom: 50px; 
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__title {
      color: #1F1F52;
      cursor: pointer;
      font-family: "Nunito Sans ExtraBold";
      font-size: 25px;
      /* margin: 10px 12px; */
    }
    &__close {
      width: 28px;
      height: 28px;
      background-color: #36369B;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      cursor: pointer;
      color: #fff;
    }
  }
  form {
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 100%;
    .send-by-container {
      margin-bottom: 10px;
      .send-by-title {
        font-size: 15px;
        color: #6C6BCC;
        font-style: normal;
        margin-bottom: 5px;
      }
      .send-by-buttons {
        display: flex;
        margin-top: 10px;
        .send-by-button {
          cursor: pointer;
          border-radius: 10px;
          outline: none;
          margin-right: 10px;
          border: none;
          background: #F0F0FF;
          display: flex;
          padding: 5px 20px;
          justify-content: center;
          align-items: center;
          color: #6C6BCC;
          font-family: "Nunito Sans";
          font-size: 15px;
          &--selected {
            background: #D2D2FF;
            color: #36369B;
            font-family: "Nunito Sans Bold";
          }
        }
      }
    }

    .card-inputs {
      /* height: calc(100% - 104px); */
      /* overflow: auto; */
      /* max-height: 420px; */
      background-color: ${({theme}) => theme.background};
      /* padding: 40px; */
      border-radius: 30px;
      /* box-shadow: 1px 1px 11px 5px ${({theme}) => theme.blackShadow}29; */
      /* overflow: auto; */
      h4 {
        margin-bottom: 15px;
        font-size: 17px;
        font-family:"Nunito ExtraBold";
      }
      p {
        font-family: "Nunito";
        font-size: .9rem;
        margin: 5px 0 10px;
      }
      .card-inputs-container {
        margin-top: 43px;
        .picture-selector {
          display: flex;
          align-items: center;
          &__img {
            width: 105.63px;
            height: 105.63px;
            border-radius: 50%;
            border: 7px #ECECFF solid;
            display: flex;
          }
          &__img-tag {
            width: 105.63px;
            height: 105.63px;
            border-radius: 50%;
            object-fit: cover;
            vertical-align: middle;
          }
          &__label {
            font-family: Nunito Sans light;
            font-size: 19px;
            text-align: left;
            margin-left: 10px;
            color: #3E3E5B;
          }
          &__button {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
          }
          &__button {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            display: flex;
          }
          &__icon {
            font-size: 39.57px;
            margin: auto;
            color: #36369B;
          }
        }
        /* margin: 40px 0; */
        .phone-inputs {
          display: flex;
          &__indicative {
            width: 200px;
            margin-right: 20px;
          }
        }
        &-toggle {
          display: flex;
          margin: 16px 0 33px 0;
          color: #333365;
          font-family: "Nunito Sans Light";
          font-size: 19px;
          &-label {
            margin-left: 10px;
          }
        }
        ::-webkit-scrollbar {
          width: 26px;
        }
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 10px 10px transparent;
          margin: 5px 0px; 
        }
        ::-webkit-scrollbar-thumb {
          border: 10px solid rgba(0, 0, 0, 0);
          box-shadow: inset 0 0 10px 10px #3E3DA366;
          background-clip: padding-box;
          border-radius: 9999px;
          background-color: transparent;
        }
        .label {
          display: flex;
          align-items: center;
          margin: 23px 0 6px 0;
          color: #3E3E5B;
          font-family: "Nunito Sans Light";
          font-size: 19px;
          &-icon {
            margin-right: 10px;
          }
        }
        .comment-content {
          margin-top: 20px;
        }
        .options-list {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 15px;
          .option {
            height: 60px;
            padding: 0px 20px;
            border-radius: 52px;
            color: #6C6BCC;
            background-color: #F0F0FF;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: Nunito Sans;
            font-size: 17px;
            &:not(:last-child) {
              margin-right: 10px;
            }
            &--selected {
              font-family: Nunito Sans Bold;
              color: #36369B;
              background-color: #D2D2FF;
              font-size: 17px;
            }
          }
        }
      }
      .invitePatientsModal__msgContainer {
        background-color: var(--background-header-input);
        padding: 5px 5px 60px 5px;
        border-radius: 10px;
        margin-top: 10px;
        max-width: 310px;
        p {
          line-height: 1.2;
        }
      }
    }
    .card-buttons {
      padding-bottom: 20px;
      margin-top: 40px;
      text-align: center;
      p {
        font-size: .9rem;
      }
    }
    .button-warning {
      text-align: center;
      font-size: 19px;
      margin-top: 8px;
      color: #3E3E5B;
      &__required {
        color: red;
      }
    }
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    display: flex;
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
  .sendBtn {
    background-color: var(--blue-color);
    border: none;
    border-radius: 43px;
    padding: 5px 80px;
    color: white;
    box-shadow: 3px 3px 5px var(--box-shadow);
    width: 308px;
    height: 42px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  @media only screen and (max-width: 375px) {
    .card-inputs {
      padding: 30px 20px !important;
    }
  }
`;

export const InputParagraphDiv = styled.div`
  display: flex;
  .text-area-input {
    width: 100%;
    height: 120px;
    overflow: auto;
    background-color: var(--background-header-input);
    border-radius: 14px;
    padding: 20px 27px;
    display: flex;
    outline: none;
    border: none;
    resize: none;
    font-family: "Nunito Sans Light";
    font-size: 19px;
  }
`

