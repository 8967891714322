import React, { useContext } from "react";
import * as S from "./ZaiaReaction.style.js";
import { ThemeContext } from 'styled-components';

function ZaiaReaction({ 
  textcolor, 
  text, 
  subText,
  zaiaImg, 
  separation, 
  widthimg, 
  widthText, 
  widthSubText, 
  sizeText,
  sizeSubText,
  sizeBetweenText,
  weighttext=true,
  loadingPoints=false,
  padding
}) {
  const theme = useContext(ThemeContext);

  return (
    <S.ZaiaReaction
      textcolor={textcolor}
      separation={separation}
      widthimg={widthimg}
      widthText={widthText}
      widthSubText={widthSubText}
      sizeText={sizeText}
      sizeSubText={sizeSubText}
      sizeBetweenText={sizeBetweenText}
      weighttext={weighttext}
      loadingpoints={loadingPoints}
      padding={padding}
    >
      <img src={zaiaImg} alt={text}/>
      <div className="reaction-text">
        {
          text ? <div className="zaia-reaction-text">{text}</div> : null
        }
        {
          subText ? <div className="zaia-reaction-subtext">{subText}</div> : null
        }
        {
          loadingPoints &&
          <S.ZaialoadingPoints color={textcolor}>
            <div className="dot-flashing"></div>
          </S.ZaialoadingPoints>
        }
      </div>
    </S.ZaiaReaction>
  );
}

export default ZaiaReaction;
