import styled from "styled-components";

export const ChatbotGeneralCase = styled.div` 
  height: 100%;
  .chatbot-container {
    padding: 0px 14px 0px 29px;
    display: flex;
    flex-direction: column;
    height: ${({footerHeight}) => `calc(100% - ${footerHeight}px)` };
    overflow-y: scroll;
    &__empty {
      margin: auto;
    }
  }
`;