import styled from "styled-components";

export const NewTransducer = styled.div`
  height: 100%;
  box-sizing: border-box;
  background-color: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  .transducer-content {
    height: calc(100% - 81px);
    padding: 20px 20px 0px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    .transducer-choose-type {
      height: 100%;
      .transductor-mode {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 10px;
        &__label {
          font-family: Nunito Sans Bold;
          font-size: 20px;
          margin-bottom: 10px;
        }
        &__selected {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .message-space {
        height: ${(modelsSelection) => !modelsSelection ? "calc(100% - 116px)" : "calc(100% - 232px)"};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        &__img {
          &--medical-consultation {
            width: 424.6px;
            height: 156.2px;
          }
          &--note-taking {
            width: 182.3px;
            height: 156.2px;
          }
          &--start {
            width: 164px;
            height: 164px;
          }
        }
        &__text {
          font-family: Nunito Sans;
          font-size: 20.72px;
          text-align: center;
          margin-top: 19px;
          color: #7776BE;
        }	
      }
    }
  }
  .space-start-recording {
    padding: 14px 10px 24px 10px;
    background-color: #F0F0FF;
    .recording-button {
      width: 100%;
      display: flex;
      background-color: #FFFFFF;
      height: 43px;
      padding: 10px 20px 10px 20px;
      border-radius: 20px;
      box-sizing: border-box;
      text-align: center;
      border: none;
      cursor: pointer;
      outline: none;
      justify-content: center;
      align-items: center;
      &__text {
        font-family: Nunito Sans;
        font-size: 17px;
        color: #36369B;
      }
      &__icon {
        font-size: 16px;
        opacity: 0.35;
        color: #36369B;
        margin-left: 10px;
        &--pause {
          margin-left: 0px;
        }
      } 
      &__circle {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        border: 7px solid #FF453A;
        margin-left: 10px;
      }
      &--pause {
        width: 43px;
        margin-right: 10px;
        box-shadow: 0px 0px 8.35px 0px #BCBCFFA3;
        border-radius: 50%;
      }
      &--finish {
        background-color: rgb(224, 230, 237);
        color: rgb(138, 147, 157);
        font-family: Nunito;
        font-size: 20.28px;
        font-family: Nunito Sans Bold;
        line-height: 18px;
        &--active {
          background-color: #00A600;
          color: #FFFFFF;
        }
      }
      &--continue {
        margin-right: 10px;
      }
    }
    .active-recording-buttons {
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .button-recording {

    }
  }
`