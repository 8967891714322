import React from "react";
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import * as P from "../../../../../../../utils/pipes"

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../../../graphql/queries/User";
import { QUERY_GET_USER_MEDICINES_NO_DATES } from "../../../../../../../graphql/queries/drugs";
import { QUERY_GET_USER_VITAL_SIGNS_SIMPLE_NO_DATES } from "../../../../../../../graphql/queries/VitalSigns";
import { QUERY_GET_USER_SIDE_EFFECT_NO_DATES } from "../../../../../../../graphql/queries/SideEffects";
import { QUERY_GET_USER_FEELINGS_NO_DATES } from "../../../../../../../graphql/queries/Feelings";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../../../graphql/queries/Process";
import { QUERY_FEELINGS_COMMENT_REPORTS_BY_USER_NO_DATES } from "../../../../../../../graphql/queries/Feelings";
import { QUERY_DRUGS_COMMENT_REPORTS_BY_USER_NO_DATES } from "../../../../../../../graphql/queries/drugs";
import { QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER_NO_DATES } from "../../../../../../../graphql/queries/VitalSigns";
import { QUERY_GET_CUSTOM_FORM_BY_ID } from "../../../../../../../graphql/queries/CustomForm";
import { QUERY_GET_USER_TO_PROCESS_HISTORY } from "../../../../../../../graphql/queries/UserToProcessHistory";
import { useTranslation } from "react-i18next";

function PdfPatientInfo({ patientID, children, onlyDocument }) {

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError 
  } = useQuery(
    QUERY_GET_USER_BY_ID, {
    variables: { 
      patientId: patientID 
    },
  });

  const {
    data: userToFeelings,
    loading: userToFeelingsLoading,
    error: userToFeelingsError
  } = useQuery(
    QUERY_GET_USER_FEELINGS_NO_DATES, 
    {
      variables: { 
        patientId: patientID
      },
    }
  );

  const {
    data: userToSideEffect,
    loading: userToSideEffectLoading,
    error: userToSideEffectError 
  } = useQuery(
    QUERY_GET_USER_SIDE_EFFECT_NO_DATES, {
    variables: {
      patientId: patientID
    },
  });

  const {
    data: userToMedicines,
    loading: userToMedicinesLoading,
    error: userToMedicinesError
  } = useQuery(
    QUERY_GET_USER_MEDICINES_NO_DATES, 
    {
      variables: { 
        patientId: patientID, 
      }
    }
  );

  const {
    data: userToVitalSigns,
    loading: userToVitalSignsLoading,
    error: userToVitalSignsError
  } = useQuery( QUERY_GET_USER_VITAL_SIGNS_SIMPLE_NO_DATES );

  const {
    data: processData,
    loading: processDataLoading,
    error: processDataError 
  } = useQuery( QUERY_GET_PROCESS_NAMES);    

  // Comment reports
  const {
    data: feelingsComments,
    loading: feelingsCommentsLoading,
    error: feelingsCommentsError 
  } = useQuery( 
    QUERY_FEELINGS_COMMENT_REPORTS_BY_USER_NO_DATES, 
    {
      variables: {
        processId: processData?.process?.length ? processData.process.map(p => p.id) : [],
        userId: patientID,
      }
    }
  );

  const {
    data: drugsComments,
    loading: drugsCommentsLoading,
    error: drugsCommentsError 
  } = useQuery(
    QUERY_DRUGS_COMMENT_REPORTS_BY_USER_NO_DATES, {
      variables: {
        processId: processData?.process?.length ? processData.process.map(p => p.id) : [],
        userId: patientID,
      },
    }
  );

  const {
    data: vitalSignsComments,
    loading: vitalSignsCommentsLoading,
    error: vitalSignsCommentsError 
  } = useQuery(
    QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER_NO_DATES,
    {
      variables: {
        processId: processData?.process?.length ? processData.process.map(p => p.id) : [],
        userId: patientID,
      }
    }
  );

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError 
  } = useQuery(
    QUERY_GET_CUSTOM_FORM_BY_ID,
    {
      variables: {
        userId: patientID,
      },
    }
  );


  const {
    data: userToProcess,
    loading: userToProcessLoading,
    error: userToProcessError
  } = useQuery(QUERY_GET_USER_TO_PROCESS_HISTORY, {
    variables: {
      patientId: patientID,
    },
  });


  const { t, i18n: { language } }= useTranslation("global");

  if (userDataLoading || userToFeelingsLoading || userToSideEffectLoading || userToMedicinesLoading || userToVitalSignsLoading || processDataLoading || feelingsCommentsLoading || drugsCommentsLoading || vitalSignsCommentsLoading || customFormLoading || userToProcessLoading) {
    return <p></p>;
  }

  if (userDataError || userToFeelingsError || userToSideEffectError || userToMedicinesError || userToVitalSignsError || processDataError || feelingsCommentsError || drugsCommentsError || vitalSignsCommentsError || customFormError || userToProcessError) {
    return <p></p>;
  }  

  return (
    onlyDocument
    ? <PDFDownloadLink document={
      <PdfDocument
        patientID={patientID}
        userData={userData}
        userToFeelings={userToFeelings}
        userToSideEffect={userToSideEffect}
        userToMedicines={userToMedicines}
        userToVitalSigns={userToVitalSigns}
        feelingsComments={feelingsComments}
        drugsComments={drugsComments}
        vitalSignsComments={vitalSignsComments}
        customForm={customForm}
        userToProcessHistory={userToProcess}
      />
    } fileName="documento.pdf">
      {({ loading }) => (
        <button className="update-outcome-button__button">
          <div className="update-outcome-button__text">
            {loading ? '...' : t("globally.downloadPatientsInfo")}
          </div>
          <i className="update-outcome-button__icon icon zaia-icono-formulario"></i>
        </button>
      )}
    </PDFDownloadLink>
    : <PDFViewer 
      style={{
        width: "100dvw",
        height: "100dvh",
      }}
    >
      <PdfDocument
        patientID={patientID}
        userData={userData}
        userToFeelings={userToFeelings}
        userToSideEffect={userToSideEffect}
        userToMedicines={userToMedicines}
        userToVitalSigns={userToVitalSigns}
        feelingsComments={feelingsComments}
        drugsComments={drugsComments}
        vitalSignsComments={vitalSignsComments}
        customForm={customForm}
        userToProcessHistory={userToProcess}
      />
    </PDFViewer>
  );
}

const PdfDocument = ({ 
  patientID,
  userData,
  userToFeelings,
  userToSideEffect,
  userToMedicines,
  userToVitalSigns,
  feelingsComments,
  drugsComments,
  vitalSignsComments,
  customForm,
  userToProcessHistory,
}) => {

  const {t, i18n: { language } }= useTranslation("global");

  const styles = {
    page: {
      padding: 40,
      fontFamily: 'Helvetica',
      fontSize: 8,
    },
    section: {
      marginBottom: 10,
    },
    image: {
      width: 200,
      height: 200,
      marginBottom: 10,
    },
    title: {
      fontFamily: 'Helvetica-Bold',
      marginTop: 5,
      fontSize: 10
    },
    bigtitle: {
      fontFamily: 'Helvetica-Bold',
      marginTop: 10,
      marginBottom: 5,
      fontSize: 12,
    },
    itemDisplay: {
      display: 'flex',
    },
    marginLeft: {
      marginLeft: 20,
    },
    titleItem: {
      fontFamily: 'Helvetica-Bold',
      marginTop: 5,
      fontSize: 10,
      color: '#960020',
    },
    titleItemblue: {
      fontFamily: 'Helvetica-Bold',
      marginTop: 5,
      marginBottom: 5,
      fontSize: 10,
      color: '#0147ca',
    },
    line: {
      color: '#960020'
    },
    blueline: {
      color: '#0147ca'
    }
  };

  return (
    <Document>

      {
        // User Info
      }
      <Page style={styles.page}>
        <Text style={styles.bigtitle}>User Info:</Text>
        <View style={styles.section}>
          <Text>
            <Text style={styles.title}>ID: </Text>
            {userData?.user?.[0]?.id}
          </Text>
          <Text>
            <Text style={styles.title}>Phone: </Text>
            {userData?.user?.[0]?.phone}
          </Text>
          {
            userData?.user?.[0]?.weight 
            ? <Text>
              <Text style={styles.title}>Weight: </Text>
              {userData?.user?.[0]?.weight} kg
            </Text>
            : null
          }
          {
            userData?.user?.[0]?.weight 
            ? <Text>
              <Text style={styles.title}>Height: </Text>
              {userData?.user?.[0]?.height} cm
            </Text>
            : null
          }
          <Text>
            <Text style={styles.title}>Birthdate: </Text>
            {userData?.user?.[0]?.birthdate}
          </Text>
        </View>
      </Page>
      
      {
        // Process info
      }
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>The user belongs to the following processes:</Text>
          {
            userToProcessHistory?.userToProcess?.map((userToProcessItem, index) => (
              userToProcessItem.isAcepted 
              ? <View style={styles.itemContainer} key={userToProcessItem?.id}>
                <Text>The user belongs to the medical process: "{userToProcessItem?.process.descript?.en}" in the stage named "{userToProcessItem?.processStage?.stageName.en}" since the date {P.formatNumberDate(userToProcessItem?.created_at, t, language)}</Text>
              </View>
              : null
            ))
          }
        </View>
      </Page>

      {
        // Feelings
      }
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Feelings reports list from user:</Text>
          {userToFeelings?.user[0].userToFeelings?.map((userToFeeling, index) => (
            <View style={styles.itemContainer} key={userToFeeling?.id}>
              <Text>
                Feeling report with {userToFeeling?.levelFeeling} level on the date {P.formatNumberDate(userToFeeling?.date, t, language)} at time {P.hoursIn24format(new Date(userToFeeling?.date).getHours(), new Date(userToFeeling?.date).getMinutes(), new Date(userToFeeling?.date).getSeconds())} 
                {
                  userToFeeling?.description &&
                  ` with the description: "${userToFeeling?.description}"` 
                }
              </Text>
            </View>
          ))}
        </View>
      </Page>


      {
        // SideEffects
      }
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Side Effects reported list from user:</Text>
          {userToSideEffect?.user[0].userToSideEffects.map((userToSideEffect, index) => (
            <View style={styles.itemContainer}>
              <Text key={index}>
                Side effect report "{userToSideEffect?.typeSideEffectVal.comment.en}" with severity {userToSideEffect?.severity} on date {P.formatNumberDate(userToSideEffect?.date, t, language)} at time {P.hoursIn24format(new Date(userToSideEffect?.date).getHours(), new Date(userToSideEffect?.date).getMinutes(), new Date(userToSideEffect?.date).getSeconds())}
              </Text>
            </View>
          ))}
        </View>
      </Page>

      {
        // Medicines
      }
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Medicine reports made by the user:</Text>
          {userToMedicines?.user[0].userToDrugs.map((userToMedicine, index) => (
            <View style={styles.itemContainer}>
              <View style={styles.marginLeft}>
                {
                  userToMedicine?.userToDrugHistories?.length === 0 &&
                  <Text style={styles.titleItem}>No reports made by the user</Text>
                }
                {
                  userToMedicine?.userToDrugHistories?.map((userToDrugHistory, index) => (
                    <View style={styles.itemContainer}>
                      <Text key={index}>
                        Report of medication with the name "{userToMedicine?.displayName || userToMedicine?.drug?.genericName}" of {userToMedicine?.dose} dose of {userToMedicine?.doseType} in {userToMedicine?.doseShapeValue.comment.en} form {userToDrugHistory?.isTaken ? 'taken' : 'not taken'} on the date {P.formatNumberDate(userToDrugHistory?.created_at, t, language)} at time {P.hoursIn24format(new Date(userToDrugHistory?.created_at).getHours(), new Date(userToDrugHistory?.created_at).getMinutes(), new Date(userToDrugHistory?.created_at).getSeconds())} {userToDrugHistory?.comment ? `, with the comment: "${userToDrugHistory?.comment}"` : null} 
                      </Text>
                    </View>
                  ))
                }
              </View>
            </View>
          ))}
        </View>
      </Page>

      {
        // Vital Signs
      }
      <Page style={styles.page}>
        <View style={styles.section} key={'section'}>
          <Text style={styles.bigtitle}>List of vital signs measured by the user:</Text>
          {
            userToVitalSigns?.enVitalSign.map((vitalSign, index) => (
              <View style={styles.itemContainer} key={'vitalSign'}>
                {
                  vitalSign?.userVitalSignHistories.map((userToVitalSignHistory, index) => (
                    <View style={styles.itemContainer} key={'itemContainer'}>
                      <Text key={index}>
                        Vital sign report of "{vitalSign?.comment.en}" {userToVitalSignHistory?.isTaken ? "with value: " + userToVitalSignHistory?.measure1 : ""} in the unit {userToVitalSignHistory?.unit} {userToVitalSignHistory?.isTaken ? 'measured' : 'not measured'} on the date {P.formatNumberDate(userToVitalSignHistory?.created_at, t, language)} at time {P.hoursIn24format(new Date(userToVitalSignHistory?.created_at).getHours(), new Date(userToVitalSignHistory?.created_at).getMinutes(), new Date(userToVitalSignHistory?.created_at).getSeconds())}{userToVitalSignHistory?.comment ? `, with the comment: "${userToVitalSignHistory?.comment}"` : null} 
                      </Text>
                    </View>
                  ))
                }
              </View>
            ))
          }
        </View>
      </Page>   

      {/* <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Medicine treatments list from user:</Text>
          {userToMedicines?.user[0].userToDrugs.map((userToMedicine, index) => (
            <View style={styles.itemContainer}>              
              <Text key={index} style={styles.title}>
                Medicine treatment of "{userToMedicine?.drug?.genericName}" medicine with the next specifications:
              </Text>
              <Text key={index}>
                <Text style={styles.title}>Permanent treatment: </Text>
                {userToMedicine?.isFix ? 'Yes' : 'No'}
              </Text>
              <Text key={index}>
                <Text style={styles.title}>The user receive notification in the app about the medicine: </Text>
                {userToMedicine?.isNotify ? 'Yes' : 'No'}
              </Text>
                <Text key={index}>
                  <Text style={styles.title}>The taking of medicines is: </Text>
                  {userToMedicine?.isPeriodic ? 'Periodic' : 'in specific days'}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>Quantity of medicines: </Text>
                  {userToMedicine?.quantity}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>Frequency of taking medicines: </Text>
                  {userToMedicine?.frequency} {userToMedicine?.frequencyTypeValue.comment.en}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>Description of the treatment: </Text>
                  "{userToMedicine?.comment}"
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>Dose of medicine: </Text>
                  {userToMedicine?.dose} of {userToMedicine?.doseType}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>Start of treatment : </Text>
                  { P.formatNumberDate(userToMedicine?.startTreatment, t, language) }
                </Text>
                <Text key={index}>

                  <Text style={styles.title}>End of treatment: </Text>
                  { userToMedicine?.endTreatment ? P.formatNumberDate(userToMedicine?.endTreatment, t, language) : 'N/A' }
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>Start time of treatment: </Text>
                  {userToMedicine?.startHour}
                </Text>

                <Text key={index}>
                  <Text style={styles.title}>Days of the week the medicine should be taken: </Text>
                  {userToMedicine?.daysOfWeek?.map((day, index) => (
                    <Text key={index}>
                      {"\n"}
                      {days[day]}
                    </Text>
                  ))}
                </Text>

                <Text key={index}>
                  <Text style={styles.title}>Medicine dose shape: </Text>
                  {userToMedicine?.doseShapeValue.comment.en}
                </Text>

                <View style={styles.marginLeft}>
                  <Text style={styles.titleItemblue}>Medicine reports made by the user:</Text>
                  {
                    userToMedicine?.userToDrugHistories?.length === 0 &&
                    <Text style={styles.titleItem}>No reports made by the user</Text>
                  }
                  {
                    userToMedicine?.userToDrugHistories?.map((userToDrugHistory, index) => (
                      <View style={styles.itemContainer}>
                        <Text key={index}>
                          The user report that he {userToDrugHistory?.isTaken ? 'took' : 'did not take'} the medicine on {P.formatNumberDate(userToDrugHistory?.created_at, t, language)} at {P.hoursIn12format(new Date(userToDrugHistory?.created_at).getHours(), new Date(userToDrugHistory?.created_at).getMinutes())}
                          {userToDrugHistory?.comment ? ` with the comment: "${userToDrugHistory?.comment}"` : null}
                        </Text>
                        <Text style={styles.blueline}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
                      </View>
                    ))
                  }
                </View>
              <Text style={styles.line}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
            </View>
          ))}
        </View>
      </Page> */}

      {/* este noooooooooooooo
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Comment made by the user about his feelings:</Text>
          {
            feelingsComments?.userToFeeling.map((feeling, index) => (
              <View style={styles.itemContainer}>

                <Text key={index}>
                  The user made the comment: "{feeling?.description}" 
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>date: </Text>
                  {feeling?.date}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>description: </Text>
                  {feeling?.description}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>id: </Text>
                  {feeling?.id}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>levelFeeling: </Text>
                  {feeling?.levelFeeling}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>userId: </Text>
                  {feeling?.userId}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>modifiedDate: </Text>
                  {feeling?.modifiedDate}
                </Text>
                <Text style={styles.line}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
              </View>
            ))
          }
        </View>
      </Page> */}

      {/* este noooooooooooooo 
      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Feelings Comments:</Text>
          {
            drugsComments?.userToDrugHistory.map((drug, index) => (
              <View style={styles.itemContainer}>
                <Text key={index}>
                  <Text style={styles.title}>id: </Text>
                  {drug?.id}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>comment: </Text>
                  {drug?.comment}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>created_at: </Text>
                  {drug?.created_at}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>delay: </Text>
                  {drug?.delay}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>dose: </Text>
                  {drug?.dose}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>doseType: </Text>
                  {drug?.doseType}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>drugId: </Text>
                  {drug?.drugId || 'N/A'}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>userId: </Text>
                  {drug?.userId}
                </Text>
                <Text style={styles.line}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
              </View>
            ))
          }
        </View>
      </Page>       */}

      {/* <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>Forms from Zaia and responses from the user:</Text>
          {
            customForm?.customForm.map((customFormItem, index) => (
              <View style={styles.itemContainer} key={customFormItem?.id}>
                <Text style={styles.title}>Form "{customFormItem?.customFormName.en}" with description: "{customFormItem?.descript.en}"</Text>
                <View style={styles.marginLeft}>
                  <Text style={styles.titleItemblue}>Group of fields from form "{customFormItem?.customFormName.en}":</Text>
                    {
                      customFormItem?.formGroups.map((formGroup, index) => (
                        <View style={styles.itemContainer} key={formGroup?.id}>

                          <Text style={styles.title}>- "{formGroup?.customFormName.en}" group with the description: "{formGroup?.descript.en}" has the next fields: </Text>

                          <View style={styles.marginLeft}>
                            {
                              formGroup?.customFormFields.map((field, index) => (
                                <View style={styles.itemContainer} key={field?.id}>
                                  <Text key={index}>
                                  <Text style={styles.titleItem}>{index + 1}.  </Text>"{field?.fieldName?.en}" field is type: {field?.typeFieldValue} with the description: "{field?.descript?.en}" and the id: {field?.id}
                                    {field?.customFormFieldParentId &&
                                      ` has as a parent the field with id: ${field?.customFormFieldParentId} ` 
                                    }
                                    {
                                      field?.customFormTypeField?.isOptions &&
                                      ` has the next options:`
                                    }
                                  </Text>

                                  <View style={styles.marginLeft}>
                                    {
                                      field?.customFormTypeField?.isOptions &&
                                      <>
                                        <Text style={styles.titleItem}>Options from the field "{field?.fieldName?.en}":</Text>
                                        {
                                          field?.customFormTypeField?.fieldOptions.map((typefield, index) => (
                                            <View style={styles.itemContainer} key={typefield?.id}>
    
                                              <Text key={index}>
                                                <Text style={styles.titleItem}>• </Text>
                                                Option "{typefield?.names?.en}" with the key: {typefield?.key} 
                                                {
                                                  typefield?.parentKey &&
                                                  `, the parent key: ${typefield?.parentKey} `
                                                }
                                                {
                                                  typefield?.payload &&
                                                  ` and the payload: "${typefield?.payload?.toString()}"`
                                                }
                                              </Text>
                                            </View>
                                          ))
                                        }
                                      </>
                                    }
                                  </View>
                                  <View style={styles.marginLeft}>
                                    <Text style={styles.titleItemblue}>User responses from the "{field?.fieldName?.en}" field:</Text>
                                    {
                                      field?.userToCustomFormFields?.map((customFormField, index) => (
                                        <View style={styles.itemContainer} key={customFormField?.id}>

                                          <Text key={index}>
                                            The user registered the value: {customFormField?.value} with the key: {customFormField?.key} on {P.formatNumberDate(customFormField?.created_at, t, language)} at {P.hoursIn12format(new Date(customFormField?.created_at).getHours(), new Date(customFormField?.created_at).getMinutes())}
                                          </Text>
                                          <Text style={styles.blueline}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
                                        </View>
                                      ))
                                    }
                                  </View>
                                </View>
                              ))
                            }
                          </View>
                        </View>
                      ))
                    }
                </View>
                <Text style={styles.line}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
              </View>
            ))
          }
        </View>
      </Page> */}


      <Page style={styles.page}>
        <View style={styles.section}>
          <Text style={styles.bigtitle}>The user belongs to the following processes:</Text>
          {
            userToProcessHistory?.userToProcess.map((userToProcessItem, index) => (
              <View style={styles.itemContainer} key={userToProcessItem?.id}>
                <Text style={styles.title}>The user belongs to the process: "{userToProcessItem?.process.descript?.en}"</Text>
                {
                  userToProcessItem?.userToProcessHistories?.length > 0 &&
                  <View style={styles.marginLeft} key={'historyList'}>
                    <Text style={styles.titleItem}>The user has the next historial with the process:</Text>
                    <View style={styles.marginLeft}>
                      {
                        userToProcessItem?.userToProcessHistories.map((history, index) => (
                          <View style={styles.itemContainer} key={history?.id}>
                            <Text key={index}>
                              The user is in the stage: "{history?.processStage?.stageName.en}" and change the description to: "{history?.descript}" on {P.formatNumberDate(history?.created_at, t, language)} at {P.hoursIn12format(new Date(history?.created_at).getHours(), new Date(history?.created_at).getMinutes())}
                            </Text>
                            <Text style={styles.blueline}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
                          </View>
                        ))
                      }
                    </View>
                  </View>
                }
                <Text style={styles.line}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
              </View>
            ))
          }
        </View>
      </Page>
      
      {/* <Page style={styles.page}>
        <View style={styles.section} key="section">
          <Text style={styles.bigtitle}>eventList:</Text>
          {
            eventList?.userToProcess.map((userToProcessItem, index) => (
              <View style={styles.itemContainer} key={userToProcessItem?.id}>
                <Text key={index}>
                  <Text style={styles.title}>descript: </Text>
                  {userToProcessItem?.descript}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>id: </Text>
                  {userToProcessItem?.id}
                </Text>
                <Text key={index}>
                  <Text style={styles.title}>descript: </Text>
                  {userToProcessItem?.process.descript?.en}
                </Text>
                <View style={styles.marginLeft} key={`${userToProcessItem?.id}-marginLeft`}>
                  <Text style={styles.titleItem}>userToProcessCalendars:</Text>
                  <View style={styles.marginLeft} key={`${userToProcessItem?.id}-marginLeft1`}>
                    {
                      userToProcessItem?.userToProcessCalendars.map((event, index) => (
                        <View style={styles.itemContainer} key={event.id}>
                          <Text>
                            <Text style={styles.title}>created_at: </Text>
                            {event?.created_at}
                          </Text>
                          <Text>
                            <Text style={styles.title}>dateTime: </Text>
                            {(new Date(event?.dateTime)).toDateString()} 
                          </Text>
                          <Text>
                            <Text style={styles.title}>descript: </Text>
                            {event?.descript}
                          </Text>
                          <Text>
                            <Text style={styles.title}>id: </Text>
                            {event?.id}
                          </Text>
                          <Text>
                            <Text style={styles.title}>isAccepted: </Text>
                            {event?.isAccepted?.toString()}
                          </Text>
                          <Text>
                            <Text style={styles.title}>isNotify: </Text>
                            {event?.isNotify?.toString()}
                          </Text>
                          <Text>
                            <Text style={styles.title}>name: </Text>
                            {event?.name}
                          </Text>
                          <Text>
                            <Text style={styles.title}>place: </Text>
                            {event?.place}
                          </Text>
                        </View>
                      ))
                    }
                  </View>
                </View>
                <Text style={styles.line}>------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------</Text>
              </View>
            ))
          }
        </View>
      </Page> */}

    </Document>
  );
}



export default PdfPatientInfo;