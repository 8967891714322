import styled from "styled-components";

export const OutcomesTable = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 10px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  min-width: 800px;
  .illness-control-reaction {
    width: 100%;
    height: 100%;
    padding: 100px 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
    thead {
      padding: 10px;
      tr {
        th {
          /* border-bottom: 1.6px solid var(--no-notification-background); */
          color: var(--blue-color);
          color: #38379C;
          font-family: "Nunito Sans";
          font-size: 15px;
          padding: 18px 10px 15px 10px;
          text-align: left;
          font-weight: 400;
          text-align: center;
        }
        .th-event-type{
          font-family: "Nunito Sans Bold";
        }
      }
    }
    tbody {
      padding: 10px;
      i {
        /* display: inline; */
      }
      td:first-child,
      th:first-child {
        border-radius: 14px 0 0 14px;
      }
      td:last-child,
      th:last-child {
        border-radius: 0 14px 14px 0;
      }
      tr {
        .icon-save-form-field {
          /* margin-right: 10px;
          color: ${({theme}) => theme.inputSelected};
          cursor: pointer; */
        }
        .td-index0 {
          display: flex;
          align-items: center;
        }
        td {
          padding: 13px 10px;
          text-align: left;
          color: var(--blue-dark-color);
          /* vertical-align: text-top; */
          .detail-item {
            display: flex;
            align-items: center;
            color: var(--blue-color);
            font-family: "Nunito Sans Bold";
            font-size: 15px;
            margin-bottom: 10px;
          }
          .template-header-item-icon {
            color: var(--blue-color);
            font-size: 12px;
            margin-left: 5px;
          }
          .highlight-button {
            margin: 0 20px 0 10px;
            border-radius: 14px;
            border: 1px solid #AAAADB;
            color: #38379C;
            padding: 8px;
            cursor: pointer;
            &--active {
              border: none;
              padding: 9px;
              background-color: #FFA800;
              color: white;
            }
            .icon-save-form-field {
              display: flex;
            }
          }
          .event-type {
            &__name {
              color: #23234F;
              font-family: "Nunito Sans Light";
              font-size: 15px;
              margin-bottom: 5px;
              display: flex;
              align-items: center;
              .template-header-item-icon {
                color: #38379C;
                font-size: 13px;
              }
            }
            &__option {
              margin-top: 5px;
              cursor: pointer;
              font-family: "Nunito Sans";
              font-size: 15px;
              font-style: normal;
              color: #38379C;
              display: flex;
              align-items: center;
              &-icon {
                font-size: 13px;
                display: flex;
                margin-right: 5px;
              }
              &-text {
                text-decoration: underline;
                color: #FFFFFF;
              }
            }
          }
        }
        .illness-control-document-icon {
          margin-left: 19px;
          font-size: 17px;
          display: flex;
          cursor: pointer;
          color: var(--blue-color);
        }
        :nth-child(odd) {
          /* background-color: var(--table-color); */
          background-color: #F8F8FB;
          /* border-radius: 14px; */
        }
      }
    }
  }

  @media only screen and (max-width: 840px) {
    padding: 0;
    .illness-control-reaction {
      padding: 100px 0;
      height: 0;
      position: fixed;
      width: 90%;
      display: flex;
      margin: auto;
    }
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Nunito';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`