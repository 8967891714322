import React, { useMemo, useState, useEffect } from "react";
import * as S from "./FeelingsBoxInfo.style";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

// pipes
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery } from "@apollo/client";
import { QUERY_GET_USER_FEELINGS } from "../../../../../../../graphql/queries/Feelings";

// Components
import FacesCard from "../../../../../shared/charts/FacesCard";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import CircleChartCard from "../../../../../shared/charts/circleChartCard";
import TimeVerticalBars from "../../../../../shared/charts/timeVerticalBars";
import TimeHorizontalBars from "../../../../../shared/charts/timeHorizontalBars";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function FeelingsBoxInfo({
  time,
  icon,
}) {

  const [t] = useTranslation("global");
  const listLevels = [
    t("patients.boxInfoPatient.report.statusList.good"),
    t("patients.boxInfoPatient.report.statusList.regular"),
    t("patients.boxInfoPatient.report.statusList.bad")
  ]
  const { patientID } = useParams();
  const { 
    data: userToFeelings, 
    loading: userToFeelingsLoading 
  } = useQuery(
    QUERY_GET_USER_FEELINGS, 
    {
      variables: { 
        patientId: patientID, 
        startDate: new Date(time?.startDate), 
        finishDate: new Date(time?.finishDate) 
      },
    }
  );
  const [totalPercentage, setTotalPercentage] = useState(0);

  const color = {
    primary: "var(--blue-color)",
    secundary: "var(--box-shadow-blue-color)",
    dark: "var(--blue-dark-color)",
    varitation: "var(--dashboard-color)"
  }

  const getPercentage = (arrayFeelings) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const quantityFeelings = arrayFeelings?.length;
    const sumFeelings = arrayFeelings?.reduce(reducer);
    const average = (sumFeelings / quantityFeelings); 
    return P.getPercentage(average, listLevels.length);
  } 

  const transformLevelFeeling = (levelFeeling) => {
    if(levelFeeling === 2 ) {
      return 1;
    } else if(levelFeeling === 1) {
      return 2;
    } else {
      return 3;
    }
  }

  const filterDataByDay = async (info) => {
    let levelFeelingsDay = [];
    info?.length > 0 ? setTotalPercentage(100) : setTotalPercentage(0);
    info?.forEach(infoItem => {

      levelFeelingsDay.push(
        {
          // day: new Date(infoItem.date).getHours() + ":" + new Date(infoItem.date).getMinutes(),
          day: P.hoursIn12format(new Date(infoItem.date).getHours(), new Date(infoItem.date).getMinutes()),
          percentage: getPercentage([transformLevelFeeling(infoItem.levelFeeling)])
        }
      )
    });
    return levelFeelingsDay;
  }

  const filterDataByWeek = async (info) => {
    
    const infoJoined = timesJoined(info);

    const totalPercentage = P.getPercentage(infoJoined?.length, 7);
    setTotalPercentage(totalPercentage); 

    let levelFeelingsWeek = [];
    let dateStartWeek = new Date(time.startDate);
    let dateFinishWeek = new Date(time.startDate);
    dateFinishWeek.setDate(dateFinishWeek.getDate() + 7);

    while(dateStartWeek.getTime() !== dateFinishWeek.getTime()) {
      let dateFiltered = infoJoined.filter(info => new Date(info.date).getDate() === new Date(dateStartWeek).getDate());      

      let percentage = dateFiltered.length ? getPercentage(dateFiltered[0].levelFeelings) : 0;
      levelFeelingsWeek.push(
        {
          day: new Date(dateStartWeek).getDate(),
          dayName: P.nameDayWeek(new Date(dateStartWeek).getDay(), 'S', t),
          percentage: percentage,
        }
      )
      dateStartWeek.setDate(dateStartWeek.getDate() + 1);
    }
    return levelFeelingsWeek;
  }

  const filterDataByMonth = async (info) => {
    const infoJoined = timesJoined(info);
    
    let levelFeelingsMonth = [];
    let dateStartMonth = new Date(time.startDate);
    let dateFinishMonth = new Date(time.startDate);
    const numberDaysMonth = new Date(dateStartMonth.getFullYear(), dateStartMonth.getMonth() + 1, 0).getDate();
    dateFinishMonth.setDate(dateFinishMonth.getDate() + numberDaysMonth);


    const totalPercentage = P.getPercentage(infoJoined?.length, numberDaysMonth);
    setTotalPercentage(totalPercentage); 

    while(dateStartMonth.getTime() !== dateFinishMonth.getTime()) {
      let dateFiltered = infoJoined.filter(info => new Date(info.date).getDate() === new Date(dateStartMonth).getDate());
      let percentage = dateFiltered.length ? getPercentage(dateFiltered[0].levelFeelings) : 0;
      levelFeelingsMonth.push(
        {
          day: new Date(dateStartMonth).getDate(),
          percentage: percentage,
        }
      )
      dateStartMonth.setDate(dateStartMonth.getDate() + 1);
    }
    return levelFeelingsMonth;
  }

  const timesJoined = (info) => {
    const filteredFeelings = [];
  
    info?.forEach(feeling => {
      const feelingDate = new Date(feeling.date).getDate();
      const existingFeeling = filteredFeelings.find(feel => new Date(feel.date).getDate() === feelingDate);
  
      if (!existingFeeling) {
        filteredFeelings.push({
          date: feeling.date,
          levelFeelings: [transformLevelFeeling(feeling.levelFeeling)]
        });
      } else {
        existingFeeling.levelFeelings.push(transformLevelFeeling(feeling.levelFeeling));
      }
    });
  
    return filteredFeelings;
  };

  const timesJoinedByFeelings = (info) => {
    let filteredFeelings = []
    info?.forEach(feeling => {
      const searchFeeling = filteredFeelings.find(feel => 
        feel.levelFeeling === feeling.levelFeeling
      );
      if (!searchFeeling) {
        const newFeeling = { levelFeeling: feeling.levelFeeling, quantity: 1 };
        filteredFeelings.push(newFeeling);
      } else {
        const feelIndex = filteredFeelings.indexOf(searchFeeling);
        filteredFeelings[`${feelIndex}`].quantity += 1;
      }
    });
    return (filteredFeelings);
  }

  const averageByDay = useMemo(() => {
    const info = userToFeelings?.user[0].userToFeelings;
    if(time?.timeOp === 'day') return filterDataByDay(info);
    if(time?.timeOp === 'week') return filterDataByWeek(info);
    if(time?.timeOp === 'month') return filterDataByMonth(info);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[userToFeelings, time])  
  // },[dataTemp])  

  const averageByLevelFeelings = useMemo(() => {
    const info = userToFeelings?.user[0].userToFeelings;
    const dataJoined = timesJoinedByFeelings(info);
    let percentageFeelings = []
    dataJoined.forEach(data => {
      percentageFeelings.push(
          {
            levelFeeling: data.levelFeeling,
            percentage: P.getPercentage(data.quantity, info.length)
          }
        )
    });
    return (percentageFeelings);
  },[userToFeelings]);  

  const getFacePercentage = (levelFace) => {
    return averageByLevelFeelings.filter(level => level.levelFeeling === levelFace)[0]?.percentage;
  }
  
  return (
    <S.FeelingsBoxInfo>
      <div className="boxInfoPatient__info">
        <div className="boxInfoPatient__info-left">
          <div className="boxInfoPatient__info-left-first">
            {
              <SymptomsBoxContainer 
                symptomsInfo={averageByDay}
                time={time}
                color={color}
                listLevels={listLevels}
                loading={userToFeelingsLoading}
              />
            }
          </div>
          <div className="boxInfoPatient__info-left-second">
            <FacesCard 
              colorPrimary={color.primary}
              colorSecundary={color.dark}
              flex={false}
              goodPercentage={
                getFacePercentage(0)
              }
              regularPercentage={
                getFacePercentage(1)
              }
              badPercentage={
                getFacePercentage(2)
              }
            />
          </div>
        </div>
        {/* <div className="boxInfoPatient__info-right">
          <CircleChartCard 
            icon={icon} 
            title={t("patients.boxInfoPatient.report.symptoms")}
            percentagePrincipal={totalPercentage}
            textPrincipal={t("patients.boxInfoPatient.report.reported")}
            colorIcon={color.dark}
            colorPrimary={color.primary}
            colorSecundary={color.secundary}
          />
        </div> */}
      </div>
    </S.FeelingsBoxInfo>
  );
}

const SymptomsBoxContainer = ({symptomsInfo, time, color, listLevels, loading}) => {
  const [infoSymptoms, setInfoSymptoms] = useState();
  const [t] = useTranslation("global");

  useEffect(() => {
    symptomsInfo?.then((info) => {
      setInfoSymptoms(info);
    })
  },[symptomsInfo, time])

  if (loading) return (
    <>
      <ZaiaReaction
        zaiaImg={loadingZaia}
        widthimg="100px"
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  if (infoSymptoms?.length === 0 || !infoSymptoms) return (
    <>
      <ZaiaReaction
        zaiaImg={logoZaiaEmpty}
        widthimg="100px"
        text={t('globally.noReports')}
        sizeText="15px"
        widthText="240px"
      />
    </>
  )

  return (
    <>
      {
        time?.timeOp === 'day' ? 
        <TimeHorizontalBars
          activeHeader={false}
          colorSecundary={color.secundary}
          colorPrimary={color.primary}
          colorVariation={color.varitation}
          time={time}
          chartInfo={infoSymptoms}
          listLevels={listLevels}
          listLevelsStripe={true}
        />:
        <TimeVerticalBars 
          activeHeader={false}
          colorSecundary={color.secundary}  
          colorPrimary={color.primary}
          colorVariation={color.varitation}
          time={time}
          chartInfo={infoSymptoms}
          listLevels={listLevels}
        />
      }
    </>
  )
}

export default FeelingsBoxInfo;
      