import React from "react";
import * as S from "./CmpDateSelection.style";
import SelectorForm from "../selectorForm";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Components
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";
import Popup from "reactjs-popup";
import ZaiaInputForm from "../../../../../../zaiaComponents/zaiaInputForm";
import ZaiaInput from "../../../../../../zaiaComponents/zaiaInput";

function CmpDateSelection({
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  
  const { t, i18n: { language } } = useTranslation("global");

  const changeOptionItem = (value) => {
    const valueDate = new Date(value);
    const userTimezoneOffset = valueDate.getTimezoneOffset() * 60000;
    const userToCustomFormField = {
      customFormFieldParentId: item?.customFormFieldParentId,
      customFormFieldId: item.id,
      value: new Date(new Date(valueDate.getTime() + userTimezoneOffset)),
      key: new Date(new Date(valueDate.getTime() + userTimezoneOffset))
    }
    insertfunction(userToCustomFormField);
  }

  return (
    <S.CmpDateSelection>
      <div className="selection-container">
        <div className="selection-container__item">
          {/* <i className="title-icon icon zaia-timeline"></i> */}
          <div className="title">
            {item.fieldName && P.dataTranslation(item.fieldName, language)}
          </div>
          <Popup
            trigger={
              <i className="template-header-item-icon icon zaia-alert"></i>
            }
            on={['hover', 'focus']}
            closeOnDocumentClick
            keepTooltipInside
            position={['right center']}
            nested
          >
            <PopUpPayload>
              <p>
              {P.dataTranslation(item.descript, language)}
              </p>
            </PopUpPayload>
          </Popup>
        </div>
        {/* {
          <SelectorForm 
            boxShadow={false}
            type='date'
            buttonText={t("outcomes.dateProcesSelector.enterDate")}
            dataInput={P.formatNumberDateInput(customFormChangeItem ? customFormChangeItem.value : item?.userToCustomFormFields[0]?.value, t, true, language)}
            handleSubmit={changeOptionItem}
          /> 

        } */}
        <div className="selection-container__item">
          {
            <ZaiaInput
              placeholder={''}
              type="date"
              name="date"
              backgroundColor="#F6F6FF"
              textcolor="#36369B"
              placeholdertextcolor="#888888"
              activeBorder={false}
              fontSize={"17px"}
              // height={"33px"}
              errorOption={false}
              borderRadius={"14px"}
              padding={"16px 20px"}
              getText={changeOptionItem}
              value={P.formatNumberDateInput(
                customFormChangeItem 
                ? customFormChangeItem.value
                  ? customFormChangeItem.value
                  : new Date(Date.now())
                : ""
              )}
              disabled={typeView === 'view'}
              // disabled={event && editionEvent ? true : false}
            />

          }
        </div>
      </div>
    </S.CmpDateSelection>
  );
}

export default CmpDateSelection;
