import styled from "styled-components";

export const Message = styled.div` 
  .message__sender-name {
    margin: ${({option}) => option === 'doctor' ? '0 0 0 auto' : '0 auto 0 0' };
    width: max-content;
    font-family: "Nunito SemiBold";
    color: var(--blue-color);
    font-size: 9.5px;
    margin-bottom: 3px;
  }
  .message {
    font-size: 0.75rem;
    width: fit-content;
    max-width: 500px;
    margin-bottom: ${({time}) => time ? '2px' : '8px' };
    &--patient {
      margin-left: auto;
      background-color: ${({color}) => color ? color : 'var(--blue-color)' };
      padding: 10px;
      border-radius: 10px 10px 0 10px;
      color: var(--white);
    }
    &--reciever {
      background-color: var(--footer-color);
      padding: 10px;
      border-radius: 10px 10px 10px 0;
      /* word-break: break-all; */
    }
    a {
      text-decoration: underline;
    }
  }
  .message__time{
    margin: ${({option}) => option === 'doctor' ? '0 0 0 auto' : '0 auto 0 0' };
    width: max-content;
    font-family: "Nunito";
    color: var(--text-toggle-gray);
    font-size: 8.5px;
    margin-bottom: 8px;
  }
`; 


