import React from "react";
import * as S from "./DatetimeXAxisAreaChart.style";
import ReactApexChart from 'react-apexcharts';
import * as P from "../../../../../utils/pipes";
import { useTranslation } from "react-i18next";


const DatetimeXAxisAreaChart = ({
  nameIndicator,
  dataChart,
  colors=['var(--blue-color)'],
  lineWidth=4,
  gradient=false,
  curveLine=true,
  multipleChart=false,
  colorMarker='#F44336',
  minDate=new Date(Date.now()).getTime(),
  maxDate=new Date(Date.now() + 604800000).getTime()
}) => {

  const [t] = useTranslation("global");

  function calcularTendencia(datos) {
    if (datos.length === 0) return [];

    // Extraer X (fechas) e Y (valores)
    const X = datos.map(d => d[0]);
    const Y = datos.map(d => d[1]);

    // Calcular promedios de X y Y
    const n = X.length;
    const sumX = X.reduce((a, b) => a + b, 0);
    const sumY = Y.reduce((a, b) => a + b, 0);
    const meanX = sumX / n;
    const meanY = sumY / n;

    // Calcular pendiente (m) y ordenada al origen (b) de la ecuación y = mx + b
    let num = 0, den = 0;
    for (let i = 0; i < n; i++) {
        num += (X[i] - meanX) * (Y[i] - meanY);
        den += (X[i] - meanX) ** 2;
    }
    const m = num / den;
    const b = meanY - m * meanX;

    // Calcular valores de tendencia
    const tendencia = X.map(x => [x, m * x + b]);

    return tendencia;
  }

  const series = multipleChart ? 
  [
    {
      name: nameIndicator[0],
      type: 'scatter',
      data: dataChart[0] ? dataChart[0] : []
    },
    {
      name: nameIndicator[1],
      type: 'scatter',
      data: dataChart[1] ? dataChart[1] : []
    },
    {
      name: nameIndicator[2],
      type: 'scatter',
      data: dataChart[2] ? dataChart[2] : []
    },
    {
      name: nameIndicator[0],
      type: 'line',
      data: dataChart[0] ? calcularTendencia(dataChart[0]) : []
    },
    {
      name: nameIndicator[1],
      type: 'line',
      data: dataChart[1] ? calcularTendencia(dataChart[1]) : []
    },
    {
      name: nameIndicator[2],
      type: 'line',
      data: dataChart[2] ? calcularTendencia(dataChart[2]) : []
    },
  ] :
  [
    {
      name: nameIndicator,
      type: 'scatter',
      data: dataChart ? dataChart : []
    },
    {
      name: nameIndicator,
      type: 'line',
      data: dataChart ? calcularTendencia(dataChart) : []
    }
  ]

  var options = {
    chart: {
      height: 350,
      stacked: false
    },
    colors:  multipleChart ? [...colors, ...colors] : [colors[0], colors[3]],
    series: series,
    stroke: {
      width: multipleChart ? [4, 4, 4, 2, 2, 2] : [4, 2],
      curve: "straight"
    },
    markers: {
      size:  multipleChart ? [6, 6, 6, 0, 0, 0] : [6, 0] // El primer valor es para scatter, el segundo para la línea
    },
    xaxis: {
      type: 'datetime',
      min: minDate, // Where the 6 is the number of days
      max: maxDate, // Today
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toFixed(2); // Limita a dos decimales
        }
      }
    },
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false
      }
    },
    legend: {
      show: true, // Mantiene la leyenda visible
      customLegendItems: multipleChart 
      ? [
        nameIndicator[0],
        nameIndicator[1],
        nameIndicator[2]
      ]
      : [ 
        nameIndicator
      ] // Solo muestra la serie de scatter
    }
  };

  // const options = {
  //     colors: colors,
  //     chart: {
  //       toolbar: {
  //         show: true,
  //         tools:{
  //           download: false // <== line to add
  //         }
  //       },
  //       height: 350,
  //       zoom: {
  //         type: 'xy'
  //       },
  //       defaultLocale: 'en/es',
  //       locales: [{
  //         name: 'en/es',
  //         options: {
  //           months: [
  //             P.nameMonth(0, 'L', t),
  //             P.nameMonth(1, 'L', t),
  //             P.nameMonth(2, 'L', t),
  //             P.nameMonth(3, 'L', t),
  //             P.nameMonth(4, 'L', t),
  //             P.nameMonth(5, 'L', t),
  //             P.nameMonth(6, 'L', t),
  //             P.nameMonth(7, 'L', t),
  //             P.nameMonth(8, 'L', t),
  //             P.nameMonth(9, 'L', t),
  //             P.nameMonth(10, 'L', t),
  //             P.nameMonth(11, 'L', t),
  //           ],
  //           shortMonths: [
  //             P.nameMonth(0, 'S', t),
  //             P.nameMonth(1, 'S', t),
  //             P.nameMonth(2, 'S', t),
  //             P.nameMonth(3, 'S', t),
  //             P.nameMonth(4, 'S', t),
  //             P.nameMonth(5, 'S', t),
  //             P.nameMonth(6, 'S', t),
  //             P.nameMonth(7, 'S', t),
  //             P.nameMonth(8, 'S', t),
  //             P.nameMonth(9, 'S', t),
  //             P.nameMonth(10, 'S', t),
  //             P.nameMonth(11, 'S', t),
  //           ],
  //           days: [
  //             P.nameDayWeek(0, 'L', t),
  //             P.nameDayWeek(1, 'L', t),
  //             P.nameDayWeek(2, 'L', t),
  //             P.nameDayWeek(3, 'L', t),
  //             P.nameDayWeek(4, 'L', t),
  //             P.nameDayWeek(5, 'L', t),
  //             P.nameDayWeek(6, 'L', t),
  //           ],
  //           shortDays: [
  //             P.nameDayWeek(0, 'S', t),
  //             P.nameDayWeek(1, 'S', t),
  //             P.nameDayWeek(2, 'S', t),
  //             P.nameDayWeek(3, 'S', t),
  //             P.nameDayWeek(4, 'S', t),
  //             P.nameDayWeek(5, 'S', t),
  //             P.nameDayWeek(6, 'S', t),
  //           ],
  //           toolbar: {
  //             download: t("dateTimeAreaChart.svg"),
  //             selection: t("dateTimeAreaChart.selection"),
  //             selectionZoom: t("dateTimeAreaChart.selectionZoom"),
  //             zoomIn: t("dateTimeAreaChart.zoomIn"),
  //             zoomOut: t("dateTimeAreaChart.zoomOut"),
  //             pan: t("dateTimeAreaChart.pan"),
  //             reset: t("dateTimeAreaChart.reset"),
  //           }
  //         }
  //       }]
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     grid: {
  //       xaxis: {
  //         lines: {
  //           show: true
  //         }
  //       },
  //       yaxis: {
  //         lines: {
  //           show: true
  //         }
  //       },
  //     },
  //     xaxis: {
  //       type: 'datetime',
  //       min: minDate, // Where the 6 is the number of days
  //       max: maxDate, // Today
  //     },
  //     legend: {
  //       show: true,
  //       position: 'bottom',
  //       fontSize: '10px',
  //       fontFamily: "Nunito SemiBold"
  //     }
  //   }

  return (
    <S.DatetimeXAxisAreaChart>
      <ReactApexChart 
        options={options} 
        series={options.series} 
        height={'100%'} 
        width={'100%'}
      />
    </S.DatetimeXAxisAreaChart>
  );
};

export default DatetimeXAxisAreaChart;
