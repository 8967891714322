import React from "react";
import * as S from "./MedicationMeasure.style";
import { useTranslation } from "react-i18next";

function MedicationMeasure({
  selectedMeasure,
  changeFormField,
  goNextInput
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const responses = [
    {
      id: 1,
      name: "Yes",
      value: true
    },
    {
      id: 2,
      name: "No",
      value: false
    }
  ]

  return (
    <S.MedicationMeasure>
      <div className="title">{t("healthAddData.medication.medicationTaken")}</div>
      <div className="responses-list">
        {
          responses.map(
            (response) => {
              return (
                <button 
                  key={response.value}
                  className={`response-item ${response.value === selectedMeasure.value && 'response-item--selected'}`} 
                  onClick={
                    () => {
                      changeFormField(
                        [
                          {
                            value: response.value, 
                            text: response.name, 
                            fieldName: "measure"
                          }
                        ]
                      );
                      goNextInput();
                    }
                  }
                >
                  <div className={`response-item__circle ${response.value === selectedMeasure.value && 'response-item__circle--selected'}`}></div>
                  <div className="response-item__text">{response.name}</div>
                </button>
              )
            }
          )
        }
      </div>
    </S.MedicationMeasure>
  );
}

export default MedicationMeasure;
