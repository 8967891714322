import React, { useEffect, useRef } from "react";
import * as S from "./CmpFileInput.style";
import SelectorForm from "../selectorForm";
import { useTranslation } from "react-i18next";
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Components
import Popup from "reactjs-popup";
import useUploadTempDoc from "../../../../../../../hooks/useUploadTempDoc";

function CmpFileInput({
  item,
  insertfunction,
  customFormChangeItem,
  typeView
}) {  
  const { t, i18n: { language } } = useTranslation("global");
  const fileInput = useRef(null);
  const {tempDoc, dataDoc, errorFormat, uploadTempDoc, deleteDoc} = useUploadTempDoc();  
  
  
  const handleClick = () => {    
    fileInput.current.click();
  }

  useEffect(() => {
    const userToCustomFormField = {
      customFormFieldParentId: item?.customFormFieldParentId,
      customFormFieldId: item.id,
      value: tempDoc,
      key: tempDoc,
      documentToSave: tempDoc
    }
    
    if(insertfunction) {
      insertfunction(userToCustomFormField)
    }
  }, [tempDoc]);

  return (
    <S.CmpFileInput>
      <div className="title">{item.fieldName && P.dataTranslation(item.fieldName, language)}
        <Popup
          trigger={
            <i className="template-header-item-icon icon zaia-alert"></i>
          }
          on={['hover', 'focus']}
          closeOnDocumentClick
          keepTooltipInside
          position={['right center']}
          nested
        >
          <PopUpPayload>
            <p>
            {P.dataTranslation(item.descript, language)}
            </p>
          </PopUpPayload>
        </Popup>
      </div>
      <div className="selection-container">
        <div className="chat-container">
          <input
            type="file"
            ref={fileInput}
            onChange={(e) => uploadTempDoc(e)}
            style={{display: 'none'}}
            // accept="image/*"  
          />
          <div className="upload-document" onClick={handleClick}>
            <div className={`upload-document__text ${errorFormat && "upload-document__text--error"}`}>
              {
                errorFormat 
                ? t("chatbotAI.formatError")
                : "Choose file"
              }
            </div>
            <i className="upload-document__icon icon zaia-i-upload"></i>
          </div>
          {
            tempDoc
            ? (
              <div className="document-container">
                <div className="document-container__text">{tempDoc.name}</div>
                <div className="document-container__icon-container"
                  onClick={() => {
                    deleteDoc();
                    fileInput.current.value = null;
                  }} 
                >
                  <i className="document-container__icon icon zaia-icono-cerrar"></i>
                </div>
              </div>
            )
            : (
              <div className="document-label">
                {t("globally.selectFileToUpload")}
              </div>
            )
          }
        </div>
      </div>
    </S.CmpFileInput>
  );
}

export default CmpFileInput;
