import React, { useRef, useState, useEffect, useContext } from "react";
import { useField } from "formik";
import { ThemeContext } from 'styled-components';
import * as S from "./ZaiaSelectForm.style.js";

function ZaiaInputForm({ 
  placeholder, 
  icon,
  setErrors,
  padding,
  items= [],
  height,
  fontSize,
  backgroundColor,
  textcolor,
  placeholdertextcolor,
  activeBorder = true,
  borderRadius,
  errorOption = true,
  maxHeightList,
  onlyBottomBorder,
  borderColor,
  fontFamily,
  disabledColor,
  disabledtextcolor,
  disabledIconColor,
  ...props
}) {

  const container = useRef(null);
  const textInput = useRef(null);
  const [field, meta, helpers] = useField(props);
  const [listActive, setlistActive] = useState(false);
  const { setValue } = helpers;
  const theme = useContext(ThemeContext);


  

  useEffect(() => {
    const handleClickOutside = e => {
      if (!container.current.contains(e.target)) {
        setlistActive(false)
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  function handleChange(item) {
    setValue(item)
    setlistActive(false);
  }
  

  return (
    <S.ZaiaSelectForm 
      padding={padding} 
      listActive={listActive} 
      error={meta}
      fontSize={fontSize}
      disabled={props.disabled} 
      backgroundColor={backgroundColor}
      textcolor={textcolor}
      placeholdertextcolor={placeholdertextcolor}
      activeBorder={props.disabled ? false : activeBorder}
      borderRadius={borderRadius}
      height={height}
      maxHeightList={maxHeightList}
      borderColor={borderColor}
      fontFamily={fontFamily}
      onlyBottomBorder={onlyBottomBorder}
      disabledColor={disabledColor}
      disabledtextcolor={disabledtextcolor}
      disabledIconColor={disabledIconColor}
    >
      <div onClick={(e) => {}} ref={container}>
        <div className="input-container" onClick={!props.disabled ? (e) => {textInput.current.focus(); setlistActive(e)} : null}>
          <i className={'icon-input-form icon zaia-' + icon} style={{ color: listActive ? theme.inputSelected : theme.inputPlaceholder }} />
          <input 
            ref={textInput}
            {...field} 
            {...props}
            value={items?.find( item => item.value === field.value)?.text ? items?.find( item => item.value === field.value)?.text : ''}
            placeholder={placeholder}
            readOnly
          />
          <i className={'icon-input-form-arrow icon zaia-icono-dropdown-light'} />
        </div>
        <div className="select-list">
          {
            items?.map(item => {
              return (
                <div className="select-item" key={item.value} onClick={() => handleChange(item.value)}>
                  <label>{item.text} </label>
                </div>
              )
            })
          }
        </div>
      </div>
      {
        errorOption ?
        <div className="input-error">{
          meta.touched && meta.error ? 
          <>
            <i className="icon zaia-icono-ayuda"></i>
            <div> {meta.error } </div>
          </>
          : null}
        </div> : null
      }
    </S.ZaiaSelectForm>
  );
}

export default ZaiaInputForm;
