import styled from "styled-components";
const waveTop = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-negra.svg`;

export const ProfileHeaderDiv = styled.div`
  width: 100%;
  height: 100%; 
  display: flex;
  flex-direction: column;
  /* padding: 35px 40px; */
  box-sizing: border-box;
    /* .image-container {
      align-self: center;
    } */
    /* .options {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-left: 5px;
      font-size: 13px;
      font-family: 'Nunito regular';
      padding: 10px 0;
      &__photo {
        cursor: pointer;
        i {
          font-size: 13px;
          margin-right: 5px;
        }
        &--edit {
          color: var(--skyblue-box-headercolor);
        }
        &--delete {
          color: var(--red-color);
        }
      }
    } */
  .image-container {
    justify-content: center;
    /* margin: 40px auto; */
    margin-bottom: 20px;
    .image-change {
      position: relative;
      .doctor-img {
        width: 167px;
        height: 167px;
        flex-shrink: 0;
        border-radius: 167px;
        border: 9.335px solid #FFF;
        box-shadow: 0px 4.149px 30px -10px #CECEEC;
      }
      .button-change-image {
        position: absolute;
        border-radius: 50%;
        width: 64.746px;
        height: 64.745px;
        font-size: 15px;
        /* box-shadow: 0px 3px 10px #1f1f5559; */
        background: #FFF;
        bottom: 0px;
        right: 0px;
        color: #36369B;
        display: flex;
        cursor: pointer;
        font-size: 20px;
        i {
          margin: auto;
        }
      }
    }
  }
  .buttons-profile-edition {
    display: flex;
    flex-direction: column;
    width: 176;
    .button-edition {
      display: flex;
      justify-content: center;
      padding: 10px;
      align-items: center;
      display: flex;
      border: none;
      outline: none;
      border-radius: 10px;
      font-family: Nunito Sans;
      font-size: 17px;
      cursor: pointer;
      margin-bottom: 20px;
      &--submit {
        color: #FFF;
        background-color: #27AE60;
      }
      &--cancel {
        border: 1px solid #36369B ;
        background-color: transparent;
        color: #36369B;
      }
      &--edit {
        background-color: #6C6BCC;
        color: #FFF; 
      }
      &__icon {
        font-size: 12px;
        margin-left: 10px;
      }
    }
  }
  h4 {
    /* font-family: "Nunito Bold"; */
  }
  span {
    /* font-family: "Nunito"; */
    margin-top: 4px;
    font-size: 12px; 
  }
  .additional-settings {
    padding: 50px 25px;
    
    h3 {
      color: var(--skyblue-dark);
      font-family: "Nunito Extrabold";
    }
    .setting-box {
      margin-top: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      .setting-box-icon {
        padding: 6px 6px;
        font-size: 20px;
        border-radius: 5px;
        color: var(--white);
        background-color: var(--skyblue-dark);
        margin-right: 10px;
        box-shadow: 2px 3px 11px -4px var(--skyblue-dark);
      }
      .setting-box-text {
        font-size: 17px;
      }
    }
  }

  @media only screen and (max-width: 840px) {
    padding: 30px 10px 15px;
    .additional-settings {
      padding: 35px 10px 0px;
    }
  }
`;
