import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router"; 
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import * as S from "./EmailVerified.style";
 import Cookies from "js-cookie";

// Components
import ZaiaButton from "../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../zaiaComponents/zaiaReaction";

// GraphQL
import { useMutation } from "@apollo/client";
import { MUTATION_ACTIVE_TOKEN } from "../../../graphql/mutations/Auth"; 

// Assets
const zaiaImage = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-success.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function EmailVerified() {
  const navigate = useNavigate();
  const location = useLocation();
  const [t] = useTranslation("global");
  const [ error, setError ] = useState(null);
     const webView = Cookies.get('webView');
  const [validateEmail, { error: validateEmailError , loading: validateEmailLoading }] = useMutation(MUTATION_ACTIVE_TOKEN, { errorPolicy: 'all' });

  useEffect(() => {
    const searchLocation = location.search;
    const params = new URLSearchParams(searchLocation);
    if (!params.get("token") || !params.get("email")) {
      navigate('/not-found');
    }
    try {
      validateEmail({
        variables: {
          email: params.get("email"),
          token: params.get("token")
        },
      });
      setError(false);
    } catch (err) {
      console.log(err);
      navigate('/not-found');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if(error === null || validateEmailLoading) 
    return (
      <S.EmailVerified>
        <ZaiaReaction
          widthimg="150px"
          zaiaImg={logoZaiaLoading}
        />  
      </S.EmailVerified>
    );

  if(error === true || validateEmailError) 
    navigate('/not-found');

  return (
    <S.EmailVerified  webView={webView}>
      <div className="content-email-verified">
        <h2 className="content-email-verified__title">{t("emailConfirm.ready")}</h2>
        <img className="zaia-image" src={zaiaImage} alt="ZAIA" />
        <div className="message">{t("emailConfirm.message1")}</div>
        <div className="message">{t("emailConfirm.message2")}</div>
        <div className="button-container" onClick={() => navigate("/")}>
          <ZaiaButton 
            title="ir al Home"
            shadow={true}

          />
        </div>
      </div>
    </S.EmailVerified>
  );
}

export default EmailVerified;
