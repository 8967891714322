import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListNotes.style";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";

import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";

function ListNotes({
  listNotes,
  maxViewActived,
  setNoteSelected,
  setCreateNoteActived,
  setEditNoteActived,
  patientSelected,
  historyActived,
  setHistoryActived,
  noteHistorySelected,
  setNoteHistorySelected,
  programIdSelected
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [processSelected, setProcessSelected] = useState();

  const processSelection = (processId) => {
    if(processId === "0") {
      setProcessSelected(null)
    } else {
      setProcessSelected(patientSelected.userToProcesses.find(userToProcess => userToProcess.process.id === processId).process)
    }
  }  

  useEffect(() => {
    if(programIdSelected) {
      processSelection(programIdSelected)
    }
  }, [programIdSelected])

  return (
    <S.ListNotes
      maxViewActived={maxViewActived}
      programIdSelected={programIdSelected}
    >
      {
        !programIdSelected &&
        <div className="select-program">
          <div className="select-program__label">
            {t("globally.selectProgram")}
          </div>
          <div className="select-program__select">
            <ZaiaSelect
              type="select"
              name="phoneLocation"
              placeholder={t("globally.selectProgram")}	
              placeholdertextcolor={"#3E3DA3"}
              setValue={(e) => 
                processSelection(e)
              }
              value={processSelected?.id}
              heightList={"250px"}
              items={
                [{
                  value: "0",
                  text: t("globally.allPrograms")
                }].concat(patientSelected?.userToProcesses.map(
                  userToProcess => {
                    return {
                      value: userToProcess.process.id,
                      text: P.dataTranslation(userToProcess.process.processName, language)
                    }
                  }
                ))
              }
              shadow={false}
              fontSize="14px"
              backgroundColor={"trans"}
              borderRadius={"10px"}
              textcolor={"#3E3DA3"}
              arrowColor={"#3E3DA3"}
              borderColor={"#6C6BCC"}
            />
          </div>
        </div>
      }
      <div className="list-notes">
        {
          listNotes?.filter(
            note => processSelected ? note.processId === processSelected.id : true
          ).map(note => {
            return (
              <Note
                key={note.id}
                note={note}
                setNoteSelected={setNoteSelected}
                setEditNoteActived={setEditNoteActived}
                setHistoryActived={setHistoryActived}
                programIdSelected={programIdSelected}
                setNoteHistorySelected={setNoteHistorySelected}
              />
            )
          })
        }
      </div>
      {
        !programIdSelected && 
        <div className="add-note-container"
          onClick={() => setCreateNoteActived(true)}
        >
          <button className="add-note">
            {t("notes.addNote")}
          </button>
        </div>
      }
    </S.ListNotes>
  );
}

const Note = ({
  note,
  setNoteSelected,
  setEditNoteActived,
  setHistoryActived,
  programIdSelected,
  setNoteHistorySelected
}) => {

  const {t, i18n: { language }} = useTranslation("global");  

  return (
    <div className="note-card"
      onClick={() => {
        setEditNoteActived(null)
        setHistoryActived(null)
        setNoteSelected(note)
        setNoteHistorySelected(null)
      }
      }
    >
      <div className="note-card__header">
        <div className="note-card__date">
          <div className="note-card__date-date">
            {P.longDate(note.created_at, t, language)}
          </div>
          <div className="note-card__date-time">
            {P.hoursIn12format(new Date(note.created_at).getHours(), new Date(note.created_at).getMinutes(), t, language)}
          </div>
        </div>
        <Popup
          trigger={
            <div className="note-card__option">
              <i className="note-card__option-icon icon zaia-icono-dots-horizontal"></i>
            </div>
          }
          keepTooltipInside
          nested
          // position={"right bottom"}
        >
          {
            close => (
              <S.CardOptions>
                <div className="card-option"
                  onClick={() => {setEditNoteActived(note)}}
                >
                  <div className="card-option__icon">
                    <i className="icon zaia-icono-editar"/>
                  </div>
                  <div className="card-option__text">
                    {t("globally.edit")}
                  </div>
                </div>
                <div className="card-option">
                  <div className="card-option__icon">
                    <i className="icon zaia-i-trash-can"/>
                  </div>
                  <div className="card-option__text">
                    {t("globally.delete")}
                  </div>
                </div>
                
                {/* <div className="card-option"
                  onClick={() => {
                    setNoteHistorySelected(null);
                    setHistoryActived(note);
                    close();
                  }}
                >
                  <div className="card-option__icon">
                    <i className="icon zaia-i-reverse-clocl"/>
                  </div>
                  <div className="card-option__text">
                    {t("globally.history")}
                  </div>
                </div> */}

              </S.CardOptions>
            )
          }
        </Popup>
      </div>
      {
        !programIdSelected &&
        <div className="note-card__program">
          {P.dataTranslation(note.userToProcess.process.processName, language)}
        </div>
      }
      <div className="note-card__note">
        {
          note.descript.split(" ").length > 10
          ? `${note.descript.split(" ").slice(0, 7).join(" ")}... `
          : note.descript
        } <b className="expand-comment">{
          note.descript.split(" ").length > 10 && t("globally.readMore")
        }</b>
      </div>
      {
        note.doctor &&
        <div className="note-card__written-by">
          <div className="note-card__written-by-label">
            {t("notes.writtenBy")}
          </div>
          <div className="note-card__written-by-name">
            {
              `${note.doctor.name} ${note.doctor.lastname}`
            }
          </div>
        </div>
      }
    </div>
  );

}

export default ListNotes;
