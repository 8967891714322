import React from "react";
import * as S from "./TranducerMessagesList.style";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';

function TranducerMessagesList({
  transducerState,
  transducerType,
  transcription,
  messages
}) {

  const { t } = useTranslation("global");

  if(transducerType === 2)
    return (
      <S.TranducerMessagesList>
        {
          messages.map((message) => {
            return (
              <S.Message
                patient={!message.messagerType}
                transducerState={transducerState}
                key={uuidv4()}
              >
                <div className="label">{message.messageType ? t("globally.patient") : t("globally.you")}</div>
                <div className="box">{message.messageText}</div>
              </S.Message>
            )
          })
        }
      </S.TranducerMessagesList>
    );

  if(transducerType === 1)
    return (
      <S.TranducerMessagesList>
        <S.Message
          patient={false}
          transducerState={transducerState}
          transducerType={transducerType}
        >
          <div className="label">{t("globally.note")}</div>
          {
            transcription
            ? <div className="box">{transcription}</div>
            : ""
          }
        </S.Message>
      </S.TranducerMessagesList>
    );
          
}

export default TranducerMessagesList;
