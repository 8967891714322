import React, { useContext, useState } from "react";
import * as S from "./MedicineSettingsChat.style";
import * as P from "../../../../../utils/pipes";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { ThemeContext } from 'styled-components';

// Components
import ZaiaButton from "../../../../zaiaComponents/zaiaButton";
import ZaiaCounter from "../../../../zaiaComponents/zaiaCounter";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { MUTATION_ADD_MEDICINE_TO_PATIENT } from "../../../../../graphql/mutations/Drugs";
import { QUERY_GET_DRUGS_INFO } from "../../../../../graphql/queries/drugs";

const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function MedicineSettingsChat({ 
  setMedicineSettings,
  selectedChat,
  setMedicineMessage
}) {
  const theme = useContext(ThemeContext);
  const { t, i18n: { language } } = useTranslation("global");
  
  const [doseQuantity, setDoseQuantity] = useState(1);
  const [drugsError, setDrugsError] = useState(false);
  
  const {
    data: drugsData,
    loading: drugsLoading,
  } = useQuery(QUERY_GET_DRUGS_INFO);
  const [addMedicineToPatient] = useMutation(MUTATION_ADD_MEDICINE_TO_PATIENT);
  

  const handleSubmit = async (values, { resetForm }) => {
    let date = new Date(Date.now())
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    try {
      // eslint-disable-next-line no-unused-vars
      const { 
        data: {
          insert_userToDrug: {
            returning
          }
        } 
      } = await addMedicineToPatient({
        variables: {
           medicineRecipe: {
            userId: selectedChat.personToChats[0].user.id,
            drugId: values.medicine,
            doseType: values.dose,
            comment: values.description,  
            dose: doseQuantity,
            doseShape: values.doseShape,
            frequency: 24,
            quantity: 10,
            frequencyType: "hours",
            startHour: P.formatNumberTimeInput(new Date(date.getTime() + userTimezoneOffset)),
          }
        },
      });

      setMedicineMessage(
        `
          {{medicine}} 
          ${selectedChat.chatNameUser} 
          ${t('chat.medicinesSetting.medicineText1')}
          "${returning[0].drug.genericName}" 
          ${t('chat.medicinesSetting.medicineText2')}
        `
      )
      setDoseQuantity(1);
      resetForm();
    } catch (err) {
      setDrugsError(true);
      console.log(err);
    }
  }


  if(drugsError)
    return (
      <S.MedicineSettingsChat>
        <div className="error-message">
          <ZaiaReaction
            zaiaImg={logoZaiaAlert}
            widthimg="130px"
            text={t("chat.medicinesSetting.errorSendingPrescription")}
            sizeText="18px"
            widthText="240px"
          />
          <div onClick={() => setDrugsError(false)}>
            <ZaiaButton
              type="submit"
              title={t("globally.tryAgain")}
            />
          </div>
        </div>
      </S.MedicineSettingsChat>
    )

  return (
    <S.MedicineSettingsChat>
      <header className="settings__header">
        <i className="icon zaia-icono-cerrar settings__header-icon" onClick={() => setMedicineSettings(false)}></i>
        <div className="settings__header-title">
          <div className="settings__header-title-icon">
            <i className="zaia-icono-medicina"></i>
          </div>
          <h4>{t('chat.medicinesSetting.prescription')}</h4>
        </div>
      </header>
      <div className="settings__container">
        <div className="bottom-container">
        <Formik
            initialValues={{
              medicine: null,
              doseShape: null,
              description: "",
              dose: null,
            }}
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form className="medicine-recipe-form" onSubmit={ props.handleSubmit }>
                <div className="medicine-recipe-form__inputs">
                  <div className="input-content">
                    <span className="input-content__label-bold">{t('globally.medication')}</span>
                    <ZaiaSelectForm
                      placeholder={t('chat.medicinesSetting.selectMedicine')}
                      type="text"
                      name="medicine"
                      items={
                        drugsData?.drug.map(
                          drug => {
                            return {
                              value: drug.id, 
                              text: drug.genericName
                            }
                          }
                        ).sort(function(a,b){
                          return a.text.localeCompare(b.text);
                        })
                      }
                    />
                  </div>
                  <div className="input-content">
                    <span>{t('chat.medicinesSetting.addMedicine')}</span>
                    <div className="input-content__double-content">
                      <ZaiaCounter
                        counterNumber={doseQuantity}
                        setCounterNumber={setDoseQuantity}
                        color
                      />
                      <ZaiaSelectForm
                        placeholder={t('chat.medicinesSetting.selectShapeDose')}
                        type="select"
                        name="doseShape"
                        errorOption={false}
                        items={
                          drugsData?.enDoseShape.map(
                            doseShape => {
                              return {
                                value: doseShape.value, 
                                text: P.dataTranslation(doseShape.comment, language)
                              }
                            }
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="input-content">
                    <span>{t('globally.dose')}</span>
                    <ZaiaSelectForm
                      placeholder={t('chat.medicinesSetting.selectDose')}
                      type="select"
                      name="dose"
                      errorOption={false}
                      disabled={props.values.medicine ? false : true}
                      items={
                        props.values.medicine
                        ? drugsData?.drug.find(drug => drug.id === props.values.medicine).drugDoses.map(
                          drugDose => {
                            return {
                              value: drugDose.doseTypeVal.value, 
                              text: P.dataTranslation(drugDose.doseTypeVal.comment, language)
                            }
                          }
                        )
                        : null
                      }
                    />
                  </div>
                  <div className="input-content">
                    <span>{t('globally.description')}</span>
                    <ZaiaInputForm
                      placeholder={t('globally.description')}
                      type="text"
                      name="description"
                      padding={"5px"}
                      textarea={true}
                      fontSize={"14px"}
                      errorOption={false}
                    />
                  </div>
                </div>
                <div className="button-container">
                  <ZaiaButton
                    type="submit"
                    backgroundColor={theme.primarySectionMedicines}
                    title={
                      drugsLoading ?
                      `${t('globally.loading')}...` :
                      t('chat.medicinesSetting.submitPrescription')
                    }
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </S.MedicineSettingsChat>
  );
}

export default MedicineSettingsChat;
