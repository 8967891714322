import styled from "styled-components";

export const OutcomesProcessByPatient = styled.div`
  /* padding: 10px; */
  /* background-color: var(--white); */
  position: relative;
  height: 100%;
  box-sizing: border-box;
  border-top: 0.5px solid #EEEEEE;
  .table-title{
    font-size: 18px;
    font-family: "Nunito";
    color: ${({theme}) => theme.primaryColor };
    /* border-bottom: 1.6px solid var(--no-notification-background); */
    padding-top: 17px;
    /* margin-bottom: 10px; */
    display: flex;
    justify-content: space-between;
    /* background-color: #E3E3F2; */
    border-radius: 50px;
    align-items: center;
    /* height: 20px; */
    min-width: 750px;
    .buttons-container {
      display:  flex;
      min-width: 358px;
    }
    .filter-buttons-container {
      display: flex;
      align-items: center;
      .highlights-button {
        cursor: pointer;
        display: flex;
        border: 1px solid #AAAADB;
        align-items: center;
        padding: 5px 15px;
        border-radius: 5px;
        color: #36369B;
        &__icon {
          margin-right: 5px;
        }
        &--active {
          padding: 6px 16px;
          color: white;
          border: none;
          background-color: #FFA800;

        }
      }
      .separator {
        height: 16px;
        border-right: 1px solid #C9C9E9;
        margin: 0 12px;
      }
      .event-type-select {
        .input-container {
          width: 270px;
        }
      }
    }
  }
  .table-event-filters {
    display: flex;
    justify-content: right;
    align-items: center;
    /* margin-bottom: 40px; */
    flex-wrap: wrap;
    &__clear {
      padding-top: 10px;
      color: #526479;
      font-family: "Nunito Sans";
      font-size: 16px;
      text-decoration-line: underline;
      margin-right: 10px;
      cursor: pointer;
    }
    .event-item {
      margin-top: 10px;
      margin-left: 10px;
      display: flex;
      border-radius: 15px;
      background: var(--Purple-II, #6C6BCC);
      display: flex;
      padding: 5px 10px;
      align-items: center;
      &__name {
        color: #FFF;
        font-family: "Nunito Sans";
        font-size: 16px;
        margin-right: 10px;
      }
      &__button {
        border-radius: 57px;
        background: rgba(255, 255, 255, 0.29);
        display: flex;
        padding: 5.613px;
        align-items: flex-start;
        font-size: 6px;
        color: #FFF;
        cursor: pointer;
      }
    }
  }
  .select-view {
    display: none;
    &__button-selector {
    }
    &__popup {
      width: 100%;
      left: 0;
      padding: 20px;
      box-sizing: border-box; 
    }
  }
  .view-container {
    overflow: auto;
    height: calc(100% - 50px);
  }

  @media only screen and (max-width: 768px) {
    padding: 10px 0px;
  }

  @media only screen and (max-width: 850px) {
    .table-title{
      background-color: transparent;
      border-bottom: none;
      padding: 0;
      .buttons-container {
        display: none;
      }
    }
    .select-view {
      display: block;
      &__label {
        margin-bottom: 5px;
        color: ${({theme}) => `${theme.primaryColor}` };
      }
      &__button-selector {
        background-color: ${({theme}) => `${theme.primaryColor}` };
        justify-content: space-between;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
      }
      &__icon-container {
        display: flex;
        align-items: center;
        color: ${({theme}) => `${theme.onPrimaryColor}` };
        padding-right: 14px;
        i {
          display: flex;
        }
      }
    }
    .view-container {
      height: calc(100% - 95px);
      width: 100%;
      min-width: auto;
    }
  }
`;

export const ProstateProcessRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  h5 {
    font-family: "Nunito Bold";
    margin-bottom: 10px;
  }
  .row-left {
    width: ${({row1}) => row1 ? row1 : 'auto'}; 
    min-width: ${({row1Min}) => row1Min ? row1Min : 'auto'}; 
    margin-right: 5px;
    padding-bottom: 10px;
    flex-grow:2;
  }
  .row-right {
    width: ${({row2}) => row2 ? row2 : 'auto'};
    min-width: ${({row2Min}) => row2Min ? row2Min : 'auto'}; 
    margin-left: 5px;
    flex-grow:2;
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Nunito';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`

export const ButtonView = styled.button`
  border-radius: 50px;
  /* height: 37px; */
  padding: 5px 15px;
  /* padding: 0 10px; */
  justify-content: center;
  min-width: 153px;
  border: none;
  outline: none;
  background-color: ${({active, theme}) => active ? "#6C6BCC" : theme.background};
  border: 1px solid ${({active, theme}) => active ? "#6C6BCC" : "#AAAADB"};
  color: ${({active, theme}) => active ? theme.background : theme.primaryColor };
  margin: 0 0 0 14px;
  font-family: "Nunito Sans Light";
  font-size: 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .icon-container {
    margin-right: 5px;
    /* padding-top: 6px; */
    i {
      font-size: 13px;
      color: ${({active, theme}) => active ? "#FFFFFF" : "#6C6BCC"};
    }
  }

  @media only screen and (max-width: 850px) {
    background-color: ${({active, theme}) => `${theme.primaryColor}${active ? '': '61'}` };
    border-radius: 0px;
    margin: 0;
    width: calc(100vw - 20px);
  }
`

export const SelectViewSelector  = styled.div`
  background-color: ${({theme}) => theme.background };
  /* padding: 10px; */
  overflow: hidden;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  width: auto;
`