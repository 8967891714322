import React, {useState, useRef, useEffect, useMemo} from "react";
import * as S from  "./PatientSelector.style";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import { useLazyQuery, useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_USERS_BY_FILTER, QUERY_GET_USERS_BY_FILTER_POLICE } from "../../../../../graphql/queries/User";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import { Actions } from "../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// pipes
import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";
import { QUERY_GET_SIDE_EFFECTS_BY_USER_COUNTER } from "../../../../../graphql/queries/SideEffects";
import { QUERY_GET_VITAL_SIGNS_BY_USER_COUNTER } from "../../../../../graphql/queries/VitalSigns";
import { QUERY_GET_PROCESS_TYPES } from "../../../../../graphql/queries/Process";
import ProcessName from "../../../shared/processName";
import { PopUpPayload } from "../../../outcomes/components/outcomesComponents/prostateCancer/cmpVerticalOptions/CmpVerticalOptions.style";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED } from "../../../../../graphql/subscriptions/UserToProcess";
import usePatientList from "../../../../../hooks/usePatientList";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import ModalCreateInvitePatient from "../../../shared/modals/modalCreateInvitePatient";
import ModalUserAiNavigator from "../../../aiNavigator/components/modalUserAiNavigator";
import PriorityPatients from "../../../aiNavigator/components/patientSelectorView/components/priorityPatients";
import FollowUpView from "../../../aiNavigator/components/patientSelectorView/components/followUpView";
import { useNavigate } from "react-router-dom";
import { Routes } from "../../../../../utils/constants";
import PatientCreation from "../patientCreation";

const reactionNoPatients = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-patients.svg`;
const searchFigure = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/search-patient-shine.svg`;

function PatientSelector() {  

    const navigate = useNavigate();
    const container = useRef(null);
    const textInput = useRef(null);
    const { POLICE } = Routes;
    const [activeinput, setActiveinput] = useState(false);
    const {t} = useTranslation("global");
    const { patientList, patientListLoading } = usePatientList(); 

    const [activeCreatePatient, setActiveCreatePatient] = useState();

    const [heightTitle, setHeightTitle] = useState(0)
    const refTitle = useRef(null)

    useEffect(() => {
      setHeightTitle(refTitle.current.clientHeight)
    })

    useEffect(() => {
      const handleClickOutside = e => {
        if (!container.current.contains(e.target)) {
          setActiveinput(false)
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const activeInput = () => {
      textInput.current.focus(); 
      setActiveinput(true);
    } 

    const [inputText, setInputText] = useState('');

    const [ getPatients, { data: patientsData }] = useLazyQuery(
      QUERY_GET_USERS_BY_FILTER_POLICE
    );
    
    const getText = (e) => {
      setInputText(e);
      if ( e.length > 1) 
      getPatients(
        {
          variables: {
            text: `%${inputText}%`,
          }
        }
      );
    }

    return (
      <S.PatientSelector
        heightTitle={heightTitle}
      >
        <Modal open={activeCreatePatient}>
          <PatientCreation
            setActiveCreatePatient={() => setActiveCreatePatient(false)}
          />
        </Modal>
        <div className="navigator-title" ref={refTitle}>
          <i className="navigator-title__icon icon zaia-i-magic"></i>
          <div className="navigator-title__text">
            {t("police.title")}
          </div>
          {/* <div className="navigator-title__text-description">
            ~Search any patient by name or physical characteristics, such as tattoos or scars
          </div> */}
        </div>
        {
          patientList?.length === 0 && !patientListLoading && 
          <div className="no-patients-container">
            <div className="no-patients">
              <ZaiaReaction
                zaiaImg={reactionNoPatients}
                widthimg="392.1px"
                text={t("aiNavigator.noPatientsTitle")}
                subText={t("aiNavigator.noPatientsSubtitle")}
                sizeText="33.25px"
                sizeSubText={"15px"}
                widthText="412px"
                widthSubText={"412px"}
              />
              <button className="no-patients__button"
                onClick={() => setActiveCreatePatient(true)}
              >
                {t("globally.inviteCreatePatient")}
              </button>
            </div>
          </div>
        }
        {
          patientList?.length > 0 &&
          <>
            <div className="searcher-container">
              <div className="searcher-content" ref={container} onClick={() => activeInput()}>
                <div className="searcher-input">
                  <input
                    className="searcher-input__input"
                    type="text"
                    onChange={(event) => getText(event.target.value)}
                    ref={textInput} 
                    placeholder={t("globally.searchPatient")}
                  />
                  <i className="searcher-input__icon icon zaia-search"/>
                </div>
                {
                  (activeinput && inputText.length > 2 && patientsData?.user.length > 0) &&
                  <div className="searcher-options">
                    <div className="options-list">
                      {
                        patientsData?.user.map((patient) => {
                        return (
                          <div className="patient-option"
                            onClick={
                              () => {
                                navigate(`${POLICE.route}/${patient.id}`); 
                                // recordAction(Actions.AINAVIGATOR_MODAL.GO_OUTCOMES);
                              }
                            }
                          >
                            <div className="patient-option__left">
                              <ZaiaImage
                                img={patient?.picture}
                                alt=""
                                size={37}
                                rounded={true}
                              />
                              <div className="patient-option__info">
                                <div className="patient-option__info-name">{`${patient.name} ${patient.lastname}`}</div>

                                <div className="patient-option__info-program">
                                  {patient.userToProcesses[0].descript}
                                </div>

                              </div>
                            </div>
                          </div>
                          )
                        })
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="suggestion-list">
              <div className="search-figure">
                <ZaiaReaction
                  zaiaImg={searchFigure}
                  widthimg="251.37px"
                />
              </div>
              <div className="search-label">
                {t("police.searchPatient")}
              </div>
              <div className="button-container">
                <button className="button-add-patient"
                  onClick={() => setActiveCreatePatient(true)}
                >
                  <i className="button-add-patient__icon icon zaia-i-invitepatient"/>
                  {t("police.register")}
                </button>
              </div>
            </div>
          </>
        }
      </S.PatientSelector>
    )
  }
  
  export default PatientSelector;