import styled from "styled-components";

export const ZaiaInputHour = styled.div`
  width: -webkit-fill-available;
  .input-container {
    background-color: ${({theme}) => theme.inputBackground};
    border: none;
    box-sizing: border-box;
    padding: ${({padding}) => padding ? padding :'4px 7px'};
    border-radius: 8px;
    display: flex;
    align-items: center;
    min-width: 100px;
    cursor: ${({disabled}) => disabled ? '' :'text'};
    position: relative;
    border: 1.8px solid ${
        ({ 
          theme, 
          active, 
          error
        }) => 
          {
            if (active) {
              return theme.inputSelected;
            } else {
              if (error?.error && error?.touched) {
                return theme.warning;
              } else {
                return theme.inputBorder;
              }
            }
          }
      };
    .icon-input-form {
      font-size: 14px;
    }
    input {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Nunito";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      margin-left: 7px;
      color: ${({theme}) => theme.inputText};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme }) => theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Nunito";
      }
    }
    .set-pm-am-button {
      border-radius: 9px;
      background-color: ${({theme}) => theme.primaryColor};
      border: none;
      margin: 0px 2px;
      padding: 9px;
      font-size: 12px;
      color: ${({theme}) => theme.onPrimaryColor};
      cursor: pointer;
      &--disabled {
        background-color: ${({theme}) => theme.disableButton};
      }
    }
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
`