import React, { useMemo } from "react";
import * as S from "./MedicationDosage.style";
import { useTranslation } from "react-i18next";

import * as P from "../../../../../../../../utils/pipes";
import ZaiaSelect from "../../../../../../../zaiaComponents/zaiaSelect";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";

function MedicationDosage({
  selectedMedication,
  selectedQuantity,
  selectedDose,
  changeFormField,
  doseList,
  goNextInput
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const doseListOrdered = useMemo(() => {
    if(selectedMedication.value === 0) {
      return doseList;
    } else {
      const newDoseList = doseList?.map((dose) => {
        return dose.doseTypeVal
      })
      return newDoseList?.length ? newDoseList : doseList; 
    }
  }, [doseList])

  return (
    <S.MedicationDosage>
      <div className="title">{t("healthAddData.medication.capsulesQuantity")}</div>
      <div className="doseage-inputs">
        <div className="input-space">
          <div className="input-label">
            {t("healthAddData.medication.capsulesQuantity")}
          </div>
          <div className="input-space__number-icon">
            #
          </div>
          <ZaiaInput 
            placeholder={t("healthAddData.medication.quantity")}
            type={"number"}
            name="data"
            errorOption={false}
            getText={(e) => {
              if(e) {
                changeFormField(
                  [
                    {
                      value: e, 
                      text: `${e} ${e > 1 ? t("healthAddData.medication.unitsOf") : t("healthAddData.medication.unitOf")} `,
                      fieldName: "quantity"
                    }
                  ]
                )
              } else {
                changeFormField(
                  [
                    {
                      value: null, 
                      text: "", 
                      fieldName: "quantity"
                    }
                  ]
                )
              }
            }}
            value={selectedQuantity.value ?? ""}
            padding={"20px"}
            borderRadius={"14px"}
            textcolor={"#6C6BCC"}
            borderColor={"transparent"}
            placeholdertextcolor={"#878792"}
            fontSize="15px"
            />
          <div className="input-msg">{t("healthAddData.medication.quantityPerDose")}</div>
        </div>
        <div className="input-space input-space--dose">
          <div className="input-label">
            {t("globally.dose")}
          </div>
          <ZaiaSelect
            placeholder={t("healthAddData.medication.doseSelection")}
            textcolor="#6C6BCC"
            placeholdertextcolor="#6C6BCC"
            fontSize={"15px"}
            height={"33px"}
            errorOption={false}
            maxHeightList={"200px"}
            padding={"20px 27px"}
            borderColor={"#6C6BCC"}
            borderRadius={"14px"}
            heightList={"200px"}
            disabled={selectedMedication.value === null} 
            value={selectedDose.value}
            setValue={(value) => {
              changeFormField(
                [
                  {
                    value: value, 
                    text: doseListOrdered?.find((dose) => dose.value === value).comment.cod, 
                    fieldName: "dose"
                  }
                ]
              )
              goNextInput()
            }
            }
            items={doseListOrdered?.map((dose) => {
              return {
                value: dose.value,
                text: P.dataTranslation(
                  dose.comment,
                  language  
                ),
              };
            })}
          />
        </div>
      </div>
    </S.MedicationDosage>
  );
}

export default MedicationDosage;
