import React, { useEffect, useState } from "react";
import * as S from "./Alerts.style";
import Popup from "reactjs-popup";
import { useTranslation } from "react-i18next";
import { useStateValue } from "../../../contextAPI/StateProvider";

// Pipes
import * as P from "../../../utils/pipes"

// Hooks
import { Actions } from "../../../utils/actionsIds";
import useAssignSelectPatient from "../../../hooks/useAssignSelectPatient";
import ZaiaImage from "../../zaiaComponents/zaiaImage";
import { useQuery, useSubscription } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../graphql/queries/User";
import Modal from "../../../utils/modal";
import ModalSelectPatient from "../shared/modals/modalSelectPatient";
import ListOfAlerts from "./components/listOfAlerts";
import ModalRangeCalendar from "../shared/modals/modalRangeCalendar/ModalRangeCalendar";
import ModalManagedAlerts from "./components/modalManagedAlerts";
import { SUBSCRIPTION_USER_ALERT_TRACKING } from "../../../graphql/subscriptions/Alerts";

// Assets
const defaultUser = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user.png`;

function Alerts() {
  const { t, i18n: { language }} = useTranslation("global"); 

  const [{ selectedPatient }] = useStateValue();

  const { selectPatient, removePatient } = useAssignSelectPatient();
  const [activeModalPatient, setActiveModalPatient] = useState(false);
  const [ modalCustomRangeActive, setModalCustomRangeActive ] = useState(false);
  const [pendingAlerts, setPendingAlerts] = useState(false);
  const aDayTimeQuantity = 24 * 60 * 60 * 1000;
  const currentDate = new Date(Date.now());
  const currentDateWithoutTime = new Date(currentDate.setHours(0,0,0,0));
  const [rangeSelected, setRangeSelected] = useState();
  const [customInitialDateRange, setCustomInitialDateRange] = useState();
  const [customFinishDateRange, setCustomFinishDateRange] = useState();
  const [managedAlertsSuccess, setManagedAlertsSuccess] = useState(false);
  const [alertsListSelected, setAlertsListSelected] = useState([]);

  const { data: usersAlerts } = useQuery(SUBSCRIPTION_USER_ALERT_TRACKING, {
    variables: {
      initialDate: rangeSelected?.initialDate,
      finalDate: rangeSelected?.finishDate
    }
  })

  console.log("allllll", usersAlerts?.userAlertTracking);

  const { data: userData } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: selectedPatient?.id },
  });

  const patient = {
    id: userData?.user[0]?.id,
    img: userData?.user[0]?.picture,
    name: userData?.user[0]?.name ? userData?.user[0]?.name + " " + userData?.user[0]?.lastname : '',
    process: userData?.user[0]?.userToProcesses?.length > 1 ? t("globally.variousProcesses") : P.dataTranslation(userData?.user[0]?.userToProcesses[0]?.process?.processName, language),
    phone: userData?.user[0]?.phone,
    weight: userData?.user[0]?.weight,
    height: userData?.user[0]?.height,
    birthdate: P.getAge(userData?.user[0]?.birthdate),
    nameCarer: userData?.user[0]?.nameCarer,
    phoneCarer: userData?.user[0]?.phoneCarer
  }

  const removePatientAction = () => {
    removePatient();
  }

  const rangeTimesList = [
    {
      id: 1,
      text: t("globally.lastWeek"),
      initialDate: new Date(currentDateWithoutTime.getTime() - 7 * aDayTimeQuantity),
      finishDate: new Date(Date.now())
    },
    {
      id: 2,
      text: t("globally.lastTwoWeeks"),
      initialDate: new Date(currentDateWithoutTime.getTime() - 14 * aDayTimeQuantity),
      finishDate: new Date(Date.now())
    },
    {
      id: 3,
      text: t("globally.lastThreeWeeks"),
      initialDate: new Date(currentDateWithoutTime.getTime() - 21 * aDayTimeQuantity),
      finishDate: new Date(Date.now())
    }
  ];

  useEffect(() => {
    if(rangeSelected) {
      setCustomInitialDateRange(null)
      setCustomFinishDateRange(null)
    }
  }, [rangeSelected])

  useEffect(() => {
    setRangeSelected(rangeTimesList[0]);
  }, []);

  return (
    <S.Alerts>
      {
        <Modal open={modalCustomRangeActive}>
          <ModalRangeCalendar
            setSeeDoubleCalendarRangeActive={setModalCustomRangeActive}
            initialDateRange={customInitialDateRange}
            finishDateRange={customFinishDateRange}
            setInitialDateRange={setCustomInitialDateRange}
            setFinishDateRange={setCustomFinishDateRange}
          />
        </Modal>
      }
      { 
        <Modal open={activeModalPatient}>
          <ModalSelectPatient
            setModalActive={setActiveModalPatient}
            setPatient={(patient) => selectPatient(patient.id)}
            idAction={Actions.ALERTS.SELECT_PATIENT}
          />
        </Modal>
      }
      {
        <Modal open={managedAlertsSuccess}>
          <ModalManagedAlerts
            setModalActive={setManagedAlertsSuccess}
          />
        </Modal>
      }
        <div className="header">
          <div className="header__left">
            {
              selectedPatient
              ? <div className="patient-filtered">
                <button className="patient-filtered__close" onClick={() => removePatientAction()}>
                  <i className="patient-filtered__close-icon icon zaia-icono-cerrar"></i>
                </button>
                <div className="patient-filtered__filtered-by">
                  {t("globally.filterBy")}
                </div>
                <div className="patient-filtered__img">
                  <ZaiaImage
                    img={patient?.img} 
                    alt="" 
                    size={20} 
                    defaultImg={defaultUser} 
                    rounded
                  />
                </div>
                <div className="patient-filtered__text">
                  <div className="patient-filtered__text-title">
                    {patient.name}
                  </div>
                </div>
              </div>
              : <div className="patient-selector" onClick={() => setActiveModalPatient(true)}>
                <div className="patient-selector__box-icon">
                  <i className="patient-selector__icon icon zaia-i-patient" />
                </div>
                <div className="patient-selector__text">
                  {t("filterPanel.filterByPatient")}
                </div>
              </div>
            }

            <div className="filter filter--program">
              <div className="filter__space">
                <Popup
                  trigger={
                    <button className={`select-filter ${"select-filter--actived"}`}>
                      <div className="select-filter__text">
                        Rango de tiempo <b>
                          {
                            customInitialDateRange && customFinishDateRange 
                              ? `${P.shortDate(customInitialDateRange, t, true, language)} - ${P.shortDate(customFinishDateRange, t, true, language)}` 
                              : rangeSelected?.text
                          }
                        </b>
                      </div>
                      <i className="select-filter__icon icon zaia-icono-dropdown-light"></i>
                    </button>
                  }
                  closeOnDocumentClick
                  keepTooltipInside
                  position={["bottom center"]}
                  nested
                >
                  {
                    close => (
                      <S.TimeRangeSelector>
                        <div className="list-ranges">
                          {
                            rangeTimesList.map((item) => {
                              return (
                                <div 
                                  className={`range-item ${item.id === rangeSelected?.id && (!customInitialDateRange && !customFinishDateRange) && "range-item--selected"}`}
                                  key={item.id}
                                  onClick={() => {
                                    setRangeSelected(item);
                                    close();
                                  }}
                                >{item.text}</div>
                              )
                            })
                          }
                          <div className="separator"></div>
                          <div className="choose-range"onClick={() => {
                              setModalCustomRangeActive(true);
                              close();
                            }}
                          >
                            <div className="choose-range__text">
                              {t("globally.customRange")}
                            </div>
                            <i className="choose-range__icon icon zaia-i-period-time"></i>
                          </div>
                        </div>
                      </S.TimeRangeSelector>
                    )
                  }
                </Popup>                   
              </div>
            </div>
          </div>
          <div className="header__right">
            <button className={`alert-button ${!pendingAlerts && "alert-button--selected"}`}
              onClick={() => setPendingAlerts(false)}
            >
               <div className={`alert-button__text ${!pendingAlerts && "alert-button__text--selected"}`}>Alertas pendienes</div>
               <div className="alert-button__number">66</div>
            </button>
            <button className={`alert-button ${pendingAlerts && "alert-button--selected"}`}
              onClick={() => setPendingAlerts(true)}
            >
               <div className={`alert-button__text ${!pendingAlerts && "alert-button__text--selected"}`}>Alertas gestionadas</div>
               <div className="alert-button__number">14</div>
            </button>
          </div>
        </div>
        <div className="alerts-container">
          <ListOfAlerts
            selectPatient={(patient) => selectPatient(patient.id)}
            pendingAlerts={pendingAlerts}
            setPendingAlerts={setPendingAlerts}
            alertsList={usersAlerts?.userAlertTracking}
            alertsListSelected={alertsListSelected}
            setAlertsListSelected={setAlertsListSelected}
          />
        </div>
        {
          alertsListSelected.length > 0 &&
          <div className="alerts-management-container">
            <div className="alerts-management">
              <div className="alerts-management__top">
                <div className="mark-as-text">Marcar como</div>
                <div className="alerts-management__buttons">
                  <button className={`button ${false && "button--selected"}`}>
                    <div className="button__text">
                      Gestionada
                    </div>
                    <div className="button__icon-container">
                      <i className="button__icon icon zaia-i-check-1"></i>
                    </div>
                  </button>
                  <button className={`button ${false && "button--selected"}`}>
                    <div className="button__text">
                      Archivado
                    </div>
                    <div className="button__icon-container">
                      <i className="button__icon icon zaia-icono-dots-horizontal"></i>
                    </div>
                  </button>
                  <button className={`button ${false && "button--selected"}`}>
                    <div className="button__text">
                      Esto no es una alerta
                    </div>
                    <div className="button__icon-container">
                      <i className="button__icon icon zaia-i-with-notification"></i>
                    </div>
                  </button>
                  <button className={`button-state ${false && "button-state--active"}`}>
                    <div className="button-state__text">
                      Cambiar estado
                    </div>
                    {
                      true &&
                      <i className="button-state__icon icon zaia-i-locked"></i>
                    }
                  </button>
                </div>
              </div>
              <div className="alerts-management__bottom">
                Implica que se tomó una acción para resolver el problema tras revisar la alerta.
              </div>
              {
                pendingAlerts &&
                <button className="button-pending">
                  <i className="button-pending__icon icon zaia-i-back-2"></i>
                  <div className="button-pending__bottom-text">
                    Mover a alertas pendites
                  </div>
                </button>
              }
            </div>
          </div>
        }
    </S.Alerts>
  );
}

export default Alerts;
