import React, { useState } from "react";
import * as S from "./CmpDiagnostics.style";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Components
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function CmpDiagnostics({
  dataForm,
  insertfunction,
  customFormChangeItem,
}) {
  const {t} = useTranslation("global");

  return (
    <S.DiagnosisListDiv>
      <div className="card-header">
        <div className="title">{t("outcomes.diagnostic.title")}</div>
        <div className="card-header-right">
          <i className="icon zaia-icono-agregar"></i>
          <Popup
            trigger={
              <div>{t("outcomes.diagnostic.addNew")}</div>
            }
            keepTooltipInside
            nested
            position="left top"
          >
            <NewDiagnostic
              dataForm={dataForm}
              insertfunction={insertfunction}
              customFormChangeItem={customFormChangeItem}
            />
          </Popup>
        </div>
      </div>
      <div className="diagnosis-list-container">
        { 
          dataForm?.customFormFields[0]?.userToCustomFormFields?.map((diagnostic) => {
            return (
              <S.DiagnosticPatientItem key={diagnostic.id}>
                <div className="diagnosis-item-info">
                  <div className="diagnosis-item-name">{diagnostic.value}</div>
                </div>
              </S.DiagnosticPatientItem>
            )
          })
        }
      </div>
    </S.DiagnosisListDiv>
  );
}

function NewDiagnostic({
  dataForm,
  insertfunction
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [selectedDiagnostic, setSelectedDiagnostic] = useState(null); 


  const saveDiagnosisData = () => {
    const userToCustomFormField = {
      customFormFieldId: dataForm?.customFormFields[0].id,
      parentKey: dataForm?.customFormFields[0]?.customFormTypeField.fieldOptions.find(field => field.key === selectedDiagnostic.key).parentKey,
      customFormFieldParentId: dataForm?.customFormFields[0]?.customFormFieldParentId,
      value: P.dataTranslation(selectedDiagnostic.names, language),
      key: selectedDiagnostic.key
    }
    insertfunction(userToCustomFormField);
  }

  return (
    <S.NewDiagnostic>
      <div className="diagnostics-list-default">
        <div className="diagnostics-list-default-header">
          <div className="diagnostics-list-default-header-left">
            <div className="diagnostics-list-default-header-title">
              {t("outcomes.diagnostic.addDiagnostic")}
            </div>
          </div>
          <div className="diagnostics-list-default-header-right" onClick={() => selectedDiagnostic ? saveDiagnosisData() : null}> { t("globally.accept") } </div>
        </div>
        <div className="diagnostics-list-default-content">
          <ListOfDiagnostics
            diagnostics={
              dataForm?.customFormFields[0].customFormTypeField.fieldOptions
            }
            diagnosticsError={false}
            diagnosticsLoading={false} 
            selectedDiagnostic={selectedDiagnostic}
            setSelectedDiagnostic={setSelectedDiagnostic}
          /> 
        </div>
      </div>
    </S.NewDiagnostic>
  )
}


function ListOfDiagnostics({ 
  diagnostics, 
  diagnosticsError, 
  diagnosticsLoading,
  selectedDiagnostic,
  setSelectedDiagnostic
}) {

  const {t, i18n: { language }} = useTranslation("global");

  if (diagnosticsError) 
    return <ZaiaReaction
        zaiaImg={logoZaiaAlert}
        widthimg="70px"
        text={t("outcomes.diagnostic.diagnosticError")}
        sizeText="12px"
        widthText="240px"
      />

  if (diagnosticsLoading) 
    return <ZaiaReaction
        zaiaImg={logoZaiaLoading}
        widthimg="70px"
      />

  if (diagnostics?.length === 0 || !diagnostics) 
    return <ZaiaReaction
      zaiaImg={logoZaiaEmpty}
      widthimg="70px"
      text={t("outcomes.diagnostic.emptyDiagnoctic")}
      sizeText="12px"
      widthText="240px"
    />

  return (
    <S.DiagnosticListDeafult>
      {
        diagnostics?.map((diagnostic) => {
          return <div className={`diagnostic-item ${diagnostic === selectedDiagnostic ? "diagnostic-item--selected" : null }`} key={diagnostic.id} onClick={() => setSelectedDiagnostic(diagnostic)}>{P.dataTranslation(diagnostic.names, language)}</div>
        })
      }
    </S.DiagnosticListDeafult>
  )
}

export default CmpDiagnostics;
