/* .react-calendar {
  width: 350px;
  max-width: 100%;
  font-family: "Nunito";
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
  color: #313131;
  font-weight: bold;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}
.react-calendar__tile--active {
  background: #ffc43b;
  color: white;
  border-radius: 60px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
} */
import styled from "styled-components";

export const CalendarRange = styled.div`
  display: flex;
  width: 100%;
  .calendar-double-container {
    /* width: 100%; */
    /* width: 752px; */    
      width: 380px;
    /* width: 720px; */
  }
  .Calendar {
    margin: 0 auto;
    box-shadow: none !important;
    padding: 0 !important;
    font-family: "Nunito Sans" !important;
    font-size: ${({fontSizeCalendar}) => fontSizeCalendar ? fontSizeCalendar : "8.5px !important"};
    min-height: 0 !important;
    width: 100%;
    background-color: ${({backgroundColor}) => backgroundColor};
    /* --animation-duration: 0ms !important; */
  }
  
  .Calendar__header {
    padding: 2em 1em !important;
  }
  
  .Calendar__section {
    font-family: "Nunito";
    padding: 0 0em !important;  
  }
  
  .Calendar__section.-hiddenPrevious {
    transform: translateX(-100%) !important;
  }
  
  .Calendar__section.-hiddenNext {
    transform: translateX(100%) !important;
  }
  
  .Calendar__weekDays {
    padding: 0;
    /* font-family: "Nunito ExtraBold"; */
    color: ${({textcolor}) => textcolor};
  }
  
  .Calendar__monthYear{
    font-family: "Nunito Sans Bold" !important;
    font-size: ${({fontSizeCalendar}) => `calc(${fontSizeCalendar} + 8px)`};
  }
  
  .Calendar__day.-ltr {
    color: ${({textcolor}) => textcolor};
    min-height: 3.1em !important;
  }

  .Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
    background-color: ${({currentDayColor}) => currentDayColor}1A;
    color: ${({currentDayColor}) => currentDayColor};
    border-radius: 10px;
  }

  .Calendar__day.-selectedBetween {
    color: ${({currentDayColor}) => currentDayColor};
  }
  
  .Calendar__day.-ltr.-selectedStart {
    border-radius: ${({range,rangeActive}) => (rangeActive) ? !range?.to || range?.from === range?.to ? '10px !important' : '10px 0 0 10px !important' : '10px 0 0 10px !important'};  
    color: ${({theme}) => theme.white};
  }
  
  .Calendar__day.-ltr.-selectedEnd {
    border-radius: 0 10px 10px 0;
    color: ${({theme}) => theme.white};
  }
  
  .Calendar__day:focus {
    outline: none;
  }
  
  .Calendar__day.-selected, .Calendar__day.-selectedStart, .Calendar__day.-selectedEnd {
    background-color: ${({titleColor}) => titleColor};
    color: #fff;
  }

  .Calendar > :not(.Calendar__footer) button {
    color: ${({titleColor}) => titleColor};
  }
  
  .Calendar__monthSelector.-open, .Calendar__yearSelector.-open {
    background-color: ${({backgroundColor}) => backgroundColor} !important;
  }

  .Calendar__yearSelectorWrapper::before {
    background-image: linear-gradient(to top,  ${({backgroundColor}) => backgroundColor},  ${({backgroundColor}) => backgroundColor} 10%, rgba(245, 245, 245, 0));
  }
  .Calendar__yearSelectorWrapper::after {
    background-image: linear-gradient(to bottom,  ${({backgroundColor}) => backgroundColor},  ${({backgroundColor}) => backgroundColor} 10%, rgba(245, 245, 245, 0));
  }

  .-active {
    button {
      color: ${({theme}) => theme.primaryVariantColor} !important;
    }
  }

  .Calendar__monthSelectorItem:not(.-active) .Calendar__monthSelectorItemText:not(:disabled):hover, .Calendar__yearSelectorItem:not(.-active) .Calendar__yearSelectorText:not(:disabled):hover {
    /* background-color: ${({theme}) => theme.onBackgroundVariant}1A; */
    background-color: #CFD5DB;
    color: ${({theme}) => theme.onBackgroundVariant};
  }
  
  .Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText, .Calendar__yearSelectorItem.-active .Calendar__yearSelectorText { 
    color: white !important;
  }

  .Calendar__monthYear.-shown > *:hover, .Calendar:not(.-noFocusOutline) .Calendar__monthYear.-shown > *:focus, .Calendar__monthYear > *.-activeBackground {
    background-color: ${({theme}) => theme.onBackgroundVariant}1A;
  }
  
  .Calendar__monthSelectorAnimationWrapper {
    height: 85%;
  }
  
  .Calendar__monthSelector, .Calendar__yearSelector {
    background-color: ${({backgroundColor}) => backgroundColor};
  }
  
  .Calendar__sectionWrapper {
    min-height: 30em !important;
  }
  
  .todayDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    font-family: "Nunito Sans Black" !important;
    color: ${({currentDayColor}) => currentDayColor} !important;
  }
  .todayDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected)::after {
    background: none !important;
  }
  
  .eventDay::after {
    content:"";  
    width:5px; 
    height:5px; 
    background-color: ${({currentDayColor}) => currentDayColor}; 
    display:block; 
    position:absolute; 
    margin-top: 23px;
    border-radius: 10px;
  }

  .Calendar__monthArrow {
    filter: ${ ({arrowFilterColor}) => arrowFilterColor ? arrowFilterColor : null}
  }

  .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
    color: ${({currentDayColor}) => currentDayColor}; 
  }

  .Calendar__day.-selected {
    border-radius: 10px;
  }
`