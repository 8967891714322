import styled from "styled-components";

export const YesOrNo = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  
  .yesOrNo-states {
    margin: 0 0 0 auto;
    width: 150px;
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    .yesOrNo-state {
      cursor: pointer;
      height: 22px;
      border-radius: 13px;
      width: 47%;
      font-family: "Nunito";
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;   
      color: var(--blue-color);
      background-color:  #ededffe6;
      margin-left: 5px;
      padding: 10px;
      &--selected {
        /* box-shadow: 0px 3px 6px var(--box-shadow); */
        background-color: #1F1F52;    
        color: var(--white);
      }
    }
  }
`
