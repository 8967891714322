import styled from "styled-components";
const waveBottom = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/curva-pequena-botton-violeta.svg`;

export const NotesView = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  background-image: url(${waveBottom});
  background-repeat: no-repeat;
  background-position: bottom;
  background-position-y: 125%;
  border-radius: 14px 14px 0px 0px;
  overflow: hidden;
  box-shadow: 0px -7px 34.2px 0px #0C0B471C;
  background-color: #F8F8FF;
  .partition-selected{
    width: 39px;
    display: flex;
    padding: 10px 10px 10px 10px;
    justify-content: center;
    align-items: center;
    &__container {
      display: flex;
      align-items: center;
      transform: rotate(-90deg);
    }
    &__point {
      width: 21px;
      height: 21px;
      border-radius: 17px;
      background: #9393FF;
      margin-right: 10px;
    }
    &__text {
      color: #1F1F52;
      font-family: "Nunito Sans";
      font-size: 14px;
      white-space: nowrap;
    }
  }
  .notes {
    height: 100%;
    width: 100%;
  }
  .notes-container {
    width: ${({maxViewActived}) => maxViewActived ? "100%" : "700px"};
    height: ${({maxViewActived}) => maxViewActived ? "calc(100% - 78px)" : "592px"};
    overflow: auto;
    border-radius: 14px 14px 0px 0px;
  }

  .notes-minimized {
    width: 261px;
    padding: 10px 20px 20px 20px;
    border-radius: 14px 14px 0px 0px;
    background-color: #F8F8FF;
    box-shadow: 0px 0px 23.59px -1.81px #23229D40;
    display: flex;
    flex-direction: column;
    &__top {
      display: flex;
      align-items: center;
    }
    &__buttons {
      display: flex;
      .circle-button {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        background: #6D6DB91F;
        border: none;
        outline: none;
        cursor: pointer;
        margin-right: 7.6px;
        font-size: 7.3px;
        &--maximize-notes {
          font-size: 12px;
        }
        &__icon {
          margin: auto;
        }
      }
    }
    &__title {
      color: #3E3DA3;
      font-family: Nunito Sans Bold;
      font-size: 20px;
      margin-left: 3px;
    }
    &__bottom {
      display: flex;
      margin-top: 10px;
      align-items: center;
      .patient-name {
        font-family: Nunito Sans Light;
        font-size: 24.27px;
        margin-left: 10px;
        line-height: 28px;
        color: #1F1F52;
      }
    }
  }
`

export const HeaderDiv = styled.div`
  .header {
    display: flex;
    justify-content: space-between;
    height: 78px;
    padding: ${({maxViewActived}) => maxViewActived ? "18px 37px 0px 37px" : "18px 20px 10px"};
    box-sizing: border-box;
    align-items: center;
    &__left {
      display: flex;
    }
    &__right {
      display: flex;
      flex-direction: ${({maxViewActived}) => maxViewActived ? "" : "column"};
    }
    &__close-chat {
      height: 36px;
      width: 36px;
      background: rgba(109, 109, 185, 0.12);
      display: flex;
      font-size: 9px;
      border-radius: 50%;
      cursor: pointer;
      &-icon {
        display: flex;
        margin: auto;
        color: ${({theme}) => theme.primaryColor};
      }
    }
    &__max {
      height: 36px;
      width: 53px;
      margin-left: 10px;
      background: rgba(109, 109, 185, 0.12);
      font-size: 9px;
      border-radius: 34.57px;
      cursor: pointer;
      align-items: center;
      display: flex;
      &-icon {
        display: flex;
        margin: auto;
        font-size: 18px;
        color: ${({theme}) => theme.primaryColor};
      }
    }
    &__minimize {
      align-items: center;
      height: 36px;
      margin-left: 10px;
      background: rgba(109, 109, 185, 0.12);
      font-size: 9px;
      border-radius: 34.57px;
      cursor: pointer;
      display: flex;
      padding: 0 14px;
      &-icon {
        display: flex;
        font-size: 16px;
        color: ${({theme}) => theme.primaryColor};
        margin-right: 13px;
      }
      &-text {
        font-size: 20px;
        font-family: Nunito Sans;
        color: #3E3DA3;
      }
    }
    &__title {
      display: flex;
      align-items: center;
      margin-right: 10px;
      &-icon {
        color: #36369B;
        width: 17.75px;
        font-size: 18px;
        opacity: 0.4;
      }
      &-text {
        font-family: Nunito Sans;
        font-size: 25px;
        text-align: left;
        color: #707582;
        margin-left: 10px;
      }
    }
    &__patient-selected {
      display: flex;
      align-items: center;
      &-img {
        margin-right: 15px;
      }
      &-name {
        color: #3E3E5B;
        font-family: Nunito Sans;
        font-size: 20px;
        margin-right: 10px;
      }
      &-close {
        background-color: #36369B;
        width: 22px;
        height: 22px;
        padding: 8px;
        box-sizing: border-box;
        border-radius: 50%;
        color: white;
        cursor: pointer;
        &-icon {
          font-size: 6px;
          margin: auto;
        }
      }
    }
  }
`

export const ChatbotChooseOptions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  .view-options {
    margin: auto;
    width: 292px;
  }
  .view-option {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
    &__button {
      border-radius: 10px;
      background: #36369B;  
      display: flex;
      padding: 10px;
      justify-content: center;
      border: none;
      outline: none;
      color: #FFF;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      cursor: pointer;
      margin-bottom: 10px;
    }
    &__description {
      color: #93A9C0;
      text-align: center;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
`
