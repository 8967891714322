import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./listOfPatients.style";

import { useNavigate, useParams } from "react-router-dom";

// Components
import PatientBox from "../patientBox/patientBox";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Utils
import * as P from "../../../../../utils/pipes";
import usePatientList from "../../../../../hooks/usePatientList";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function ListOfPatients({
  filterProccessIds,
  idPatientFilter,
  patientsQuantity,
  setPatientsQuantity,
  urlRedirection,
  processIdFilter,
  component,
  setHide
}) {
  const { patientID } = useParams();
  const navigate = useNavigate();
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { width } = useWindowDimensions();

  const { patientList, patientListLoading, patientListError, patientQuantity } =
    usePatientList(processIdFilter, filterProccessIds);

  useEffect(() => {
    setPatientsQuantity(patientQuantity);
  }, [patientQuantity, setPatientsQuantity]);

  if (patientListLoading)
    return (
      <S.ListOfPatientsContainer>
        <ZaiaReaction widthimg="150px" zaiaImg={logoZaiaLoading} />
      </S.ListOfPatientsContainer>
    );

  if (patientListError)
    return (
      <S.ListOfPatientsContainer>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthimg="100px"
          text={t("patients.errorLoadingPatients")}
          sizeText="15px"
          widthText="240px"
        />
      </S.ListOfPatientsContainer>
    );

  if (patientList?.length < 1)
    return (
      <S.ListOfPatientsContainer>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthimg="100px"
          text={t("patients.patientsEmpty")}
          sizeText="15px"
          widthText="200px"
        />
      </S.ListOfPatientsContainer>
    );

  return (
    <S.ListOfPatientsContainer>
      {patientList?.map((patient) => {
          const filterPatients = !idPatientFilter ||
          (
            String(patient.name + " " + patient.lastname 
            ).toLowerCase().includes(idPatientFilter.toLowerCase()) ||
            String(patient.email 
            ).toLowerCase().startsWith(idPatientFilter.toLowerCase()) ||
            String(patient.documentNumber 
            ).toLowerCase().startsWith(idPatientFilter.toLowerCase())
          );

          return ( filterPatients ) 
          ? (
            <div
              className="patient-box"
              key={patient.email}
              onClick={() => {navigate(`${urlRedirection}/` + patient.id); width <= 768 && setHide(true) }}
            >
              <PatientBox
                active={patient.id === patientID ? true : false}
                img={patient.picture}
                medicalProcedure={
                  patient?.userToProcesses.length > 1
                    ? t("globally.variousProcesses")
                    : P.dataTranslation(
                        patient?.userToProcesses[0]?.process?.processName,
                        language
                      )
                }
                patient={patient.name + " " + patient.lastname}
                id={patient.email}
              />
            </div>
          ) : null;
        })}
    </S.ListOfPatientsContainer>
  );
}

export default ListOfPatients;
