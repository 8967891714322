import styled from 'styled-components';

export const SurveyBox = styled.tr`
  padding: 20px;
  border-bottom: 1px #F0F0FF solid;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #F0F0FF;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    border-bottom: 3px solid #6C6BCC
  }
  &:hover .survey-principal .survey-patient-img {
    width: 42px;
    height: 42px;
  }
  .survey-principal {
    display: flex;
    align-items: center;
    .survey-favourite {
      width: 35px;
      height: 35px;
      margin-right: 13px;
      padding: 10px; 
      border-radius: 14px;
      color: #FFFFFF;
      background-color: #FFA800;
      border: none;
      outline: none;
      &-icon {
        font-size: 15px;
      }
    }
    .survey-patient-img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
      transition: all 0.3s;
      object-fit: cover;
    }
    .survey-info {
      font-family: Nunito Sans;
      font-size: 18px;
      &__title {
        color: #3E3E5B;
      }
      &__subtitle {
        color: #707582;
        margin-top: 10px;
      }
    }
  }
  .survey-status {
    display: flex;
    align-items: center;
    &__icon {
      margin-right: 5px;
      font-size: 13px;
      color: #38379C;
    }
    &__text {
      font-family: Nunito Sans;
      font-size: 15px;
      color: #707582;
    }
  }
  .survey-date{
    width: 123px;
    color: #707582;
  }
  .survey-frequency {
    color: #1F1F52;
    &--no-frequency {
      color: #707582;
    }
  }
  .button-preview {
    font-family: Nunito Sans;
    font-size: 16px;
    min-width: 100px;
    height: 40px;
    background-color: #EFEEF8;
    border: none;
    outline: none;
    border-radius: 40px;
    padding: 0px 11px;
    margin: 5px 0px 5px 10px;
    color: #5C5BEF;
    cursor: pointer;
    float: right;
    &--last {
      color: #FFFFFF;
      background-color: #3E3DA3;
    }
    &--disabled {
      opacity: 0.3;
      cursor: default
    }
  }
  .survey-delicate {
    display: flex;
    align-items: center;
    &__icon-container {
      background-color: #FFECEC;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
    }
    &__icon {
      margin: auto;
      color: #FF3030;
      font-size: 15px;
    }
    &__number {
      color: #707582;
      font-family: Nunito Sans Bold;
      font-size: 16px;
      margin: 0 5px;
    }
    &__expand {
      width: 32px;
      height: 32px;
      display: flex;
      border-radius: 50%;
      background-color: #36369B;
      cursor: pointer;
      &-icon {
        font-size: 16px;
        color: #FFFFFF;
        margin: auto;
      }
    }
  }
`

export const TabDescription = styled.div`
  background: var(--white);
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  max-width: 200px;
  padding: 5px 10px;
  font-family: "Nunito Sans Light";
  font-size: 12px;
`