import React, { useEffect, useState } from "react";
import * as S from  "./AiNavigatorOutcomes.style";
import OutcomesProcessByPatient from "../../../outcomes/components/dasboardOutcomesByPatient/components/outcomesProcessByPatient/OutcomesProcessByPatient";
import { useTranslation } from "react-i18next";
import { Actions } from "../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";

// pipes
import * as P from "../../../../../utils/pipes";
import Modal from "../../../../../utils/modal";
import ModalAddOutcomesEvent from "../../../shared/modals/modalAddOutcomesEvent";
import ChartsOutcomes from "./chartsOutcomes";
import { QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS } from "../../../../../graphql/queries/CustomForm";
import { useQuery } from "@apollo/client";
import OutcomesTabs from "../outcomesTabs";
import { useStateValue } from "../../../../../contextAPI/StateProvider";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfPatientInfo from "../../../patients/components/dashboardPatient/components/pdfPatientInfo/PdfPatientInfo";

function AiNavigatorOutcomes() {

  const { i18n: { language }, t } = useTranslation("global");
  const [{ selectedPatient }] = useStateValue();
  const [processSelected, setProcessSelected] = useState(selectedPatient?.userToProcesses.filter((userToProcess) => userToProcess.process)[0].process?.id);
  const { recordAction } = useRecordNavigation();
  const [createEventCalendarActive, setCreateEventCalendarActive] = useState(false);

  const { data: markerFormField, refetch: markerFormFieldRefetch } = useQuery(QUERY_GET_CUSTOM_FORMS_FIELD_MARKERS, {
    variables: { 
      customFormParent: selectedPatient?.userToProcesses.find((userToProcess) => userToProcess?.process?.id === processSelected)?.process?.processToCustomForms[0]?.customFormId 
    },
  })

  const [viewTab, setViewTab] = useState(1);
  const [viewSelected, setViewSelected] = useState(1);
  const [highlightsFilter, setHighlightsFilter] = useState(false);
  const [customFormsList, setCustomFormsList] = useState();
  const [eventsSelected, setEventsSelected] = useState([]);

  const [tabs, setTabs] = useState(
    [
      {
        id: 1,
        icon: "zaia-i-table-view",
        name: t("aiNavigator.outcomes.medicalEventTable"),
        description: t("aiNavigator.outcomes.medicalEventTableDescription"),
        action: Actions.OUTCOMES.MEDICAL_TABLE
      }
    ]
  )

  useEffect(() => {
    const tabExist = tabs.find((tab) => tab.id === 2);
    if(markerFormField?.customFormField.length > 0 && !tabExist) {
      setTabs([...tabs, {
        id: 2,
        icon: "zaia-i-survey-chart",
        name: t("aiNavigator.outcomes.medicalChart"),
        description: t("aiNavigator.outcomes.medicalChartDescription"),
        action: Actions.OUTCOMES.MEDICAL_GRAPHIC
      }])
    }
  }, [markerFormField])

  const addEvent = (event) => {
    if(eventsSelected.indexOf(event) === -1) {
      setEventsSelected([...eventsSelected, event]);
    }
  }

  return(
    <S.AiNavigatorOutcomes>
      <Modal open={createEventCalendarActive}>
        <ModalAddOutcomesEvent
          setCreateEventActive={setCreateEventCalendarActive}
          patientID={selectedPatient?.id}
          processID={processSelected}
        />
      </Modal>      
      {/* <div className="process-tabs">
        <div className="process-tabs__title">
          {
            selectedPatient?.userToProcesses.length === 1
            ? "Program Name"
            : t("globally.selectProgram")
          }
        </div>
        {
          selectedPatient?.userToProcesses.length === 1 &&
          <div className="process-tabs__process-name">
            {P.dataTranslation(selectedPatient?.userToProcesses[0].process?.processName, language)}
          </div>
        }
        {
          selectedPatient?.userToProcesses.length > 1 &&
          <div className="process-tabs__list">
            {
              selectedPatient?.userToProcesses.filter((item) => item.process).map((item) => {
                return (
                  <div 
                    className={`process-tab ${item.process?.id === processSelected && 'process-tab--selected'}`}
                    onClick={() => {
                      recordAction(Actions.OUTCOMES.SELECT_PROGRAM, item.process?.id);
                      setProcessSelected(item.process?.id)
                    }}
                  >
                    {P.dataTranslation(item.process?.processName, language)}
                  </div>
                //   { 
                //   value: item.process.id, 
                //   text: P.dataTranslation(item.process.processName, language)
                // };
                )
              })
            }
          </div>
        }
      </div> */}

      <div className="header-options">
        <div className="header-options__section">
          <div className="principal-selects-container">
            <div className="select-container select-container--program">
              <div className="select-container__label">
                {t("globally.programName")}
              </div>
              <ZaiaSelect
                type="select"
                setValue={(e) => {
                    recordAction(Actions.OUTCOMES.SELECT_PROGRAM, e);
                    setProcessSelected(e)
                  }
                }
                value={processSelected}
                items={selectedPatient?.userToProcesses.filter((item) => item.process).map(
                  (userToProcess) => {
                    return {
                      value: userToProcess.process?.id,
                      text: P.dataTranslation(userToProcess.process?.processName, language)
                    }
                  }
                )}
                shadow={false}
                fontSize="13px"
                backgroundColor={"#36369B"}
                borderRadius={"10px"}
                heightList={"200px"}
                textcolor={"#FFFFFF"}
                arrowColor={"#FFFFFF"}
                borderColor={"#36369B"}
                textItemsColor={"#36369B"}
              />
            </div>
          </div>
          <div className="right-buttons">
            <div className="update-outcome-button">
              <PdfPatientInfo onlyDocument={true} patientID={selectedPatient?.id} />
            </div>
            <div className="update-outcome-button">
              <button className="update-outcome-button__button" onClick={
                () => {
                  setCreateEventCalendarActive(true);
                  recordAction(Actions.OUTCOMES.ADD_UPDATE);
                }
              }>
                <div className="update-outcome-button__text">
                {t("aiNavigator.outcomes.addUpdate")}
                </div>
                <i className="update-outcome-button__icon icon zaia-icono-agregar"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="header-options__section">
          <div className="outcomes-tabs">
            <OutcomesTabs
              setViewTab={setViewTab}
              viewTab={viewTab}
              tabs={tabs}
            />
          </div>
          {
            viewTab === 1 &&
            <div className="filter-buttons-container">
              {
                viewSelected === 1 &&
                <>
                  <div className={`highlights-button ${ highlightsFilter && "highlights-button--active"}`} onClick={() => setHighlightsFilter(!highlightsFilter)}>
                    <i className={`highlights-button__icon icon ${ highlightsFilter ? "zaia-star-on" : "zaia-star-off"}`}></i>
                  </div>
                  <div className="separator"></div>
                </>
              }
              <div className="event-type-select">
                <ZaiaSelect
                  placeholder={t("aiNavigator.outcomes.selectEventType")}
                  textcolor="#36369B"
                  placeholdertextcolor="#36369B"
                  fontSize={"17px"}
                  height={"33px"}
                  errorOption={false}
                  maxHeightList={"200px"}
                  padding={"5px 15px"}
                  borderColor={"#AAAADB"}
                  borderRadius={"5px"}
                  setValue={(value) => addEvent(value)}
                  items={customFormsList?.map((customForm) => {
                    return {
                      value: customForm.id,
                      text: P.dataTranslation(
                        customForm.customFormName,
                        language  
                      ),
                    };
                  })}
                />
              </div>
            </div>
          }
        </div>
      </div>

      {
        viewTab === 2 &&
        <ChartsOutcomes
          userId={selectedPatient?.id}
          customFormParent={selectedPatient?.userToProcesses.find((userToProcess) => userToProcess?.process?.id === processSelected)?.process?.processToCustomForms[0]?.customFormId}
          setViewTab={setViewTab}
          viewTab={viewTab}
          tabs={tabs}
        />
      }
      {
        viewTab === 1 &&
        <OutcomesProcessByPatient
          patientID={selectedPatient?.id}
          processID={processSelected}
          viewSelected={viewSelected}
          setViewSelected={setViewSelected}
          highlightsFilter={highlightsFilter}
          customFormsList={customFormsList}
          setCustomFormsList={setCustomFormsList}
          eventsSelected={eventsSelected}
          setEventsSelected={setEventsSelected}
          processToCustomFormID={
            selectedPatient?.userToProcesses.find((userToProcess) => userToProcess?.process?.id === processSelected)?.process?.processToCustomForms[0]?.customFormId
          }  
        />
      }
    </S.AiNavigatorOutcomes>
  );
}

export default AiNavigatorOutcomes;