import styled from "styled-components";

export const CmpCheckMultipleOptionList = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  h5 {
    font-family: "Nunito Bold";
  }
  .molecular-variant-boxes-container {
    max-height: 120px;
    overflow: auto;
    margin-top: 8px;
  }
`;

export const Box = styled.div`
  border-radius: 10px;
  margin-bottom: ${({last}) => last ? '' : '5px'};
  border-bottom: ${({last}) => last ? '' : '1px solid var(--grey-items-separaton)'};
  color: var(--title-register-color);
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
  .molecular-variant-name {
    font-family: "Nunito Bold";
    font-size: 13px;
    color: var(--blue-color);
    margin: auto 10px;
    width: 25%;
  }
  .molecular-variant-states {
    width: 75%;
    display: flex;
    justify-content: space-between;
    padding: 3px;
  }
`
