import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NoteHistory.style";

import * as P from "../../../../../utils/pipes";

function NoteHistory({
  listNotes,
  maxViewActived,
  setNoteHistorySelected,
  setHistoryActived
}) {

  const {t, i18n: { language }} = useTranslation("global");

  return (
    <S.NoteHistory
      maxViewActived={maxViewActived}
    >
      {
        !maxViewActived &&
        <div className="header">
          <button className="back-button"
            onClick={() => { 
              setHistoryActived(null);
            }}
          >
            <i className="back-button__icon icon zaia-icono-atras"></i>
            <div className="back-button__text">
              {t("notes.backToNotes")}
            </div>
          </button>
        </div>
      }
      <div className="list-notes-title">
        {t("globally.history")}
      </div>
      <div className="list-history-notes">
        <div className="list-history-notes__notes">
          {
            listNotes?.map(note => {
              return (
                <Note
                  key={note.id}
                  note={note}
                  setNoteHistorySelected={setNoteHistorySelected}
                />
              )
            })
          }
        </div>
        
      </div>
    </S.NoteHistory>
  );
}

const Note = ({
  note,
  setNoteHistorySelected
}) => {

  const {t, i18n: { language }} = useTranslation("global");  

  return (
    <div className="note-card"
      onClick={() => setNoteHistorySelected(note)}
    >
      <div className="history-point"></div>
      <div className="note-card__header">
        <div className="note-card__date">
          <div className="note-card__date-date">
            {P.longDate(note.created_at, t, language)}
          </div>
          <div className="note-card__date-time">
            {P.hoursIn12format(new Date(note.created_at).getHours(), new Date(note.created_at).getMinutes(), t, language)}
          </div>
        </div>
      </div>
      <div className="note-card__info">
        <div className="note-card__program">
          {P.dataTranslation(note.userToProcess.process.processName, language)}
        </div>
        <div className="note-card__note">
          {
            note.descript.split(" ").length > 10
            ? `${note.descript.split(" ").slice(0, 7).join(" ")}... `
            : note.descript
          } <b className="expand-comment">{
            note.descript.split(" ").length > 10 && t("globally.readMore")
          }</b>
        </div>
        {
          note.doctor &&
          <div className="note-card__written-by">
            <div className="note-card__written-by-label">
              {t("notes.editedBy")}
            </div>
            <div className="note-card__written-by-name">
              {
                `${note.doctor.name} ${note.doctor.lastname}`
              }
            </div>
          </div>
        }
      </div>
    </div>
  );

}

export default NoteHistory;
