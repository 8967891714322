import styled from "styled-components";

export const PatientHeaderSections = styled.div`
  .dashboard-patient-header {
    width: 100%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;  
    /* margin-bottom: 20px; */
    padding: 5px 15px 5px 20px;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 0.5px solid #23229D40;
    &__filtered {
      align-items: center;
      background-color: #F0F0FF;
      padding: 2.5px 12px 2.5px 2.5px;
      display: flex;
      border-radius: 37.06px;
    }
    &__left {
      height: 45.45px;
      display: flex;
      align-items: center;
      .patient-selector {
        box-sizing: border-box;
        padding: 5.5px 10px;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        border-radius: 31px;
        background: #3E3DA3;
        cursor: pointer;
        color: #FFFFFF;
        &__box-icon {
          border-radius: 50%;
          padding: 2px;
          display: flex;
          margin-right: 10px;
          background-color: #FFFFFF30;
        }
        &__icon {
          border-radius: 50%;
          width: 17px;
          height: 17px;
          color: #FFF;
          filter: brightness(2.25);
        }
        &__text {
          color: #FFF;
          text-align: center;
          font-family: "Nunito Sans";
          font-size: 17px;
        }
      }
      &-filtered-by {
        margin: 0 10px 0 5px;
        font-size: 17px;
        color: #36369B;
      }
      &-close {
        border: none;
        outline: none;
        background-color: #36369B;
        color: #FCFCFE;
        font-size: 8px;
        width: 28px;
        height: 28px;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
      }
      &-text {
        margin-left: 5px;
        &-title {
          /* margin-left: 5px; */
          color: #36369B;
          font-size: 20px;
          font-family: "Nunito Sans";
          strong{
            margin-left: 10px;
          }
        }
        &-extra {
          display: flex;
          align-items: center; 
        }
        &-icon {
          margin-right: 10px;
          color: #FF4A4A;
          font-size: 12px;
        }
        &-programs {
          display: flex;
          font-family: "Nunito Sans";
          padding: 0px 5px;
          color: #526479;
          font-size: 16px;
          background-color: #EEF1F4;
          width: fit-content;
          border-radius: 4px;
          align-items: center;
          strong {
            margin-right: 10px;
          }
        }
      }





      /* &-img {
        img {
          min-width: 60px;
          min-height: 60px;
        }
      } */


      /* &-text-title {
        display: flex;
        align-items: baseline;
        h3 {
          color: ${({theme}) => theme.onBackground};
        }
        h5 {
          margin-left: 10px;
          color: ${({theme}) => theme.primaryColor};
          cursor: default;
        }
      }
      &-text-tags {
        margin-top: 8px;
        margin-right: 10px;
        height: 20px;
        .text-tags {
          display: flex;
          .text-tags-title {
            padding: 3px 8px;
            justify-content: space-between;
            display: none;
            &__text {
              font-size: 11px;
              margin-right: 5px;
              font-family: "Nunito SemiBold";
              white-space: nowrap;
            }
            &__icon {
              font-size: 11px;
              margin-top: 2px;
              display: flex;
            }
          }
        }
      }
      &-icon {
        margin: 0px 0px auto auto;
        display: flex;
        color: ${({theme}) => theme.primaryColor};
        padding: 8px;
        font-size: 9px;
        border: 1px solid ${({theme}) => theme.primaryColor};
        border-radius: 50%;
        display: none;
        i {
          display: flex;
        }
      } */
    }
    &__right {
      align-items: center;
      display: flex;
      .patient-options {
        display: flex;
        .patient-option {
          padding: 4px 10px;
          margin: 0 5px;
          font-size: 17px;
          background-color: #F0F0FF;
          color: #6C6BCC;
          border-radius: 10px;
          display: flex;
          align-items: center;
          cursor: pointer;
          position: relative;
          width: auto;
          cursor: pointer;
          &--selected {
            background-color: #D2D2FF;
            color: #36369B;
            font-family: "Nunito Sans Bold";
          }
        }
      }
      .notification-icon {
        margin: 0 15px;
        &__icon {
          font-size: 23.27px;
          color: #36369B;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .text-tags {
      flex-direction: column;
      color: ${({theme}) => theme.onCardFilter};
      width: fit-content;
      background-color: ${({theme}) => theme.cardFilter};
      border-radius: 10px;
      height: ${({additionalInfoActive}) => !additionalInfoActive ? "20px" : "auto"};
      overflow: hidden;
      position: absolute;
      z-index: 500;
    }
    .text-tags-title {
      display: flex !important;
    }
    .dashboard-patient-header__left-icon {
      display: flex;
    }
    .dashboard-patient-header {
      flex-direction: column;
    }

    .dashboard-patient-header__right {
      margin-top: -25px;
      margin-left: auto;
      &-button--close {
        display: none;
      }
    }
    .dashboard-patient-header__left-text {
      margin-right: 10px;
    }

  }

  @media only screen and (max-width: 365px) {
    .dashboard-patient-header__left-img {
      img {
        min-width: 40px;
        min-height: 40px;
        width: 40px;
        height: 40px;
      }
    }
    .dashboard-patient-header__left {
      align-items: flex-start;
      min-height: 60px;
    }
    .dashboard-patient-header__left-text-tags {
      margin-left: -47px;
    }
    .dashboard-patient-header__left-text-title {
      h3 {
        min-height: 41px;
      }
    }

  }
`;

export const Tag = styled.p`
  background-color: ${({theme}) => theme.cardFilter};
  color: ${({theme}) => theme.onCardFilter};
  padding: 3px 8px;
  font-size: 10px;
  border-radius: 10px;
  margin-right: 5px;
  font-weight: normal;
  font-family: "Nunito";
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    padding: 1px 8px;
  }
`;

export const SquareButton = styled.button`
  height: 42px;
  width: 42px;
  background-color: ${({theme}) => theme.primaryColor};
  border: none;
  border-radius: 12px;
  cursor: pointer;
  box-shadow: 3px 3px 5px ${({theme}) => theme.blackShadow}29;
  color: ${({theme}) => theme.onPrimaryColor};
  font-size: 24px;
  /* padding-top: 5px; */
  position: relative;
  display: flex;
  i {
    display: flex;
    margin: auto;
  }
  .button-plus {
    position: absolute;
    /* float: right; */
    display: flex;
    bottom: -5px;
    right: -5px;
    background-color: var(--white);
    border: 2px solid var(--blue-color-200);
    color: var(--blue-color-200);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: none;
    border-radius: 50%;
    transform: none;
    font-size: 15px;
  }
  @media only screen and (max-width: 768px) {
    height: 30px;
    width: 30px;    
    font-size: 16px;
    .button-plus {
      display: none;
    }
  }
`;

export const TooltipCelphone = styled.h4`
  /* background-color: ${({theme}) => theme.onPrimaryColor};
  padding: 5px 10px;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  font-family: 'Nunito ExtraBold';
  color: ${({theme}) => theme.primaryColor};
  border-radius: 15px; */
  .phone-number-container {
    width: 212px;
    position: relative;
    .contact-info {
      background-color: ${
        ({
          theme,
        }) => 
          theme.background
      };
      border-radius: 10px;
      box-shadow: ${
        ({theme}) => 
        `3px 3px 5px ${theme.blackShadow}29`
      };
      &-box {
        padding: 10px 15px 7px 15px;
        &--emergency{ 
          border-top: solid 1px ${({theme}) => theme.lineSeparation};
          padding: 7px 15px 10px 15px;
        }
        .label {
          font-size: 12px;
          font-family: 'Nunito Bold';  
          color: ${({theme}) => theme.primaryColor} !important;
          &--emergency{ 
            color: ${({theme}) => theme.primaryVariantColor} !important;
          }
        }
        .name {
          font-family: 'Nunito';
          font-size: 15px;  
          color: ${({theme}) => theme.primaryVariantColor} !important;
        }
        .number {
          font-family: 'Nunito ExtraBold';  
          font-size: 18px;
          color: ${({theme}) => theme.primaryColor} !important;
          &--emergency{ 
            color: ${({theme}) => theme.primaryVariantColor} !important;
          }
        }
      }
    }
  }
`