
import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ListOfAlerts = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px 20px 20px 20px;
  box-sizing: border-box;
  .table-container {
    border: 2px solid #F6F6FF;
    width: 100%;
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
    overflow: auto;
    box-shadow: 0px 4px 4px 0px #F6F7F7;
    border-radius: 20px;
    .filters {
      display: flex;
      padding: 5px 20px;
      align-items: center;
      justify-content: space-between;
      &__label {
        color: #707582;
        font-size: 14px;
        font-family: Nunito Sans;
        margin-right: 10px;
      }
      &__left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      &__right {
        align-self: flex-start;
      }
      .filter-container {
        padding: 5px;
        display: flex;
        box-shadow: 0px 0px 4px 0px #D8D8EA;
        align-items: center;
        border-radius: 10px;
        margin: 5px 10px 5px 0;
        &__close {
          font-size: 6px;
          padding: 5px;
          color: #5A7078;
          margin-right: 10px;
          background-color: transparent;
        }
        &__label {
          font-family: Nunito Sans;
          font-size: 14px;
          margin-right: 10px;
          color: #1F1F52;
          inline-size: max-content;
        }
        &__item {
          margin-left: 10px;
          display: flex;
          align-items: center;
          &-icon {
            font-size: 6px;
            padding: 5px;
            background-color: transparent;
            color: #5A7078;
            margin-right: 10px;
            &-type {
              font-size: 16px;
              margin-right: 6px;
              color: #3E3E5B;
            }
          }
          &-text {
            font-family: Nunito Sans Semibold;
            font-size: 14px;
            color: #707582;
          }
          &-point {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-left: 5px;
          }
        }
      }
    }

    .suggestion-table {
      table-layout: auto;
      border-collapse: collapse;
      width: 100%;
      position: relative;
      &__item {
        &--selected {
          background-color: #F6F6FF;
        }
      }
      thead {
        border-bottom: solid #23229D40 0.5px;
        background-color: white;
        position: relative;
        z-index: 100;
      }
      th {
        position: sticky;
        top: 0;
        height: 54px;
        align-items: center;
        color: #8686A9;
        font-size: 19px;
        font-family: "Nunito Sans";
        font-weight: 400;
        text-align: start;
        background-color: white;
        .filter {
          background-color: white;
          &__button {
            cursor: pointer;
            display: flex;
            align-items: center;
            &--name {
              display: flex;
              align-items: center;
            }
          }
          &__button-content {
            &--name {
              margin-left: 31px;
            }
          } 
          &__icon {
            font-size: 12px;
            margin-right: 5px;  
            color: #36369B;
            &-description {
              color: #3E3E5B;
            }
          }
          &__text {
            font-family: Nunito Sans;
            font-size: 16px;
            color: #3E3E5B;
          }
          &__label-actived {
            color: #707582;
            font-family: Nunito Sans;
            font-size: 12px;         
          }
          &__container {
            padding-left: 20px;
            display: flex;
            align-items: center;
            .check-patient {
              font-size: 20px;
              color: #36369B;
              cursor: pointer;
            }
          }
        }
        .management-status-selector {
          width: 217px;
          justify-self: end;
          margin-right: 10px;
        }
      }
      
      tbody {
        overflow: auto;
        tr {
          border-bottom: 1px solid #F6F6FF;
          :hover .patient-content__extra-option {
            opacity: 1;
            transform: translateY(0);
            pointer-events: auto;
          }
        }
        .patient-email {
          text-align: center;
          color: #526479;
          font-size: 16px;
          background-color: #eef1f4;
          border-radius: 5px;
          width: fit-content;
          margin: auto;
        }
        .patient-program {
          font-size: 16px;
          padding: 0px 10px;
          border-radius: 4px;
          background-color: #F7F7FB;
          font-family: Nunito Sans;
          font-size: 14px;
          color: #3E3E5B;
          width: fit-content;
        }
        .patient-date {
          font-family: Nunito Sans;
          font-size: 16px;
          color: #707582;
        }
        .patient-info {
          display: flex;
          align-items: center;
          height: 60px;
          .check-patient {
            font-size: 20px;
            color: #36369B;
            margin-right: 10px;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        .patient-content {
          display: flex;
          /* margin-left: 20px; */
          /* width: 100%; */
          /* justify-content: center; */
          /* margin: 0 15%; */
          align-items: center;
          position: relative;
          width: auto;
          &__info {
            margin-left: 10px;
            &-name-psa {
              color: #1F1F52;
              font-size: 15px;
              font-family: "Nunito Sans";
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &--selected {
                color: #6C6BCC;
                font-family: "Nunito Sans Bold";
              }
            }
          }
          &__alert-selected {
            position: absolute;
            color: #707582;
            font-size: 12px;
            margin-top: -8px;
            inline-size: max-content;
          }
          &__extra-option {
            position: absolute;
            display: flex;
            align-items: center;
            color: #36369B;
            opacity: 0;
            transform: translateY(-5px); /* Lo mueve un poco hacia arriba */
            transition: opacity 0.5s ease, transform 0.5s ease;
            pointer-events: none; 
            cursor: pointer;
            &-text {
              font-size: 14px;
              inline-size: max-content;
            }
            &-icon {
              margin-left: 5px;
              font-size: 10px;
            }
          }
        }
        .alert-level {
          display: flex;
          align-items: center;
          font-size: 16px;
          background-color: white;
          border-radius: 5px;
          padding: 5px;
          width: fit-content;
          &__icon {
            margin-right: 5px;
            font-size: 14px;
          }
        }
        .alert-type {
          display: flex;
          align-items: center;
          font-size: 16px;
          color: #3E3E5B;
          &__icon {
            margin-right: 5px;
            font-size: 14px;
          }
        }
        .alert-description {
          font-size: 16px;
          color: #1F1F52;
          align-items: center;
          display: flex;
          font-family: Nunito Sans;
          b {
            font-family: Nunito Sans;
          } 
          &__icon {
            margin-right: 5px;
            display: flex;
            font-size: 14px;
            color: #3E3DA3;
          }
          .expand-comment {
            color: #6C6BCC;
            font-family: Nunito Sans;
            font-size: 16px;
            text-decoration: underline;
            cursor: pointer;
            font-weight: 400;
          }
        }
        .alert-date {
          color: #707582;
        }
        .alert-management {
          justify-content: end;
          width: 100%;
          display: flex;
          align-items: center;
          &__status {
            margin-right: 7px;
            color: #707582;
            display: flex;
            &-text {
              color: #707582;
              font-size: 14px;
            }
            &-icon {
              color: #3E3E5B;
              margin-left: 10px;
              font-size: 15px;
            }
          }
          .alert-options{
            background-color: #F0F0FF;
            border-radius: 50%;
            width: 28px;
            height: 28px;
            cursor: pointer;
            justify-content: center;
            display: flex;
            align-items: center;
            margin-right: 20px;
            &_icon {
              font-size: 15px;
              color: #36369B;
            }
          }          
        }
      }
      td {
        box-sizing: border-box;
      }
      
    }
    .no-alerts {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: calc(100% - 55px);
      &__img {

      }
      &__text {
        color: #707582;
        margin: 10px 0px;
      }
      &__button {
        font-family: Nunito Sans; 
        background-color: #36369B;
        border-radius: 75px;
        padding: 8px 20px;
        font-size: 17px;
        color: white;
        outline: none;
        border: none;
        cursor: pointer;
      }
    }
    .zaia-reaction {
      width: 100%;
      height: 100%;
      display: flex;
    }
    
  }
`;

export const FiltersAlerts = styled.div`
  background-color: white;
  box-shadow: 0px 0px 15px 0px #37358F2B;
  display: flex;
  border-radius: 14px;
  padding: 10px;
  .filters-box {
    border-radius: 14px;
    background-color: #F8F8FF;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    &--left {
      margin-right: 10px;
    }
    &__label {
      font-family: Nunito Sans;
      font-size: 14px;
      color: #707582;
    }
    &__title {
      font-family: Nunito Sans Bold;
      font-size: 17px;
      color: #1F1F52;
      margin-bottom: 20px;
    }
    .levels-list {
      .level-item {
        padding: 5px 10px;
        font-family: Nunito Sans;
        font-size: 16px;
        color: #707582;
        display: flex;
        border-radius: 20px;
        margin-bottom: 10px;
        align-items: center;
        cursor: pointer;
        &--actived {
          background-color: #1F1F52;
          color: white;
        }
        &__point {
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background-color: #1F1F52;
          &--actived {
            background-color: #FFF;
          }
        }
        &__text {
          margin: 0 5px;
          align-items: center;
        }
        &__number {
          font-family: Nunito Sans Bold;
        }
      }
    }
    .types-list {
      display: flex;
      .type-item {
        width: 92px;
        position: relative;
        cursor: pointer;
        margin-right: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        &:last-child {
          margin-right: 0;
        }
        &__circle {
          position: relative;
          width: 51px;
          height: 51px;
          border-radius: 50%;
          box-shadow: 0px 0px 8.1px 0px #DADAF6;
          border: 1px solid;
          border-image-source: linear-gradient(289.9deg, rgba(255, 255, 255, 0) -0.91%, rgba(255, 255, 255, 0.264578) 81.21%, #FFFFFF 95.32%);
          background-color: #F9F9FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #3E3DA3;
          &-icon {
            font-size: 21px;
            display: flex;
          }
          &-number {
            display: flex;
            border-radius: 20px;
            height: 23px;
            min-width: 23px;  
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: #8A8AC3;
            position: absolute;
            font-size: 13px;
            color: white;
            top: -9px;
            right: -7px;
            &--actived {
              background-color: white;
              color: #8A8AC3;
            }
          }
          &--actived {
            background-color: #8A8AC3;
            color: white;
          }
        }
        &__label {
          font-family: Nunito Sans;
          font-size: 14px;
          text-align: center;
          color: #3E3E5B;
          margin: 5px;
        }
      }
    }
    .just-comments {
      padding: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      border-radius: 10px;
      margin-top: 23px;
      &__left {
        display: flex;
      }
      &__icon {
        font-size: 19px;
        color: #8A8AC3;
        margin-right: 10px;
      }
      &__text {
        font-family: Nunito Sans;
        font-size: 14px;
        color: #566377;
      }
    }
  }
`;

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  max-width: 400px;
  font-size: 16px;
  padding: 8px;
`

export default ListOfAlerts;