import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalChangeProgramStatus.style";
import * as P from "../../../../../utils/pipes";
import { Actions } from "../../../../../utils/actionsIds";

// graphQL
import { useQuery, useMutation } from "@apollo/client";

// hooks
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction/ZaiaReaction";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";
import ZaiaSelect from "../../../../zaiaComponents/zaiaSelect";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";
import ZaiaInput from "../../../../zaiaComponents/zaiaInput";
import { QUERY_GET_PROCESS_STAGES_BY_PROCESS_IDS } from "../../../../../graphql/queries/UserToProcess";
import { MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP } from "../../../../../graphql/mutations/UserToProcess";

const zaiaImgLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalChangeProgramStatus({ 
  setChangeProgramStatus,
  patient
}) {

  const [updateUserToProcess, {loading: updateUserToProcessLoading}] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP);
  const [processSelected, setProcessSelected] = useState(patient.userToProcesses[0]?.process?.id);
  const [formSuccess, setFormSuccess] = useState(null);
  const [successText, setSuccessText] = useState("");
  const { recordAction } = useRecordNavigation();
  const [statusDescription, setStatusDescription] = useState("");
  const [statusDate, setStatusDate] = useState(null);
  const {t, i18n: { language }} = useTranslation("global");

  const [statusSelectionView, setStatusSelectionView] = useState(0);

  const { data: processStagesData } = useQuery(QUERY_GET_PROCESS_STAGES_BY_PROCESS_IDS, {
    variables: { 
      processIds: [processSelected]
    },
  })

  const [currentStatus, setCurrentStatus] = useState(patient.userToProcesses[0]?.processStage?.id);
  const [newCurrentStatus, setNewCurrentStatus] = useState(patient.userToProcesses[0]?.processStage?.id);

  const frequency = (stage) => {
    if(stage) {
      if(stage.frequencyType === "days") {
        return (
          `${t("globally.each")}
          ${stage.frequency > 1 ? stage.frequency : ""} 
          ${stage.frequency > 1 ? t("globally.days") : t("globally.day")}`
        ) 
      } else if(stage.frequencyType === "months") {
        return (
          `${t("globally.each")} 
          ${stage.frequency > 1 ? stage.frequency : ""} 
          ${stage.frequency > 1 ? t("globally.months") : t("globally.month")}`
        ) 
      } else {
        return (`${t("globally.each")} ${stage.frequency}`)
      }
    }
  } 
  
   
  const handleSubmit = async () => {    
    try {
      await updateUserToProcess({
        variables: {
          updates: {
            stageId: newCurrentStatus,
            descript: statusDescription,
            statusChangedDate: new Date(statusDate)
          },
          userToProcessId: patient.userToProcesses.find((item) => item.process?.id === processSelected).id
        },
      });
      setCurrentStatus(newCurrentStatus);
      setStatusSelectionView(0);
      setStatusDescription("")
      setStatusDate(null)
      setFormSuccess(true);
      setSuccessText(t("changeOfStatus.successChangingStatus"));
    } catch (error) {
      setFormSuccess(false);
      setSuccessText(t("changeOfStatus.errorChangingStatus"));
      console.log(error);
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setChangeProgramStatus(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const newCurrenStatusData = useMemo(() => {
    return processStagesData?.process[0]?.processStages.find((item) => item.id === newCurrentStatus);
  }, [newCurrentStatus]) 

  if(
    updateUserToProcessLoading
  ) 
    return(
      <S.ModalChangeProgramStatus>
        <div className="modal-background">
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaReaction
            widthimg={"150px"}
            zaiaImg={zaiaImgLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalChangeProgramStatus>
    )

  if(formSuccess)
    return(
      <S.ModalChangeProgramStatus>
        <div className="modal-background" onClick={() => setChangeProgramStatus(false)}>
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaModalResponseContent
            button1={() => setFormSuccess(null)}
            button1Text={t("globally.done")}
            formSuccess={formSuccess}
            title={successText}
          />
        </div>
      </S.ModalChangeProgramStatus>
    )

  if(formSuccess === false)
    return(
      <S.ModalChangeProgramStatus>
        <div className="modal-background" onClick={() => setChangeProgramStatus(false)}>
        </div>
        <div className="modal-container modal-container--response">
          <ZaiaModalResponseContent
            button1={() => setFormSuccess(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.accept")}
            title={t("globally.errorForm")}
            subtitle={successText}
          />
        </div>
      </S.ModalChangeProgramStatus>
    )    

  return (
    <S.ModalChangeProgramStatus
      programSelected={statusSelectionView > 0}
    >
      <div className="modal-background" onClick={() => setChangeProgramStatus(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="title">
            <div className="title__icon icon zaia-i-manage-account"></div>
            <div className="title__text">{t("changeOfStatus.title")}</div>
          </div>
          <button className="close"
            onClick={() => setChangeProgramStatus(false)}
          >
            <i className="close__icon icon zaia-icono-cerrar"></i>
          </button>
        </div>
        <div className="content">
          <div className="content__patient-card">
            <div className="content__patient-card-left">
              <div className="photo-container">
                <img 
                  src={patient?.picture}
                  alt="qwerty" 
                  className="photo-patient" 
                />
              </div>
              <div className="patient-info">
                <div className="patient-info__top">
                  <div className="patient-info__name">{patient?.name + " " + patient?.lastname}</div>
                  <div className="patient-info__years">{P.getAge(patient?.birthdate)} {t("globally.years")}</div>
                </div>
                <div className="patient-info__bottom">
                  <div className="patient-info__program-label">{t("globally.program")}</div>
                  {
                    statusSelectionView !== 0
                    ? <div className="patient-info__program-selected">
                      {P.dataTranslation(patient?.userToProcesses.find((item) => item.process?.id === processSelected)?.process?.processName, language)}
                    </div>
                    : <div className="patient-info__program-select">
                      <ZaiaSelect
                        type="select"
                        setValue={(e) => {
                            recordAction(Actions.OUTCOMES.SELECT_PROGRAM, e);
                            setProcessSelected(e)
                          }
                        }
                        value={processSelected}
                        items={patient?.userToProcesses.filter((item) => item.process).map(
                          (userToProcess) => {
                            return {
                              value: userToProcess.process?.id,
                              text: P.dataTranslation(userToProcess.process?.processName, language)
                            }
                          }
                        )}
                        padding={"5px 15px"}
                        shadow={false}
                        fontSize="20px"
                        backgroundColor={"#F0F0FF"}
                        borderRadius={"32px"}
                        heightList={"200px"}
                        textcolor={"#3E3DA3"}
                        arrowColor={"#3E3DA3"}
                        borderColor={"#F0F0FF"}
                        textItemsColor={"#36369B"}
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className="content__patient-card-right">
              <div className="status-explained">
                <i className="status-explained__icon icon zaia-i-light"></i>
                <div className="status-explained__text">
                  <div className="status-explained__text-title">{t("changeOfStatus.aboutProgramStatus")}</div>
                  <div className="status-explained__text-descript">{t("changeOfStatus.aboutProgramStatusSub")}</div>
                </div>
              </div>
            </div>
          </div>
          {
            statusSelectionView !== 2 &&
            <div className="content__change-status">
              <div className="current-status">
                <div className="current-status__left">
                  <div className="label-status">{t("changeOfStatus.currentStatus")}</div>
                  <div className="text-status">{P.dataTranslation(patient?.userToProcesses.find((item) => item.process?.id === processSelected)?.processStage.stageName, language)}</div>
                </div>
                <div className="current-status__right">
                  <div className="info-status">
                    <i className="info-status__icon icon zaia-i-info"></i>
                    <div className="info-status__text">
                    {t("changeOfStatus.statusDescription1")} <b>{t("changeOfStatus.statusDescription2")} {frequency(newCurrenStatusData)}
                      :</b> {t("changeOfStatus.statusDescription3")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="status-list">
                {
                  processStagesData?.process[0]?.processStages?.map(
                    (stage) => {
                      return (
                        <div className="status-item">
                          <button className={
                              `status-item__button 
                              ${
                                statusSelectionView === 0
                                ? stage.id === currentStatus 
                                  ? "status-item__button--current" 
                                  : "status-item__button--disabled"
                                : stage.id === newCurrentStatus
                                  ? "status-item__button--selected"
                                  : ""
                              }`
                            }
                            onClick={() => {
                              setNewCurrentStatus(stage.id);
                            }}
                          >{P.dataTranslation(stage.stageName, language)}</button>
                          <div 
                            className={
                              `status-item__caption
                              ${
                                statusSelectionView === 0
                                ? stage.id === currentStatus 
                                  ? "status-item__caption--current" 
                                  : ""
                                : stage.id === newCurrentStatus
                                  ? "status-item__caption--selected"
                                  : ""
                              }`
                            }
                          >
                            {
                              stage.id === currentStatus &&
                              t("changeOfStatus.currentStatus")
                            }
                            {
                              statusSelectionView > 0 && stage.id === newCurrentStatus && newCurrentStatus !== currentStatus &&
                              t("changeOfStatus.newStatus")
                            }
                          </div>
                        </div>
                      )
                    }
                  )
                }
              </div>
            </div>
          }
          {
            statusSelectionView === 2 &&
            <div className="content__change-form">
              <div className="status-changed">
                <div className="status-changed__item">
                  <div className="status-changed__info">
                    <div className="status-changed__label">
                      <i className="status-changed__label-icon icon zaia-i-routine-activity"></i>
                      <div className="status-changed__label-text">{t("changeOfStatus.oldStatus")}</div>
                    </div>
                    <div className="status-changed__status-name">
                      {
                        P.dataTranslation(processStagesData.process[0].processStages.find((item) => item.id === currentStatus)?.stageName, language)
                      }
                    </div> 
                  </div>
                  <div className="status-changed__tooltip-info">
                    <i className="status-changed__tooltip-info-icon icon zaia-i-info"></i>
                  </div>
                </div>
                <i className="status-changed__tooltip-icon icon zaia-i-next-1 status-changed__tooltip-icon--new">
                </i>
                <div className="status-changed__item">
                  <div className="status-changed__info">
                    <div className="status-changed__label">
                      <i className="status-changed__label-icon icon zaia-i-new-record status-changed__label-icon--new"></i>
                      <div className="status-changed__label-text status-changed__label-text--new">{t("changeOfStatus.currentStatus")}</div>
                    </div>
                    <div className="status-changed__status-name status-changed__status-name--new">
                      {
                        P.dataTranslation(newCurrenStatusData?.stageName, language)
                      }
                    </div>
                  </div>
                  <div className="status-changed__tooltip-info">
                    <i className="status-changed__tooltip-info-icon icon zaia-i-info"></i>
                  </div>
                </div>
              </div>
              <div className="status-data">
                <div className="status-info">
                  <i className="status-info__icon icon zaia-i-info"></i>
                  <div className="status-info__text">
                    <b>{
                      P.dataTranslation(newCurrenStatusData?.stageName, language)
                    }:</b> {t("changeOfStatus.statusDescription1")} <b>{t("changeOfStatus.statusDescription2")} {frequency(newCurrenStatusData)}
                    :</b> {t("changeOfStatus.statusDescription3")}
                  </div>
                </div>
                <div className="status-field">
                  <div className="status-field__label">{t("changeOfStatus.statusDescription")}</div>
                  <div className="status-field__input">
                    <ZaiaInput
                      name="descript"
                      type="text"
                      placeholder={t("changeOfStatus.statusDescription")}
                      backgroundColor={"#F6F6FF"}
                      borderRadius={"14px"}
                      textarea={true}
                      fontSize={"19px"}
                      padding={"20px"}
                      placeholdertextcolor={"#878792"}
                      textcolor={"#1F1F52"}
                      value={statusDescription}
                      getText={(e) => setStatusDescription(e)}
                    />
                  </div>
                </div>
                <div className="status-field">
                  <div className="status-field__label">{t("changeOfStatus.statusDate")}</div>
                  <div className="status-field__input">
                    <ZaiaInput
                      name="date"
                      type="date"
                      backgroundColor={"#F6F6FF"}
                      borderRadius={"14px"}
                      fontSize={"19px"}
                      padding={"20px"}
                      placeholdertextcolor={"#878792"}
                      textcolor={"#1F1F52"}
                      value={statusDate}
                      getText={(e) => setStatusDate(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className="footer">
          <div className="footer__description">
            {
              statusSelectionView === 0 &&
              <div className="footer__description-text">
                {t("changeOfStatus.footerText1")}
              </div>
            }
            {
              statusSelectionView === 1
              ? newCurrentStatus !== currentStatus
                ? <>
                    <i className="footer__description-icon icon zaia-i-info" />
                    <div className="footer__description-text">
                      <b>{
                        P.dataTranslation(newCurrenStatusData?.stageName, language)
                      }:</b> {t("changeOfStatus.statusDescription1")} <b>{t("changeOfStatus.statusDescription2")} {frequency(newCurrenStatusData)}
                      :</b> {t("changeOfStatus.statusDescription3")}
                    </div>
                  </>
                : <div className="footer__description-text">
                  {t("changeOfStatus.footerText21")} <b>{t("changeOfStatus.footerText22")}</b> {t("changeOfStatus.footerText23")}
                </div>
              : ""
            }
            {
              statusSelectionView === 2 &&
              <div className="footer__description-text">
                {t("changeOfStatus.footerText3")}
              </div>
            }
          </div>
          <div className="footer__buttons">
            {
              statusSelectionView === 0 &&
              <button className="button-status"
                onClick={() => {
                  setStatusSelectionView(1)
                }}
                >
                <div className="button-status__text">{t("changeOfStatus.changeProgramStatus")}</div>
                <i className="button-status__icon icon zaia-i-refresh"></i>
              </button>
            } 
            {
              statusSelectionView > 0 &&
              <button className="button-back"
                onClick={() => {
                  setStatusSelectionView(statusSelectionView - 1)
                }}
              >
                <i className="button-back__icon icon zaia-icono-atras"></i>
                <div className="button-back__text">{t("globally.back")}</div>
              </button>
            }
            {
              statusSelectionView === 1 &&
              <button className={
                `button-status 
                ${newCurrentStatus === currentStatus && "button-status--disabled"}
                `
              }
                onClick={() => {
                  setStatusSelectionView(statusSelectionView + 1)
                }}
              >
                <div className="button-status__text">{t("globally.next")}</div>
                {
                  newCurrentStatus === currentStatus 
                  ? <i className="button-status__icon icon zaia-i-lock-chat"></i>
                  : <i className="button-status__icon icon zaia-i-next"></i>
                }
              </button>
            }
            {
              statusSelectionView === 2 &&
              <button className={
                `button-status button-status--green 
                  ${(!statusDescription || !statusDate) && "button-status--disabled"}
                `}
                onClick={() => {
                  (statusDescription && statusDate) && handleSubmit();
                }}
              >
                <div className="button-status__text">{t("changeOfStatus.updateChanges")}</div>
                {
                  !statusDescription || !statusDate
                  ? <i className="button-status__icon icon zaia-i-lock-chat"></i>
                  : <i className="button-status__icon icon zaia-i-check-1"></i>
                }
              </button>
            }
          </div>
        </div>
      </div>
    </S.ModalChangeProgramStatus>
  );
}

export default ModalChangeProgramStatus;
