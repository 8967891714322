import React from "react";
import * as S from "./ProgressBarCard.style";
import { useTranslation } from "react-i18next";

// components
import ProgressBar from "../progressBar";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// assets
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;
const loadingZaia = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

const ProgressBarCard = ({
  title='',
  colorPrimary, 
  colorSecundary,
  colorDark,
  heightBarsContainer=80,
  bgActive,
  infoBars=[],
  loading=false
}) => {

  const [t] = useTranslation("global");

  // useEffect(() => {
  //   infoBars?.then((info) => {
  //     setInfoProgressBars(info)
  //   })
  // }, [infoBars])

  const infoToRender = () => {
    if (loading) return (
      <S.ZaiaReactionContainer>
        <ZaiaReaction
          zaiaImg={loadingZaia}
          widthimg="100px"
          sizeText="15px"
          widthText="240px"
          />
      </S.ZaiaReactionContainer>
    )
  
    if (infoBars?.length === 0) return (
      <S.ZaiaReactionContainer>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthimg="100px"
          text={t('globally.noReports')}
          sizeText="15px"
          widthText="240px"
          textcolor={bgActive ? 'var(--white)' : 'var(--text-toggle-gray)'}
        />
      </S.ZaiaReactionContainer>
    )

    return(
      infoBars?.map(element => {
        return (
          <ProgressBar
            key={element.name}
            symptom={element.name}
            completed={element.percentage}
            bgColor={colorPrimary}
            bgColor2={colorSecundary}
          />
        )
      })
    )
  }

  return (
    <S.ProgressBarCard
      colorPrimary={colorPrimary} 
      colorSecundary={colorSecundary}
      colorDark={colorDark}
      heightBarsContainer={heightBarsContainer}
      bgActive={bgActive}
    >
      <h4>{title}</h4>
      <div className="progress-bars-container">
        { 
          infoToRender()
        }
      </div>
    </S.ProgressBarCard>
  );
};

export default ProgressBarCard;
