import styled, {keyframes} from "styled-components";


const expansion = () => keyframes`
 0% {
  box-shadow: 0 0 0 0px rgba(255,0,0,.5);
 }
 100% {
  box-shadow: 0 0 0 5px rgba(255,0,0,.5);
 }
`

export const ZaiaInputForm = styled.div`
  width: -webkit-fill-available;
  height: ${({height}) => height ? height : 'auto'};
  .input-container {
    height: ${({height}) => height ? height : 'auto'};
    background-color: ${({theme, backgroundColor, disabledColor, disabled}) => 
      disabled 
      ? (disabledColor ?? backgroundColor ?? theme.inputBackground)
      : backgroundColor 
        ? backgroundColor 
        : theme.inputBackground
    };
    border: none;
    box-sizing: border-box;
    padding: ${({padding}) => padding ? padding :'7px 7px'};
    border-radius: ${({borderRadius}) => borderRadius ? borderRadius : "8px"};
    display: flex;
    align-items: center;
    min-width: 100px;
    cursor: ${({disabled}) => disabled ? '' :'text'};
    position: relative;
    border: 1.8px solid ${
        ({ 
          theme, 
          active, 
          activeBorder,
          borderColor,
          error
        }) => 
          {
            if(activeBorder) {
              if (borderColor) {
                return borderColor;
              } else {
                if (active) {
                  return theme.inputSelected;
                } else {
                  if (error?.error && error?.touched) {
                    return theme.warning;
                  } else {
                    return theme.inputBorder;
                  }
                }
              }
            } else {
              return 'transparent';
            }
          }
      };
    border-left: ${({onlyBottomBorder}) => onlyBottomBorder && 'transparent'};
    border-right: ${({onlyBottomBorder}) => onlyBottomBorder && 'transparent'};
    border-top: ${({onlyBottomBorder}) => onlyBottomBorder && 'transparent'};
    .icon-input-form {
      font-size: ${({rightIcon}) => rightIcon ? "20px" : "15px"};
      margin-right: 5px;
      color: ${({theme, iconColor, disabledIconColor, disabled}) => 
        disabled 
        ? (disabledIconColor ?? iconColor ?? theme.inputText)
        : iconColor 
          ? iconColor 
          : theme.inputText
      };
    }
    .icon-input-pass {
      margin-bottom: -5px;
      font-size: 14px;
      cursor: pointer;
    }
    input {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Nunito Sans";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      height: ${({height}) => height ? height : 'auto'};
      /* margin-left: 7px; */
      color: ${({theme, textcolor, disabled, disabledtextcolor}) => 
      disabled 
        ? (disabledtextcolor ?? textcolor ?? theme.inputText)
        : textcolor 
          ? textcolor 
          : theme.inputText
      };
      text-transform: ${({theme, type}) => type === "time" || type === "date" ? "uppercase" : ""};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme, placeholdertextcolor }) => placeholdertextcolor ? placeholdertextcolor: theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Nunito Sans light";
      }
    }
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none; 
      margin: 0; 
    }
    textarea {
      border: none;
      background-color: transparent;
      width: 100%;
      font-family: "Nunito Sans light";
      font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
      height: ${({height}) => height ? height : 'auto'};
      height: 100%;
      resize: none;
      color: ${({theme, textcolor}) => textcolor ? textcolor : theme.inputText};
      &:focus {
        outline: none;
      }
      &::placeholder { 
        color: ${({ theme, placeholdertextcolor }) => placeholdertextcolor ? placeholdertextcolor: theme.inputPlaceholder};
        font-size: ${({fontSize}) => fontSize ? fontSize :'14px'};
        margin-left: 30px;
        font-family: "Nunito sans light";
      }
      &::-webkit-scrollbar {
        height: 4px;
        width: 20px;
        margin: 0;
        opacity: .9;
        padding-bottom: 10px;
        cursor: pointer;
      }
      ::-webkit-scrollbar-track {
        min-height: 5px !important;
      }
      ::-webkit-scrollbar-thumb {
        min-height: 30px !important;
      }
    }
    .mic-container {
      color: transparent;
      position: absolute;
      right: 7px;
      bottom: 7px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      animation: ${({activedMic}) => activedMic ? expansion() : null} 2s ease infinite;
      cursor: pointer;
      display: flex;
      .mic-icon {
        color: ${({activedMic, theme}) => !activedMic ? theme.primaryColor : theme.warning};
        margin: 3px auto;
      }
    }
    .play-record-button {
    }
  }
  .input-error {
    height: 15px;
    padding: 1px 5px;
    font-size: 10px;
    color: ${({theme}) => theme.warning};
    display: flex;
    align-items: center;
    i {
      margin-right: 2px;
    }
  }
`