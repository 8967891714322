import React, { useEffect, useMemo, useState } from "react";
import * as S from "./SurveyPatients.style";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../utils/pipes";
import { v4 as uuidv4 } from 'uuid';
import { QUERY_GET_TYPE_SIDEEFFECTS } from "../../../../../../../graphql/queries/SideEffects";
import { useLazyQuery } from "@apollo/client";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";
import usePatientList from "../../../../../../../hooks/usePatientList";
import ZaiaImage from "../../../../../../zaiaComponents/zaiaImage";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const magnifyingGlass = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/magnifying-glass.svg`;
const noData = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;

function SurveyPatients({
  selectedPatientsList,
  changeFormField,
  patientId,
  processId,
  selectedProcess
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const {patientList, patientListLoading, patientListError, patientQuantity } = usePatientList();
  
  useEffect(() => {
    // get patient with id patientId from patientList
    const patient = patientList?.find((patient) => patient.id === patientId);
    if(selectedPatientsList.value.length === 0 && patient) {
      addPatient(patient);
    }
  }, [patientId, patientList]);

  const [filterText, setFilterText] = useState("");

  const getFilterText = (text) => {
    return filterText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(filterText).toLowerCase())
      : true
  }

  // const [ filterSymptoms, { data: symptomsData, error: symptomsError, loading: symptomsLoading }] = useLazyQuery(
  //   QUERY_GET_TYPE_SIDEEFFECTS
  // );

  const addPatient = (patient) => {
    const tempSymptomsList = JSON.parse(JSON.stringify(selectedPatientsList.value));
    const newPatient = {
      value: patient.id,
      text: `${patient.name} ${patient.lastname}`,
      image: patient.image
    }
    tempSymptomsList.push(newPatient);
    changeFormField(
      [
        {
          value: tempSymptomsList, 
          text: `(${tempSymptomsList.length})`, 
          fieldName: "patientsList"
        }
      ]
    )
  }
  
  const removePatient = (patient) => {
    const tempPatientsList = JSON.parse(JSON.stringify(selectedPatientsList.value));
    changeFormField(
      [
        {
          value: tempPatientsList.filter((item) => item.value !== patient.id), 
          text: `(${tempPatientsList.length - 1})`, 
          fieldName: "patientsList"
        }
      ]
    )
  }

  const removeAllPatients = () => {
    changeFormField(
      [
        {
          value: [], 
          text: `(${0})`, 
          fieldName: "patientsList"
        }
      ]
    )
  }

  const  handleChangePatientsList = (patient, exist) => {
    if (exist) {
      removePatient(patient);
    } else {
      addPatient(patient);
    }
  }

  // const handlefilterSymptoms = (e) => {
  //   setFilterText(e);
  //   if ( e !== "" ) 
  //     filterSymptoms(
  //       {
  //         variables: {
  //           filterText: `%${e}%`,
  //         }
  //       }
  //     );
  // }


  const existSymptom = (patient) => { 
    return selectedPatientsList?.value.find((item) => item.value === patient.id) ? true : false; 
  }


  const getSymptomsList = () => {

    if(selectedProcess.value === null) 
      return (<div>Necesitas seleccionar un programa primero</div>)
    

    if (patientListError)
      return (
        <div className="zaia-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaAlert}
            text={"Error loading symptoms"}
            sizeText="20px"
            textcolor="var(--blue-color)"
            widthimg="110px"
          />
        </div>
      )
    
    
    if (patientListLoading)
      return (
        <div className="zaia-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            sizeText="20px"
            textcolor="var(--blue-color)"
            widthimg="110px"
          />
        </div>
      )

    // if(filterText === "" && !symptomsData)
    //   return (
    //     <div className="search-symptoms">
    //       <img src={magnifyingGlass} alt="" className="search-symptoms__img" />
    //       <div className="search-symptoms__text">
    //         Type something to search the symptoms you need
    //       </div>
    //     </div>
    //   )

    if(patientQuantity === 0)
      return (
        <div className="empty-symptoms">
          <img src={noData} alt="" className="empty-symptoms__img" />
          <div className="empty-symptoms__title">
            No se encontraron resultados
          </div>
          <div className="empty-symptoms__subtitle">
            Zaia no puede encontrar ningún paciente que coincida con tu búsqueda
          </div>
        </div>
      )

    return(
      patientList?.filter((patient) => patient.userToProcesses.find((item) => item.process?.id === processId)).map((patient) => {
        const exist = existSymptom(patient);
        if(getFilterText(`${patient.name} ${patient.lastname}`)) {
          return (
            <div 
              key={uuidv4()}
              className={`symptom-box${exist ? " symptom-box--selected" : ""}`}
              onClick={() => handleChangePatientsList(patient, exist)}
            >
              {
                exist
                ? <i class="symptom-box__icon icon zaia-i-checkbox-on"></i>
                : <i class="symptom-box__icon icon zaia-i-checkbox-off"></i>
              }
              <ZaiaImage
                img={patient.picture}
                alt={`${patient.name} ${patient.lastname}`}
                size={40}
                rounded={true}
              />
              <div 
                className={`symptom-box__name`}
              >
                {`${patient.name} ${patient.lastname}`}
              </div>
            </div>
          )
        } else {
          return null
        }
      })
      // patientList?.map(
      //   patient => {
      //     return {
      //       value: patient.id, 
      //       text: `${patient.name} ${patient.lastname}`
      //     }
      //   }
      // ).sort(function(a,b){
      //   return a.text.localeCompare(b.text);
      // })

      // symptomsData?.typeSideEffect?.map((symptom) => {
      //   const exist = existSymptom(symptom);
      //   return (
      //     <div 
      //       key={uuidv4()}
      //       className={`symptom-box${exist ? " symptom-box--selected" : ""}`}
      //       onClick={() => handleChangeSymptomsList(symptom, exist)}
      //     >
      //       {
      //         exist
      //         ? <i class="symptom-box__icon icon zaia-i-checkbox-on"></i>
      //         : <i class="symptom-box__icon icon zaia-i-checkbox-off"></i>
      //       }
      //       <div 
      //         className={`symptoms-box__name`}
      //       >{P.dataTranslation(symptom.comment, language)}</div>
      //     </div>
      //   )
      // })
    )
  }

  return (
    <S.SurveyPatients
      searcher={filterText}
    >
      <div className="surveys-list-header">
        <div className="surveys-list-header__title">{t("globally.selectPatients")}</div>
        <div className="surveys-list-header__remove-selection"
          onClick={() => removeAllPatients()}
        >{t("surveys.sendSurveyModal.diselectAllPatients")}</div>
      </div>
      <div className="input-container">
        <ZaiaInput
          textcolor={"#59599E"}
          borderColor={"#68688D"}
          placeholdertextcolor={"#878792"}
          fontSize={"12px"}
          placeholder={t("surveys.sendSurveyModal.searchPatients")}
          type="text"
          name="description"
          padding={"9px 20px"}
          borderRadius={"10px"}
          errorOption={false}
          height={"100%"}
          getText={(e) => setFilterText(e)}
          backgroundColor={"transparent"}
          value={filterText}
        />
      </div>
      <div className="input-msg">
        {t("surveys.sendSurveyModal.severalPatients")}
      </div>
      <div className="symptoms-list">
        <div className={`symptoms-list__results ${filterText === "" ?  "symptoms-list__results--hidden" : ""}`}>{t("globally.resultsFor")} "{filterText}"</div>
        <div className="symptoms-list__container">
          {  
            getSymptomsList()
          }
        </div>
      </div>
      
    </S.SurveyPatients>
  );
}

export default SurveyPatients;
