import styled from "styled-components";

const heightLookup = {
  day: 'auto',
  week: {
    8: '100px',
    14: '150px',
    20: '200px'
  },
  month: {
    0: '100px',
    8: '150px',
    14: '200px',
    20: '230px'
  }
};

export const MedicinesLogCard = styled.div`
  .medicines-log-card {
    width: 314px;
    height: auto;
    min-height: 111px;
    border-radius: 10px;
    background-color: var(--white);
    box-shadow: ${({activeHeader}) => activeHeader ? '0px 3px 6px #00000029' : '' };
    padding: 5px;
    display: flex;
    flex-direction: column;
    margin: 10px;
    &__header {
      display: flex;
      height: 52px;
      align-items: center;
      justify-content: space-between;
      &-days {
        background-color: var(--green-color);
        width: 100px;
        border-radius: 5px;
        display: flex;
        color: var(--white);
        font-size: 27px;
        height: inherit;
        p {
          margin: auto;
          padding: auto;
        }
      }
      &-text {
        margin-left: 10px;
        &-title {
          display: flex;
          align-items: center;
          &-icon {
            background-color: #EEF1F4;
            color: #6C6BCC;
            border-radius: 50%;
            height: 20px;
            width: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-right: 5px;
            cursor: pointer;
            justify-content: center;
          }
        }
        p {
          margin-top: 5px;
          font-family: "Nunito";
          color: var(--text-toggle-gray);
          font-size: 12px;
        }
      }
    }
    &__bars-container {
      height: 100%;
      min-height: ${({timeOp}) => 
        timeOp === 'day' ? 'auto' : '40px'
      };;
      display: flex;
      margin: 10px 0;
      &-listlevels {
        width: 55px;
        height: 110px;
        text-align: end;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #8A0515;
        p {
          font-size: 9px;
          font-family: "Nunito Bold"
        } 
      }
      h5 {
        margin: auto;
        color: var(--green-color);
      }
      &-intervals {
        display: flex;
        flex-direction: column;
        height: ${({ intervalsQuantity, timeOp }) =>
          heightLookup[timeOp]?.[intervalsQuantity]
        };
        height: ${({intervalsQuantity, timeOp}) => 
          {
            let height;

            if(timeOp === 'day') {
              height = 'auto';
            } else {
              if(intervalsQuantity > 20) {
                height = '230px';
              } else if(intervalsQuantity > 14) {
                height = '200px';
              } else if(intervalsQuantity > 8) {
                height = '150px';
              } else {
                height = '100px';
              }
            }
            return height
          }
        };
      }
      &-interval {
        font-size: ${({intervalsQuantity}) => 
          intervalsQuantity <= 8 ? '9px' : '7px'};
        inline-size: max-content;
        margin: 0 5px 0 10px;
        display: flex;
        height: ${({timeOp}) => 
          timeOp === 'day' ? '14px' : '100%'
        };
        align-items: center;
        color: var(--text-toggle-gray);
      }
      &-bars {
        height: 100%;
        width: 100%;
        margin-right: 10px;
        display: flex;
        overflow: auto;
        justify-content: space-between;
        overflow-x: hidden;
        &::-webkit-scrollbar {
          height: 15px;
          margin: 0;
          opacity: .9;
        }
        ::-webkit-scrollbar-track {
          margin-left: 0px;
          box-shadow: inset 0 0 10px 10px transparent;
          opacity: .5;
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
          min-height: 10px;
          min-width: 1px;
          width: 1px;
          max-width: 1px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #9A9A9A40;
          border: solid 3px transparent;
          opacity: .8;
        }
        &:hover {
          overflow-x: overlay;
        }
      }
    }
    &__footer-indicator {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 5px;
      .type-indicator {
        display: flex;
        font-family: "Nunito";
        color: var(--text-toggle-gray);
        font-size: 12px;
        align-items: center;
        &__point {
          &-cross {
            font-size: 9px;
            color: var(--red-color);
            display: flex;
          }
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-right: 5px;
          &--green {
            background-color: var(--green-color);
          }
          &--grey {
            background-color: #F4F4F4;
          }
        }
      }
    }
  }
`;

export const CheckboxMedicine = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  min-width: ${({ timeOp }) => {
    if (timeOp === 'day') {
      return '55px';
    } else if (timeOp === 'month') {
      return '25px';
    } else {
      return null;
    }
  }};
  width: 100%;
  text-align: center;
  padding: 0px 3px 0;
  .vertical-progress-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: ${({ intervalsQuantity, timeOp }) => {
        if (timeOp === 'day') {
          return 'auto';
        } else if (intervalsQuantity > 20) {
          return '230px';
        } else if (intervalsQuantity > 14) {
          return '200px';
        } else if (intervalsQuantity > 8) {
          return '150px';
        } else {
          return '100px';
        }
      }
    };
  }
  .vertical-text {
    margin-top: 8px;
    font-family: "Nunito";
    color: var(--text-toggle-gray);
    &__time {
      width: 100%;
      font-size: 10px;
    }
  }
`

export const VerticalBoxMedicine = styled.div`
  height: ${({timeOp}) => 
    timeOp === 'day' ? '10px' : '100%'
  };
  width: ${({ timeOp, active }) => {
      if (timeOp === 'day') {
        return '100%';
      } else if (active === 'haveTakeIt' || active === 'haveNoTakeIt') {
        return '7px';
      } else {
        return '8px';
      }
    }
  };
  background-color: ${
    ({ active }) => {
      if (active === 'takeIt') {
        return 'var(--green-color)';
      } else if (active === 'noTakeIt' || active === 'noReported') {
        return '#E3E3E3';
      } else {
        return '';
      }
    }
  };
  border: ${
    ({ active }) => {
      if (active === 'haveTakeIt') {
        return `solid 1px var(--green-color)`;
      } else if (active === 'haveNoTakeIt') {
        return `solid 1px #E3E3E3`;
      } else {
        return '';
      }
    }
  };
  border-radius: 50px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin: 1.5px 0 1.5px 0;
  i {
    color: var(--red-color);
    position: relative;
    display: flex;
    margin: auto auto auto -0.1px;
    font-size: ${
      ({ intervalsQuantity }) => {
        if (intervalsQuantity > 20) {
          return '6px';
        } else if (intervalsQuantity > 14) {
          return '7px';
        } else if (intervalsQuantity > 8) {
          return '8px';
        } else {
          return '9px';
        }
      }
    };
  }
`

export const CardOptions = styled.div`
  position: relative;
  background-color: var(--white);
  width: 145px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  &::-webkit-scrollbar{
    display: none;
  }
  .option {
    color: #36369B;
    cursor: pointer;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    &__text {
      font-size: 16px;
    }
    &__icon {
      font-size: 15px;
      margin-right: 5px;
    }
  }
`
