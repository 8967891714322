import React, { useEffect, useState } from "react";
import * as S from "./OutcomesProcessByPatient.style";
import { useTranslation } from "react-i18next";
import { Actions } from "../../../../../../../utils/actionsIds";
import useRecordNavigation from "../../../../../../../hooks/useRecordNavigation";
import * as P from "../../../../../../../utils/pipes";

// GraphQL
import { useQuery, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID } from "../../../../../../../graphql/subscriptions/CustomForm";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../../../graphql/queries/UserToProcess";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../../../graphql/queries/CustomForm";
import OutcomesTable from "./components/outcomesTable";
import CurrentState from "../../../outcomesComponents/shared/currentState";
import Popup from "reactjs-popup";
import ZaiaSelect from "../../../../../../zaiaComponents/zaiaSelect";

function OutcomesProcessByPatient({
  patientID,
  processID,
  processToCustomFormID,
  viewSelected, 
  setViewSelected,
  highlightsFilter,
  customFormsList,
  setCustomFormsList,
  eventsSelected,
  setEventsSelected
}) {
  const {t, i18n: { language }} = useTranslation("global");
  const subsActive = window.navigator.userAgent.indexOf('Mac') === -1;
  const { recordAction } = useRecordNavigation();

  const viewsList = [
    {
      id: 1,      
      name: t("globally.dashboard"),
      icon: "zaia-dashboard",
      actionId: Actions.OUTCOMES.DASHBOARD
    },
    {
      id: 2,
      name: t("outcomes.views.timeline"),
      icon: "zaia-timeline",
      actionId: Actions.OUTCOMES.TIMELINE
    },
    // ,
    // {
    //   id: 4,
    //   name: t("outcomes.views.summaryView2"),
    //   icon: "zaia-star-off",
    // },
  ];

  const { data: userToProcessData } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientID, processId: processID },
  });

  const { 
    data: customFormSub, 
    loading: customFormLoadingSub 
  } = useSubscription(
    SUBSCRIPTION_GET_CUSTOM_FORM_BY_ID,
    {
      variables: {
        userId: patientID,
        // doctorId: doctorData.id,
        customFormId: processToCustomFormID,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
      },
    }
  );

  const { 
    data: customForm, 
    loading: customFormLoading 
  } = useQuery(
    QUERY_GET_CUSTOM_FORMS_BY_IDS,
    {
      variables: {
        userId: patientID,
        // doctorId: doctorData.id,
        customFormId: processToCustomFormID,
        userToProcessId: userToProcessData?.userToProcess[0]?.id,
      },
    }
  );

  useEffect(() => {
    if(customForm) {
      setCustomFormsList([...customForm?.customForm_by_pk.formGroups]);
    } else {
      setCustomFormsList([]);
    }
  }, [customForm])

  function getViewComponent(viewSelected, patientID, processID, processToCustomFormID, customFormData, customFormLoading, userToProcessData) {
    const outcomesTableProps = {
      patientID,
      processID,
      processToCustomFormID,
      customFormData,
      customFormLoading,
      userToProcessData
    };
    
    switch (viewSelected) {
      case 1:
        return <OutcomesTable {...outcomesTableProps} highlightsFilter={highlightsFilter} eventFilter={eventsSelected}/>;
      case 2:
        return <CurrentState patientId={patientID} processId={processID} eventFilter={eventsSelected}/>;
      default:
        return <OutcomesTable {...outcomesTableProps} highlightsFilter={highlightsFilter} eventFilter={eventsSelected}/>;
    }
  }

  const addEvent = (event) => {
    if(eventsSelected.indexOf(event) === -1) {
      setEventsSelected([...eventsSelected, event]);
    }
  }
  
  const deleteEvent = (event) => {
    setEventsSelected(eventsSelected.filter(item => item !== event));
  }

  const deleteAllEvents = () => {
    setEventsSelected([]);
  }

  return (
    <S.OutcomesProcessByPatient>
      <div className="table-title">
        {/* <h3>{t("globally.recentEvents")}</h3> */}
        <div className="buttons-container">
          {viewsList.map((view) => {
            return (
              <S.ButtonView
                key={view.id}
                active={view.id === viewSelected}
                onClick={
                  () => {
                    recordAction(view.actionId, null, processID);
                    setViewSelected(view.id);
                  }
                }
              >
                <div className="icon-container">
                  <i className={`icon ${view.icon}`} />
                </div>
                {view.name}
              </S.ButtonView>
            );
          })}
        </div>
        <div className="table-event-filters">
          {
            eventsSelected.length > 0 && 
            <div className="table-event-filters__clear" onClick={() => deleteAllEvents()}>{t("globally.clear")}</div>
          }
          {
            eventsSelected?.map(
              event => (
                <div className="event-item">
                  <div className="event-item__name">{P.dataTranslation(customFormsList.find(customForm => customForm.id === event)?.customFormName, language)}</div>
                  <div className="event-item__button" onClick={() => {deleteEvent(event);}}>
                    <i className="event-item__icon icon zaia-icono-cerrar"/>
                  </div>
                </div>
              )
            )
          }          
        </div>
      </div>
      <div className="select-view">
        <p className="select-view__label">Seleccionar vista</p>
        <div className="select-view__container">
          <Popup
            trigger={
              <div className="select-view__button-selector">
                  <S.ButtonView
                    active
                  >
                    <div className="icon-container">
                      <i className={`icon ${viewsList.find((view) => view.id === viewSelected).icon}`} />
                    </div>
                    {viewsList.find((view) => view.id === viewSelected).name}
                  </S.ButtonView>
                  <div className="select-view__icon-container">
                    <i className="icon zaia-icono-dropdown-light"></i>
                  </div>
              </div>
            }
            // on={["hover", "focus"]}
            closeOnDocumentClick
            // keepTooltipInside
            position={["bottom center"]}
            // nested
            className={"select-view__popup"}
            // contentStyle={{ 
            //   // width: '100%',
            //   // left: '0 !important',
            //   // padding: '20px',
            //   // boxSizing: 'border-box'
            // }}
          >
            <S.SelectViewSelector>
              {viewsList.map((view) => {
                return (
                  <S.ButtonView
                    key={view.id}
                    active={view.id === viewSelected}
                    onClick={
                      () => {
                        recordAction(view.actionId, null, processID);
                        setViewSelected(view.id)
                      }
                    }
                  >
                    <div className="icon-container">
                      <i className={`icon ${view.icon}`} />
                    </div>
                    {view.name}
                  </S.ButtonView>
                );
              })}
            </S.SelectViewSelector>
          </Popup>                          
        </div>
      </div>
      <div className="view-container">
        {
          getViewComponent(
            viewSelected, 
            patientID, 
            processID, 
            processToCustomFormID, 
            subsActive ? customFormSub : customForm, 
            customFormLoading || customFormLoadingSub, 
            userToProcessData
          )
        }
      </div>
    </S.OutcomesProcessByPatient>
  );
}

export default OutcomesProcessByPatient;
