import React from "react";
import * as S from "./SelectorForm.style";
import ZaiaInput from "../../../../../../zaiaComponents/zaiaInput";

function SelectorForm({
  boxShadow,
  type,
  placeholder,
  icon,
  dataInput="",
  handleSubmit,
  disabled
}) {

  return (
    <S.SelectorFormDiv
      boxShadow={boxShadow}
    >
      <div className="date-selector-form">
          <S.SelectorForm absolute={true}>
            <div className="progression-free-survival-form-inputs">
              <div className="progression-free-survival-form-input">
                <ZaiaInput 
                  placeholder={placeholder}
                  type={type}
                  textarea={type === "textarea" ? true : false}
                  name="data"
                  icon={icon}
                  errorOption={false}
                  getText={handleSubmit}
                  value={dataInput}
                  disabled={disabled}
                  padding={"20px"}
                  borderRadius={"14px"}
                  textcolor={"#6C6BCC"}
                  borderColor={"transparent"}
                  placeholdertextcolor={"#878792"}
                  heightList={"200px"}
                  fontSize="15px"
                  />
              </div> 
            </div>
          </S.SelectorForm>
      </div>
    </S.SelectorFormDiv>
  );
}

export default SelectorForm;
