import React, { useMemo } from "react";
import * as S from "./CmpSelect.style";
import { PopUpPayload } from "../genericTemplate/GenericTemplate.style";
import { useTranslation } from "react-i18next";

// pipes
import * as P from "../../../../../../../utils/pipes";

// Component
import ZaiaSelect from "../../../../../../zaiaComponents/zaiaSelect";

import Popup from "reactjs-popup";
import useChatVerification from "../../../../../../../hooks/useGetListOptions";

function CmpSelect({
  item,
  insertfunction,
  inline = false,
  customFormChangesList,
  customFormChangeItem,
  customFormFields,
  typeView,
  isHistorical,
}) {
  const {
    i18n: { language },
  } = useTranslation("global");

  const itemParent = useMemo(() => {
    const itemParent = customFormChangesList.find(
      (change) => change.customFormFieldId === item?.customFormFieldParentId
    );
    return itemParent;
  }, [item, customFormChangesList]);

  const {listOptions} = useChatVerification(item, itemParent, customFormFields, isHistorical);

  const handleFunction = (optionKey) => {
    const userToCustomFormField = {
      customFormFieldId: item.id,
      parentKey: item?.customFormTypeField.fieldOptions.find(
        (field) => field.key === optionKey
      ).parentKey,
      customFormFieldParentId: item?.customFormFieldParentId,
      value: P.dataTranslation(
        item?.customFormTypeField.fieldOptions.find(
          (field) => field.key === optionKey
        ).names,
        language
      ),
      key: optionKey,
    };
    insertfunction(userToCustomFormField);
  };

  return (
    <S.CmpSelect inline={inline}>
      <div className="title">
        {P.dataTranslation(item?.fieldName, language)}
        <Popup
          trigger={
            <i className="template-header-item-icon icon zaia-alert"></i>
          }
          on={["hover", "focus"]}
          closeOnDocumentClick
          keepTooltipInside
          position={["right center"]}
          nested
        >
          <PopUpPayload>
            <p>{P.dataTranslation(item.descript, language)}</p>
          </PopUpPayload>
        </Popup>
      </div>
      <div className="select-container">
        <ZaiaSelect
          type="select"
          name="phoneLocation"
          setValue={handleFunction}
          value={
            customFormChangeItem && customFormChangeItem.key
          }
          items={listOptions.map((field) => {
            return {
              value: field.key,
              text: P.dataTranslation(field.names, language),
            };
          })}
          shadow={true}
          disabled={typeView === "view"}
          padding={"20px"}
          borderRadius={"70px"}
          textcolor={"#FFFFFF"}
          backgroundColor={"#36369B"}
          borderColor={"transparent"}
          placeholdertextcolor={"#FFFFFF"}
          textItemsColor={"#36369B"}
          heightList={"200px"}
          fontSize="20px"
        />
      </div>
    </S.CmpSelect>
  );
}

export default CmpSelect;
