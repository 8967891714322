import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./DashboardAllPatients.style";
import { useStateValue } from "../../../../../contextAPI/StateProvider";

// Components
import NoPatients from "../../../shared/noPatients";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Components
import BoxInfoTimeFilter from "../boxInfoTimeFilter";
import FilterPrograms from "../../../shared/filterPrograms";
import SymptomsBoxInfo from "./components/symptomsBoxInfo";
import MedicinesBoxInfo from "./components/medicinesBoxInfo";

// GraphQL 
import { useQuery } from "@apollo/client";
import { QUERY_GET_USERS_WITH_PROCESS } from "../../../../../graphql/queries/User";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../graphql/queries/Process";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-data-loaded.svg`;

function DashboardAllPatients() {
  const [t] = useTranslation("global");
  const [symptomsTime, setSymptomsTime] = useState();
  const [medicinesTime, setMedicinesTime] = useState();
  const [processIds, setProcessIds] = useState([]);
  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES );   
  const [, dispatch] = useStateValue();

  const {data: usersData, error: usersError, loading: usersLoading} = useQuery(QUERY_GET_USERS_WITH_PROCESS);

  useEffect(() => {
    if(processIds.length === 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: processIds[0],
      });
    } else if (processIds.length === 0 || processIds.length > 1) {
      dispatch({
        type: "SET_CONTEXT_PROCESS",
        contextProcess: null,
      });
    }
  }, [processIds])

  if(usersLoading) 
    return (
      <S.DashboardAllPatients>
        <ZaiaReaction
          widthimg="150px"
          zaiaImg={logoZaiaLoading}
        />    
      </S.DashboardAllPatients>
    );

  if(usersError) 
    return (
      <S.DashboardAllPatients>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthimg="120px"
          text={t("chat.errorLoadingChats")}
          sizeText="15px"
          widthText="240px"
        />
      </S.DashboardAllPatients>
    );

  if (usersData?.user.length === 0)
    return (
      <S.DashboardAllPatients>
        <NoPatients 
          title={t("dashboard.boxes.noAlerts")}
          img={logoZaiaEmpty}
        />
      </S.DashboardAllPatients>
    );

  return (
    <S.DashboardAllPatients>
      <h3 className="rightSidePatient__title">
        {t("patients.patientList.generalInfo")}
      </h3>
      <FilterPrograms
        processIds={processIds}
        setProcessIds={setProcessIds}
        activeQuantity={false}
      />
      <div className="cards-container">
        <BoxInfoTimeFilter
          icon="zaia-icono-sintomas"
          title={t("globally.symptoms")}
          color="var(--blue-color)"
          colorTitle="var(--blue-color-200)"
          setTime={setSymptomsTime}
          time={symptomsTime}
        >
          <SymptomsBoxInfo icon="zaia-icono-sintomas" time={symptomsTime} processIds={processIds.map((process) => {return process.toString()})} allProcessIds={processData?.process.map((process) => {return process?.id.toString()})}/>
        </BoxInfoTimeFilter>
        <BoxInfoTimeFilter
          icon="zaia-icono-medicina"
          title={t("globally.medications")}
          color="var(--green-color)"
          colorTitle="var(--green-color-100)"
          setTime={setMedicinesTime}
          time={medicinesTime}
        >
          <MedicinesBoxInfo icon="zaia-icono-medicina" time={medicinesTime} processIds={processIds.map((process) => {return process.toString()})} allProcessIds={processData?.process.map((process) => {return process?.id.toString()})}/>
        </BoxInfoTimeFilter>
      </div>
    </S.DashboardAllPatients>
  );
}

export default DashboardAllPatients;
