import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ModalResultsAnalysis.style";
import * as P from "../../../../../utils/pipes.js";

// Hooks
import useDoctorData from "../../../../../hooks/useDoctorData";
import usePatientList from "../../../../../hooks/usePatientList";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const defaultUserImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`;

function ModalResultsAnalysis({
  setModalActive,
  data, 
  surveyData
}) {

  const {patientList} = usePatientList()

  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();

  return (
    <>
      <S.ModalResultsAnalysis
        hidden={false}
      >
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <div className="modal-reaction">
            <ZaiaReaction
              widthimg={"150px"}
              zaiaImg={logoZaiaLoading}
              padding={"200px 0px"}
            />
          </div>
        </div>
      </S.ModalResultsAnalysis>
      <S.ModalResultsAnalysis>
        <div className="modal-background" onClick={() => setModalActive(false)}>
          </div>
          <div className="modal-container">
            <div className="header">
              <div className="header__text">
                <div className="header__title">{
                  t("surveys.analysis.title")
                }</div>
                <div className="header__survey">
                  {t("globally.surveys")}: {P.dataTranslation(surveyData?.customForm[0].customFormName, language)}
                </div>
              </div>
              <div className="header__close" onClick={() => setModalActive(false)}>
                <i className="header__close-icon icon zaia-icono-cerrar"/>
              </div>
            </div>
            <div className="modal-content">
              <div className="modal-content__left">
                <div className="patient-info">
                  <div className="patient-info__left">
                    <div className="patient-info__image">
                      <img src={
                        // data?.user.picture
                        // ? data?.user.picture
                        // : 
                        defaultUserImg
                      } alt=""/>
                    </div>
                    <div className="patient-info__name">
                      { data?.user.name } { data?.user.lastname }
                    </div>
                  </div>
                  <div className="patient-info__right">
                    <div className="patient-info__date">
                      {t("globally.date")} {P.longDate(data?.date, t, language)}
                    </div>
                  </div>
                </div>
                <div className="dinamic-content">
                  <img 
                    src={`data:image/svg+xml;base64,${surveyData.customForm[0].userCustomFormHistories[0].dinamicContent}`} 
                    alt="" 
                  />
                </div>
              </div>
              <div className="modal-content__right">
                <img 
                  src={`data:image/svg+xml;base64,${surveyData?.customForm[0].staticContent}`} 
                  alt="" 
                />
              </div>
            </div>
          </div>
      </S.ModalResultsAnalysis>
    </>
  );
}

export default ModalResultsAnalysis;
