import styled from "styled-components";

export const OutcomesTable = styled.div`
  overflow: auto;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  border-radius: 10px;
  padding: 0 10px 0 10px;
  box-sizing: border-box;
  min-width: 800px;
  .illness-control-reaction {
    width: 100%;
    height: 100%;
    padding: 100px 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 15px;
    table-layout: auto;
    width: 100%;
    thead {
      padding: 10px;
      tr {
        th {
          /* border-bottom: 1.6px solid var(--no-notification-background); */
          color: var(--blue-color);
          color: #38379C;
          font-family: "Nunito Sans";
          font-size: 15px;
          padding: 18px 10px 15px 10px;
          text-align: left;
          font-weight: 400;
          text-align: center;
        }
        .th-event-type{
          font-size: 16px;
          font-family: "Nunito Sans Bold";
        }
      }
    }
    tbody {
      padding: 10px;
      i {
        /* display: inline; */
      }
      td:first-child,
      th:first-child {
        border-radius: 14px 0 0 14px;
      }
      td:last-child,
      th:last-child {
        border-radius: 0 14px 14px 0;
      }
      tr {
        .icon-save-form-field {
          /* margin-right: 10px;
          color: ${({theme}) => theme.inputSelected};
          cursor: pointer; */
        }
        /* .td-index0 {
          display: flex;
          align-items: center;
        } */
        .td-0 {
          width: 16%;
        }
        .td-1 {
          width: 59%;
        }
        .td-2 {
          width: 25%;
        }
        td {
          padding: 20px 10px;
          text-align: left;
          color: var(--blue-dark-color);
          font-family: "Nunito Sans";
          font-size: 15px;
          /* vertical-align: text-top; */
          .template-header-item-icon {
            color: var(--blue-color);
            font-size: 12px;
            margin-left: 10px;
          }
          .highlight-button {
            margin: 0 20px 0 10px;
            border-radius: 14px;
            border: 1px solid #AAAADB;
            color: #38379C;
            padding: 8px;
            cursor: pointer;
            &--active {
              border: none;
              padding: 9px;
              background-color: #FFA800;
              color: white;
            }
            .icon-save-form-field {
              display: flex;
            }
          }
          .event-info {
            display: flex;
            align-items: center;
            font-family: "Nunito ExtraBold";
            vertical-align: "middle";
            margin-right: 40px;
          }
          .event-type {
            &__name {
              color: #23234F;
              font-family: "Nunito Sans Light";
              font-size: 17px;
              margin-bottom: 5px;
              display: flex;
              align-items: center;
              .template-header-item-icon {
                color: #38379C;
                font-size: 13px;
              }
            }
            &__options {
              width: fit-content;
            }
            &__option {
              margin-top: 5px;
              cursor: pointer;
              font-family: "Nunito Sans";
              font-style: normal;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 15px;
              padding: 5px 15px;
              border-radius: 60px;
              background-color: #3E3DA3;
              color: #F7F6FC;
              text-align: center;
              white-space: nowrap;
              &-icon {
                font-size: 10px;
                display: flex;
                margin-left: 5px;
              }
            }
          }
          .outcomes-data {
            width: auto;
          }
          .changes-info {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #8A8AC3;
            .changes-item {
              color: #23234F;
              margin-right: 50px;
              opacity: 0.6;
              &__label {
                font-family: Nunito Sans Bold;
                font-size: 15px;
              }
              &__info {
                font-family: Nunito Sans;
                font-size: 15px;
              }
            }
          }
          .outcomes-list-container {
            overflow: hidden;
          }
          .outcomes-list {
            display: flex;
            flex-wrap: wrap;
            .detail { 
              margin-top: 20px;
              margin-right: 40px;
              &__label {
                display: flex;
                align-items: center;
                font-family: "Nunito Sans Bold";
                font-size: 15px;
                white-space: nowrap;
                color: #3E3E5B;
              }
              &__data {
                font-family: "Nunito Sans";
                font-size: 15px;
                color: #23234F;
                margin-top: 10px;
                align-items: center;
                display: flex;
                .file-value {
                  color:#6C6BCC;
                  display: flex;
                  align-items: center;
                  &__icon {
                    margin-right: 5px;
                    font-size: 15px;
                  }
                  &__text {
                    color:#6C6BCC;
                    font-size: 15px;
                    text-decoration: underline;
                  }
                }
              }
            }
          }
          .expand-list {
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
            background-color: #D2D2FF;
            color: #36369B;
            display: flex;
            align-items: center;
            border: none;
            outline: none;
            font-size: 14px;
            font-family: "Nunito Sans";
            cursor: pointer;
            margin-top: 20px;
            &__icon {
              margin-left: 10px;
            }
          }
          .comment-container {
            overflow: hidden;
            .comment-space {
              margin-left: 40px;
              text-overflow: ellipsis;
              &__title {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
                &-icon {
                  color: #707582;
                }
                &-text {
                  margin-left: 5px;
                  color: #3E3E5B;
                  font-family: "Nunito Sans SemiBold";
                }
              }
            }
          }
          .expand-comment {
            cursor: pointer;
            text-decoration: underline;
            margin-left: 40px;
            color: #6C6BCC;
          }
        }
        .illness-control-document-icon {
          margin-left: 19px;
          font-size: 17px;
          display: flex;
          cursor: pointer;
          color: var(--blue-color);
        }
      }
      tr:nth-child(odd) {
        background-color: var(--table-color);
        /* border-radius: 14px; */
      }
    }
  }

  @media only screen and (max-width: 840px) {
    padding: 0;
    .illness-control-reaction {
      padding: 100px 0;
      height: 0;
      position: fixed;
      width: 90%;
      display: flex;
      margin: auto;
    }
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Nunito';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`