  import styled from "styled-components";

  export const FilterPrograms = styled.div`
    display: flex;
    align-items: center;
    text-align: right;
    color: ${({theme}) => theme.onBackground};
    h5 {
      font-family: 'Nunito Bold';
      color: ${({theme}) => theme.onBackgroundVariant};
      margin-left: 5px;
      cursor: pointer;
      justify-content: end;
    } 
    p {
      white-space: nowrap;
    }
    .dropdown-container {
      padding: 10px;
      box-shadow: 0px 3px 8px ${({theme}) => theme.blackShadow}29;
      background-color: ${({theme}) => theme.background};
    }
    .dropdown-activer {
      color: ${({theme}) => theme.onBackgroundVariant};
      display: flex;
      align-items: center;
      user-select: none; 
      white-space: nowrap;
      p {
        font-family: 'Nunito SemiBold';
        color: red;
        font-size: 0.65rem;
      }
      i {
        font-size: 12px;
        display: flex;
        margin-left: 5px;
      }
    }
  `

export const DropdownContainer = styled.div`
  padding: 10px;
  box-shadow: 0px 3px 8px ${({theme}) => theme.blackShadow}29;
  background-color: ${({theme}) => theme.background};
  border-radius: 10px;
`