import styled from "styled-components";

export const CmpVerticalOptions = styled.div`
  border-radius: 10px;
  /* padding: 13px 10px 10px 10px; */
  h5 {
    font-family: "Nunito Sans Bold";
  }
  .boxes-container {
    /* max-height: 120px;
    overflow: auto; */
    margin-top: 8px;
  }
`;

export const Box = styled.div`
  padding: 5px 0px;
  width: 100%;
  box-sizing: border-box;
  .box {
    cursor: pointer;
    border-radius: 14px;
    background-color: ${({active}) => active ? '#DEDEFF' : '#F6F6FF'};
    justify-content: center;
    display: flex;
    width: 100%;
    height: 50px;
    font-family: "Nunito Sans";
    color: #3E3E5B;
    text-align: center;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    &-name {
      font-size: 17px;
      margin: auto auto auto 20px;
    }
    .box-icon {
      margin: auto 10px;
    }
  }
`

export const PopUpPayload = styled.div`
  background-color: var(--white);
  color: black !important;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
  border-radius: 5px;
  font-size: 'Nunito';
  max-width: 200px;
  p {
    color: black !important;
    padding: 8px;
  }
`