import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as S from "./ModalAddEventCalendar.style";
import * as P from "../../../../../utils/pipes";
import * as Yup from "yup";
import { ThemeContext } from "styled-components";

// Components
import ZaiaInputForm from "../../../../zaiaComponents/zaiaInputForm";
import ZaiaSelectForm from "../../../../zaiaComponents/zaiaSelectForm";
import ZaiaToggleCircular from "../../../../zaiaComponents/zaiaToggleCircular";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";

// graphQL
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_GET_USER_BY_ID } from "../../../../../graphql/queries/User";
import { MUTATION_INSERT_USER_TO_PROCESS_CALENDAR, MUTATION_UPDATE_USER_TO_PROCESS_CALENDAR } from "../../../../../graphql/mutations/Calendar";

// hooks
import usePatientList from "../../../../../hooks/usePatientList";
import { MUTATION_DELETE_USER_TO_PROCESS_CALENDAR } from "../../../../../graphql/mutations/UserToProcessCalendar";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction/ZaiaReaction";
import ZaiaModalResponseContent from "../../../../zaiaComponents/zaiaModalResponseContent";

const defaultUserImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`;
const zaiaImgLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;

function ModalAddEventCalendar({ 
  setCreateEventCalendarActive,
  setCardEventSelected,
  event,
  patientID
}) {

  const [sendEventNotification, setSendEventNotification] = useState(event ? null : true);
  const [notificationAccepted, setNotificationAccepted] = useState(); 
  const [editionEvent, setEditionEvent] = useState(false);
  const [editionEventId, setEditionEventId] = useState();
  const [defaultEventInfo, setDefaultEventInfo] = useState({
    name: null,
    descript: null,
    place: null,
    patient: patientID,
    userToProcessId: null,
    date: null,
    time: null,
  });
  const { t } = useTranslation("global");

  const [insertUserToProcessCalendar, { loading: insertUserToProcessCalendarLoading }] = useMutation(MUTATION_INSERT_USER_TO_PROCESS_CALENDAR);
  const [updateUserToProcessCalendar, { loading: updateUserToProcessCalendarLoading }] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_CALENDAR);
  const [formSuccess, setFormSuccess] = useState(null);
  const [successText, setSuccessText] = useState("");
  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    
    if(event) {
      setDefaultEventInfo(
        {
          name: event.name,
          descript: event.descript,
          place: event.place,
          patient: event.userId,
          userToProcessId: event.userToProcessId,
          date: P.formatNumberDateInput(event.dateTime),
          time: P.formatNumberTimeInput(event.dateTime),
        }
      )
      setSendEventNotification(event.isNotify);
      setNotificationAccepted(event.isAccepted)
      setEditionEventId(event.id)
      setEditionEvent(true);
    }
  }, [event])


  const handleSubmit = async (values, { resetForm }) => {
    if(editionEvent) {
      try {
        // eslint-disable-next-line no-unused-vars
          await updateUserToProcessCalendar({
            variables: {
              eventId: editionEventId,
              objects: {
                dateTime: new Date(values.date + 'T' + values.time).getTime(),
                descript: values.descript,
                isNotify: sendEventNotification,
                name: values.name,
                place: values.place,
                userToProcessId: values.userToProcessId,
              }
            },
          });
        resetForm();
        event && setCardEventSelected(false);
        setFormSuccess(true);
        setSuccessText(t("calendar.form.successUpdate"))
      } catch (err) {
        setFormSuccess(false);
        setErrorText(t("calendar.form.errorUpdate"))
        console.log(err);
      }
    } else {
      try {
        // eslint-disable-next-line no-unused-vars
          await insertUserToProcessCalendar({
            variables: {
               objects: {
                dateTime: new Date(values.date + 'T' + values.time).getTime(),
                descript: values.descript,
                // isAccepted: null,
                isNotify: sendEventNotification,
                name: values.name,
                place: values.place,
                userId: values.patient,
                userToProcessId: values.userToProcessId,
              }
            },
          });
        resetForm();
        event && setCardEventSelected(false);
        setFormSuccess(true);
        setSuccessText(t("calendar.form.successCreate"))
      } catch (err) {
        console.log(err);
        setFormSuccess(false);
        setErrorText(t("calendar.form.errorCreate"))
      }
    }
  }

  useEffect(() => {
    function handleKeyDown(e) {
      // console.log(e.keyCode);
      // console.log(e);
      if (e.keyCode === 27) {
        setCreateEventCalendarActive(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const status = [
    {
      value: null,
      text: t("globally.accepted"),
      color: "#CFAB29"
    },
    {
      value: true,
      text: t("globally.pending"),
      color: "#53C25D"
    },
    {
      value: false,
      text: t("globally.rejected"),
      color: "#FE4545"
    }
  ]

  const [deleteUserProcessCalendar] = useMutation(
    MUTATION_DELETE_USER_TO_PROCESS_CALENDAR
  );

  const deleteEvent = async () => {
    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data,
      } = await deleteUserProcessCalendar({
        variables: {
          eventId: event.id,
        },
      });
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };


  if(
    insertUserToProcessCalendarLoading
    || updateUserToProcessCalendarLoading
  ) 
    return(
      <S.ModalAddEventCalendar>
        <div className="modal-background">
        </div>
        <div className="modal-container">
          <ZaiaReaction
            widthimg={"150px"}
            zaiaImg={zaiaImgLoading}
            padding={"200px 0px"}
          />
        </div>
      </S.ModalAddEventCalendar>
    )

  if(formSuccess)
    return(
      <S.ModalAddEventCalendar>
        <div className="modal-background" onClick={() => event ? setCardEventSelected(false) : setCreateEventCalendarActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setFormSuccess(null)}
            button1Text={t("globally.done")}
            formSuccess={formSuccess}
            title={successText}
          />
        </div>
      </S.ModalAddEventCalendar>
    )

  if(formSuccess === false)
    return(
      <S.ModalAddEventCalendar>
        <div className="modal-background" onClick={() => event ? setCardEventSelected(false) : setCreateEventCalendarActive(false)}>
        </div>
        <div className="modal-container">
          <ZaiaModalResponseContent
            button1={() => setFormSuccess(null)}
            formSuccess={formSuccess}
            button1Text={t("globally.accept")}
            title={t("globally.errorForm")}
            subtitle={errorText}
          />
        </div>
      </S.ModalAddEventCalendar>
    )

  return (
    <S.ModalAddEventCalendar>
      <div className="modal-background" onClick={() => event ? setCardEventSelected(false) : setCreateEventCalendarActive(false)}>
      </div>
      <div className="modal-container">
        {/* <header className="settings__header">
          <div className="settings__header-title">
            <div className="settings__header-title-icon">
              <i className="zaia-icono-calendar"></i>
            </div>
            <h4>{t("calendar.form.title")}</h4>
          </div>
        </header> */}
        <div className="settings__container">
          <Formik
            initialValues={defaultEventInfo}
            validationSchema={
              Yup.object().shape(
                {
                  name: Yup.string()
                    .required(),
                  descript: Yup.string()
                    .required(),
                  place: Yup.string()
                    .required(),
                  patient: Yup.string()
                    .required(),
                  userToProcessId: Yup.string()
                    .required(),
                  date: Yup.string()
                    .required(),
                  time: Yup.string()
                    .required(),
                },
              )
            }
            enableReinitialize={true}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <form className="event-calendar-form" onSubmit={ props.handleSubmit }>
                <EventCalendarInputs 
                  patientID={patientID ? patientID : props.values?.patient}
                  setCreateEventCalendarActive={setCreateEventCalendarActive}
                  setSendEventNotification={setSendEventNotification}
                  sendEventNotification={sendEventNotification}
                  patientIDInput={patientID}
                  event={event}
                  setCardEventSelected={setCardEventSelected}
                  editionEvent={editionEvent}
                />
                <div className="buttons-container">
                  {
                    ((!event && !editionEvent)) ?
                    <div className="toogle-container">
                      <span>{t("calendar.form.alertPatient")}</span>
                      <ZaiaToggleCircular
                        id={'patient-alert-toggle'}
                        color={"#7877BF"}
                        toggle={setSendEventNotification}
                        active={sendEventNotification}
                      />
                    </div>
                    : <div className="bottom-status">
                      <div className="bottom-status__point"
                        style={{backgroundColor: status.find(stat => stat.value === notificationAccepted)?.color}}
                      ></div>
                      <div className="bottom-status__text">
                        {
                          status.find(stat => stat.value === notificationAccepted)?.text
                        }
                      </div>
                      {/* <i className="bottom-status__icon zaia-vector"/> */}
                    </div>
                  }
                  {
                    event
                    ? <div className="button-options">
                      <button className={"button-option"} type="submit" onClick={() => deleteEvent()}>
                        <i className="button-option__icon icon zaia-i-trash-can"/>
                        <div className="button-option__text">
                          {t("globally.delete")}
                        </div>
                      </button>
                      <button className={"button-option button-option--margin-left"} type="submit" onClick={() => {setCreateEventCalendarActive(true); setCardEventSelected(false)}}>
                        <i className="button-option__icon icon zaia-i-pen"/>
                        <div className="button-option__text">
                          {t("globally.edit")}
                        </div>
                      </button>
                    </div>
                    : <button className={`button-submit ${!(props.isValid && (editionEvent ? true : props.dirty)) && "button-submit--disabled"}`} type="submit">{editionEvent ? t("globally.save") : t("globally.create")}</button>
                  }
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </S.ModalAddEventCalendar>
  );
}

const EventCalendarInputs = ({ 
  patientIDInput,
  patientID,
  processID,
  setCreateEventCalendarActive,
  setSendEventNotification,
  sendEventNotification,
  event,
  setCardEventSelected,
  editionEvent
}) => {

  const { data: userData } = useQuery(QUERY_GET_USER_BY_ID, {
    variables: { patientId: patientID },
  });
  const { t, i18n: { language } } = useTranslation("global");

  const {patientList} = usePatientList()

  return (

    <div className="event-calendar-form__inputs">
      <div className="title-container">
        <ZaiaInputForm
          placeholder={t("calendar.form.eventName")}
          type="text"
          name="name"
          backgroundColor="#EFF3F6"
          textcolor="#36369B"
          placeholdertextcolor="#888888"
          activeBorder={false}
          fontSize={"27px"}
          height={"57px"}
          errorOption={false}
          borderRadius={"14px"}
          padding={"10px"}
          disabled={event && editionEvent}
        />
        <button className="title-container__close-button" type="button" onClick={() => event ? setCardEventSelected(false) : setCreateEventCalendarActive(false)}>
          <i className="title-container__close-button-icon icon zaia-icono-cerrar"></i>
        </button>
      </div>
      <div className="event-info">
        <div className="event-info__time">
          <i className="event-info__time-icon icon zaia-timeline"/>
          <div className="input-content">
            <span className="input-content__label-bold">{t("globally.eventDate")}</span>
            <ZaiaInputForm
              placeholder={''}
              type="date"
              name="date"
              backgroundColor="#EFF3F6"
              textcolor="#36369B"
              placeholdertextcolor="#888888"
              activeBorder={false}
              fontSize={"17px"}
              height={"33px"}
              errorOption={false}
              borderRadius={"4px"}
              padding={"5px 10px"}
              disabled={event && editionEvent}
            />
          </div>
          <div className="input-content input-content--margin-left">
            <span className="input-content__label-bold">{t("globally.eventTime")}</span>
            <ZaiaInputForm
              placeholder={''}
              type="time"
              name="time"
              backgroundColor="#EFF3F6"
              textcolor="#36369B"
              placeholdertextcolor="#888888"
              activeBorder={false}
              fontSize={"17px"}
              height={"33px"}
              errorOption={false}
              borderRadius={"4px"}
              padding={"5px 10px"}
              disabled={event && editionEvent}
            />
          </div>
        </div>
        <div className="event-info__separator"/>
        <div className="event-info__patient">
          <div className="event-info__patient-image">
            <ZaiaImage 
              defaultImg={defaultUserImg}
              img={patientIDInput ? patientList?.find(patient => patient.id === patientIDInput)?.picture : patientList?.find(patient => patient.id === patientID)?.picture}
              rounded={true}
              alt={"asdfasdf"}
              size={30}
            />
          </div>
          <div className="input-content">
            <span className="input-content__label-bold">{t("globally.patient")}</span>
            <ZaiaSelectForm
              placeholder={t("globally.selectPatient")}
              disabled={Boolean(patientIDInput) || (editionEvent ? true : false)}
              type="text"
              name="patient"
              backgroundColor="#EFF3F6"
              textcolor="#36369B"
              placeholdertextcolor="#888888"
              activeBorder={false}
              fontSize={"17px"}
              height={"33px"}
              errorOption={false}
              borderRadius={"4px"}
              maxHeightList={"200px"}
              padding={"5px 10px"}
              items={
                patientList?.map(
                  patient => {
                    return {
                      value: patient.id, 
                      text: `${patient.name} ${patient.lastname}`
                    }
                  }
                ).sort(function(a,b){
                  return a.text.localeCompare(b.text);
                })
              }
            />
          </div>
          <div className="input-content input-content--margin-left">
            <span className="input-content__label-bold">{t("calendar.form.eventProcessPatient")}</span>
            <ZaiaSelectForm
              placeholder={t("globally.selectProgram")}
              disabled={!Boolean(patientID) || (event && editionEvent ? true : false)}
              name="userToProcessId"
              backgroundColor="#EFF3F6"
              textcolor="#36369B"
              placeholdertextcolor="#888888"
              activeBorder={false}
              fontSize={"17px"}
              height={"33px"}
              errorOption={false}
              borderRadius={"4px"}
              maxHeightList={"200px"}
              padding={"5px 10px"}
              items={
                userData?.user[0]?.userToProcesses?.map(
                  user => {
                    return {
                      value: user?.id, 
                      text: `${P.dataTranslation(user?.process?.processName, language)}`
                    }
                  }
                ).sort(function(a,b){
                  return a.text.localeCompare(b.text);
                })
              }
            />
          </div>
        </div>
      </div>
      <div className="event-ubication">
        <i className="event-ubication__icon icon zaia-i-location"/>
        <div className="input-content">
          <span className="input-content__label-bold">{t("calendar.form.eventPlace")}</span>
          <ZaiaInputForm
            placeholder={t("calendar.form.typeLocation")}
            type="text"
            name="place"
            backgroundColor="#EFF3F6"
            textcolor="#36369B"
            placeholdertextcolor="#888888"
            activeBorder={false}
            fontSize={"17px"}
            height={"33px"}
            errorOption={false}
            borderRadius={"4px"}
            padding={"5px 10px"}
            disabled={event && editionEvent ? true : false}
          />
        </div>                 
      </div>
      <div className="input-content">
        <span className="input-content__label-bold">{t("globally.eventDescription")}</span>
        <ZaiaInputForm
          type="text" 
          name="descript"
          placeholder={t("globally.eventDescription")}
          textarea={true}
          backgroundColor="#EFF3F6"
          textcolor="#36369B"
          placeholdertextcolor="#888888"
          activeBorder={false}
          fontSize={"17px"}
          height={"153px"}
          errorOption={false}
          borderRadius={"4px"}
          padding={"10px"}
          disabled={event && editionEvent ? true : false}
        />
      </div>
      
    </div>
  )
}

export default ModalAddEventCalendar;
