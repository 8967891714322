import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./MedicinesLogCard.style";
import "react-alice-carousel/lib/alice-carousel.css";

// pipes
import * as P from "../../../../../utils/pipes";
import Popup from "reactjs-popup";
import { useMutation } from "@apollo/client";
import { MUTATION_DELETE_USER_TO_DRUG } from "../../../../../graphql/mutations/Drugs";

const MedicinesLogCard = ({
  medicinesToChart,
  time,
  activeHeader=true,
  updateUserToDrug
}) => {
  const { t } = useTranslation("global");

  return (
    <S.MedicinesLogCard
      activeHeader={activeHeader}
      timeOp={time.timeOp}
      intervalsQuantity={medicinesToChart.frequencyTimes?.length}
    >
      <div className="medicines-log-card">
        { activeHeader ? 
          <div className="medicines-log-card__header">
            <div className="medicines-log-card__header-text">
              <div className="medicines-log-card__header-text-title">
                <Popup
                  trigger={
                    <div className="medicines-log-card__header-text-title-icon">
                      <i className="icon zaia-icono-dots-vertical"/>
                    </div>
                  }
                  keepTooltipInside
                  nested
                >
                  <CardOptions
                    item={medicinesToChart}
                    updateUserToDrug={updateUserToDrug}
                  />
                </Popup>
                <h4>
                  {medicinesToChart.name} {medicinesToChart.dose.cod}
                </h4>
              </div>
              <p>{medicinesToChart.quantity} {t('patients.boxInfoPatient.report.every')} {medicinesToChart.frequency} {P.firstLetterLower(medicinesToChart.frequencyType)}</p>
            </div>
            {
              medicinesToChart.ubicationInTreatment === 'after' ? null : 
              <div className="medicines-log-card__header-days">
                <p>{medicinesToChart.quantityMedicationDays}/{medicinesToChart.quantityMedicationLogDays} { t('globally.reports') }</p>
              </div>
            }
          </div> : ""
        }
        <div className="medicines-log-card__bars-container">
          { medicinesToChart.ubicationInTreatment === 'after' ?
            <h5>{t("patients.boxInfoPatient.report.finishedMedicine")}</h5> : 
            <>
              <div className="medicines-log-card__bars-container-intervals disable-select">
                {
                  medicinesToChart.frequencyTimes?.map(time => {
                    return <div className="medicines-log-card__bars-container-interval" key={time}>{P.hoursIn12format(time.getHours(),  time.getMinutes())}</div>
                  })
                }
              </div>
              <div className="medicines-log-card__bars-container-bars">
                {
                  medicinesToChart.medicationToChart.map((info, index) => {
                    return <CheckboxMedicine 
                      name={info.name} 
                      active={info.active} 
                      timeOp={time.timeOp} 
                      date={info.date} 
                      key={index} 
                      intervalsQuantity={medicinesToChart.frequencyTimes?.length}
                      frequencyStates={info.frequencyStates}
                    />
                  }) 
                }
              </div>
            </>
          }
        </div>
        <div className="medicines-log-card__footer-indicator">
          <div className="type-indicator">
            <div className="type-indicator__point type-indicator__point--green"></div>
            <div className="type-indicator__text">{t("patients.boxInfoPatient.medicines.taken")}</div>
          </div>
          <div className="type-indicator">
            <div className="type-indicator__point type-indicator__point--grey">
              <i className="type-indicator__point-cross icon zaia-icono-cerrar"/>
            </div>
            <div className="type-indicator__text">{t("patients.boxInfoPatient.medicines.noTaken")}</div>
          </div>
          <div className="type-indicator">
            <div className="type-indicator__point type-indicator__point--grey"></div>
            <div className="type-indicator__text">{t("patients.boxInfoPatient.medicines.noReport")}</div>
          </div>
        </div>
      </div>
    </S.MedicinesLogCard>
  );
};

const CheckboxMedicine = ({name, active, timeOp, date, intervalsQuantity, frequencyStates}) => {

  return (
    <S.CheckboxMedicine
      active={active}
      timeOp={timeOp}
      intervalsQuantity={intervalsQuantity}
    >
      <div className="vertical-progress-bar">
        {
          frequencyStates?.map((frequencyState, index) => {
            return (
              <VerticalBoxesMedicines active={frequencyState} timeOp={timeOp} key={index}/>
            )
          })
        }
      </div>
      <div className="vertical-text disable-select">
        <p className="vertical-text__time">{name}</p> 
        {
          timeOp === 'week' ?
          <p className="vertical-text__time">{new Date(date).getDate()}</p> : null
        }
      </div>
    </S.CheckboxMedicine>
  )
}

const VerticalBoxesMedicines = ({active, timeOp}) => {
  
  return(
    <S.VerticalBoxMedicine
      active={active}
      timeOp={timeOp}
    >
      { 
        active === 'noTakeIt' ? 
        <i className="icon zaia-icono-cerrar"></i>
        : null
      }
    </S.VerticalBoxMedicine>
  )
} 

const CardOptions = ({item, updateUserToDrug}) => {

  const { t } = useTranslation("global");
  const [deleteUserToDrug, {deleteUserToDrugLoading, deleteUserToDrugError}] = useMutation(
    MUTATION_DELETE_USER_TO_DRUG
  );

  const deleteItem = async (item) => {
    try {
      const {
        // eslint-disable-next-line no-unused-vars
        data,
      } = await deleteUserToDrug({
        variables: {
          userToDrugId: item.id,
        },
      });
      updateUserToDrug(item);
      console.log(data);
    } catch (err) {
      console.log(err);
    }    
  }

  return (
    <S.CardOptions>
      <div 
        className="option" 
        onClick={() => deleteItem(item)}
      >
        <i className="option__icon icon zaia-icono-borrar"></i>
        <div className="option__text">
          {t("globally.delete")}
        </div>
      </div> 
    </S.CardOptions>
  )
}

export default MedicinesLogCard;
