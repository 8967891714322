import styled from "styled-components";
import { getHexOpacity } from "../../../utils/pipes";

export const DashboardDiv = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(243, 243, 252);
  .header-patient-container {
    padding: 20px 30px 25px;
  }
  .alerts-container {
    height: calc(100% - 105.44px);
    display: flex;
    position: relative;
    z-index: 200;
    width: 100%;
    position: relative;
    position: relative;
    padding: 0px 33px 41px 33px;
    box-sizing: border-box;
    &__box {
      border-radius: 14px;
      overflow: auto;
      width: 100%;
      height: 100%;
      background-color: #FFFFFF;
    }
  }
  .dashboard__container {
    width: 100%;
    height: 100%;
    display: flex;
  }
  .actions-list {
    display: flex;
    height: 100%;
    box-sizing: border-box;
  }
  .dashboard__container-right{
    position: relative;
    width: calc(100% - 282px);
    .dashboard__container-right-modal {
      background-color: ${({theme}) => `${theme.background}${getHexOpacity(0.8)}`};
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      display: flex;
      .box-container {
        margin: auto;
        width: 49%;
        display: flex;
        flex-direction: column;
        min-width: 400px;
        justify-content: center;
        .buttons-box-container {
          .buttons-box-patient {
            display: flex;
            justify-content: space-between;
            .button-box-patient {
              width: 48%;
            }
            &--center {
              justify-content: center;
            }
          }
          .phone-number-container {
            /* height: 43px; */
            width: 212px;
            position: relative;
            margin: 30px auto 0;
            .contact-info {
              background-color: ${
                ({
                  theme,
                }) => 
                  theme.background
              };
              border-radius: 10px;
              box-shadow: ${
                ({theme}) => 
                `3px 3px 5px ${theme.blackShadow}29`
              };
              &-box {
                padding: 10px 15px 7px 15px;
                &--emergency{ 
                  border-top: solid 1px ${({theme}) => theme.lineSeparation};
                  padding: 7px 15px 10px 15px;
                }
                .label {
                  font-size: 12px;
                  font-family: 'Nunito Bold';  
                  color: ${({theme}) => theme.primaryColor};
                  &--emergency{ 
                    color: ${({theme}) => theme.primaryVariantColor};
                  }
                }
                .name {
                  font-family: 'Nunito';
                  font-size: 15px;  
                  color: ${({theme}) => theme.primaryVariantColor};
                }
                .number {
                  font-family: 'Nunito ExtraBold';  
                  font-size: 18px;
                  color: ${({theme}) => theme.primaryColor};
                  &--emergency{ 
                    color: ${({theme}) => theme.primaryVariantColor};
                  }
                }
              }
            }
          }
          .buttons-box-close {
            width: 35%;
            margin: auto;
          }
        }
      }
    }
    .dashboard__container-right-content{
      padding: 36px 53px 0 62px;
      filter: ${({selectedBox}) => selectedBox ? "blur(4px)" : "none"};
      height: 100%;
      box-sizing: border-box;
      .text-container{
        margin-bottom: 61px;
        margin-left: 5px;
        &__title {
          font-size: 32.25px;
          font-family: 'Nunito Sans Bold';
          color: #3E3DA3;
        }
        &__subtitle {
          /* display: flex; */
          font-size: 18px;
          color: #68688D;
          b {
            margin-left: 5px;
          }
        }
      }
      .severity-filtered {
        margin-bottom: 20px;
        display: flex;
        font-size: 16px;
        color: #68688D;
        width: fit-content;
        align-items: center;
        height: 17.23px;
        &__close-button {
          margin-left: 10px;
          background-color: #E6E6E6;
          border-radius: 50%;
          width: 17.23px;
          height: 17.23px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          cursor: pointer;
          &-icon {
            color: #8C8DA4;
            font-size: 6px;
          }
        }
      }
      .dashboard__notifications {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        align-items: center;
      }
      .dashboard__filters {
        display: flex;
        position: relative;
        width: calc(100% - 150px);
        overflow: auto;
        mask-image: linear-gradient(90deg, #000 90%, transparent);
        ::-webkit-scrollbar {
          display: none;
        }
        /* flex-wrap: wrap; */
        .notification-container {
          display: flex;
          padding: 2px 0;
        }
        .notification-list-end {
          padding: 0px 10px;
          background-color: transparent
        }
      }
      .dashboard__filter-by {
        display: none;
        width: 140px;
        margin-left: 10px;
        .dropdown-activer {
          color: ${({theme}) => theme.onBackgroundSoft};
          display: flex;
          align-items: center;
          user-select: none; 
          white-space: nowrap;
          p {
            font-family: 'Nunito Semibold';
            font-size: 16px;
            /* color: ${({theme}) => theme.onBackgroundSoft} */
          }
          i {
            font-size: 18px;
            display: flex;
            margin-left: 5px;
          }
        }
      }
      .dashboard__filter-programs {
        margin-left: 10px;
        width: 140px;
      }
      .dashboard__boxes-container {
        display: flex;
        height: 100%;
        height: calc(100% - 194.62px);
        overflow: hidden;
        .dashboard__boxes-area {
          overflow: hidden;
          width: 100%;
          display: flex;
        }
        .dashboard__boxes-filter {
          width: 60px;
          padding-left: 20px;
        }
      }
    }
  }

  .dashboard__input-patient-calendar {
    display: none;
    width: 100%;
    &-input-container {
      display: flex; 
      padding: 10px 0px 30px;
      width: calc(100% - 25px);
    }
    &-trigger-button {
      height: 40px;
      width: 45px;
      margin-top: 7px;
      border-radius: 10px;
      margin-left: 25px;
      background-color: ${({theme}) => theme.primarySectionCalendar};
      display: flex;
      cursor: pointer;
      &-icon {
        margin: auto;
        color: ${({theme}) => theme.onPrimaryColor};
        display: flex;
        font-size: 20px;
      }
    }
  }


  .dashboard__container-left {
    position: relative;
    /* border-left: 1px solid ${({theme}) => theme.lineSeparation}; */
    box-sizing: border-box;
    height: 100%;
    z-index: 100;
  }

  .invite-modal {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  @media only screen and (max-width: 1024px) {
    .dashboard__container-right-modal {
      .box-container {
        width: 500px !important;
      }
    }
    .dashboard__container-right {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .dashboard__container-right{
      width: 100%;
    }
    .dashboard__container-right {
      display: ${({rightSideActive}) => rightSideActive ? "block" : "none"};
      width: auto;
      position: absolute;
      border-left: 1px solid ${({theme}) => `${theme.background}`};
      right: 0;
      box-sizing: border-box;
      height: 100%;
      background-color: white;
      box-shadow: ${
            ({theme}) => 
            `4px -13px 16px ${theme.blackShadow}29`};
      box-sizing: border-box;
      /* border-top-left-radius: 30px;
      border-bottom-left-radius: 30px; */
    }
    .dashboard__input-patient-calendar {
      display: flex;
    }
    .dashboard__boxes-filter {
      display: none;
    }
    .dashboard__boxes-area {
      width: 100% !important;
    }
    .dashboard__filters {
      display: none !important;
    }
    .dashboard__filter-programs {
      display: none; 
    } 
    .dashboard__filter-by {
      display: block !important;
    }
    .dashboard__boxes-container {
      height: calc(100% - 150px - 67px) !important;
    }
  }

  @media only screen and (max-width: 500px) {
    .dashboard__container-left-modal {
      .box-container {
        margin: auto 15px !important;
        min-width: initial !important;
      }
    }
  }
  
  @media only screen and (max-width: 375px) {
    .buttons-box-patient {
      display: block !important;
    }
    .button-box-patient {
      width: 100% !important;
    }
  }
`;

export default DashboardDiv;
