import React, { useEffect, useState } from "react";
import * as S from  "./GraphNumericSurvey.style";
import { useTranslation } from "react-i18next";
import ReactApexChart from "react-apexcharts";
import * as P from "./../../../../../../utils/pipes"

function GraphNumericSurvey({
  lineGraphData,
  markersGraphData,
  lineGraphInfo,
  markersGraphInfo
}) {

  const { i18n: { language }, t } = useTranslation("global");

  const initialSeries = [
    {
      name: 'area',
      type: 'area',
      data: lineGraphData
    },
    {
      name: 'area',
      type: 'area',
      data: lineGraphData
    }
  ];

  const initialSeriesLine = [
    {
      name: 'area',
      type: 'area',
      data: lineGraphData
    },
    {
      name: 'area',
      type: 'area',
      data: lineGraphData
    }
  ];

  const [series, setSeries] = useState(initialSeries);
  const [seriesLine, setSeriesLine] = useState(initialSeriesLine);
  const [options, setOptions] = useState(); 

  useEffect(() => {

    const highValue = Math.max(...lineGraphData?.map((info) => { return Number(info[1]) }));

    const markersIntoSeries = markersGraphData?.map((marker, index) => {
      return (
        {
          name: 'Points',
          type: 'scatter',
          data: marker?.map((value) => {
            return(
              [
                value[0], 
                highValue + ((index + 1) * 0.5)
              ]
            )
          })
        }
      )
    })

    const markersIntoSeriesLine = markersGraphData?.map((marker, index) => {
      return (
        {
          name: 'Points',
          type: 'scatter',
          data: marker?.map((value) => {
            return(
              [ 
                value[0], 
                highValue + ((index + 1) * 0.5)
              ]
            )
          })
        }
      )
    })

    setSeries([...initialSeries, ...markersIntoSeries]);
    setSeriesLine([...initialSeriesLine, ...markersIntoSeriesLine])
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    lineGraphData,
    markersGraphData,
    lineGraphInfo,
    markersGraphInfo,
    setSeries,
    setSeriesLine
  ])


const handleTooltip = (series, seriesIndex, dataPointIndex) => {
  let title;
  let color;
  let label;
  let values;

  if(seriesIndex === 1 || seriesIndex === 0) {
    color = '#3E8DF3';
    const lineInfo = lineGraphInfo[dataPointIndex]; 
    title = lineInfo?.customFormName;
    label = lineInfo?.customFormFieldName;

    return '<div class="tooltip-chart">' +
      '<div class="tooltip-chart__header">' +
        title +
      '</div>' +
      '<div class="tooltip-chart__content">' +
        '<div class="tooltip-chart__point" style="background-color:' + 
        color +
        '"></div>' +
        '<div class="tooltip-chart__name">' +
          label +
        '</div>' +
        '<div class="tooltip-chart__value">' +
          series[seriesIndex][dataPointIndex] +
        '</div>' +
        // '<div class="tooltip-chart__measure">' +
        //   "mg" +
        // '</div>' +
      '</div>' +
      '<div class="tooltip-chart__date">' +
        '<i class="zaia-i-clock icon zaia-icono-cerrar"></i>' +
        '<div class="date-label">' +
          t("globally.date") +
        '</div>' +
        '<div class="date-text">' +
          P.formatNumberDate(lineInfo?.date, true, language) +
        '</div>' +
      '</div>' +
      ( 
        lineInfo?.comment
      ? (
          '<div class="tooltip-chart__comment">' +
            '<div class="comment-title">' +
              '<i class="comment-icon icon zaia-i-message-outline"></i>' +
              '<div class="comment-label">' +
                t("globally.comments") +
              '</div>' +
            '</div>' +
            '<div class="comment-text">' +
            lineInfo?.comment +
            '</div>' +
          '</div>'
        )
        : "" 
      ) +
    '</div>'
  } else if (seriesIndex > 1) {
    const markerInfo = markersGraphInfo[seriesIndex - 2][dataPointIndex];
    title = markerInfo?.customFormFieldName;
    values = markerInfo?.values;
    color = markerInfo?.color;

    return '<div class="tooltip-chart">' +
      '<div class="tooltip-chart__header">' +
        title +
      '</div>' +
      '<div class="tooltip-chart__content">' +
        '<div class="tooltip-chart__point" style="background-color:' + 
          color +
        '"></div>' +
        '<div class="tooltip-chart__name-box">' +
          values?.map((item) => {
            return '<div class="tooltip-chart__name">' +
              item.label +
              ': ' +
              '<b>' +
                item.value +
              '</b>' + 
            '</div>'
          }).join("") +
        '</div>' +
      '</div>' +
      '<div class="tooltip-chart__date">' +
        '<i class="zaia-i-clock icon zaia-icono-cerrar"></i>' +
        '<div class="date-label">' +
        t("globally.date") +
        '</div>' +
        '<div class="date-text">' +
          P.formatNumberDate(markerInfo?.date, true, language) +
        '</div>' +
      '</div>' +
      ( 
        markerInfo?.comment
      ? (
          '<div class="tooltip-chart__comment">' +
            '<div class="comment-title">' +
              '<i class="comment-icon icon zaia-i-message-outline"></i>' +
              '<div class="comment-label">' +
                t("globally.comments") +
              '</div>' +
            '</div>' +
            '<div class="comment-text">' +
              markerInfo?.comment +
            '</div>' +
          '</div>'
        )
        : "" 
      ) +
    '</div>'
  }
}

const changeOptions = async () => {

  const tempOptions = {
    series: series,
    options: {
      chart: {
        id: 'chart2',
        // height: 400,
        type: ['area', "area", 
          ...markersGraphInfo.map(() => {return "scatter"})
        ],
        animations: {
          enabled: false,
        }
        // width: [1 ,1 ,1]
      },
      grid: {
        yaxis: {
          lines: {
            show: false
          }
        }, 
      },
      dataLabels: {
        // enabled: [true, false]
        enabled: true,
        enabledOnSeries: [1],
        offsetY: -7,
        style: {
          colors: ['#000', '#3E8DF3', 
            ...markersGraphInfo.map(() => {return '#000'})
          ],
        }
      },
      colors: ['', '#3E3DA9'],
      stroke: {
        width: [0, 1,
          ...markersGraphInfo.map(() => {return 0})
        ],
        curve: 'straight'
      },
      plotOptions: {  
        bar: { 
          columnWidth: 20
        },
      },
      fill: {
        type: ['solid', 'gradient', 
          ...markersGraphInfo.map(() => {return 'solid'})
        ],
        gradient: {
          opacityFrom: 1,
          opacityTo: 0.8,
        },
        opacity: 1,
        colors: ['#FFF', '#F0EDFF']
      },
      markers: {
        size: [0, 7, 
          ...markersGraphInfo.map(() => {return 8})
        ],
        colors: ['#fff', '#fff',
          ...markersGraphInfo.map((marker) => {return marker[0]?.color ?? "#FF6D2A"})
        ],
        strokeColors: ['#FFF', '#3E8DF3'],
        strokeWidth: [2,2,
          ...markersGraphInfo.map(() => {return 0})
        ],
        hover: {
          size: 10
        }
      },
      tooltip: {
        shared: false,
        intersect: true,
        custom: ({series, seriesIndex, dataPointIndex, w}) => handleTooltip(series, seriesIndex, dataPointIndex)
      },
      legend: {
        show: false
      },
      yaxis: {
        labels: {
          show: false,
        }
      },
      xaxis: {
        type: 'datetime',
        // min: 0,
        // max: 12,
        tickAmount: 12
      }, 
  
    },
    seriesLine: seriesLine,
    optionsLine: {
      chart: {
        id: 'chart1',
        height: 130,
        type: ['area', "area", 
          ...markersGraphInfo.map(() => {return "scatter"})
        ],
        animations: {
          enabled: false,
        },
        brush:{
          target: 'chart2',
          enabled: true
        },
        selection: {
          fill: {
            color: '#3E3DA9',
            opacity: 0.1
          },
          stroke: {
            width: 1,
            dashArray: 3,
            color: '#00F',
            opacity: 0.4
          },
          enabled: true,
          xaxis: {
            min: lineGraphData[0] && lineGraphData[0][0],
            max: lineGraphData[lineGraphData.length-1 ] && lineGraphData[lineGraphData.length-1][0]
          }
        },
      },
      stroke: {
        width: [0, 1,
          ...markersGraphInfo.map(() => {return 0})
        ],
        curve: 'straight'
      },
      colors: ["transparent", '#3E3DA9'],
      fill: {
        type: ["fill", 'gradient'],
        gradient: {
          opacityFrom: 1,
          opacityTo: 0.99,
          stops: [0, 50, 100]
        },
        colors: ["transparent",'#F0EDFF']
      }, 
      markers: {
        size: [0, 0, 
          ...markersGraphInfo.map(() => {return 1})
        ],
        strokeColors: ['#3E3DA9', '#fff', 
          ...markersGraphInfo.map((marker) => {return marker[0]?.color ?? "#FF6D2A"})
        ],
        colors: ['#3E3DA9', '#3E8DF3'],
        strokeWidth: [0,0,
          ...markersGraphInfo.map(() => {return 4})
        ],
        hover: {
          size: 10
        }
      },
      xaxis: {
        type: 'datetime',
        tooltip: {
          enabled: false
        }
      },
      yaxis: {
        tickAmount: 2,
        labels: {
          show: false,
        }
      },
      legend: {
        show: false
      },
    },
  }

  setOptions(null);
  setOptions(tempOptions)
}

useEffect(() => {

  changeOptions()
  
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [
  series, 
  seriesLine,
  markersGraphInfo,
  markersGraphData,
  lineGraphData,
  lineGraphInfo
])

  return(
    <S.GraphNumericSurvey>
      <div className="chart-content">
        {
         options && 
          <ReactApexChart options={options?.options} series={options?.series} type={"area"} height={200} />
        }
      </div>
    </S.GraphNumericSurvey>
  );
}

export default GraphNumericSurvey;