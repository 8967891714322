import React, { useEffect, useState } from "react";
import * as S from "./SymptomDate.style";
import { useTranslation } from "react-i18next";
import CalendarRange from "./calendarRange";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../../utils/pipes";
import ZaiaToggleCircular from "../../../../../../../zaiaComponents/zaiaToggleCircular";

function SymptomDate({
  selectedDate,
  changeFormField
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [date, setDate] = useState();

  const saveDates = async () => {
    if (date) {
        changeFormField(
          [
            {
              value: P.formatNumberDateInput(date), 
              text: P.formatNumberDate(date, true),
              fieldName: "date",
            }
          ]
        )
      } else {
        if(!selectedDate.value) {
          changeFormField(
            [
              {
                value: null, 
                text: "",
                fieldName: "date",
              }
            ]
          )
        } else {
          setDate(new Date(selectedDate.value.replace(/-/g,'/').replace('T',' ')));
        }
    }
  }

  useEffect(() => {
    saveDates();    
  }, [date])

  return (
    <S.SymptomDate>
      <div className="title">
        {t("healthAddData.symptoms.chooseDate")}
      </div>
      <div className="calendar-inputs">
        <div className="input-container">
          <div className="input-label">{t("globally.initial")}</div>
          <ZaiaInput 
            type={"date"}
            name="data"
            errorOption={false}
            getText={(e) => {
              setDate(new Date(e));
              // setInitialDate(new Date(e.replace(/-/g,'/').replace('T',' ')));
            }}
            disabled={true}
            value={selectedDate.value ? selectedDate.value : ""}
            padding={"5px 10px"}
            borderRadius={"4px"}
            textcolor={"#6C6BCC"}
            borderColor={"transparent"}
            fontSize="15px"
            />
        </div>
        {/* <div className={`input-container ${disableFinishDate && "input-container--unfocus"}`}>
          <div className="input-label">Final</div>
          <ZaiaInput
            type={"date"}
            name="data"
            errorOption={false}
            getText={(e) => {
              setFinishDate(new Date(e.replace(/-/g,'/').replace('T',' ')));
              // setFinishDate(new Date(e.replace(/-/g,'/').replace('T',' ')));
            }}
            disabled={true}
            value={selectedRangeFinish.value ? selectedRangeFinish.value : ""}
            padding={"5px 10px"}
            borderRadius={"4px"}
            textcolor={"#6C6BCC"}
            borderColor={"transparent"}
            placeholdertextcolor={"#878792"}
            fontSize="17px"
            />
        </div> */}
      </div>
      <div className="calendar-range-container">
        <div className="calendar-range">
          <CalendarRange
            initialDate={date}
            // finishDate={finishDate}
            setInitialDate={setDate}
            // setFinishDate={setFinishDate}
            rangeActive={false}
            titleColor={"#37358F"}
            arrowsColor={"#37358F"}
            textcolor={"#6A6898"}
            dateSelectedPrimaryColor={"#37358F"}
            dateSelectedSecondColor={"#37358F"}
            currentDayColor={"#37358F"}
            backgroundColor={"#F1F3F5"}
            fontSizeCalendar={"8px"}
            maxDate={new Date(Date.now())}
          />
        </div>
      </div>
      {/* <div className="toogle-container">
        <span>{"Without end date"}</span>
        <ZaiaToggleCircular
          id={'end-date-toggle'}
          color={"#37358F"}
          toggle={setDisableFinishDate}
          active={disableFinishDate}
        />
      </div> */}
    </S.SymptomDate>
  );
}

export default SymptomDate;
