import React, { useMemo, useState } from "react";
import * as S from "./MedicationSearcher.style";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../../utils/pipes";
import { v4 as uuidv4 } from 'uuid';

function MedicationSearcher({
  selectedMedication,
  changeFormField,
  drugsList,
  goNextInput
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [filterText, setFilterText] = useState("");

  const drugsOrder = useMemo(() => {
    const organizedDrugs = drugsList?.map((drug) => {
      return drug.drugNames.map((drugName) => {
        return {
          drugName: drugName.name.trim(),
          drug: drug
        }
      })
    })

    return organizedDrugs?.flat().sort((a, b) => {
      const aDrugName = P.removeAccent(a.drugName);
      const bDrugName = P.removeAccent(b.drugName);
      if (aDrugName > bDrugName) {
        return 1;
      }
      if (aDrugName < bDrugName) {
        return -1;
      }
      return 0;
    });
  }, [drugsList])

  const getFilterText = (text) => {
    return filterText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(filterText).toLowerCase())
      : true
  }

  const getMedicationList = () => {

    return (
      <>
        <div className={`medication-list__results ${filterText === "" && "medication-list__results--hidden"}`}>{t("globally.resultsFor")} "{filterText}"</div>
        <div className="medication-list__container">
          {
            drugsOrder?.map((drug) => {
              if(getFilterText(drug.drugName)) {
                return (
                  <div 
                    key={uuidv4()}
                    className={`medication-name ${drug.drug.id === selectedMedication.value && drug.drugName === selectedMedication.text && "medication-name--selected"}`}
                    onClick={() => 
                      {
                        if(drug.drug.id !== selectedMedication.value && drug.drugName !== selectedMedication.text) {
                          changeFormField(
                            [
                              {
                                value: drug.drug.id,
                                text: drug.drugName,
                                fieldName: "medication"
                              },
                              {
                                value: null,
                                text: "",
                                fieldName: "dose"
                              }
                            ]
                          )
                          goNextInput()
                        }
                      }
                    }
                  >{drug.drugName}</div>
                )
              } else {
                return null
              }
            })
          }
          {
            filterText !== "" &&
            <div 
              className={`medication-name ${0 === selectedMedication.value && filterText === selectedMedication.text && "medication-name--selected"}`}
              onClick={() => {
                changeFormField(
                  [
                    {
                      value: 0,
                      text: filterText,
                      fieldName: "medication"
                    }
                  ]
                )
                goNextInput()
              }
              }
            >{filterText}</div>
          }
        </div>
      </>
    )

  }

  return (
    <S.MedicationSearcher
      searcher={filterText}
    >
      <div className="input-container">
        <ZaiaInput
          textcolor={"#59599E"}
          borderColor={"#68688D"}
          placeholdertextcolor={"#878792"}
          fontSize={"15px"}
          placeholder={t("healthAddData.medication.searchMedication")}
          type="text"
          name="description"
          padding={"9px 20px"}
          borderRadius={"10px"}
          errorOption={false}
          height={"100%"}
          getText={(e) => setFilterText(e)}
          backgroundColor={"transparent"}
          value={filterText}
        />
      </div>
      <div className="medication-list">
        {
          getMedicationList()
        }
      </div>
      
    </S.MedicationSearcher>
  );
}

export default MedicationSearcher;
