import styled from "styled-components";

export const ModalContainerDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  .container {
    width: 70%;
    min-height: 500px;
    margin: auto;
    padding: 20px;
    .card-inputs {
      margin: 0 auto;
      /* max-height: 420px; */
      background-color: ${({ theme }) => theme.background};
      padding: 40px;
      border-radius: 30px;
      box-shadow: 1px 1px 11px 5px ${({ theme }) => theme.blackShadow}29;
      h4 {
        font-family: "Nunito Bold";
        margin-bottom: 10px;
        color: var(--blue-dark-color);
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .container {
      width: 90%;
    }
    .card-inputs {
      padding: 40px 10px 40px 20px !important;
    } 
  }
`;

export const FormContainer = styled.div`
  height: 400px;
  overflow-y: hidden;
  overflow-y: scroll;
  width: 100%;
`;

export const NewEventModal = styled.div`
  display: flex;
  h5 {
    font-family: "Nunito Bold";
    margin-bottom: 10px;
    color: var(--blue-dark-color);
  }
  form {
    width: 100%;
    .rows {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      .row-left {
        width: ${({ row1 }) => (row1 ? row1 : "auto")};
        min-width: ${({ row1Min }) => (row1Min ? row1Min : "auto")};
        margin-right: 5px;
        padding: 0 10px;
        flex-grow: 2;
      }
      .row-right {
        width: ${({ row2 }) => (row2 ? row2 : "auto")};
        min-width: ${({ row2Min }) => (row2Min ? row2Min : "auto")};
        flex-grow: 2;
        padding: 25px 20px 0px 20px;
      }
    }
    .current-state-form-button {
      width: 40%;
      margin: 35px auto 0;
    }
  }
`;
