import styled from "styled-components";

export const EventCalendarForm = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  .settings__header {
    display: flex;
    align-items: center;
    padding: 0 20px;
    height: 60px;
    box-sizing: border-box;
    &-icon {
      font-size: 15px;
      margin-bottom: -2px;
      margin-right: 15px;
      cursor: pointer;
      position: absolute;
    }
    .settings__header-title {
      display: flex;
      align-items: center;
      margin: auto;     
      .settings__header-title-icon {
        font-family: "Nunito";
        font-size: 17px;
        color: var(--white) !important;
        display: flex;
        justify-content: center;
        background-color: ${({theme}) => theme.primarySectionCalendar};
        border-radius: 10px;
        padding: 8px;
        margin: 10px 8px 10px 0px;
        i {
          display: flex;
        }
      }
      h4 {
        font-family: "Nunito ExtraBold";
        color: ${({theme}) => theme.primarySectionCalendar};
        font-size: 17px;
      }
    }
  }  
  .settings__container {
    height: calc(100% - 60px);
    overflow: auto;
    .bottom-container {
      padding: 0 20px;
      height: 100%;
      .event-calendar-form {
        height: 100%;
        &__inputs {
          height: calc(100% - 65px);
          overflow: auto;
        }
      }
      .input-content {
        font-size: 15px;
        font-family: 'Nunito';
        /* color: var(--blue-color); */
        justify-content: space-between;
        span {
          padding: 2px 0;
          display: block;
        }
        &__label-bold {
          font-family: "Nunito Bold";
          font-size: 16px;
        }
        &__double-content {
          justify-content: space-between;
          display: flex;
          align-items: center;
        }
      }
      .participats-info {
        display: flex;
        color: var(--blue-color);
        justify-content: space-between;
        font-family: "Nunito SemiBold";
        font-size: 15px;
        i {
          cursor: pointer;
        }
      }
      .buttons-container {
        display: flex;
        justify-content: space-between;
        .button-submit {
          width: calc(100% - 50px);
          button {
            margin: 10px auto;
          }
        }
        .button-close {
          .button-close-icon {
            display: flex;
            box-sizing: border-box;
            padding: 10px 0 5px;
            
            justify-content: center;
            .icon {
              display: flex;
              /* border: 2px solid ${({theme}) => theme.primarySectionCalendar}; */
              background-color: ${({theme}) => theme.primaryColor};
              color: ${({theme}) => theme.onPrimaryColor};
              border-radius: 50%;
              padding: 12px;
              box-shadow: 4px 2px 11px -3px #00000029;
            }
          }
        }
      } 
    }
  }

  @media only screen and (max-width: 435px) {
    .buttons-container {
      .button-submit {
        button {
          /* width: 70%; */
          margin: auto;
        }
      }
      .button-close {

      }
    } 
  }
`;