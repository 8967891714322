import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import * as S from "./ListOfCommentCards.Style";

// Pipes
import * as P from "../../../../../../../utils/pipes";

// components

import Box from "../../../../../dashboard/components/box";
import ZaiaReaction from "../../../../../../zaiaComponents/zaiaReaction";

// Apollo
import {  
  QUERY_FEELINGS_COMMENT_REPORTS_BY_USER
} from "../../../../../../../graphql/queries/Feelings";
import {
  QUERY_DRUGS_COMMENT_REPORTS_BY_USER
} from "../../../../../../../graphql/queries/drugs";
import {
  QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER
} from "../../../../../../../graphql/queries/VitalSigns";
import { useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_NAMES } from "../../../../../../../graphql/queries/Process";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const logoZaiaEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-no-alerts.svg`;

function ListOfCommentCards({
  initialDate,
  finishDate,
  selectedPatient,
  setSelectedBox,
}) {
  const addDay = (date, day) => {
    date?.setDate(date?.getDate() + day);
    return date;
  };
  const newFinishDate = finishDate ? addDay(finishDate, +1) : null;

  const {
    t,
    i18n: { language },
  } = useTranslation("global");

  const { data: processData } = useQuery( QUERY_GET_PROCESS_NAMES);    

  // Comment reports
  const { data: feelingsComments, loading: feelingsCommentsLoading, error: feelingsCommentsError } = useQuery( 
    QUERY_FEELINGS_COMMENT_REPORTS_BY_USER, 
    {
      variables: {
        processId: processData?.process.map((process) => {return process?.id}),
        initialDate: initialDate,
        finalDate: newFinishDate,
        userId: selectedPatient,
      }
    }
  );

  console.log("feelingsComments", feelingsComments);

  const { data: drugsComments, loading: drugsCommentsLoading, error: drugsCommentsError } = useQuery(
    QUERY_DRUGS_COMMENT_REPORTS_BY_USER, {
      variables: {
        processId: processData?.process.map((process) => {return process?.id}),
        initialDate: initialDate,
        finalDate: newFinishDate,
        userId: selectedPatient,
      },
    }
  );

  const { data: vitalSignsComments, loading: vitalSignsCommentsLoading, error: vitalSignsCommentsError } = useQuery(
    QUERY_GET_VITAL_SIGNS_COMMENT_REPORTS_BY_USER,
    {
      variables: {
        processId: processData?.process.map((process) => {return process?.id}),
        initialDate: initialDate,
        finalDate: newFinishDate,
        userId: selectedPatient,
      }
    }
  );

  const getVitalSigns = (vitalSign, reportlevel) => {
    return vitalSign?.userVitalSignHistory.map((vitalSign) => {
      let vitalSignTemp = JSON.parse(JSON.stringify(vitalSign));
      vitalSignTemp.type = 3;
      vitalSignTemp.orderDate = vitalSign.created_at;
      vitalSignTemp.description =
        reportlevel === 4
          ? `"${vitalSign.comment}" ${t(
              "dashboard.boxes.comment.symptom"
            )} "${P.dataTranslation(vitalSign.vitalSignVal.comment, language)}"`
          : `${P.dataTranslation(vitalSign.vitalSignVal.comment, language)}  (${
              vitalSign.measure1
            } ${vitalSign.unit})`;
      vitalSignTemp.reportlevel = reportlevel;
      return vitalSignTemp;
    });
  };

  const getDrugs = (drug, reportlevel) => {
    return drug?.userToDrugHistory.map((drug) => {
      let drugTemp = JSON.parse(JSON.stringify(drug));
      drugTemp.type = 2;
      drugTemp.orderDate = drug.created_at;
      drugTemp.description = drug.comment;
      drugTemp.reportlevel = reportlevel;
      return drugTemp;
    });
  };

  const getFeelings = (feeling, reportlevel) => {
    return feeling?.userToFeeling.map((feeling) => {
      let feelingTemp = JSON.parse(JSON.stringify(feeling));
      feelingTemp.type = 1;
      feelingTemp.orderDate = feeling.date;
      feelingTemp.description = feeling.description;
      feelingTemp.reportlevel = reportlevel;
      return feelingTemp;
    });
  };

  function cleanArray(actual) {
    let newArray = [];
    for (let i = 0, j = actual.length; i < j; i++) {
      if (actual[`${i}`]) {
        newArray.push(actual[`${i}`]);
      }
    }
    return newArray;
  }

  const dataForCard = useMemo(
    () => {
      let cardsInfo = [];

      let allCardsInfo = cleanArray(
        cardsInfo.concat(
          getDrugs(drugsComments, 4),
          getVitalSigns(vitalSignsComments, 4),
          getFeelings(feelingsComments, 4)
        )
      );

      allCardsInfo = allCardsInfo
        .slice(0)
        .reverse()
        .filter((value, index, self) => {
          return self.findIndex((v) => v.id === value.id) === index;
        });

      const mildCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 1;
      });

      const mediumCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 2;
      });

      const seriousCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 3;
      });

      const commentCardsInfo = allCardsInfo.filter((value) => {
        return value.reportlevel === 4;
      });

      cardsInfo = commentCardsInfo;

      cardsInfo = cardsInfo.sort(function (a, b) {
        return new Date(a.orderDate) - new Date(b.orderDate);
      });
      
      return {
        cardsInfo,
        allCardsInfo,
        mildCardsInfo,
        mediumCardsInfo,
        seriousCardsInfo,
        commentCardsInfo,
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      vitalSignsComments,
      feelingsComments,
      drugsComments
    ]
  );


  if (
    feelingsCommentsLoading ||
    drugsCommentsLoading ||
    vitalSignsCommentsLoading
  )
    return (
      <>
        <ZaiaReaction widthimg="150px" zaiaImg={logoZaiaLoading} />
      </>
    );

  if (
    feelingsCommentsError ||
    drugsCommentsError ||
    vitalSignsCommentsError
  )
    return (
      <>
        <ZaiaReaction
          zaiaImg={logoZaiaAlert}
          widthimg="125px"
          text={t("dashboard.boxes.noAlertsError")}
          sizeText="17px"
          widthText="240px"
        />
      </>
    );

  if (
    dataForCard.cardsInfo?.length < 1
  )
    return (
      <>
        <ZaiaReaction
          zaiaImg={logoZaiaEmpty}
          widthimg="200px"
          text={t("dashboard.boxes.noAlerts")}
          sizeText="17px"
          widthText="200px"
        />
      </>
    );

  return (
    <S.ListOfCommentCards>
      {dataForCard?.cardsInfo
        ?.slice(0)
        .reverse()
        .map((dataCard, index) => {
          return dataCard 
            ? (
              <div
                className="box-container"
                key={dataCard.id}
                onClick={() => (dataCard ? setSelectedBox(dataCard) : null)}
              >
                <Box
                  patient={
                    dataCard?.user?.name + " " + dataCard?.user?.lastname
                  }
                  time={P.hoursIn12format(
                    new Date(dataCard?.orderDate).getHours(),
                    new Date(dataCard?.orderDate).getMinutes()
                  )}
                  date={P.shortDate(new Date(dataCard?.orderDate), t, true, language)}
                  description={
                    dataCard?.userToDrug?.drug?.genericName
                      ? `${dataCard?.userToDrug?.drug.genericName} - ${dataCard?.description}`
                      : ` ${dataCard?.description}`
                  }
                  report={t("dashboard.boxes.vitalSigns.report")}
                  type={dataCard?.type}
                  patientPhoto={dataCard?.user?.picture}
                  levelReport={dataCard?.reportlevel}
                />
              </div>
            ) : null;
        })}
    </S.ListOfCommentCards>
  );
}

export default ListOfCommentCards;