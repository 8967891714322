import styled from "styled-components";

export const DiagnosisListDiv = styled.div`
  background-color: var(--white);
  border-radius: 10px;
  padding: 13px 10px 10px 10px;
  .card-header {
    font-family: "Nunito Sans";
    display: flex;
    justify-content: space-between;
    &-right {
      display: flex;
      color: var(--blue-dark-color);
      font-size: 12px;
      cursor: pointer;
      i {
        font-size: 6.5px;
        align-self: center;
        margin-right: 3px;
      }
    }
  }
  .diagnosis-list-container {
  }
`;


export const DiagnosticPatientItem = styled.div`
  margin-top: 10px;
  .diagnosis-item {
    &-info {
      display: flex;
      justify-content: space-between;
      width: 100%; 
      align-items: center;
      padding: 10px 5px 10px 10px ;
      box-sizing: border-box;
      background-color: var(--blue-dark-color);
      border-radius: 7px
      /* height: 50px; */
    }
    &-name {
      color: var(--white);
      font-family: "Nunito Bold"
    }
  }
`;


export const DiagnosticListDeafult = styled.div`
  width: 100%;
  overflow: auto;
  .diagnostic-item {
    padding: 4px 10px;
    font-size: 12.5px;
    color: var(--blue-dark-color);
    /* margin-bottom: 6px; */
    font-size: "Nunito";
    cursor: pointer;
    :hover {
      background-color: var(--blue-color);
      color: var(--white);
    }
    &--selected {
      background-color: var(--blue-color);
      color: var(--white);
    }
  }
`;

export const NewDiagnostic = styled.div`
  background-color: var(--white); 
  box-shadow: 3px 3px 5px var(--box-shadow);
  border-radius: 10px;
  .diagnostics-list-default {
    width: 100%;
    height: 100%;
    &-header {
      border-radius: 10px 10px 0 0;
      background-color: var(--blue-dark-color);
      color: var(--white);
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 5px 10px;
      box-sizing: border-box;
      font-size: 12.5px;
      height: 28px;
      &-left {
        display: flex;
        cursor: pointer;
      }
      &-right {
        font-weight: bold;
        cursor: pointer;
      }
      &-icon {
        font-size: 10px;
        display: flex;
        margin: auto;
      }
      &-title {
        margin-right: 20px;
      }
    }
    &-content {
      display: flex;
      height: calc(100% - 37.6px);
      background-color: var(--white);
      min-height: 100px;
    }
  }
`