import React, { useEffect, useMemo, useState } from "react";
import * as S from "./FollowUp.style";
import OutcomesTabs from "../aiNavigator/components/outcomesTabs";
import ZaiaImage from "../../zaiaComponents/zaiaImage";
import { useTranslation } from "react-i18next";
import Popup from "reactjs-popup";
import ZaiaInput from "../../zaiaComponents/zaiaInput";
import UserAINavigatorBoxes from "../aiNavigator/components/modalUserAiNavigator/userAINavigatorView";
import Modal from "../../../utils/modal";
import ZaiaModalResponseContent from "../../zaiaComponents/zaiaModalResponseContent";
import { useStateValue } from "../../../contextAPI/StateProvider";
import * as P from '../../../utils/pipes'
import ZaiaReaction from "../../zaiaComponents/zaiaReaction";
import { MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP } from "../../../graphql/mutations/UserToProcess";
import { useMutation, useQuery, useSubscription } from "@apollo/client";
import { SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED_AND_ID } from "../../../graphql/subscriptions/UserToProcess";
import useRecordNavigation from "../../../hooks/useRecordNavigation";
import { Actions } from "../../../utils/actionsIds";
import ModalAddOutcomesEvent from "../shared/modals/modalAddOutcomesEvent";
import { QUERY_GET_USER_TO_PROCESS_HISTORY_BY_PATIENT } from "../../../graphql/queries/UserToProcessHistory";
import NoteHistory from "../notesView/components/noteHistory/NoteHistory";
import NoteForm from "../notesView/components/noteForm";
import NoteComplete from "../notesView/components/noteComplete";
import ListNotes from "../notesView/components/listNotes/ListNotes";
import { QUERY_GET_USER_BY_ID } from "../../../graphql/queries/User";

const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/list-events-empty.svg`;

function FollowUp({}){

  const [noteSelected, setNoteSelected] = useState(false);
  const [createNoteActived, setCreateNoteActived] = useState(false);
  const [editNoteActived, setEditNoteActived] = useState(false);
  const [historyActived, setHistoryActived] = useState(false);
  
  const [{
    followUpActive
  }] = useStateValue();
  const { recordAction } = useRecordNavigation();

  const { data: userToProcessData, error: userToProcessError, loading: userToProcessLoading } = useSubscription(SUBSCRIPTION_GET_USER_TO_PROCESS_BY_ISACEPTED_AND_ID, {
    variables: { 
      isAcepted: true,
      userToProcessId: followUpActive.id
    },
  });

  const {t, i18n: { language }} = useTranslation("global");
  const [{followUpMinimized}, dispatch] = useStateValue();

  const [viewTab, setViewTab] = useState(1);
  const [dirtyModal, setDirtyModal] = useState(false);
  const [activeCloseModal, setActiveCloseModal] = useState(false);
  const [description, setDescription] = useState("");
  const [descriptionWarning, setDescriptionWarning] = useState(false);
  const [activeSuccessModal, setActiveSuccessModal] = useState(false);

  const frequency = () => {
    if(userToProcessData?.userToProcess[0].processStage.frequencyType === "days") {
      return (
        `${userToProcessData?.userToProcess[0].processStage.frequency > 1 ? userToProcessData?.userToProcess[0].processStage.frequency : ""}
        ${userToProcessData?.userToProcess[0].processStage.frequency > 1 ? t("globally.days") : t("globally.day")}`
      ) 
    } else if(userToProcessData?.userToProcess[0].processStage.frequencyType === "months") {
      return (
        `${userToProcessData?.userToProcess[0].processStage.frequency > 1 ? userToProcessData?.userToProcess[0].processStage.frequency : ""}
        ${userToProcessData?.userToProcess[0].processStage.frequency > 1 ? t("globally.months") : t("globally.month")}`
      ) 
    } else {
      return (`${userToProcessData?.userToProcess[0].processStage.frequency}`)
    }
  } 

  const frequencyHours = (date) => {
    const frequency = userToProcessData?.userToProcess[0].processStage.frequency;
    const frequencyType = userToProcessData?.userToProcess[0].processStage.frequencyType;
    if(frequencyType === "days") {
      return (frequency * 24) 
    } else if (frequencyType === "months") {
      const firstDate = new Date(date);
      const calc = Math.abs(new Date(firstDate.getFullYear(), firstDate.getMonth() + frequency, firstDate.getDate()) - firstDate);
      const days = Math.ceil(calc / (1000 * 60 * 60 * 24));
      
      return (days * 24)
    } else {
      return frequency
    }
  } 

  const [tabs, setTabs] = useState(
    [
      {
        id: 1,
        // icon: "zaia-dashboard",
        name: t("followUp.followUpManagement"),
        description: t("followUp.followUpManagementDescription")
      },
      {
        id: 2,
        // icon: "zaia-dashboard",
        name: t("globally.ainavigator"),
        description: t("followUp.ainavigatorDescription")
      },
    ]
  )

  const setFollowUpMinimized = (active) => {
    dispatch({
      type: "SET_FOLLOWUP_MINIMIZED",
      followUpMinimized: active,
    });
  }

  const closeModalConfirmation = () => {
    return (
      <S.FollowUpModalClose>
        <div className="modal-background" onClick={() => setActiveCloseModal(false)}>
        </div>
        <div className="content">
          <ZaiaModalResponseContent
            button1={() => closeModal()}
            button2={() => setActiveCloseModal(false)}
            formSuccess={false}
            button1Text={t("followUp.confirm")}
            button2Text={t("globally.cancel")}
            title={t("followUp.closeManagement")}
            subtitle={t("followUp.closeManagementSub")}
            doubleButton={true}
          />
        </div>
      </S.FollowUpModalClose>
    )
  }

  const successFollowUpCompletedModal = () => {
    return (
      <S.FollowUpModalClose>
        <div className="modal-background" onClick={() => setActiveSuccessModal(false)}>
        </div>
        <div className="content">
          <ZaiaModalResponseContent
            button1={() => setActiveSuccessModal(false)}
            formSuccess={true}
            button1Text={t("globally.done")}
            title={t("followUp.followUpCompleted")}
            doubleButton={false}
          />
        </div>
      </S.FollowUpModalClose>
    )
  }

  const closeModalConfirm = () => {
    if(dirtyModal) {
      setActiveCloseModal(true);
    } else {
      closeModal();
    }
  }

  const closeModal = () => {
    dispatch({
      type: "SET_FOLLOWUP_ACTIVE",
      followUpActive: false,
    });
    dispatch({
      type: "SET_FOLLOWUP_MINIMIZED",
      followUpMinimized: true,
    });
  } 

  const followUpInfo = useMemo(() => {
    return [
      {
        id: userToProcessData?.userToProcess[0].id,
        processId: userToProcessData?.userToProcess[0].process?.id,
        processName: P.dataTranslation(userToProcessData?.userToProcess[0].process?.processName, language),
        stageId: userToProcessData?.userToProcess[0].stageId,
        lastFollowingDate: userToProcessData?.userToProcess[0].userToProcessHistories[0] ? new Date(userToProcessData?.userToProcess[0].userToProcessHistories[0].created_at) : null,
        nextFollowingDate: new Date(userToProcessData?.userToProcess[0].nextUpdate),
        futureFollowingDate: new Date(new Date(userToProcessData?.userToProcess[0].nextUpdate).setHours(frequencyHours(userToProcessData?.userToProcess[0].nextUpdate),0,0,0))
      }
    ]
  }, [userToProcessData, frequencyHours])

  return (
    <S.FollowUp
      viewTab={viewTab}
      descriptionWarning={descriptionWarning}
    >
      <Modal open={activeCloseModal}>
        {closeModalConfirmation()}
      </Modal>
      <Modal open={activeSuccessModal}>
        {successFollowUpCompletedModal()}
      </Modal>
      {
        followUpMinimized &&  
        <div className="minimized">
          <div className="header-minimized">
            <button 
              className="option-button" 
              onClick={() => {
                recordAction(Actions.FOLLOW_UP.CLOSE);
                closeModalConfirm();
              }}
            >
              <i className="option-button__icon icon zaia-icono-cerrar" />
            </button>
            <button className="option-button"
              onClick={
                () => {
                  recordAction(Actions.FOLLOW_UP.MAXIMIZE);
                  setFollowUpMinimized(false);
                }
              }
            >
              <i className="option-button__icon icon zaia-i-up"></i>
            </button>
            <div className="header__title">
              {t("followUp.followUpManagement")}
            </div>  
          </div>
          <div className="patient-info">
            <img className="patient-info__img-patient" src={userToProcessData?.userToProcess[0].user.picture} alt="F" />
            <div className="patient-info__name">{userToProcessData?.userToProcess[0].user.name + " " + userToProcessData?.userToProcess[0].user.lastname}</div>
          </div>
        </div>
      }
      {
        !followUpMinimized &&
        <div className="big-screen">
          <div className="header-big-screen">
            <div className="buttons">
              <button 
                className="option-button" 
                onClick={
                  () => {
                    closeModalConfirm();
                    recordAction(Actions.FOLLOW_UP.CLOSE);
                  }
                }
              >
                <i className="option-button__icon icon zaia-icono-cerrar" />
              </button>
              <button 
                className="option-button" 
                onClick={
                  () => {
                    setFollowUpMinimized(true);
                    recordAction(Actions.FOLLOW_UP.MINIMIZE);
                  }
                }
              >
                <i className="option-button__icon option-button__icon--minimize icon zaia-i-down"></i>
                <div className="option-button__text">
                  {t("globally.minimize")} 
                </div>
              </button>
            </div>
            <div className="tabs">
              <OutcomesTabs
                setViewTab={setViewTab}
                viewTab={viewTab}
                tabs={tabs}
              />
            </div>  
          </div>
          <div className="content">
            <div className="content__left">
              <div className="patient-card">
                <div className="patient-card__info">
                  <div className="patient-image">
                    <ZaiaImage
                      img={userToProcessData?.userToProcess[0].user.picture}
                      rounded={true}
                      size={72}
                    />
                  </div>
                  <div className="patient-info">
                    {
                      userToProcessData 
                      ? (
                        <>
                          <Popup
                            trigger={() => (
                              <div
                                className="patient-info__name"
                              >
                                {userToProcessData?.userToProcess[0].user.name + " " + userToProcessData?.userToProcess[0].user.lastname}
                              </div>
                            )}
                            on={["hover"]}
                            position="top center"
                          >
                            {() => (
                              <S.PatientNamePopup>
                                {userToProcessData?.userToProcess[0].user.name + " " + userToProcessData?.userToProcess[0].user.lastname}
                              </S.PatientNamePopup>
                            )}
                          </Popup>

                        </>
                      )
                      : null
                    }
                    <div className="patient-info__program">
                      {
                        <Popup
                          trigger={
                            <div className="patient-info__program-detail">
                              {/* {
                                patient?.userToProcesses.length > 1 
                                ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                <div>{P.dataTranslation(patient?.userToProcesses[0].process?.processName, language)}</div>
                                
                              } */}
                              {P.dataTranslation(userToProcessData?.userToProcess[0].process?.processName, language)}
                            </div>
                          }
                          keepTooltipInside
                          nested
                          // position={"right bottom"}
                          on="hover"
                          // disabled={patient?.userToProcesses.length > 1 ? false : true}
                        >
                          {
                            // <ProcessName userToProcesses={patient?.userToProcesses}/>
                          }
                        </Popup>
                      }
                      
                    </div>
                    <div className="patient-info__email">{userToProcessData?.userToProcess[0].user.email}</div>
                    <div className="patient-info__fequency">
                      {t("globally.frequency")}: <b>{t("globally.each")} {frequency()}</b>
                    </div>
                  </div>
                </div>
                <div className="patient-card__right">
                  <div className="numbers">
                    <div className="numbers__title">
                      <div className="numbers__title-text">{t("aiNavigator.patientModal.phoneNumbers")}</div>
                      <i className="numbers__title-icon icon zaia-i-phone"></i>
                    </div>
                    <div className="number">
                      <div className="number__title">
                        {t("globally.patient")}
                      </div>
                      <div className="number__phone">
                        {userToProcessData?.userToProcess[0].user.phone}
                      </div>
                    </div>
                    {
                      userToProcessData?.userToProcess[0].user.phoneCarer &&
                      <div className="number">
                        <div className="number__title">
                          {t("globally.caregiver")}
                        </div>
                        <div className="number__phone">
                          {userToProcessData?.userToProcess[0].user.phoneCarer}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className="principal-content"
                style={
                  viewTab === 2
                  ? {
                    backgroundColor: "transparent"
                  }
                  : {
                    backgroundColor: "#FFF"
                  }
                } 
              >
                {
                  viewTab === 2
                  ? <UserAINavigatorBoxes
                    followUpInfo={followUpInfo}
                    patient={userToProcessData?.userToProcess[0].user}
                    followUpMinimizeFunc={setFollowUpMinimized}
                    closeModal={setFollowUpMinimized}
                  />
                  : <>
                      {/* <div className="add-comment">

                        <div className="add-comment__msg">
                          {t("followUp.commentDescription")}
                        </div>
                        
                      </div> */}
                      {
                        userToProcessData &&
                        <div className="card-list">
                          <FollowUpCard
                            date={new Date(userToProcessData?.userToProcess[0].nextUpdate)}
                            titleCard={t("followUp.nextFollowUp")}
                            changeDateButton={true}
                            description={description}
                            setDescription={setDescription}
                            frequency={frequency}
                            descriptionWarning={descriptionWarning}
                            setDescriptionWarning={setDescriptionWarning}
                            setDirtyModal={setDirtyModal}
                            setActiveSuccessModal={setActiveSuccessModal}
                            comment={true}
                            outcomesFillingOption={userToProcessData?.userToProcess[0].process?.id === 47 || userToProcessData?.userToProcess[0].process?.id === 65}
                            selectedPatient={userToProcessData?.userToProcess[0].user}
                            processSelected={userToProcessData?.userToProcess[0].process?.id}
                          />
                          <FollowUpCard
                            date={new Date(new Date(userToProcessData?.userToProcess[0].nextUpdate).setHours(frequencyHours(userToProcessData?.userToProcess[0].nextUpdate),0,0,0))}
                            titleCard={t("followUp.futureFollowUp")}
                            changeDateButton={false}
                          />
                        </div>
                      }
                    </>
                }
              </div>
            </div>
            <div className="content__right">
              <div className="title">
                <i className="title__icon icon zaia-i-message-outline"></i>
                <div className="title__text">{t("followUp.followUpHistory")}</div>
              </div>
              <div className="comments-list">
                <NotesContent
                  setCreateNoteActived={setCreateNoteActived}
                  createNoteActived={createNoteActived}
                  noteSelected={noteSelected}
                  setNoteSelected={setNoteSelected}
                  editNoteActived={editNoteActived} 
                  setEditNoteActived={setEditNoteActived}
                  historyActived={historyActived}
                  setHistoryActived={setHistoryActived}
                  patientSelected={userToProcessData?.userToProcess[0].user}
                  processSelected={userToProcessData?.userToProcess[0].process}
                />
              </div>
              {/* <div className="comments-list">
                <div className="comments-list__last-follow-up-text">{t("followUp.lastFollowUp")}</div>
                {
                  userToProcessData?.userToProcess[0].userToProcessHistories.filter((history) => history.descript).map(
                    (history, index) => {
                      return (
                        <div key={history.id}>
                          <div className="comment-card">
                            <div className="comment-card__time">
                              <div className="comment-card__date">
                              {P.shortDate(history.created_at, t, true, language)}
                              </div>
                              <div className="comment-card__hour">
                                {P.hoursIn12format(new Date(history.created_at).getHours(), new Date(history.created_at).getMinutes())}
                              </div>
                            </div>
                            <div className="comment-card__text">
                              {history.descript}
                            </div>
                          </div>
                          {
                            index === 0 && userToProcessData?.userToProcess[0].userToProcessHistories.length > 1 && <div className="last-comment-separator"></div>
                          }
                        </div>
                      )
                    }
                  )
                }
                {
                  userToProcessData?.userToProcess[0].userToProcessHistories.filter((history) => history.descript).length === 0 &&
                  <ZaiaReaction
                    zaiaImg={eventsCalendarEmpty}
                    text={t("followUp.noCommentsYet")}
                    // subText={}
                    sizeText="30px"
                    sizeSubText="15px"
                    textcolor="var(--blue-color)"
                    widthimg="250px"
                  />
                }
              </div> */}
            </div>
          </div>
        </div>
      }
    </S.FollowUp>
  );
}

const NotesContent = ({
  setCreateNoteActived,
  createNoteActived,
  noteSelected,
  setNoteSelected,
  patientSelected,
  editNoteActived,
  setEditNoteActived,
  historyActived,
  setHistoryActived,
  processSelected
}) => {

  const {t, i18n: { language }} = useTranslation("global");

  const [formNoteText, setFormNoteText] = useState("");
  const [userToProcessSelected, setUserToProcessSelected] = useState(null);

  const {
    data: userToProcessHistory,
    loading: userToProcessHistoryLoading,
    error: userToProcessHistoryError,
    refetch: refetchUserToProcessHistory
  } = useQuery(QUERY_GET_USER_TO_PROCESS_HISTORY_BY_PATIENT, {
    variables: {
      patientId: patientSelected?.id
    },
  });  

  console.log("hellow", patientSelected );
  
  
  useEffect(() => {
    refetchUserToProcessHistory();
  }, [createNoteActived])
  
  if(patientSelected) {
    if(historyActived) {
      return <NoteHistory
        listNotes={userToProcessHistory?.userToProcessHistory.filter(userToProcessHistory => userToProcessHistory.descript)}
        setCreateNoteActived={setCreateNoteActived}
        createNoteActived={createNoteActived}
        noteSelected={noteSelected}
        setNoteSelected={setNoteSelected}
        setEditNoteActived={setEditNoteActived}
        patientSelected={patientSelected}
        historyActived={historyActived}
        setHistoryActived={setHistoryActived}
      />
    } else if(createNoteActived || editNoteActived) {
      return <NoteForm
        setCreateNoteActived={setCreateNoteActived}
        editNoteActived={editNoteActived}
        setEditNoteActived={setEditNoteActived}
        patientSelected={patientSelected}
        formNoteText={formNoteText}
        setFormNoteText={setFormNoteText}
        userToProcessSelected={userToProcessSelected}
        setUserToProcessSelected={setUserToProcessSelected}
      />
    } else {
      if(noteSelected) {
        return <NoteComplete
          setNoteSelected={setNoteSelected}
          noteSelected={noteSelected}
          setEditNoteActived={setEditNoteActived}
        />
      } else {
        return <ListNotes
          listNotes={userToProcessHistory?.userToProcessHistory.filter(userToProcessHistory => userToProcessHistory.descript)}
          setCreateNoteActived={setCreateNoteActived}
          createNoteActived={createNoteActived}
          noteSelected={noteSelected}
          setNoteSelected={setNoteSelected}
          setEditNoteActived={setEditNoteActived}
          patientSelected={patientSelected}
          historyActived={historyActived}
          setHistoryActived={setHistoryActived}
          programIdSelected={processSelected.id}
        />
      }
    }
  }
   
    
  
}

function FollowUpCard({
  titleCard,
  date,
  changeDateButton,
  description,
  setDescription,
  frequency,
  setDescriptionWarning,
  setDirtyModal,
  setActiveSuccessModal,
  comment,
  outcomesFillingOption,
  selectedPatient,
  processSelected
}) {

  const {t, i18n: { language }} = useTranslation("global");
  const [buttonActive, setButtonActive] = useState(true);
  const [updateUserToProcess, {loading: updateUserToProcessLoading, error: updateUserToProcessError}] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP);
  const [changeDateActive, setChangeDateActive] = useState(false);
  const [newDate, setNewDate] = useState(P.formatNumberDateInput(date));
  const [addOutcomesEvent, setAddOutcomesEvent] = useState(false);
  const [commentOnly, setCommentOnly] = useState(outcomesFillingOption ? false : true);

  const states = [
    {
      id: 1,
      key: "next",
      name: t("followUp.due"),
      color: "#09BB5B",
      backgroungColor: "#FBFFFD",
      buttonColor: "#36369B",
    },
    {
      id: 2,
      key: "now",
      name: t("globally.today"),
      color: "#0B8FEE",
      backgroungColor: "#F7FAFF",
      buttonColor: "#0B8FEE",
    },
    {
      id: 3,
      key: "past",
      name: t("followUp.next"),
      color: "#DB5454",
      backgroungColor: "#FFFDFD",
      buttonColor: "#36369B",
    },
  ]

  const handleKeyUp = (e, msg) => {
    if(e.target.scrollHeight !== 22) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`; 
    }
    if(msg === ""){
      e.target.style.height = '22px';
    }
    // In case you have a limitation
    // e.target.style.height = `${Math.min(e.target.scrollHeight, limit)}px`;
  }

  const state = useMemo(() => {
    if(
      date.getDate() === new Date(Date.now()).getDate() &&
      date.getMonth() === new Date(Date.now()).getMonth() &&
      date.getFullYear() === new Date(Date.now()).getFullYear()
    ) {
      return states.find(state => state.key === "now")
    } else if(date > new Date(Date.now())) {
      return states.find(state => state.key === "next")
    } else {
      return states.find(state => state.key === "past")
    }
  }, [date])

  const daysRemaining = () => {
    const dateNow = new Date(Date.now());
    const daysRemaining = Math.floor((date.setHours(0,0,0,0) - dateNow.setHours(0,0,0,0)) / (1000 * 60 * 60 * 24));
    return Math.abs(daysRemaining)
  }

  const [{
    followUpActive
   }] = useStateValue();

  const markAsCompleted = async () => {
    if(description.trim() !== "") {
      setDescriptionWarning(false);
      try {
        await updateUserToProcess({
          variables: {
            updates: {
              descript: description,
              isChecked: true,
            },
            userToProcessId: followUpActive.id
          }, 
        });
        setDescription("");
        setButtonActive(false);
        setDirtyModal(false);
        setActiveSuccessModal(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      setDescriptionWarning(true);
    }
  }

  const changeNextUpdate = async () => {
    const nextUpdateDate = new Date(new Date(newDate).getTime() + new Date(newDate).getTimezoneOffset() * 60000);

    if(P.formatNumberDateInput(date) !== newDate) {
      try {
        const 
          data
        = await updateUserToProcess({
          variables: {
            updates: {
              nextUpdate: nextUpdateDate
            },
            userToProcessId: followUpActive.id
          },
        });
        console.log(data);
        setChangeDateActive(false);
      } catch (error) {
        console.log(error);
      } 
    } else {
      setChangeDateActive(false);
    }
  }
  
  return (
    <S.FollowUpCard
      color={state.color}
      backgroungColor={state.backgroungColor}
      buttonColor={state.buttonColor}
    >
      <Modal open={addOutcomesEvent}>
        <ModalAddOutcomesEvent                  
          setCreateEventActive={setAddOutcomesEvent}
          patientID={selectedPatient?.id}
          processID={processSelected}
          followUpChecked={true}
        />
      </Modal>
      {/* <div className="status">{state.title}</div> */}
      <div className="status">
        <div className="status__text">
          {titleCard}
        </div>
        {
          outcomesFillingOption &&
          <div className="status__outcomes-tabs">
            <div className="outcomes-tabs">
              <div className={`outcomes-tab ${ commentOnly && "outcomes-tab--selected"}`}
                onClick={() => setCommentOnly(true)}
              >{t("followUp.fillOutcomesTabComment")}</div>
              <div className={`outcomes-tab ${ !commentOnly && "outcomes-tab--selected"}`}
                onClick={() => setCommentOnly(false)}
              >{t("followUp.fillOutcomesTabOutcomes")}</div>
            </div>
          </div>
        }
      </div>
      {
        !changeDateActive && 
        <div className="card-content">
          <div className="info-card">
            <div className="info-card__left">
              <i className="calendar-icon icon zaia-i-calendar"></i>
              <div className="card-info">
                <div className="card-info__date">{P.longDate(date, t, language)}</div>
              </div>
              {
                changeDateButton &&
                <div className="change-date-action" onClick={() => setChangeDateActive(true)}>
                  {t("globally.changeDate")}
                </div>
              }
            </div>          
            {
              <div className="info-card__right">
                <div className="card-info">
                  {
                    daysRemaining() === 0 &&
                    <div className="card-info__status-simple">{t("globally.today").toUpperCase()}</div>
                  }
                  {
                    daysRemaining() > 0 &&
                    <div className="card-info__status-large">
                      {daysRemaining()} {
                        state.key === "next" 
                        ? daysRemaining() > 1
                          ? t("followUp.daysLeft")
                          : t("followUp.dayLeft")
                        : daysRemaining() > 1
                          ? t("followUp.daysLate")
                          : t("followUp.dayLate")
                      }
                    </div>
                  }
                </div>
              </div>
            }
          </div>
          {
            !commentOnly &&
            <div className="button-outcommes"
              onClick={() => setAddOutcomesEvent(true)}
            >
              <button className="fill-outcomes">
                <div className="fill-outcomes__text">{t("followUp.fillOutcomes")}</div>
                <i className="fill-outcomes__icon icon zaia-i-next"></i>
              </button>
            </div>
          }
          {
            comment && commentOnly &&
            <div className="add-comment">
              <ZaiaInput
                placeholder={t("followUp.addComment")}
                textarea
                value={description}
                getText={(e) => {
                  if(e !== "") {
                    setDirtyModal(true)
                  } else {
                    setDirtyModal(false)
                  }
                  if(e.trim() !== "") {
                    setDescriptionWarning(false)
                  } else {
                    setDescriptionWarning(true)
                  }
                  setDescription(e)
                }}
                onKeyUp={(e) => handleKeyUp(e, description)}
              />
              <div className="add-comment__button-container">
                <button 
                  className={`button button--mark ${buttonActive && description ? '' : 'button--disabled'}`}
                  onClick={() => 
                    buttonActive 
                    ? markAsCompleted()
                    : null
                  }
                >
                  {
                    buttonActive && description &&
                    <i className="button__icon icon zaia-i-check-1"></i>
                  }
                  <div className="button__text">
                    {
                      buttonActive
                      ? t("followUp.commentAndMark")
                      : t("followUp.completed")
                    }
                  </div>
                </button>
                <div className="add-comment__msg">
                  {t("followUp.buttonText1")} <b>{t("followUp.buttonText2")}</b> {t("followUp.buttonText3")}. <p className="required">*</p>
                </div>
              </div>
            </div>
          }
        </div>
      }
      {
        changeDateActive &&
        <div className="change-date">
          <div className="remember">{t("followUp.rememberFrequency")}: {t("globally.each")} {frequency(date)}</div>
          <div className="change-date__form">
            <div className="change-date__info">
              <div className="date-container">
                <div className="date-container__title">{t("followUp.previousDate")}</div>
                <div className="date-container__date-box">
                  <div className="date-container__text">
                    <div className="date-container__date">{P.formatNumberDate(date, true, language)}</div>
                    <div className="date-container__day">{P.nameDayWeek(new Date(date).getDay(), "L", t)}</div>
                  </div>
                </div>
              </div>
              <i className="change-date__icon icon zaia-i-next"></i>
              <div className="date-container">
                <div className="date-container__title">{t("followUp.newDate")}</div>
                <div className="date-container__date-box date-container__date-box--new-date">
                  {/* <i className="date-container__icon zaia-i-time"></i> */}
                  <div className="date-container__text">
                    {/* <div className="date-container__date">January 30th 2024</div> */}
                    <input 
                      className="date-container__date-input" 
                      type="date"
                      value={newDate}
                      onChange={(e) => setNewDate(e.target.value)}
                    />
                    <div className="date-container__day">{P.nameDayWeek(new Date(newDate).getDay() + 1, "L", t)}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="change-date__buttons">
              <button className="button button--cancel" onClick={() => setChangeDateActive(false)}>
                {t("globally.cancel")}
              </button>
              <button className="button button--mark" onClick={() => changeNextUpdate()}>
                {t("globally.save")}
              </button>
            </div>
          </div>
        </div>
      }
    </S.FollowUpCard>
  );
}

export default FollowUp;
