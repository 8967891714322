import styled from "styled-components";
import { getHexOpacity } from "../../../../utils/pipes";
const background = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico.svg`;
const background390 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico-390.svg`;
const background450 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico-450.svg`;
const background550 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico-550.svg`;
const background750 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico-750.svg`;
const background900 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico-900.svg`;
const background1024 = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaDecorators/Wave-ZAIA-Medico-1024.svg`;


export const Sidebar = styled.div` 
  flex: 0.19;
  background-color: ${({theme, env}) => env !== 'test' && env !== 'development' ? '' : '#FCFCFE'};
  /* height: ${({webView}) => webView ? '100dvh' : '100svh'}; */
  padding: 14.5px 31px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  .title-container {
    height: 100%;
    cursor: pointer;
    .zaia-title {
      height: 46px;
    }
  }
  .sidebar-close {
    color: ${({theme}) => theme.onPrimaryColor};  
    display: none;
    bottom: 0;
    justify-content: center;
    margin: auto 30px auto;
    font-size: 15px;
    cursor: pointer;
    &-icon {
      display: flex;
      border: 2px solid ${({theme}) => theme.onPrimaryColor};  
      border-radius: 50%;
      padding: 12px;
    }
  }
  .sidebar-tabs-container {
    display: flex;
    .sidebarOption {
      align-items: center;
      /* margin: 15px 26px; */
      color: #68688D;
      padding: 10px;
      font-size: larger;
      font-family: "Nunito Sans";
      cursor: pointer;
      font-size: 17px;

      &:not(:last-child) {
        margin-right: 20px;
      }

      &::after {
        content: '';
        display: flex;
        margin: auto;
        width: 10px;
        display: block;
        height: 3px;
        border-radius: 10px;
        margin-top: 7px;
      }

      &:hover {
        color: #3E3DA3;
        &::after {
          background-color: #B3B3F2;
        }
      }
    }

    .sidebarOption[data-nav="blog"],
    .sidebarOption[data-nav="file-reading"] {
      display: none;
    }

    .sidebar-option-dropdown {
      color: #68688D;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;

      i {
        margin-left: 10px;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 24%;
        width: 10px;
        height: 3px;
        border-radius: 10px;
      }

      &:hover {
        color: #3E3DA3;
        &::after {
          background-color: #B3B3F2;
        }
      }
    }


    .sign-out {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #6C6BCC;
      color: #fff;
      border-radius: 14px;
      padding: 20px;
      outline: none;
      border: none;
      font-family: "Nunito Sans";
      font-size: 15px;
      cursor: pointer;
      width: 200px;
      margin: auto 0 40px;
      &__icon {
        margin-right: 10px;
        font-size: 24px;
      }
    }

    /* sidebarOption:not(:last-child) {
      margin-right: 10px;
    } */
    .sidebar-tab-name {
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none;
      white-space: nowrap;
    }
    .sidebar-tab-alarm {
      width: 10px;
      height: 10px;
      background-color: ${({theme}) => theme.warning};
      border-radius: 50%;
      margin-left: auto;
    }
    .icon-container {
      width: 25px;
      height: 26px;
      overflow: hidden;
      font-size: 24px;
      margin-right: 5px;
    }
    /* .sidebarOption:hover {
      background-color: ${({theme}) => theme.primaryVariantColor}${getHexOpacity(0.22)};
      border-radius: 10px;
    } */
    .active {
      font-family: "Nunito Sans Bold" !important;
      color: #3E3DA3 !important;
      &::after {
        content: '';
        display: flex;
        margin: auto;
        width: 80%;
        display: block;
        height: 3px;
        background-color: #B3B3F2;
        /* border: 2px solid #B3B3F2; */
        border-radius: 10px;
        margin-top: 7px;
      }
    }

    .active-dropdown {
      font-family: "Nunito Sans Bold" !important;
      color: #3E3DA3 !important;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -6px;
        left: 24%;
        width: 80%;
        height: 3px;
        border-radius: 10px;
        background-color: #B3B3F2;
      }
      
      ~ .icon {
        margin-bottom: 5px;
      }
    }
    /* .active:hover {
      background-color: white;
      color: ${({theme}) => theme.primaryColor};
      border-radius: 10px;
    } */

    ::-webkit-scrollbar {
      width: 17px;
      height: 25px;
    }

    ::-webkit-scrollbar-track {
      margin-left: 0px;
      border-radius: 20px;
      box-shadow: inset 0 0 10px 10px transparent;
      border: solid 6px transparent;
      opacity: .5;
      /* background-color: red; */
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      min-height: 50px;
      min-width: 50px;
      border-radius: 20px;
      box-shadow: inset 0 0 10px 10px #ffffff;
      border: solid 5px transparent;
      /* opacity: .8; */
    }
  }

  .sidebar-selected-patient {
    height: 100px;
    padding: 0 18px;
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
    width: 100%;
    box-sizing: border-box;
    p {
      color: ${({theme}) => theme.onPrimaryColor};
      font-family: "Nunito Bold";
    }
    .card-selected-patient {
      margin: 10px 0;
      background-color: ${({theme}) => theme.onPrimaryColorPrimary};
      border-radius: 10px;
      display: flex;
      padding: 5px;
      align-items: center;
      .card-selected-patient-image-container {

      }
      .card-selected-patient-info-container {
        justify-content: space-between;
        cursor: default;
        .card-selected-patient-name {
          font-family: "Nunito Bold";
          color: ${({theme}) => theme.onPrimaryColor};
          display: -webkit-box;
          max-width: 100%;
          max-height: 38px;
          /* height: 43px; */
          margin: 0 auto;
          font-size: 14px;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .card-selected-patient-program {
          /* max-height: 15px;
          width: min-content;
          display: -webkit-box;
          font-size: 12px;
          color: var(--white);
          background-color: var(--blue-color-hover);
          padding: 0 3px 2px 3px;
          border-radius: 5px;
          overflow: hidden;
          text-overflow: ellipsis; */
          font-family: "Nunito";
          color: ${({theme}) => theme.onPrimaryColor};
          display: -webkit-box;
          max-width: max-content;
          max-height: 15px;
          padding: 0 3px 2px 3px;
          font-size: 12px;
          background-color: ${({theme}) => theme.primaryVariantColor}${getHexOpacity(0.4)};
          border-radius: 5px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .card-selected-patient-close-patient {
        font-size: 12px;
        color: ${({theme}) => theme.onPrimaryColor};
        margin: 0 5px;
        cursor: pointer;
        margin-left: auto;
      }
    }
  }

  .user-content {
    background: #F0F0FF;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 1px 2px 1px 20px;
    border-radius: 50px;
    cursor: pointer;

    &__label {
      font-weight: 400;
      color: #36369B;
      font-size: 17px;
      line-height: 23.19px;
      margin-right: 15px;
    }

    .user-user {
      border-radius: 10px;
      cursor: pointer;
      box-shadow: 1px 1px 6px 0px ${({theme}) => theme.blackShadow}29;
    }

    i {
      cursor: pointer;
      z-index: 1;
      color: #36369B; 
      font-size: 13px;
      margin-left: 10px;
    }
  }

  .menu-sign-out {
    display: none;
  }

  .menu-trigger {
    font-size: 24px;
    display: none;
    align-items: center;
    cursor: pointer;
  }

  @media only screen and (max-width: 1350px) {
    .sidebar-tabs-container {
      position: absolute;
      padding: 21px 0 0 28px;
      background-color: white;
      flex-direction: column;
      width: 100%;
      height: calc(100dvh - 74px);
      box-sizing: border-box;
      left: 0;
      top: 74px;
      margin-left: 0;
      display: ${({menuactive}) => menuactive ? 'flex' : 'none'};
      .sidebarOption {
        width: fit-content;
      }

      .sidebarOption[data-nav="blog"],
      .sidebarOption[data-nav="file-reading"] {
        display: block;
      }

      .sidebar-option-dropdown {
        display: none;
      }
    }

    .menu-sign-out {
      display: flex;
      margin-top: auto;
    }

    .menu-trigger {
      display: flex;
    }

    .user-content {
      display: none;
    }
  }

  /* @media only screen and (max-width: 900px) {
    .title-container {
      background-image: url(${background900});
    }
  }

  @media only screen and (max-width: 750px) {
    .title-container {
      background-image: url(${background750});
    }
  }
  
  @media only screen and (max-width: 750px) {
    .title-container {
      background-image: url(${background750});
    }
  }
  
  @media only screen and (max-width: 550px) {
    .title-container {
      background-image: url(${background550});
    }
  }

  @media only screen and (max-width: 450px) {
    .title-container {
      background-image: url(${background450});
    }
  }

  @media only screen and (max-width: 390px) {
    .title-container {
      background-image: url(${background390});
    }
  }

  @media only screen and (max-width: 360px) {
    .title-container {
      background-image: url(${background});
    }
  } */

`

export const DropdownContent = styled.div`
  border-radius: 20px;
  flex-direction: column;
  background-color: #fff;
  width: 248px;
  min-height: 90px;
  box-shadow: 0px 4px 4px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 15px 15px;

  .label {
    cursor: pointer;
    color: #68688D;
    font-size: 17px;
    font-weight: 400;
    line-height: 23.19px;
    border-radius: 10px;
    padding: 10px 10px;

    &:hover {
      background: #F0F0FF;
    }
  }
`


export const ProcessNameContainer = styled.div` 
  background-color: ${({theme}) => theme.background};
  padding: 5px;
  border-radius: 7px;
  cursor: default;
  box-shadow: 0px 3px 6px ${({theme}) => theme.blackShadow}29;
`

export const ProcessName = styled.div` 
  font-size: 17px;
  font-family: "Nunito Sans Light";
  border-bottom: ${({last, theme}) => !last ? '1px solid ' + theme.lineSeparation : null};
  /* margin: 5px 0px; */
  padding: 2px 5px; 
  .card-selected-patient-process {
    color: #526479 !important;
  }
`

export const AccountUserMenu = styled.div` 

  position: absolute;
  display: flex;
  width: 100vw ;
  background: rgba(112, 112, 125, 0.56);
  backdrop-filter: blur(11.5px);
  height: calc(100dvh - 74px);
  
  .menu {
    height: 324px;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    position: absolute;
    padding: 20px 30px 40px 30px;
    box-sizing: border-box;
    display: flex;
    &__left {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
    }
    &__right {
      display: flex;
      flex-direction: column;
      height: 100%;
      width: 100%;
      .sign-out {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #6C6BCC;
        color: #fff;
        border-radius: 14px;
        padding: 20px;
        outline: none;
        border: none;
        font-family: "Nunito Sans";
        font-size: 15px;
        cursor: pointer;
        margin-top: auto;
        margin-left: auto;
        &__icon {
          margin-right: 10px;
          font-size: 24px;
        }
      }
    }
    &__name {
      color: #526479;
      font-family: "Nunito Sans Bold";
      font-size: 42.819px;
    }
    &__email {
      color: #526479;
      font-family: Nunito;
      font-size: 15px;
      margin-bottom: 22px;
    }
    &__action {
      cursor: pointer;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      &-icon {
        font-size: 16px;
        margin-right: 8.5px;
        color: #41417B;
        opacity: 0.8;
      }
      &-text {
        color: #41417B;
        font-family: "Nunito Sans";
        font-size: 17px;
      }
    }
    &__bottom {
      display: flex;
      margin-top: auto;
    }
    &__link { 
      cursor: pointer;
      color: #3E3DA3;
      font-family: "Nunito Sans";
      font-size: 15px;
      text-decoration-line: underline;
      &-separator { 
        margin: 0 10px;
        border-right: 1px #C9C9E9 solid;
      }
    }
  }
  .background {
    height: 100%;
    width: 100%;
    /* position: absolute; */
  }
`
