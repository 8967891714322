import React, { useEffect, useState } from "react";
import * as S from "./ModalSelectPatient.style"
import { Actions } from "../../../../../utils/actionsIds";
import * as P from "../../../../../utils/pipes";

// pipes
import { QUERY_GET_USERS } from "../../../../../graphql/queries/User";
import { useQuery } from "@apollo/client";
import ZaiaReaction from "../../../../zaiaComponents/zaiaReaction";
import { useTranslation } from "react-i18next";
import useRecordNavigation from "../../../../../hooks/useRecordNavigation";
import ZaiaImage from "../../../../zaiaComponents/zaiaImage";
import Popup from "reactjs-popup";
import ProcessName from "../../processName";
import usePatientList from "../../../../../hooks/usePatientList";

const eventsCalendarEmpty = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function ModalSelectPatient({ 
  setModalActive,
  setPatient,
  patientSelected,
  idAction
}) {

  const {t, i18n: { language }} = useTranslation("global"); 
  const { recordAction } = useRecordNavigation();

  const {
    patientList,
    patientListLoading,
    patientListError
  } = usePatientList()
  

  // const { 
  //   data: patientsData,
  //   loading: loadingPatientsData,
  //   error: errorPatientsData,
  // } = useQuery(
  //   QUERY_GET_USERS
  // );


  const [inputText, setInputText] = useState('');
  const [selectedPatient, setSelectedPatient] = useState(patientSelected);
  
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        setModalActive(null);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    // Don't forget to clean up
    return function cleanup() {
      document.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  const getFilterText = (text) => {
    return inputText 
      ? String(P.removeAccent(text)).toLowerCase().includes(P.removeAccent(inputText).toLowerCase())
      : true
  }

  return (
    <S.ModalSelectPatient>
      <div className="modal-background" onClick={() => setModalActive(false)}>
      </div>
      <div className="modal-container">
        <div className="header">
          <div className="header__text">
            <div className="header__title">{t("globally.selectPatient")}</div>
          </div>
          <div className="header__close" onClick={() => setModalActive(false)}>
            <i className="header__close-icon icon zaia-icono-cerrar"/>
          </div>
        </div>
        <div className={`filter-patient`}>
          <input 
            className={`filter-patient__input`} 
            type="text" 
            placeholder={t("globally.searchPatient")} 
            onChange={(event) => setInputText(event.target.value)}
          />
          <i className={`filter-patient__icon icon zaia-search`}></i>
        </div>
        <div className="users-list">
          {
            patientListError && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaAlert}
                text={t("globally.resultAlarm")}
                sizeText="20px"
                textcolor="var(--blue-color)"
                widthimg="110px"
              />
            </div>
          }
          {
            patientListLoading && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={logoZaiaLoading}
                sizeText="20px"
                textcolor="var(--blue-color)"
                widthimg="110px"
              />
            </div>
          }
          {
            !patientListError && !patientListLoading && (patientList?.length === 0 || patientList?.filter((patient) => getFilterText(`${patient.name} ${patient.lastname}`)).length === 0) && 
            <div className="users-list__responses">
              <ZaiaReaction
                zaiaImg={eventsCalendarEmpty}
                text={t("globally.noResults")}
                subText={t("globally.noMatchingPatients")}
                sizeText="33.25px"
                sizeSubText="15px"
                textcolor="var(--blue-color)"
                widthimg="210px"
              />
            </div>
          }
          {
            !patientListError && !patientListLoading && patientList?.length !== 0 &&
            patientList?.map((patient) => 
              {
                if(getFilterText(`${patient.name} ${patient.lastname}`)) {
                  return(
                  <div 
                    className={`user-item ${selectedPatient?.id === patient?.id && 'user-item--selected'}`}  
                    onClick={() => 
                      {
                        if(selectedPatient?.id !== patient?.id) {
                          recordAction(idAction, patient.id);
                          setSelectedPatient(patient);
                          setPatient(patient)
                        }
                      }
                    }
                  >
                    <ZaiaImage
                      size={55}
                      img={patient.picture}
                      rounded={true}
                    />
                    <div className="user-item__info">
                      <div className="user-item__name">{`${patient.name} ${patient.lastname}`}</div>
                      <div className={`user-item__program ${selectedPatient?.id === patient?.id && 'user-item__program--selected'}`}>
                        {
                          <Popup
                            trigger={
                              <div className="patient-option__info-program-detail">
                                {
                                  patient?.userToProcesses.length > 1 
                                  ? <div style={{"textDecoration": "underline"}}>{t("globally.variousProcesses")}</div> : 
                                  <div>{P.dataTranslation(patient?.userToProcesses[0].process?.processName, language)}</div>
                                  
                                }
                              </div>
                            }
                            keepTooltipInside
                            nested
                            // position={"right bottom"}
                            on="hover"
                            disabled={patient?.userToProcesses.length > 1 ? false : true}
                          >
                            {
                              <ProcessName userToProcesses={patient?.userToProcesses}/>
                            }
                          </Popup>
                        }
                      </div>
                    </div>
                  </div>
                  )
                } else {
                  return null
                }
              }
            )
          }
        </div>
      </div>
    </S.ModalSelectPatient>
  );
}

export default ModalSelectPatient;
