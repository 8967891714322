import React, { useEffect, useState } from "react";
import * as S from "./FollowUpStages.style";
import * as P from '../../../../../../utils/pipes';
import ZaiaSelect from "../../../../../zaiaComponents/zaiaSelect";
import { useMutation, useQuery } from "@apollo/client";
import { QUERY_GET_PROCESS_STAGES_BY_PROCESS_IDS } from "../../../../../../graphql/queries/UserToProcess";
import { useTranslation } from "react-i18next";
import { MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP } from "../../../../../../graphql/mutations/UserToProcess";

function FollowUpStages({
  setActiveFollowUpStages,
  followUpInfo
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [updateUserToProcess, {loading: updateUserToProcessLoading, error: updateUserToProcessError}] = useMutation(MUTATION_UPDATE_USER_TO_PROCESS_FOLLOW_UP);

  const { data: processStagesData } = useQuery(QUERY_GET_PROCESS_STAGES_BY_PROCESS_IDS, {
    variables: { 
      processIds: followUpInfo?.map(info => info.processId)
    },
  })
  const [selectedStages, setSelectedStages] = useState([])
  const [userToProcessStages, setUserToProcessStages] = useState([])

  useEffect(() => {
    setSelectedStages(
      processStagesData?.process.map((process => {
        return (followUpInfo.find(info => info.processId === process.id)?.stageId)
      }))
    )
    setUserToProcessStages(
      processStagesData?.process.map((process => {
        return (followUpInfo.find(info => info.processId === process.id))
      }))
    )
  }, [processStagesData])

  const frequency = (stage) => {
    if(stage.frequencyType === "days") {
      return (
        `${t("globally.each")}
        ${stage.frequency > 1 ? stage.frequency : ""} 
        ${stage.frequency > 1 ? t("globally.days") : t("globally.day")}`
      ) 
    } else if(stage.frequencyType === "months") {
      return (
        `${t("globally.each")} 
        ${stage.frequency > 1 ? stage.frequency : ""} 
        ${stage.frequency > 1 ? t("globally.months") : t("globally.month")}`
      ) 
    } else {
      return (`${t("globally.each")} ${stage.frequency}`)
    }
  } 

  const updateProcess = async (value, index) => {
    try {
      const {
        data: {
          hola
        },
      } = await updateUserToProcess({
        variables: {
          updates: {
            stageId: value,
          },
          userToProcessId: userToProcessStages[index].id
        },
      });
      setSelectedStages([]);
      setSelectedStages([
        ...selectedStages.slice(0, index),
        value,
        ...selectedStages.slice(index + 1)
      ]);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <S.FollowUpStages>
      <div className="follow-up-header">
        <div className="return-button"
          onClick={() => setActiveFollowUpStages(false)}
        >
          <i className="return-icon icon zaia-icono-atras"></i>
        </div>
        <div className="initial-text">
          {t("followUp.stageAssignment")}
        </div>
      </div>
      <div className="follow-ups-list">
        {
          processStagesData?.process.map(
            (item, index) => {
              return (
                <div className="follow-up-item"
                  key={item.id}
                >
                  <div className="follow-up-item__program">
                    {P.dataTranslation(item.processName, language)}
                  </div>
                  <div className="follow-up-item__content">
                    <div className="follow-up-item__select">
                      <ZaiaSelect
                        placeholder={"Seleccione una opción de etapa"}
                        padding={"5px 15px"}
                        fontSize={"17px"}
                        backgroundColor={"transparent"}
                        borderColor={"#6C6BCC"}
                        textcolor={"#6C6BCC"}
                        arrowColor={"#6C6BCC"}
                        placeholdertextcolor={"#6C6BCC"}
                        borderRadius={"10px"}
                        items={item.processStages.map((stage) => ({
                          value: stage.id,
                          text: P.dataTranslation(stage.stageName, language)
                        }))}
                        value={selectedStages ? selectedStages[index] : null}
                        setValue={(value) => {
                            updateProcess(value, index)
                          } 
                        }
                      />
                    </div>
                    <div className="follow-up-item__frequency">
                      {
                        // false 
                        // ? "This patient has no following for this program"
                        // : 
                        <>
                          {t("followUp.followUpFrequency")}
                          <b>
                            {
                              selectedStages && selectedStages[index] && frequency(item.processStages.find(stage => stage.id === selectedStages[index]))
                            }
                          </b>
                        </>
                      }
                    </div>
                  </div>
                </div>
              )
            }
          )
        }
      </div>
    </S.FollowUpStages>
  );
}

export default FollowUpStages;
