import styled from "styled-components";

export const MessageDisplayDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  height: 100%;
  width: 100%;
  .messageDisplay__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    height: 18px;
    border-bottom: 1px solid #f3f3f3;
    height: 60px;
    box-sizing: border-box;
    .messageDisplay__headerBack{
      margin-right: 10px;
      display: none;
      font-size: 22px;
      i {
        display: flex;
      }
    }
    .messageDisplay__headerName {
      display: flex;
      width: 100%;
      align-items: center;
      position: relative;
      .oneSingleChat__imgContainer{
        position: relative;
        .oneSingleChat__online {
          background-color: ${(props) => props.online ? "var(--green-color)" : "var(--red-color)"};
          height: 12px;
          width: 12px;
          border-radius: 50%;
          position: absolute;
          right: 3px;
          top: -4px;
          border: 2px solid var(--white);
        }
      }
      /* img {
        width: 56px;
        height: 56px;
        border-radius: 10px;
        margin-right: 8px;
        object-fit: cover;
      } */
      h4 {
        color: var(--title-register-color);
        font-family: "Nunito ExtraBold";
        font-size: 20px;
        margin-left: 8px;
      }
    }
    .zaia-icono-dots-horizontal {
      margin-right: 5px;
      cursor: pointer;
    }
  }
  /* .messageDisplay__containerDate {
    color: var(--subtitle-color);
    font-size: 0.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .messageDisplay__containerMsgs {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .messageDisplay__containerMsg {
      margin-left: 10px;
      font-size: xx-small;
      .chat__reciever {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        p {
          font-size: 0.88rem;
          padding: 10px;
          border-radius: 10px 10px 0 10px;
          width: fit-content;
          background-color: var(--blue-color);
          color: var(--white);
          margin-left: auto;
          font-family: "Nunito";
        }
        small {
          margin-left: auto;
          color: var(--subtitle-color);
        }
      }
      .chat__patient {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        p {
          background-color: var(--footer-color);
          font-size: 0.88rem;
          padding: 10px;
          border-radius: 10px 10px 10px 0;
          width: fit-content;
          font-family: "Nunito";
        }
        small {
          color: var(--subtitle-color);
        }
      }
    }
  }
  .messageDisplay__containerFooter {
    background-color: var(--footer-color);
    padding: 15px;
    display: flex;
    color: var(--blue-color);
    align-items: center;
    justify-content: space-evenly;
    p {
      font-size: 2rem;
    }
    input {
      border: none;
      border-radius: 20px;
      padding: 10px;
      width: 540px;
      margin: 0 5px 0 5px;
      &:focus {
        outline: none;
      }
      ::placeholder {
        color: #e3e3e3;
      }
    } 
  }*/
  .rightside__container-msgs {
    display: flex;
    height: -webkit-fill-available;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    .rightside__msgs {
      padding: 10px 10px 0 30px;
      /* padding-top: 10px; */
      width: 100%;
      display: flex;
      flex-direction: column-reverse;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        border: solid 10px transparent;
        box-shadow: inset 0 0 10px 10px transparent;      
      }
    }
  }
  .messageDisplay__containerFooter {
    background-color: var(--footer-color);
    padding: 15px 0;
    display: flex;
    color: var(--blue-color);
    align-items: center;
    justify-content: space-evenly;
    input {
      border: none;
      border-radius: 20px;
      padding: 10px;
      width: 85%;
      margin: 0 5px 0 5px;
      outline: none;
      ::placeholder {
        color: #e3e3e3;
        font-family: "Nunito";
      }
    }
    button {
      color: var(--blue-color);
      border: none;
      cursor: pointer;  
      display: flex;
      margin: 0 10px;
      .send-icon{
        font-size: 20px;
        margin-bottom: -6px;
        &--big {
          font-size: 25px;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .messageDisplay__headerBack {
      display: block !important;
    }
  }
`;

export const ChatSettings = styled.div`
  position: relative;
  background-color: var(--white);
  height: auto;
  width: 160px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  &::-webkit-scrollbar{
    display: none;
  }
  .chat-settings-box {
    font-family: "Nunito";
    font-size: 15px;
    color: var(--blue-color) !important;
    display: flex;
    padding: 10px 0;
    justify-content: center;
    cursor: pointer;
    .icono-settings {
      font-size: 20px;
      margin-right: 10px;
      margin-bottom: -6px;
    }
  }
`

export const MessagesTypes = styled.div`
  position: relative;
  display: flex;
  background-color: var(--white);
  height: auto;
  overflow-y: auto;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  padding: 0 5px;
  &::-webkit-scrollbar{
    display: none;
  }
  .chat-settings-box {
    font-family: "Nunito";
    font-size: 15px;
    color: var(--white) !important;
    display: flex;
    justify-content: center;
    background-color: ${({theme}) => theme.primarySectionMedicines};
    border-radius: 10px;
    padding: 8px;
    margin: 10px 8px;
    cursor: pointer;
    .icono-settings {
      font-size: 20px;
      display: flex;
    }
  }
`
