import styled from "styled-components";

export const DropdownCheckDiv = styled.div`
  width: 180px;
  /* position: absolute; */
  display: flex;
  background-color: ${({theme}) => theme.background};
  flex-direction: column;
  color: var(--blue-color);
  justify-content: center;
  z-index: 100;
`;

export const DropdownCheckOptionsDiv = styled.div`
  display: flex;
  align-items: center;
  padding: 3px 1px;
  input[type="checkbox"] {
    outline: none;
    padding: 2px;
    cursor: pointer;
    /* border: 1px solid var(--blue-color); */
  }
  label {
    user-select: none; 
    cursor: pointer;
    margin: 0 10px 0 5px;
    font-size: 0.8rem;
    color: ${({theme}) => theme.onBackground} !important;
  }
  .process-quantity {
    color: ${({theme}) => theme.onBackground} !important;
    font-size: 0.8rem;
    margin-left: auto;
    font-family: "Nunito Bold";
  }
`;
