import React, { useRef, useState } from "react";
import * as S from "./ChatbotPatientCase.style"
import { useLazyQuery } from "@apollo/client";
import { CONSULT_PATIENT_HISTORY_CHAT_IN_FLORENCE_MEDPALM } from "../../../../../../graphql/mutations/Chat";
import useSize from "../../../../../../hooks/useSize";
import { useTranslation } from "react-i18next";
import useDoctorData from "../../../../../../hooks/useDoctorData";
import ZaialoadingPoints from "../../../../../zaiaComponents/zaiaLoadingPoints";
import ChatbotInput from "../../chatbotInput";
import Message from "../../message";
import ModalSelectPatient from "../../../../shared/modals/modalSelectPatient";
import Modal from "../../../../../../utils/modal";

const bgChatbot = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/chat-background.svg`
const defaultPatientImg = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaImgDefault/default-user2.svg`

function ChatbotPatientCase(
  {
    selectedPatient,
    selectPatient
  }
) {

  const footerRef = useRef();
  const { height } = useSize(footerRef);
  const element = document.getElementById("chatbot-container");
  const {t, i18n: { language }} = useTranslation("global");
  const { doctorData } = useDoctorData();
  const [activeModalPatient, setActiveModalPatient] = useState(false);
  const ID_STANDARD_PATIENT_GUIDE_MEDPALM = process.env.REACT_APP_ID_STANDARD_PATIENT_GUIDE_MEDPALM;

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    patientMessage(values.msg, 'doctor');
    sendBotMessage(values.msg, setSubmitting, resetForm);
    autoScroll();
  };

  const sendBotMessage = async (msg, setSubmitting, resetForm) => {    
    try {
      if(resetForm) resetForm();
      if(setSubmitting) setSubmitting(false);
      sendChatbotMessage(msg);
    } catch (error) {
      console.log(error);
    }
  }


  const patientMessage = (msg, type) => {
    let allMessages = messages;
    allMessages.push(
      {
        id: Date.now(),
        message: msg,
        senderName: `${doctorData.name} ${doctorData.lastname}`,
        type: type
      }
    )
    setMessages([...allMessages])
  }

  const autoScroll = () => {
    if(element) element.scrollTop = element.scrollHeight - element.clientHeight;
  }

  const [chatBotPatient, {loading: chatBotPatientLoading}] = useLazyQuery(
    CONSULT_PATIENT_HISTORY_CHAT_IN_FLORENCE_MEDPALM,
    {
      fetchPolicy: "network-only"
    }
  );

  const sendChatbotMessage = async (msg) => {
    const {
      data: {
        consult_medical_history_in_florence,
      },
    } = await chatBotPatient({
      variables: {
        language: language.split('-')[0],
        question: msg,
        user_id: ID_STANDARD_PATIENT_GUIDE_MEDPALM
        // user_id: selectedPatient?.id
      },
    });

    if (!chatBotPatientLoading ) {
      setMessages(
        [
          ...messages,
          {
            id: Date.now(),
            message: consult_medical_history_in_florence.answer,
            senderName: t("globally.chatbotName"),
            type: 'bot',
          }
        ]
      )
      autoScroll()
    }
  }
  
  const [messages, setMessages] = useState([]);

  return (
    <S.ChatbotPatientCase 
      footerHeight={height}
      selectedPatient={selectedPatient}
    >
      { 
        <Modal open={activeModalPatient}>
          <ModalSelectPatient
            setModalActive={setActiveModalPatient}
            setPatient={selectPatient}
          />
        </Modal>
      }
      <div className="chatbot-selectors">
        {
          selectedPatient 
          ? <div className="patient-selected" onClick={() => setActiveModalPatient(true)}>
            <div className="patient-selected__box-image">
              <img src={selectedPatient.picture ?? defaultPatientImg} alt="user" className="patient-selected__image" />
            </div>
            <div className="patient-selected__name">
              {`${selectedPatient.name} ${selectedPatient.lastname}`}
            </div>
          </div>
          : <div className="patient-selector" onClick={() => setActiveModalPatient(true)}>
            <div className="patient-selector__box-icon">
              {
                defaultPatientImg &&
                <img src={defaultPatientImg} alt="user" className="patient-selector__icon" />
              }
            </div>
            <div className="patient-selector__text">
              {t("chatbotAI.selectPatient")}
            </div>
          </div>
        }
      </div>
      <div className="chatbot-container" id="chatbot-container">
        {
          messages.length === 0 &&
          <div className="chatbot-container__empty">
            <img src={bgChatbot} alt="" className="chatbot-container__empty-image" />
            <div className="chatbot-container__empty-msg">
              {t("chatbotAI.patients.letsTalk")}
            </div>
          </div>
        }
        {messages.map((message, index) => (
          <div key={message.id}>
            { 
              <Message
                key={index}
                message={message.message?.replace('0chatgpt', '')}
                senderName={message.senderName}
                option={message.type}
              />
            }
          </div>
        ))}
        { 
          chatBotPatientLoading
          ? <Message
            senderName={t("globally.chatbotName")}
          >
            <ZaialoadingPoints />
          </Message> 
          : null
        }
      </div>
      <div className="chatbot-footer" ref={footerRef}>
        <ChatbotInput
          chatbotDisabled={!selectedPatient}
          disabledText={t("chatbotAI.patients.inputCondition")}
          handleSubmit={handleSubmit}
        />
      </div>
    </S.ChatbotPatientCase>
  );

}

export default ChatbotPatientCase;
