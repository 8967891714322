import styled from 'styled-components';

export const FollowUpBox = styled.div`
  padding: 10px 10px;
  display: flex;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 14px;
  cursor: pointer;

  .left-content {
    width: 30%;
    display: flex;
    align-items: center;
    margin-right: 20px;
    position: relative;

    .img-container {
      margin-right: 10px;
      .patient-img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .patient-info {
      overflow: hidden;

      &__frecuency {
        width: 70%;
        color: var(--textiii-grey, #707582);
        text-align: center;
        font-family: 'Nunito Sans';
        font-size: 14px;
        text-align: start;
        b {
          margin-left: 5px;
        }
      }

      &__name {
        width: 70%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #3e3da3;
        font-family: 'Nunito Sans';
        font-size: 22px;
        margin: 5px 0;
      }
    }

    &::after {
      content: '';
      width: 1px;
      height: 60%;
      background-color: #e2dedf;
      position: absolute;
      right: 12px;
    }
  }

  .center-content {
    width: 30%;
    display: flex;
    align-items: center;
    position: relative;
    padding-right: 30px;
    box-sizing: border-box;

    .program-info {
      width: 100%;
      &__label {
        font-size: 14px;
        font-weight: 400;
        color: #707582;
      }

      &__value {
        font-size: 16px;
        font-weight: 400;
        color: #1f1f52;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &::after {
      content: '';
      width: 1px;
      height: 60%;
      background-color: #e2dedf;
      position: absolute;
      right: 30px;
    }
  }

  .right-content {
    width: 33%;
    display: flex;
    border-radius: 14px;
    background: ${({ color }) => color};
    padding: 10px;
    transition: background-color 0.8s;
    &:hover {
      background-color: ${({ hoverColor }) => hoverColor};
    }

    .dates-box {
      display: flex;
      align-items: center;
      width: 100%;

      .date-container {
        display: flex;
        align-items: start;
        color: #ffffff;

        &__info {
          overflow: hidden;
        }

        &__icon {
          margin-right: 10px;
          margin-top: 15px;
          font-size: 15px;
        }

        &__info-state {
          display: flex;
          align-items: center;

          &-days-time {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 5px;
          }

          b {
            font-size: 18.789px;
          }
        }

        &__info-date {
          margin-top: -3px;
          color: #fff;
          font-family: 'Nunito Sans';
          font-size: 17px;
        }

        &__info-final-date {
          font-size: 10px;
          font-weight: 400;
          margin-top: 10px;
          line-height: 13.64px;

          &--days {
            font-weight: 700;
            margin-left: 6px;
          }
        }

        &--initial-date {
          padding-right: 10px;
          width: 100%;
        }

        &--final-date {
          width: 48%;
          padding-left: 28px;
        }
      }
    }
  }
`;
