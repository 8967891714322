import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";
const bgFlorence = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/background-florence.svg`;

export const ModalUserAiNavigator = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    max-height: 90%;
    z-index: 200;
    background-color: #F0EFF9;
    width: 90%;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: end;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 1200px;
    &__reaction {
      width: 100%;
      margin: 200px 0;
    }
    .header{
      position: absolute;
      top: 20px;
      left: 25px;
      z-index: 100;
      .close {
        width: 36px;
        height: 36px;
        background-color: #FCFCFE;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        cursor: pointer;
        color: #36369B;
        box-shadow: 0px 0px 17.9px 0px #DADAF6;
      }
    }

    .content {    
      display: flex;
      flex-direction: column;
      padding: 30px 35px;
      width: 100%;
      box-sizing: border-box;
      position: relative;
      height: 100%;
      .patient-card{
        margin-bottom: 20px;
        padding: 20px 26px;
        border-radius: 14px;
        background: #FFF;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        &__top {
          display: flex;
          justify-content: space-between;
        }
        &__info {
          margin-bottom: 10px;
          align-items: center;
          display: flex;
          width: 100%;
          .patient-image {
            width: 61.74px;
            height: 108.47px;
            border-radius: 80px;
            border: 2px solid #FFFFFF;
            box-shadow: 0px 0px 8.1px 0px #DADAF6;
            object-fit: cover;
          }
          .patient-info {
            width: calc(100% - 83.74px);
            margin-left: 20px;
            &__name {
              color: #1F1F52;
              font-family: "Nunito Sans";
              font-size: 30px;
            }
            &__email {
              color: #707582;
              font-family: "Nunito Sans Light";
              font-size: 17px;
              margin-bottom: 12px;
            }
          }
        }
        &__left {
          width: 80%;
        }
        &__right {
          width: 20%;
          .numbers {
            display: flex;
            padding: 10px 40px 10px 20px;
            flex-direction: column;
            border-radius: 10px;
            background: #F0EFF9;
            box-sizing: border-box;
            height: 100%;
            &__title {
              display: flex;
              &-text {	
                color: #1F1F52;
                font-family: "Nunito Sans Bold";
                font-size: 14px;
                margin-right: 10px;
                white-space: nowrap;
              }
              &-icon {
                font-size: 15px;
              }
            }
            .number {
              margin-top: 10px;
              &__title {
                font-family: Nunito Sans;
                font-size: 12px;
                font-weight: 300;
                color: #4A4A75; 
              }
              &__phone {
                font-family: Nunito Sans;
                font-size: 14px;
                font-weight: 300;
                color: #1F1F52;
              }
            }
          }
          .button-email {
            display: flex;
            width: 244px;
            margin-top: 10px;
            padding: 10px 20px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 10px;
            background: #6C6BCC;
            color: #FFF;
            text-align: center;
            box-shadow: 0px 0px 10px -3.111px rgba(35, 34, 157, 0.25);
            border: none;
            outline: none;
            cursor: pointer;
            &__text {
                font-family: "Nunito Sans Light";
                font-size: 14px;
            }
          }
        }
        .patient-info {
          &__program {
            display: flex;
            flex-wrap: wrap;
            height: 42px;
            overflow: hidden;
            .program-box {
              font-size: 14px;
              margin-right: 15px;
              border-radius: 10px;
              color: #3E3E5B;
              &__name {
                white-space: nowrap;
                display: flex;
                i {
                  margin-right: 5px;
                }
              }
              &__status {
                white-space: nowrap;
                padding: 0px 10px;
                border-radius: 10px;
                width: fit-content;
                background-color: #F0F0FF;
              }
            }
            .more-programs {
              display: flex;
              align-items: center;
              font-size: 14px;
              background-color: #6C6BC6;
              cursor: pointer;
              height: 42px;
              width: 115px;
              border-radius: 50px;
              color: #FFFFFF;
              border: none;
              outline: none;
              justify-content: center;
            }
          }
          &__shortcuts {
            display: flex;
            .shortcut {
              display: flex;
              cursor: pointer;
              padding: 4px 10px;
              border-radius: 28px;
              background: #EFEEF8;
              margin-right: 12px;
              color: #36369B;
              text-align: center;
              text-shadow: 0px 0px 11.4px rgba(255, 255, 255, 0.73);
              font-family: "Nunito Sans";
              font-size: 16px;
              align-items: center;
              &__icon {
                margin-left: 10px;
                color: #6C6BC6;
                font-size: 17px;
                &--status {
                  font-size: 23px;
                }
              } 
            }
          }
        }
      }  
      .user-ainavigator-boxes {
        height: calc(100% - 177px);
        position: relative;
        overflow: auto;
      }
    }
  }
`;

export const ProgramsContainer = styled.div`
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 0px 28px 0px #37358F26;
  padding: 20px;
  max-height: 300px;
  overflow: auto;
  .title {
    color: var(--TEXT-I, #1F1F52);
    margin-bottom: 10px;
    font-family: "Nunito Sans Bold";
  }
  .program-box {
    overflow: auto;
    font-size: 14px;
    margin-bottom: 5px;
    border-radius: 10px;
    color: #3E3E5B;
    &__name {
      white-space: nowrap;
      display: flex;
      i {
        margin-right: 5px;
      }
    }
    &__status {
      white-space: nowrap;
      padding: 0px 10px;
      border-radius: 10px;
      width: fit-content;
      background-color: #F0F0FF;
    }
  }
`;