import React, { useState, useEffect } from "react";
import * as S from "./NewEventModal.style";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

// pipes
import * as P from "../../../../../../../../utils/pipes";

// components
import ZaiaSelect from "../../../../../../../zaiaComponents/zaiaSelect";
import ZaiaInputForm from "../../../../../../../zaiaComponents/zaiaInputForm";
import ZaiaButton from "../../../../../../../zaiaComponents/zaiaButton";

// Hooks
import useDoctorData from "../../../../../../../../hooks/useDoctorData";

// GraphQL
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS } from "../../../../../../../../graphql/mutations/UserToProcess";
import { QUERY_GET_USER_TO_PROCESS } from "../../../../../../../../graphql/queries/UserToProcess";
import ZaiaReaction from "../../../../../../../zaiaComponents/zaiaReaction";
import { QUERY_GET_CUSTOM_FORMS_BY_IDS } from "../../../../../../../../graphql/queries/CustomForm";
import GenericTemplate from "../../../../../../outcomes/components/outcomesComponents/shared/genericTemplate";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function NewEventModal({
  patientID,
  processID,
  processToCustomFormID,
  customFormFieldID,
}) {
  const {
    t,
    i18n: { language },
  } = useTranslation("global");
  const { doctorData } = useDoctorData();
  const [customFormsList, setCustomFormsList] = useState();
  const [editUserToProcess] = useMutation(
    MUTATION_UPDATE_USER_TO_PROCESS_AND_CUSTOM_FORMS
  );

  const {
    data: userToProcessData,
    loading: userToProcessDataLoading,
    error: userToProcessDataError,
  } = useQuery(QUERY_GET_USER_TO_PROCESS, {
    variables: { patientId: patientID, processId: processID },
  });

  const {
    data: customForm,
    loading: customFormLoading,
    error: customFormError,
  } = useQuery(QUERY_GET_CUSTOM_FORMS_BY_IDS, {
    variables: {
      userId: patientID,
      customFormId: processToCustomFormID,
      userToProcessId: userToProcessData?.userToProcess[0]?.id,
    },
  });

  useEffect(() => {
    setCustomFormsList([...customForm?.customForm_by_pk.formGroups]);
  }, [customForm])

  const [customFormChangesList, setCustomFormChangesList] = useState([]);
  const [, setCustomFormFieldsInit] = useState([]);

  const insertFormField = async (e) => {
    let tempList = customFormChangesList.filter(
      (item) =>
        item.customFormFieldId !== e.customFormFieldId &&
        (item.customFormFieldParentId === e.customFormFieldId
          ? item.parentKey === e.key
          : true)
    );
    setCustomFormChangesList([...tempList, e]);
  };

  const [cmpSelected, setCmpSelected] = useState(null);
  const [cmpDescript, setCmpDescript] = useState("");

  useEffect(() => {
    if (customFormFieldID) handleChangesSelect(customFormFieldID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFormFieldID, customForm]);

  useEffect(() => {
    const customFormFieldsInitTemp = cmpSelected?.customFormFields.map(
      (field) => {
        return {
          customFormFieldId: field.id,
          value: field?.userToCustomFormFields[0]?.value,
          key: field?.userToCustomFormFields[0]?.key
        };
      }
    );
    setCustomFormFieldsInit(customFormFieldsInitTemp);
  }, [cmpSelected]);

  const handleSubmit = async (values, { resetForm }) => {
    const objectCustomFormChanges = customFormChangesList.map((item) => {
      return {
        customFormFieldId: item.customFormFieldId,
        value: item.value,
        key: item.key,
        doctorId: doctorData.id,
        userId: patientID,
      };
    });

    const changesString = customFormChangesList.map((item) => {
      if (item.value instanceof Date)
        return " " + P.shortDate(item.value, t, true, language);
      else return " " + item.value;
    });

    try {
      const { data: returning } = await editUserToProcess({
        variables: {
          processId: processID,
          userId: patientID,
          doctorId: doctorData.id,
          stageId: "enroll",
          descript: values.description + " -" + changesString,
          latestCustomFormId: cmpSelected.id,
          // updated_at: new Date(Date.now())
          objectsCustomForms: objectCustomFormChanges,
        },
      });
      resetForm();
      setCmpSelected(null);
      setCmpDescript(null);
    } catch (err) {
      resetForm();
      setCmpSelected(null);
      setCmpDescript(null);
      console.log(err);
    }
  };

  const handleChangesSelect = (e) => {
    const tempDescript = customForm?.customForm_by_pk.formGroups
      ?.find((cmp) => cmp.id === e)
      .userToProcesses[0]?.descript.split("-")[0];
    setCmpSelected(
      customForm?.customForm_by_pk.formGroups?.find((cmp) => cmp.id === e)
    );
    setCmpDescript(
      tempDescript === "undefined " || tempDescript === undefined
        ? ""
        : tempDescript
    );
  };

  useEffect(() => {}, [cmpSelected, cmpDescript]);

  if (userToProcessDataError || customFormError)
    return (
      <S.ModalContainerDiv>
        <div className="container">
          <div className="card-inputs">
            <ZaiaReaction
              zaiaImg={logoZaiaAlert}
              widthimg="170px"
              text={t("globally.genericError")}
              sizeText="18px"
              widthText="290px"
            />
          </div>
        </div>
      </S.ModalContainerDiv>
    );

  if (userToProcessDataLoading || customFormLoading)
    return (
      <S.ModalContainerDiv>
        <div className="container">
          <div className="card-inputs">
            <ZaiaReaction widthimg="150px" zaiaImg={logoZaiaLoading} />
          </div>
        </div>
      </S.ModalContainerDiv>
    );

  return (
    <S.ModalContainerDiv>
      <div className="container">
        <div className="card-inputs">
          <h4>{t("outcomes.tableOutcomes.modalNewEvent.addNewEvent")}</h4>
          <br />
          <S.NewEventModal row1={"30%"} row2={"58%"} row1Min={"200px"}>
            <S.FormContainer>
              <Formik
                initialValues={{
                  cmpId: "",
                  description: cmpDescript,
                }}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {(props) => (
                  <>
                    <form onSubmit={props.handleSubmit}>
                      <div className="rows">
                        <div className="row-left">
                          <h5>
                            {t(
                              "globally.typeOfEvent"
                            )}
                          </h5>
                          <div className="input-container">
                            <ZaiaSelect
                              type="select"
                              name="phoneLocation"
                              setValue={handleChangesSelect}
                              value={cmpSelected?.id}
                              items={customFormsList?.map((customForm) => {
                                return {
                                  value: customForm.id,
                                  text: P.dataTranslation(
                                    customForm.customFormName,
                                    language
                                  ),
                                };
                              })}
                              shadow={false}
                              fontSize="14px"
                            />
                          </div>
                          <br />
                          <h5>
                            {t("outcomes.tableOutcomes.modalNewEvent.addNotes")}
                          </h5>
                          <div className="input-container">
                            <ZaiaInputForm
                              placeholder={t(
                                "outcomes.tableOutcomes.modalNewEvent.eventDescript"
                              )}
                              type="text"
                              name="description"
                              padding={"5px"}
                              textarea={true}
                              fontSize={"14px"}
                              errorOption={false}
                            />
                          </div>
                        </div>
                        <div className="row-right">
                          {cmpSelected ? (
                            <GenericTemplate
                              title={P.dataTranslation(
                                cmpSelected.customFormName,
                                language
                              )}
                              dataForm={customForm?.customForm_by_pk.formGroups?.find(
                                (cmp) => cmp.id === cmpSelected.id
                              )}
                              insertfunction={insertFormField}
                              customFormChangesList={customFormChangesList}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="current-state-form-button">
                        <ZaiaButton
                          type="submit"
                          option="primary"
                          marginTop="5"
                          title={t("outcomes.tableOutcomes.modalNewEvent.add")}
                          borderRadius="5"
                          fontSize="10"
                          height="30"
                        />
                      </div>
                    </form>
                  </>
                )}
              </Formik>
              <div>.</div>
            </S.FormContainer>
          </S.NewEventModal>
        </div>
      </div>
    </S.ModalContainerDiv>
  );
}

export default NewEventModal;
