import { gql } from "@apollo/client";

export const SUBSCRIPTION_USER_ALERT_TRACKING = gql`
  query SUBSCRIPTION_USER_ALERT_TRACKING($initialDate: timestamp, $finalDate: timestamp) {
    userAlertTracking(where: {created_at: {_gte: $initialDate, _lte: $finalDate}}) {
      id
      created_at
      alertStatusId
      alertTypeId
      doctorComment
      doctorId
      drugComment
      drugName
      isDrugTaken
      measure
      sideEffectComment
      processId
      sideEffectName
      sideEffectSeverity
      updated_at
      userDrugHistoryId
      userId
      userReportDate
      userSideEffectHistoryId
      userVitalSignHistoryId
      vitalSignComment
      user {
        id
        name
        lastname
        picture
        phone
        phoneCarer
        nameCarer
      }
    }
  }
`
