import styled from "styled-components";

export const TranducerMessagesList = styled.div`
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Message = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: ${({patient}) => 
    patient 
      ? "flex-start"
      : "flex-end"
  };
  .label {
    font-family: Nunito Sans;
    font-size: 16px;
    color: #68688D;
    margin-bottom: 5px;
  }
  .box {
    /* width: ${({transducerType}) => transducerType === 2 ? "cacl(100% - 20px)" : "411px"}; ; */
    border-radius: 14px;
    padding: 10px;
    border-bottom-left-radius: ${({patient}) => patient ? "0px" : "14px"};
    border-bottom-right-radius: ${({patient}) => patient ? "14px" : "0px"};
    background-color: ${({patient, transducerState}) => 
      transducerState
        ? patient ? "#F0F0FF" : "#D2D2FF"
        : patient ? "#F0F0FF" : "#F8F8FF"
      };
  }
`