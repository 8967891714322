import React, { useMemo } from "react";
import * as S from "./MedicationTime.style";
import { useTranslation } from "react-i18next";
import ZaiaReaction from "../../../../../../../zaiaComponents/zaiaReaction";

import * as P from "../../../../../../../../utils/pipes";
import ZaiaSelect from "../../../../../../../zaiaComponents/zaiaSelect";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";

const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;

function MedicationTime({
  selectedStartingHour,
  changeFormField
}) {

  const {t, i18n: { language }} = useTranslation("global");


  return (
    <S.MedicationTime>
      
      <div className="title">{t("healthAddData.medication.medicationTakenTime")}</div>
      <div className="doseage-inputs">
        <div className="input-space input-space--dose">
          <div className="input-label">
            {t("healthAddData.medication.selectTheMedicationTakingTime")}
          </div>
          <ZaiaInput 
            placeholder={t("healthAddData.medication.typeQuantity")}
            type={"time"}
            name="data"
            errorOption={false}
            getText={(e) => {
              if(e) {
                changeFormField(
                [
                  {
                    value: e, 
                    text: e, 
                    fieldName: "time"
                  }
                ]
              )
              } else {
                changeFormField(
                  [
                    {
                      value: null, 
                      text: "", 
                      fieldName: "time"
                    }
                  ]
                )
              }
            }}
            value={selectedStartingHour.value ?? ""}
            padding={"20px"}
            borderRadius={"14px"}
            textcolor={"#6C6BCC"}
            borderColor={"transparent"}
            placeholdertextcolor={"#878792"}
            fontSize="15px"
            />
        </div>
      </div>
    </S.MedicationTime>
  );
}

export default MedicationTime;
