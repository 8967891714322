import React, { useMemo, useState } from "react";
import * as S from "./SymptomSearcher.style";
import { useTranslation } from "react-i18next";
import ZaiaInput from "../../../../../../../zaiaComponents/zaiaInput";
import * as P from "../../../../../../../../utils/pipes";
import { v4 as uuidv4 } from 'uuid';
import { QUERY_GET_TYPE_SIDEEFFECTS } from "../../../../../../../../graphql/queries/SideEffects";
import { useLazyQuery } from "@apollo/client";
import ZaiaReaction from "../../../../../../../zaiaComponents/zaiaReaction";

// Assets
const logoZaiaLoading = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/loader-florence.gif`;
const logoZaiaAlert = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/reaction-error-data.svg`;
const magnifyingGlass = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaFigures/magnifying-glass.svg`;
const noData = `${process.env.REACT_APP_STORAGE_ZAIA_RESOURCES}/zaiaReactions/no-results.svg`;

function SymptomSearcher({
  selectedSymptomsList,
  changeFormField
}) {

  const {t, i18n: { language }} = useTranslation("global");

  const [filterText, setFilterText] = useState("");
  const [ filterSymptoms, { data: symptomsData, error: symptomsError, loading: symptomsLoading }] = useLazyQuery(
    QUERY_GET_TYPE_SIDEEFFECTS
  );

  const addSymptoms = (symptom) => {
    const tempSymptomsList = JSON.parse(JSON.stringify(selectedSymptomsList.value));
    const newSymptom = {
      value: symptom.value,
      text: P.dataTranslation(symptom.comment, language),
      severity: 1,
    }
    tempSymptomsList.push(newSymptom);
    changeFormField(
      [
        {
          value: tempSymptomsList, 
          text: `(${tempSymptomsList.length})`, 
          fieldName: "symptomsList"
        }
      ]
    )
  }
  
  const removeSymptoms = (symptom) => {
    const tempSymptomsList = JSON.parse(JSON.stringify(selectedSymptomsList.value));
    changeFormField(
      [
        {
          value: tempSymptomsList.filter((item) => item.value !== symptom.value), 
          text: `(${tempSymptomsList.length - 1})`, 
          fieldName: "symptomsList"
        }
      ]
    )
  }

  const  handleChangeSymptomsList = (symptom, exist) => {
    if (exist) {
      removeSymptoms(symptom);
    } else {
      addSymptoms(symptom);
    }
  }

  const handlefilterSymptoms = (e) => {
    setFilterText(e);
    if ( e !== "" ) 
      filterSymptoms(
        {
          variables: {
            filterText: `%${e}%`,
          }
        }
      );
  }


  const existSymptom = (symptom) => { 
    return selectedSymptomsList?.value.find((item) => item.value === symptom.value) ? true : false; 
  }


  const getSymptomsList = () => {
    if (symptomsError)
      return (
        <div className="zaia-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaAlert}
            text={"Error loading symptomss"}
            sizeText="20px"
            textcolor="var(--blue-color)"
            widthimg="110px"
          />
        </div>
      )
    
    
    if (symptomsLoading)
      return (
        <div className="zaia-reaction">
          <ZaiaReaction
            zaiaImg={logoZaiaLoading}
            sizeText="20px"
            textcolor="var(--blue-color)"
            widthimg="110px"
          />
        </div>
      )

    if(filterText === "" && !symptomsData)
      return (
        <div className="search-symptoms">
          <img src={magnifyingGlass} alt="" className="search-symptoms__img" />
          <div className="search-symptoms__text">
            {t("healthAddData.symptoms.typeSomething")}
          </div>
        </div>
      )

    if(symptomsData?.typeSideEffect.length === 0)
      return (
        <div className="empty-symptoms">
          <img src={noData} alt="" className="empty-symptoms__img" />
          <div className="empty-symptoms__title">
            {t("globally.noResults")}
          </div>
          <div className="empty-symptoms__subtitle">
            {t("healthAddData.symptoms.noPatientsFound")}
          </div>
        </div>
      )

    return(
      symptomsData?.typeSideEffect?.map((symptom) => {
        const exist = existSymptom(symptom);
        return (
          <div 
            key={uuidv4()}
            className={`symptom-box${exist ? " symptom-box--selected" : ""}`}
            onClick={() => handleChangeSymptomsList(symptom, exist)}
          >
            {
              exist
              ? <i class="symptom-box__icon icon zaia-i-checkbox-on"></i>
              : <i class="symptom-box__icon icon zaia-i-checkbox-off"></i>
            }
            <div 
              className={`symptoms-box__name`}
            >{P.dataTranslation(symptom.comment, language)}</div>
          </div>
        )
      })
    )
  }

  return (
    <S.SymptomSearcher
      searcher={filterText}
    >
      <div className="input-container">
        <ZaiaInput
          textcolor={"#59599E"}
          borderColor={"#68688D"}
          placeholdertextcolor={"#878792"}
          fontSize={"15px"}
          placeholder={t("healthAddData.symptoms.searchSymptom")}
          type="text"
          name="description"
          padding={"9px 20px"}
          borderRadius={"10px"}
          errorOption={false}
          height={"100%"}
          getText={(e) => handlefilterSymptoms(e)}
          backgroundColor={"transparent"}
          value={filterText}
        />
      </div>
      <div className="input-msg">
        {t("healthAddData.symptoms.severalSymptoms")}
      </div>
      <div className="symptoms-list">
        <div className={`symptoms-list__results ${filterText === "" ?  "symptoms-list__results--hidden" : ""}`}>{t("globally.resultsFor")} "{filterText}"</div>
        <div className="symptoms-list__container">
          {  
            getSymptomsList()
          }
        </div>
      </div>
      
    </S.SymptomSearcher>
  );
}

export default SymptomSearcher;
