import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalSelectPatient = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#090949${getHexOpacity(0.50)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-container {
    width: 100%;
    max-height: 90%;
    z-index: 200;
    background-color: var(--white);
    max-width: 783px;
    padding: 20px 40.75px;
    margin: auto;
    box-sizing: border-box;
    border-radius: 14px;
    justify-content: center;
    position: relative;
    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__title {
        color: #59599E;
        font-family: "Nunito Sans Bold";
        font-size: 30.309px;
      }
      &__close {
        display: flex;
        padding: 11.864px;
        background-color: #36369B;
        border-radius: 50%;
        font-size: 10px;
        background: #EFEFFF;
        cursor: pointer;
        color: #36369B;
      }
    }
    .filter-patient {
      height: 47px;
      background-color: #E0E6ED;
      padding: 10px 20px;
      align-items: center;
      box-sizing: border-box;
      max-width: 503px; 
      border-radius: 10px;
      display: flex;
      margin-top: 10px;
      cursor: text;
      &__input {
        background-color: transparent;
        font-family: "Nunito Sans";
        border: none;
        outline: none;
        font-size: 20px;
        color: #59599E;
        width: 100%;
        ::placeholder {
          color: #59599E;
          font-family: "Nunito Sans";
        }
      }
      &__icon {
        color: #59599E !important;
        font-size: 20px;
      }
    }
    .users-list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 20px;
      overflow-y: scroll;
      height: 430px;
      align-items: flex-start;
      align-content: flex-start;
      ::-webkit-scrollbar {
        width: 25px;
      }
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 10px 10px transparent;
        /* margin: 1px 0px;  */
      }
      ::-webkit-scrollbar-thumb {
        border: 10px solid rgba(0, 0, 0, 0);
        box-shadow: inset 0 0 10px 10px #3E3DA366;
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: transparent;
      }
      &__responses {
        height: 100%;
        text-align: center;
        margin: auto;
        padding-left: 28px;
        display: flex;
      }
      .user-item {
        width: 270px;
        display: flex;
        padding: 8.5px 20px;
        border-radius: 12px;
        margin-bottom: 20px;
        background: #FCFCFE;
        align-items: center;
        color: #1F1F52;
        cursor: pointer;
        :nth-child(odd) {
          margin-right: 10px;
        }
        &--selected {
          color: #FCFCFE;
          background-color: #6C6BCC;
        }
        &__info {
          margin-left: 10px;
        }
        &__name {
          font-size: 17px;
          font-family: "Nunito Sans";
        }
        &__program {
          color: #68688D;
          font-family: "Nunito Sans";
          font-size: 12px;
          &--selected {
            color: #FCFCFE;
          }
        }
      }
    }
  }
`;