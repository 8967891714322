import styled from "styled-components";
import { getHexOpacity } from "../../../../../../utils/pipes";

export const ChatbotAlgorithmsCase = styled.div` 
  height: 100%;
  .chatbot-container {
    height: ${({footerHeight}) => `calc(100% - ${footerHeight}px)` };
  }
`;

export const ProgramBoxes = styled.div`
  font-family: "Nunito ExtraBold";
  margin: auto;
  max-width: 100%;
  min-width: 60%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  .box-searcher {
    display: flex;
    .searcher-container {
      display: flex;
      justify-content: center;
      width: 80%;
      margin: 20px auto 0;
      .searcher-content {
        width: 100%;
        padding: 10px;
        border-radius: 74px;
        background: #F4F4FA;
        box-sizing: border-box;
        cursor: text;
        z-index: 100;
        box-shadow: 0px 6px 10.2px -3px #27267724 inset;
        display: flex;
        align-items: center;
        &__icon {
          color: #36369B;
          font-size: 21px;
        }
      }
      .searcher-input {
        display: flex;
        justify-content: space-between;
        height: 100%;
        &__input {
          margin: 0 5px;
          border: none;
          outline: none;
          width: 100%;
          background-color: transparent;
          font-family: "Nunito Sans";	
          font-size: 19px;
          color: #36369B;
          height: 100%;
          ::placeholder {
            color: #707582;
          }
        }
        &__icon {
          color: #36369B;
          font-size: 28px;
        }
      }
    }
  }
  .reaction-content {
    margin: 40px 0 20px;
  }
  .algorithms__containerBoxes {
    /* background-color: var(--blue-transparent-200); */
    display: flex;
    align-items: center;
    overflow-y: auto;
    max-height: calc(100% - 220px);
    overflow: auto;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 46px;
    ::-webkit-scrollbar {
      position: sticky;
    }
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 10px 10px transparent;
      margin: 30px 30px; 
    }
    .box-container {
      width: 100%;
      box-sizing: border-box;
      margin: auto;
    }
    .fade {
      animation: fadeOut 1s forwards;
    }
  }

  @media only screen and (max-width: 375px) {
    .algorithms__containerBoxes {
      margin: 0px 30px 20px;
    }
  }
`

export const AlgorithmsDiv = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    var(--title-register-color) 50%,
    var(--blue-color) 50%
  );
  display: flex;
  flex-direction: column;
  .algorithms__container {
    display: flex;
    position: relative;
    justify-content: center;
    height: 100%;
    transition: 0.2s;
    .patient-list {
      width: auto;
    }
    .patient-info-content {
      background-color: var(--background-patient);
      width: 100%;
      overflow: hidden;
    }
  }
`;

export const BoxDiv = styled.div`
  background-color: var(--title-register-color);
  cursor: pointer;
  height: 19px;
  width: 100%;
  max-width: 500px;
  padding: 32px 20px;
  border-radius: 20px;
  border: 2px solid var(--white);
  box-shadow: 0px 4px 33.8px 0px #6E6DDF7A;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto 20px;

  .box__iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--blue-color);
    color: white;
    border-radius: 15px;
    margin: 20px;
    width: 40px;
    height: 40px;
    i {
      font-size: 23px;
    }
  }
  h5 {
    color: var(--white);
    text-align: center;
    word-break: break-word;
    font-family: "Nunito";
  }
`;



