import React from "react";
import { useTranslation } from "react-i18next";
import * as S from "./NoPatients.style";

// Components
import ZaiaButton from "../../../zaiaComponents/zaiaButton";
import ZaiaReaction from "../../../zaiaComponents/zaiaReaction";
import InvitePatientForm from "../../patients/components/invitePatientForm";
import InvitePatientButton from "../../patients/components/invitePatientButton";

// Hooks
import useActivateModal from "../../../../hooks/useActivateModal"

function NoPatients({title, img}) {
  const [t] = useTranslation("global");
  const {activeModal, desactiveModal} = useActivateModal()

  const modalPatient = () => {
    return (
      <>
        <InvitePatientForm />
        <div onClick={() => desactiveModal()}>
          <InvitePatientButton activate={true} />
        </div>
      </>
    )
  } 

  return (
    <S.noPatients>
      <ZaiaReaction
        zaiaImg={img}
        widthimg="200px"
        text={title}
        sizeText="17px"
        widthText="200px"
      />
      <div className="message">{t("dashboard.boxes.addPatientMsg")}</div>
      <div onClick={() => activeModal(() => modalPatient())}>
      {/* <div onClick={() => desactiveModal()}> */}
        <ZaiaButton 
          title={t("patients.invite")}
        />
      </div>
    </S.noPatients>
  )
}

export default NoPatients;