import styled from "styled-components";
import { getHexOpacity } from "../../../../../utils/pipes";

export const ModalResultsAnalysis = styled.div`
  display: ${({hidden}) =>  hidden ? 'none' : 'flex' };
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
  .modal-background {
    background-color: ${`#36369B${getHexOpacity(0.30)}`};
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 100;
  }
  .modal-reaction {
    padding: 40px 40px;
    margin: auto;
  }
  .modal-container {
    background-color: white;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    z-index: 200;
    height: 90%;
    margin-top: auto;
    .header {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;
      border-bottom: 1px solid var(--Grey-button-unselect, #E0E6ED);
      color:  #3E3E5B;
      font-family: Nunito Sans;
      align-items: center;
      &__title {
        font-size: 25px;
        font-family: Nunito Sans Bold;
      }
      &__survey {
        font-size: 16px;
      }
      &__close {
        background: #36369B;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        color: #FCFCFE;
        font-size: 9px;
        align-items: center;
        cursor: pointer;  
      }
    }
    .modal-content {
      display: flex;
      padding: 15px 20px;
      height: calc(100% - 107px);
      width: 100%;
      box-sizing: border-box;
      &__left {
        border: 1px solid #6C6BCC4D;
        border-radius: 10px;
        padding: 20px 28px;
        overflow: auto;
        flex-grow: 1; /* Ocupa el espacio restante */
        flex-shrink: 1;
      }
      &__right { 
        background-color: #F2F2FE;
        border-radius: 10px;
        padding: 20px 10px;
        margin-left: 20px;
        overflow: auto;
        flex-grow: 0; /* No crece más de lo necesario */
        flex-shrink: 0;
      } 
      .patient-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        &__left {
          display: flex;
          align-items: center;
        }
        &__image {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-right: 10px;
          border: 0.62px solid var(--White, #F6F6FF);
        }
      }
      .dinamic-content {
        display: flex;
        flex-direction: column;
        img {
          width: auto;  /* Mantiene el tamaño original */
          height: auto; /* Mantiene la proporción */
          max-width: 100%; /* Evita que sea más grande que el contenedor */
          align-self: flex-start; /* Evita que crezca automáticamente */
          margin-bottom: 20px;
        }
      }
    }
  }
`;